import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getEvents } from '../../../services/evenements/eventAPI';
import Cookies from 'js-cookie';

export default function Events() {
    // LISTE DES Events
    const [ListeEvents, setListeEvents] = useState([])
    //------- INIT LANGAGE 
    const language = Cookies.get('locale')
    //------------- Traduction -------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getEvents().then((result) => {
            if (result?.data?.code === 200) {
                setListeEvents((result?.data?.data).slice(0, 4))
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])
    return (
        <div className="row g-3 mt-3">
            {/* Single Blog Card */}
            {ListeEvents?.length !== 0 ? ListeEvents?.map((items, keys) =>
                <div className="col-12 col-sm-6 col-md-6" key={keys}>
                    <div className="card position-relative shadow-sm float-right">
                        <img className="card-img-top " style={{ height: "150px !important" }} src={items.image !== null ? process.env.REACT_APP_IMAGE + items.image : "img/bg-img/23.jpg"} alt="news_images" />
                        <div className="card-body">
                            <span className="badge bg-purple rounded-pill mb-2 d-inline-block fz-12">{moment(items.date).format("DD MMMM YYYY ")}</span>
                            <h6 className='fz-14'>{language==="fr" ? items.titre : items.titre_en} </h6>
                            <a className="text-purple float-end  fz-12" href={"/details_events&id=" + items.id}>{t("Lire la suite")}  <svg xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-arrow-right-circle-fill m-2" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                            </svg>
                            </a>
                        </div>
                    </div>
                </div>
            ) : <div className="col-12 col-sm-12 col-md-12" >
                <div className=" text-center">
                    <img className="text-center mb-3" width={50} src="../img/bg-img/nodata.png" alt="news_images" />
                    <h6 className='text-purple'>{t("Aucun évenement pour le moment")} </h6>
                </div>
            </div>
            }
        </div>
    )
}
