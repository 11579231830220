/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from '../../Auth/useUser'
// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";
import Loader from '../../components/loading/Loader';
import _ from 'lodash'
import DevisVoyage from './components/voyage/DevisVoyage';
import DevisEducation from './components/education/DevisEducation';
import DevisVehicule from './components/vehicule/DevisVehicule';
import DevisRetraite from './components/retraite/DevisRetraite';
import DevisAccidents from './components/accidents/DevisAccidents';
import Deces from './components/deces/Deces';
import Cookies from 'js-cookie';


export default function DevisProduit() {

    // Parametre id = id categorie
    const { id, type } = useParams("")
     //------- INIT LANGAGE 
     const language = Cookies.get('locale')
    // ------------ LOADER ------------------
    const [Loading, setLoading] = useState(false)
    //------------- Traduction ---------------
    const [t] = useTranslation("global");

    return (
        <div className='row'>
            <div className='col-12'>
            {Loading ? <Loader /> :
                <div className='container'>
                    {type === "vehicule" && (
                        <DevisVehicule t={t} categorie_id={id}   />
                    )}
                    {type === "voyage" && (
                        <DevisVoyage t={t}  categorie_id={id}/>
                    )}
                    {type === "education" && (
                        <DevisEducation t={t} categorie_id={id}/>
                    )}
                    {type === "retraite" && (
                        <DevisRetraite t={t} categorie_id={id}/>
                    )}
                    {type === "accident" && (
                        <DevisAccidents categorie_id={id} t={t}/>
                    )}
                    {type === "deces" && (
                        <Deces  t={t} categorie_id={id}/>
                    )}
                    
                </div>}
            </div>
         
        </div>

    )
}
