/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom'
import Loader from '../../../components/loading/Loader';
import { getAdresse, update_adrresse } from '../../../services/adresses/AdresseAPI';
import { pays } from '../../../utils/ListePays';

// # TOAST AND ALERT ELEMENTS
import { toast } from "react-toastify";
import { useUser } from '../../../Auth/useUser';
import Icon from '../../../components/obligatoire/Icon';

export default function EditAdresse() {

    const user = useUser()
    //------------- PARAMETRE -----------
    const { id } = useParams("")
    // # History CALL
    const history = useHistory();
    //------------- FORMULAIRE ----------
    const [listePays, setlistePays] = useState(pays)
    const [country, setcountry] = useState("")
    const [rue, setrue] = useState("")
    const [codePostal, setcodePostal] = useState("")
    const [region, setregion] = useState("")
    const [cite, setcite] = useState("")
    // -------------- Loading -----------
    const [loading, setLoading] = useState(true);
    //------------- Traduction ---------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getAdresse(id).then((result) => {
            const informations = result?.data; //results data
            if (informations?.code === 200) {
                var data = informations?.data
                // SET FORM VALUES
                setrue(data?.ligne)
                setregion(data?.region)
                setcodePostal(data?.code_postal)
                setcountry(data?.pays)
                setcite(data?.cite)
            }
        })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [id])

    const updateAdresse = () => {
        // VERIF IF ANY FORM IS EMPTY
        if ( region !== "" && pays !== "") {
            const data = {
                id: id,
                pays: country,
                ligne: rue,
                region: region,
                cite: cite,
                code_postal: codePostal
            }
            update_adrresse(data)
                .then((result) => {
                    if (result?.data?.code === 500) {
                        toast(t("Erreur Temporaire !"), {
                            type: "error",
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else if (result?.data?.code === 200) {
                        toast(t("Modifié avec succès"), {
                            type: "success",
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        history.push({
                            pathname: "/adresses"
                        })
                    }
                    console.log(result);

                }).catch((err) => {
                    console.log(err)
                });
        } else {
            toast(t("Veuillez remplir correctement les champs du formulaire"), { type: "info", position: "top-right" })
        }
    }

    return (
        <div className='container'>
            {loading ? <Loader /> :
                <div >
                    <h1 className="mb-3 text-center"> {t("Modifier votre adresse")}</h1>
                    <div >
                        <div className='card-body'>
                            <form >
                                <div className="form-group">
                                    <label class="form-label">{t("Pays")}  <Icon /></label>
                                    <select class="form-select" id="defaultSelect" name="defaultSelect" required
                                        value={country} onChange={(e) => setcountry(e.target.value)}>
                                        <option value={""}> {t("Choisir un pays")}</option>
                                        {listePays?.map((items, keys) => (
                                            <option value={items.name} key={keys}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" >{t("Région")} <Icon /></label>
                                    <input className="form-control" placeholder="Choisir une région" type="text" required value={region} onChange={(e) => setregion(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" >{t("N° Villa")} </label>
                                    <input className="form-control" placeholder="Choisir la rue" type="text" value={rue} onChange={(e) => setrue(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" >{t("Quartier")} </label>
                                    <input className="form-control" placeholder="Choisir la cité" type="text" value={cite} onChange={(e) => setcite(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" >{t("Code Postal")} </label>
                                    <input className="form-control" placeholder="Choisir un code postal" type="number" value={codePostal} onChange={(e) => setcodePostal(e.target.value)} />
                                </div>

                                <button className='btn btn-purple col-12 ' type='button' onClick={() => updateAdresse()}>{t("Modifier l'adresse")}</button>
                            </form>
                        </div>
                    </div>
                </div>}
        </div>
    )
}
