
// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"

//GET URL FROM .ENV ELEMENT
import { getUrl } from "../authentification/DotenvConfig";

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
const url = getUrl()


/**
 * SERVICE AUTHENTIFICATION WITH E-MAIL AND PASSWORD
 * @param {*} data  
 * @returns 
 */
export async function login_email (data){
    try{
        const response = await axios.post(url+"users/login-email",data,{
            IsEssential: true
          });
          return response;
    }catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
         return(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
      }
}  
/**
 * SERVICE AUTHENTIFICATION WITH E-MAIL AND PASSWORD
 * @param {*} data  
 * @returns 
 */
export async function login_email_code_PIN (data){
  try{
      const response = await axios.post(url+"users/login-email-code-pin",data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
       return(error.response);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
} 
/**
 * SERVICE AUTHENTIFICATION WITH E-MAIL AND PASSWORD
 * @param {*} data  
 * @returns 
 */
export async function login_mobile_code_PIN (data){
  try{
      const response = await axios.post(url+"users/login-mobile-code-pin",data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
       return(error.response);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
} 
/**
 * Login with google
 * @param {*} data 
 * @returns 
 */
export async function login_google (){
  try{
    const response = await axios.get(
      url+ "auth/google/url"
    );
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
       return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  

/**
 * SERVICE AUTHENTIFICATION WITH PHONE AND PASSWORD
 * @param {*} data  
 * @returns 
 */
 export async function login_phone (data){
  try{
      const response = await axios.post(url+"users/login-mobile",data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
       return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  

/**
 * SEND VERIFICATION EMAIL BY CODE 
 * @param {*} code  //code_verification_email
 * @returns 
 */
 export async function send_verification_email (code ,data){
  try{
      const response = await axios.post(url+"users/send-email/"+code,data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  

/**
 * FORGOT PASSWORD
 * @param {*} email 
 * @returns 
 */
 export async function forgot_password (email){
  try{
      const response = await axios.get(url+"users/forgot-password/"+email,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  
/**
 * Change Password
 * @param {*} code : Validation Code
 * @returns 
 */
 export async function change_password (code,data){
  try{
      const response = await axios.put(url+"users/"+code, data , {
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
} 
export async function forget_password (code,data){
  try{
      const response = await axios.put(url+"users/change-password/"+code, data , {
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
} 
/**
 * SEND SMS VERIFICATION
 * @param {*} data  
 *  {
  "mobile": "string"
    }
 * @returns 
 */
 export async function send_sms (data){
  try{
      const response = await axios.post(url+"users/send-sms",data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  