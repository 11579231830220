import React from "react";
import Assure from "./Assure";
import CalculPrime from "./CalculPrime";
import InformationContrat from "./InformationContrat";
import Souscripteur from "./Souscripteur";

export default function ResumCard({ category_id, groupedAssuree, t, data, groupedAdresses, type, setLISTE_ASSUREE, LISTE_ASSUREE ,cout_police, prime_nette, paramTech}) {
  /**
   * ADRESSE SOUSCRIPTEUR
   */
  const { cite, ligne, code_postal, pays, region } = groupedAdresses[data?.adresse_id][0];
  return (
    <div>
      <Souscripteur
        t={t}
        data={data}
        cite={cite}
        ligne={ligne}
        region={region}
        code_postal={code_postal}
        pays={pays}
        type={type}
        LISTE_ASSUREE={LISTE_ASSUREE}
      />
      <Assure t={t} data={data} LISTE_ASSUREE={LISTE_ASSUREE} type={type} groupedAssuree={groupedAssuree} />
      <InformationContrat
        t={t}
        data={data}
        type={type}
        LISTE_ASSUREE={LISTE_ASSUREE}
      />
      <CalculPrime 
        t={t}
        data={data}
        type={type}
        LISTE_ASSUREE={LISTE_ASSUREE}
        setLISTE_ASSUREE={setLISTE_ASSUREE}
        groupedAssuree={groupedAssuree} 
        category_id={category_id} 
        cout_police={cout_police}
        prime_nette={prime_nette}
        paramTech={paramTech}
        />
     
    </div>
  );
}
