/* eslint-disable no-unused-vars */
/* Import modules */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// # TOAST AND ALERT ELEMENTS
import { toast } from "react-toastify";

import { useTranslation } from 'react-i18next';
// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";
import { pays } from "../../../utils/ListePays";
import { useUser } from "../../../Auth/useUser";
import { add_adresse } from "../../../services/adresses/AdresseAPI";
import DotLoader from "../../../components/loading/DotLoader";
import Icon from "../../../components/obligatoire/Icon";
import Cookies from "js-cookie";

export default function AjouterAdresse() {

  const user = useUser()
  // # History CALL
  const history = useHistory();

  const [listePays, setlistePays] = useState(pays)
  //------------- Traduction ---------------
  const [t] = useTranslation("global");

  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);
  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit } = useForm();
  const language = Cookies.get("locale");

  /**
   * Handle Registration  : OnSubmit Form function
   * @param {*} data
   */
  const handleRegistration = (data) => {
    var user_info = user?.result
    setLoading(true)
    Object.assign(data, {
      user_id: user_info?.id
    })
    add_adresse(data).then((result) => {
      const informations = result?.data; //results data
      if (informations?.code === 500) {
        toast(("Erreur Temporaire !"), {
          type: "error",
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (informations?.code === 200) {
        toast(("Adresse ajouté avec succès !"), {
          type: "success",
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push({
          pathname: "/adresses"
        })
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  };

  return (
    <>

      <div className='container'>

        {/* Register Form */}
        <h1 className="mb-3 text-center"> {t("Ajouter une adresse")}</h1>

        <div>
          <div className="card-body">
            <form onSubmit={handleSubmit(handleRegistration)}>
              <div className="form-group">
                <label class="form-label">{t("Pays")} <Icon /></label>
                <select class="form-select" id="defaultSelect" name="defaultSelect" required {...register("pays")}>
                  <option value={""}> {t("Choisir un pays")}</option>
              
                  {listePays?.map((items, keys) => (
            <option key={keys} value={items.name}>
               {language === "fr" ? items.nameFr : items.name}
            </option>
          ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label" >{t("Région")} <Icon /></label>
                <input className="form-control" placeholder="Choisir une région" type="text" required  {...register("region")} />
              </div>
              <div className="form-group">
                <label className="form-label" >{t("N° Villa")} </label>
                <input className="form-control" placeholder="Choisir la rue" type="text"   {...register("ligne")} />
              </div>
              <div className="form-group">
                <label className="form-label" >{t("Quartier")} </label>
                <input className="form-control" placeholder="Choisir la cité" type="text"   {...register("cite")} />
              </div>
              <div className="form-group">
                <label className="form-label" >{t("Code Postal")} </label>
                <input className="form-control" placeholder="Choisir un code postal" type="number"  {...register("code_postal")} />
              </div>
              {loading ?
                <button className="btn btn-purple w-100 d-flex align-items-center justify-content-center" disabled type="submit"><DotLoader /> </button> :
                <button className="btn btn-purple w-100 d-flex align-items-center justify-content-center" type="submit">{t("Ajouter")}</button>}
            </form>
          </div>
        </div>

      </div>
    </>

  )
}
