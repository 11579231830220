/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next'

export default function Footer({ isDark }) {
  // --------------- TRADUCTION --------------
  const [t, i18n] = useTranslation("global")
  return (
    <div className="footer-nav-area" id="footerNav">
      <div className="container px-0">
        {/* =================================== */}
        {/* Footer Content */}
        <div className="footer-nav position-relative">
          <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
            <li>
              <a href="/choisir_contrats">
                <svg xmlns="http://www.w3.org/2000/svg" fill={isDark ? "#fff" : "#1f0757"} id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={20} height={20}><path d="M14,13c0-1.1-.9-2-2-2s-2,.9-2,2c0,.81,.58,2.03,1.42,3.03-.62,.56-1.42,.97-2.42,.97-.8,0-1.58-.39-2.28-.99,1.39-1.6,2.28-3.76,2.28-5.01,0-1.65-1.35-3-3-3s-3,1.35-3,3c0,1.23,.87,3.43,2.3,5.03-.58,.5-1.22,.86-1.88,.97-.27,.04-.46,.3-.41,.57,.04,.25,.25,.42,.49,.42,.03,0,.05,0,.08,0,.88-.14,1.7-.61,2.42-1.25,.87,.76,1.88,1.26,3,1.26,3.3,0,5-3.5,5-5Zm-10-2c0-1.1,.9-2,2-2s2,.9,2,2-.8,2.93-1.98,4.31c-1.2-1.36-2.02-3.22-2.02-4.31Zm7,2c0-.55,.45-1,1-1s1,.45,1,1c0,.45-.29,1.41-.91,2.27-.68-.85-1.09-1.8-1.09-2.27Zm12.27-1.27c-.94-.94-2.59-.94-3.54,0l-7.71,7.71c-.66,.66-1.03,1.54-1.03,2.47v1.59c0,.28,.22,.5,.5,.5h1.59c.94,0,1.81-.36,2.47-1.03l7.71-7.71c.47-.47,.73-1.1,.73-1.77s-.26-1.3-.73-1.77Zm-.71,2.83l-7.71,7.71c-.47,.47-1.1,.73-1.77,.73h-1.09v-1.09c0-.67,.26-1.3,.73-1.77l7.71-7.71c.57-.57,1.55-.57,2.12,0,.28,.28,.44,.66,.44,1.06s-.16,.78-.44,1.06Zm-14.06,6.44H4.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h6.5v3.5c0,1.38,1.12,2.5,2.5,2.5h3.5v3.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-3.67c.01-.98-.35-1.89-1.01-2.56l-3.24-3.24c-.66-.66-1.54-1.03-2.47-1.03H4.5C2.02,0,0,2.02,0,4.5v13c0,2.48,2.02,4.5,4.5,4.5h4c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5ZM16.28,4.97c.29,.29,.49,.64,.61,1.03h-3.39c-.83,0-1.5-.67-1.5-1.5V1.11c.39,.12,.74,.33,1.04,.62l3.24,3.24Z" /></svg>
                <span>{t("Gestion Contrat")}</span>
              </a>
            </li>
            <li>
              <a href="/nos_produits">
                <svg xmlns="http://www.w3.org/2000/svg" fill={isDark ? "#fff" : "#1f0757"} id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={20} height={20}><path d="M23.634,6.3l-1.466-2.435c-.304-.509-.719-1.205-1.33-1.398L13.957,.29c-1.283-.406-2.639-.406-3.922,0L3.154,2.468c-.58,.184-1.073,.572-1.404,1.124L.381,6.262c-.413,.685-.491,1.508-.214,2.258,.276,.75,.87,1.326,1.629,1.578l.21,.07-.006,7.604c-.001,1.941,1.235,3.659,3.077,4.272l4.862,1.621c.663,.221,1.359,.332,2.056,.332s1.393-.11,2.056-.332l4.866-1.622c1.842-.614,3.082-2.328,3.083-4.266v-7.614l.238-.079c.749-.25,1.334-.817,1.607-1.557,.273-.74,.196-1.552-.211-2.228ZM1.105,8.174c-.171-.464-.123-.973,.149-1.426l1.369-2.671c.066-.11,.146-.21,.237-.298l8.408,2.812-1.974,3.639c-.387,.645-1.156,.929-1.868,.691l-5.313-1.771c-.469-.157-.836-.512-1.007-.976Zm10.383,14.8c-.418-.038-.833-.124-1.233-.257l-4.862-1.621c-1.433-.478-2.395-1.813-2.394-3.323l.005-7.272,4.103,1.368c1.164,.388,2.413-.075,3.053-1.144l1.333-2.457-.007,14.705ZM4.219,3.179l6.118-1.936c1.085-.343,2.233-.343,3.319,0l6.135,1.942-7.786,2.598L4.219,3.179Zm16.781,14.598c-.001,1.507-.965,2.84-2.399,3.318l-4.866,1.622c-.404,.135-.823,.221-1.246,.258l.007-14.712,1.346,2.481c.48,.801,1.323,1.26,2.212,1.26,.275,0,.555-.044,.83-.136l4.117-1.372v7.28Zm1.907-9.596c-.167,.454-.526,.802-.985,.955l-5.355,1.785c-.714,.235-1.481-.046-1.857-.672l-1.982-3.654,8.241-2.75c.135,.188,.259,.396,.341,.533l1.468,2.438c.25,.415,.297,.912,.129,1.366Z" /></svg>

                <span>{t("Nos produits")}</span>
              </a>
            </li>
            <li>
              <a href="/">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" fill={isDark ? "#fff" : "#1f0757"} data-name="Layer 1" viewBox="0 0 24 24" width={20} height={20}><path d="M22.017,5.831l-1.017-.686V1.5c0-.276-.224-.5-.5-.5s-.5,.224-.5,.5v2.97L14.517,.77c-1.529-1.032-3.506-1.031-5.033,0L1.983,5.831c-1.242,.837-1.983,2.232-1.983,3.73v9.939c0,2.481,2.019,4.5,4.5,4.5h3c.276,0,.5-.224,.5-.5V14.5c0-.827,.673-1.5,1.5-1.5h5c.827,0,1.5,.673,1.5,1.5v9c0,.276,.224,.5,.5,.5h3c2.481,0,4.5-2.019,4.5-4.5V9.561c0-1.499-.741-2.893-1.983-3.73Zm.983,13.669c0,1.93-1.57,3.5-3.5,3.5h-2.5V14.5c0-1.378-1.121-2.5-2.5-2.5h-5c-1.379,0-2.5,1.122-2.5,2.5v8.5h-2.5c-1.93,0-3.5-1.57-3.5-3.5V9.561c0-1.166,.576-2.25,1.542-2.901L10.042,1.599c1.189-.803,2.727-.803,3.916,0l7.5,5.061c.966,.651,1.542,1.736,1.542,2.901v9.939Z" /></svg>

                <span>{t("Accueil")}</span>
              </a>
            </li>
            <li>
              <a href="/contacts">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" fill={isDark ? "#fff" : "#1f0757"} data-name="Layer 1" viewBox="0 0 24 24" width={20} height={20}><path d="M8.535,14.464c-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.95,1.95-1.95,5.122,.013,7.084l1.988,1.847c.432,.402,.986,.602,1.54,.602s1.112-.202,1.545-.606l1.985-1.855c1.95-1.95,1.95-5.122,0-7.071Zm-.695,6.353l-1.973,1.843c-.483,.451-1.238,.451-1.721,.002l-1.975-1.834c-1.56-1.56-1.56-4.098,0-5.657,.756-.755,1.76-1.171,2.829-1.171s2.073,.416,2.828,1.171c1.56,1.56,1.56,4.098,.012,5.646Zm9.624-10.423c.433,.402,.987,.603,1.541,.603s1.113-.203,1.546-.607l1.984-1.854c1.95-1.95,1.95-5.122,0-7.071-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.95,1.95-1.95,5.122,.013,7.084l1.986,1.845Zm-1.292-8.222c.756-.755,1.76-1.171,2.829-1.171s2.073,.416,2.828,1.171c1.56,1.56,1.56,4.098,.012,5.646l-1.972,1.842c-.482,.451-1.239,.452-1.724,.002l-1.973-1.833c-1.56-1.56-1.56-4.098,0-5.657Zm7.829,18.329c0,1.93-1.57,3.5-3.5,3.5H9.5c-.276,0-.5-.224-.5-.5s.224-.5,.5-.5h11c1.378,0,2.5-1.122,2.5-2.5s-1.122-2.5-2.5-2.5h-6c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5c.276,0,.5,.224,.5,.5s-.224,.5-.5,.5c-1.378,0-2.5,1.122-2.5,2.5s1.122,2.5,2.5,2.5h6c1.93,0,3.5,1.57,3.5,3.5Z" /></svg>
                <span>{t("Contacts Utiles")}</span>
              </a>
            </li>
            <li>
              <a href="/chat_support">
                {/* <i className="bi bi-chat-right-text fz-18" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" fill={isDark ? "#fff" : "#1f0757"} data-name="Layer 1" viewBox="0 0 24 24" width={20} height={20}><path d="m23.949,9.603c0-1.637-1.318-2.971-2.949-2.999V.5c0-.262-.202-.479-.463-.499-.258-.018-.492.166-.532.425-.482,3.226-4.746,3.574-6.557,3.574h-7.949C2.467,4,0,6.467,0,9.5c0,1.651.738,3.134,1.904,4.143l3.247,8.713c.229.627.688,1.128,1.293,1.41.336.156.694.235,1.054.235.289,0,.579-.051.858-.153,1.295-.472,1.964-1.91,1.493-3.204l-2.046-5.644h5.698c2.207,0,5.987.465,6.507,3.582.041.243.25.418.493.418.014,0,.028,0,.042-.001.259-.021.459-.238.459-.499v-5.899c1.631-.028,2.949-1.362,2.949-2.999Zm-22.949-.103c0-2.481,2.019-4.5,4.5-4.5h2.5v9h-2.449c-2.51,0-4.551-2.019-4.551-4.5Zm7.909,11.486c.137.376.12.784-.049,1.146-.169.363-.47.639-.846.776-.779.284-1.64-.119-1.924-.899l-2.788-7.482c.688.303,1.449.473,2.25.473h1.187l2.17,5.986Zm11.091-4.737c-1.229-1.451-3.478-2.249-6.5-2.249h-4.5V5h4.449c3.057,0,5.321-.788,6.551-2.223v13.472Zm1-4.648v-3.997c1.079.027,1.949.913,1.949,1.999s-.869,1.971-1.949,1.999Z" /></svg>
                <span>{t("Support")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
