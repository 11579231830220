/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { useUser } from "../../../../Auth/useUser";
import DotLoader from "../../../../components/loading/DotLoader";
import { getUser } from "../../../../services/users/usersAPI";
import {
  geneateTransactionId,
  generateNFacture,
  generatePolicyCode,
  generatePolicyCodeByType,
} from "../../../../utils/FileExtension";
import ModalSaisieCode from "../../../securite/ModalSaisieCode";
import MethodePaiement from "./MethodePaiement";
import Countdown from "react-countdown";
import {
  addAccidentFunction,
  addAutoFunction,
  addDecesFunction,
  addEducationFunction,
  addRetraiteFunction,
  addVoyageFunction,
} from "./ProductFunctions";
import moment from "moment";
import {
  paiementPaytech,
  verifPaiement,
} from "../../../../services/paytech/paytechAPI";
import {
  error_message,
  success_message,
} from "../../../../components/alerte/AlerteMessage";
import { types } from "./Type_Paiement_array";
import { UpdateFacture, addFacture } from "../../../../services/factures/FactureApi";
import { comparaisonBetweenTwoDates } from "../../../../utils/Comparaison";
import MethodePaiementCotisation from "./MethodePaiementCotisation";
import { toast } from "react-toastify";

export default function Paiement_specifique({
  id,
  data,
  LISTE_ASSUREE,
  groupedAdresses,
  register,
  handleSubmit,
  type,
  watch,
  t,
  unpaidFactures,
  totalCotisation
}) {
console.log("+++++++++++++");
console.log("+++++++++++++");
console.log("+++++++++++++");
console.log("+++++++++++++", id,
data,
LISTE_ASSUREE,
groupedAdresses,
register,
handleSubmit,
type,
watch,
t,
unpaidFactures,
totalCotisation);
  const user = useUser();
  const history = useHistory("");
  // var { mobile } = user?.result !== undefined ? user?.result : "";
  const [done, setdone] = useState(false);
  const [statut, setStatut] = useState("");
  const [cumul_des_points, setcumul_des_points] = useState(0);
  const date = moment(new Date()).format("YY");
  const [mobile, setmobile] = useState(
    user?.result !== undefined ? user?.result?.mobile : ""
  );
  const [paiement, setPaiement] = useState(0);
  const [enableCountDown, setenableCountDown] = useState(false);
  // LISTE OF DIFFERENT TYPE OF PAIEMENT
  var array = types;
  // show Modal CODE PIN -----------
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  //----------------- LOADER ------------------
  const [Loading, setLoading] = useState(true);
  const selectedFacture = JSON.parse(localStorage.getItem('selectedFacture')) 
  console.log('selected facture', selectedFacture);
  
console.log(user?.result);
  useEffect(() => {
    getUser(user?.result?.id)
      .then((result) => {
        console.log("data of user",result)
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          setcumul_des_points(obj?.cumul_points);
          if (obj?.verification_paiement_avec_code_pin === 1) {
            setshowCodePIN(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
 
  // # ADRESSE
//   const { cite, ligne, code_postal, pays, region } =
//     groupedAdresses[data?.adresse_id][0];
  var code = generatePolicyCodeByType(type, date);
  /* var code_policy = generatePolicyCodeByType(type)
  console.log(code_policy); */
  const verifStatus = (status, intervalId) => {
    // Vérifiez si le statut est "success" ou "failed"
    if (status === "SUCCESS" || status === "FAILLED") {
      // Si le statut est "success" ou "failed", arrêtez l'exécution de setInterval
      clearInterval(intervalId);
    }
  };



  //SECOND STEP ====> VERIFICATION STATUS
  const traitement = (externalTransactionId, dataRes, intervalId) => {
    verifPaiement({
      externalTransactionId: externalTransactionId,
    })
      .then((result) => {
        if (result?.data?.code === 2000) {
          var message = result?.data?.msg;
          var status = result?.data?.data?.status;
          if (status === "FAILLED" || status === "CANCELED") {
            error_message(message);
            history.push({ pathname: "/paytech_fail" });
            setenableCountDown(false);
            // Vérifiez si le statut est "success" ou "failed"
            verifStatus(status, intervalId);
          } else if (status === "SUCCESS") {
            success_message(message);
            history.push({ pathname: "/paytech_success" });
            //call web service paiement (interne)
            setenableCountDown(false);
          } else {
            // Vérifiez si le statut est "success" ou "failed"
            verifStatus(status, intervalId);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /// first step 
  const payerContrat = (dataRes) => {
    //------ Post paiement method
    var card_data = {};
    console.log("mohamed dataRes the best :",dataRes)
    if (dataRes?.type_paiement === "BANK_CARD_API_CASH_OUT") {
      card_data = {
        phone: mobile,
        amount: selectedFacture.montantNumber,
        codeService: dataRes?.type_paiement,
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        bankAuthRedirectUrl: "https://secure-3ds.intech.sn/ping",
        customerFirstName: user?.result?.prenom,
        customerLastName: user?.result?.nom,
        customerEmail: dataRes?.email,
        cardNumber: dataRes?.cardNumber,
        cardExpireMonth: dataRes?.cardExpireMonth,
        cardExpireYear: dataRes?.cardExpireYear,
        cardCVC: dataRes?.cardCVC,
        cardType: dataRes?.cardType,
        merchantName: "CouchTech",
        operationDescription: "Assurance : " + type,
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
      console.log(card_data);
    } else if (dataRes?.type_paiement === "WIZALL_SN_API_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: selectedFacture.montantNumber,
        codeService: "WIZALL_SN_API_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: user?.result?.prenom,
        customerLastName: user?.result?.nom,

        merchantName: "CouchTech",
        operationDescription: "Assurance : " + type,
      };
    } else if (dataRes?.type_paiement === "WAVE_SN_API_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: selectedFacture.montantNumber,
        codeService: "WAVE_SN_API_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: user?.result?.prenom,
        customerLastName: user?.result?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl: "https://paytech.sn/wave_redirect_success",
        errorRedirectUrl: "https://paytech.sn/wave_redirect_error",
      };
    } else if (dataRes?.type_paiement === "ORANGE_SN_API_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: selectedFacture.montantNumber,
        codeService: "ORANGE_SN_API_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: user?.result?.prenom,
        customerLastName: user?.result?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
    } else if (dataRes?.type_paiement === "FREE_SN_WALLET_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: selectedFacture.montantNumber,
        codeService: "FREE_SN_WALLET_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: user?.result?.prenom,
        customerLastName: user?.result?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
    } else if (dataRes?.type_paiement === "EXPRESSO_SN_WALLET_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: selectedFacture.montantNumber,
        codeService: "EXPRESSO_SN_WALLET_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: user?.result?.prenom,
        customerLastName: user?.result?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
    }

    setenableCountDown(true);

    paiementPaytech(card_data)
      .then((result) => {
        console.log('Mohammed card data:', card_data);
        if (result?.data?.code === 2000) {
          var externalTransactionId = result?.data?.data?.externalTransactionId;

          let intervalId = setInterval(() => {
            traitement(externalTransactionId, dataRes, intervalId);
          }, 8000); // 8 secondes
          let obj = {id:selectedFacture.id,statut:'paid'}
          console.log('Attributes to update:', obj);
          UpdateFacture(obj).then((result)=>{
            if(result?.data?.code===200){
              console.log(result?.data?.data)
              addFacture(data).then((result)=>{
                if(result?.data?.code===200){
                  console.log("add new Facture ",result?.data?.data)
                  // setTimeout(() => {
                  //   toast.success('Payment successful!', { position: 'top-right' }); 
                  //   window.location.reload(); 
                  // }, 80000); 
            
                  // setTimeout(() => {
                  //   window.location.reload(); 
                  // }, 80000); 
                }
              })
              let intervalId = setInterval(() => {
                traitement(externalTransactionId, dataRes, intervalId);
              }, 8000); // 8 secondes
            }
            else {
              console.log(result?.data?.message)
            }
           
          }).catch((err) => {
            console.log(err);
          })
        } else {
          error_message(result?.data?.msg);
           setenableCountDown(false);
          
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {Loading ? (
        <DotLoader />
      ) : (
        <div>
          {done ? (
            <div className="order-success-wrapper mt-0">
              <div className="order-done-content">
                <img
                  className="mb-4"
                  src="img/bg-img/valide.png"
                  style={{ width: "150px" }}
                  alt="NO DATA"
                />
                <h5>{t("Succès!")}</h5>
                <p>
                  {t("Votre N° de contrat")} :
                  <strong className="badge bg-light text-dark shadow-sm me-2 fz-14">
                    {code}
                  </strong>
                </p>
                <p>{t("Merci d'avoir choisi DiokoAssur")}.</p>
                {type === "vehicule" && (
                  <p>
                    <b>
                      {t(
                        "Vos contrats sont livrés dans les 3 heures qui suivent, du lundi au dimanche, uniquement dans la Région de Dakar et entre 08h et 00h."
                      )}
                    </b>
                  </p>
                )}
                <a
                  className="btn btn-success text-white mt-3 col-12"
                  href="/liste_contrats"
                >
                  {t("Voir vos contrats")}
                </a>
              </div>
            </div>
          ) : (
            <div className="shop-pagination mb-3 mt-3">
              <div className="card">
                <div className="card-body">
                  {showCodePIN && (
                    <ModalSaisieCode
                      id={user?.result?.id}
                      t={t}
                      setshowCodePIN={setshowCodePIN}
                    />
                  )}
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <small className="ms-1 mb-3"> {t("Paiement")} </small> */}
                  </div>
                  <MethodePaiementCotisation
                    id={id}
                    handleSubmit={handleSubmit}
                    payerContrat={payerContrat}
                    type={type}
                    data={data}
                    t={t}
                    array_pay={array}
                    register={register}
                    watch={watch}
                    paiement={paiement}
                    setPaiement={setPaiement}
                    cumul_des_points={cumul_des_points}
                    enableCountDown={enableCountDown}
                    mobile={mobile}
                    setmobile={setmobile}
                    unpaidFactures={unpaidFactures}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
