/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { useUser } from "../../../../Auth/useUser";
import DotLoader from "../../../../components/loading/DotLoader";
import { getUser } from "../../../../services/users/usersAPI";
import {
  geneateTransactionId,
  generateNFacture,
  generatePolicyCode,
  generatePolicyCodeByType,
} from "../../../../utils/FileExtension";
import ModalSaisieCode from "../../../securite/ModalSaisieCode";
import MethodePaiement from "./MethodePaiement";
import Countdown from "react-countdown";
import {
  addAccidentFunction,
  addAutoFunction,
  addDecesFunction,
  addEducationFunction,
  addRetraiteFunction,
  addVoyageFunction,
} from "./ProductFunctions";
import moment from "moment";
import {
  paiementPaytech,
  verifPaiement,
} from "../../../../services/paytech/paytechAPI";
import {
  error_message,
  success_message,
} from "../../../../components/alerte/AlerteMessage";
import { types } from "./Type_Paiement_array";
import { addFacture } from "../../../../services/factures/FactureApi";
import MethodePaiementRenouvellement from "./MethodePaiementRenouvellement";
import { getContratByPolicy, updateContrat } from "../../../../services/contrats/ContratApi";

export default function PaiementRenouvellement({
  id,
  data,
  LISTE_ASSUREE,
  groupedAdresses,
  register,
  handleSubmit,
  type,
  watch,
  t,
  contract,
  results,
}) {
  console.log(
    "We're at the the renouvellement payment component fellas!",
    data
  );
  console.log("coming form contract: ", contract);
  console.log("coming form results", results);
  const user = useUser();
  const history = useHistory("");
  // var { mobile } = user?.result !== undefined ? user?.result : "";
  const [done, setdone] = useState(false);
  const [statut, setStatut] = useState("");
  const [cumul_des_points, setcumul_des_points] = useState(0);
  const date = moment(new Date()).format("YY");
  const [contractId, setContractId] = useState("");
  const [mobile, setmobile] = useState(
    user?.result !== undefined ? user?.result?.mobile : ""
  );
  const [remise, setRemise] = useState(0);

  const [paiement, setPaiement] = useState(
    type === "education"
      ? data?.prime_commercial
      : type === "accident" || type === "vehicule"
      ? data?.primettc
      : type === "voyage"
      ? data?.prime_ttc
      : type === "retraite"
      ? data?.prime_commercial
      : type === "deces"
      ? data?.prime_annuelle
      : 0
  );
  const [enableCountDown, setenableCountDown] = useState(false);
  // LISTE OF DIFFERENT TYPE OF PAIEMENT
  var array = types;
  // show Modal CODE PIN -----------
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  //----------------- LOADER ------------------
  const [Loading, setLoading] = useState(true);
  var info = {};
  useEffect(() => {
    getUser(user?.result?.id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          setcumul_des_points(obj?.cumul_points);
          if (obj?.verification_paiement_avec_code_pin === 1) {
            setshowCodePIN(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  // # ADRESSE
  const { cite, ligne, code_postal, pays, region } =
    groupedAdresses[data?.adresse_id][0];

  var code = generatePolicyCodeByType(type, date);
  /* var code_policy = generatePolicyCodeByType(type)
  console.log(code_policy); */
  const verifStatus = (status, intervalId) => {
    // Vérifiez si le statut est "success" ou "failed"
    if (status === "SUCCESS" || status === "FAILLED") {
      // Si le statut est "success" ou "failed", arrêtez l'exécution de setInterval
      clearInterval(intervalId);
    }
  };

  const createSaveContract = (
    dataRes,
    status,
    intervalId,
    statut,
    externalTransactionId
  ) => {
    console.log(
      "create contrat save :",
      dataRes,
      status,
      intervalId,
      statut,
      externalTransactionId
    );

    data.date_effet = results?.date_effet;
    data.company_id = contract?.contract?.item?.company_id;
    data.category_id = contract?.contract?.item?.category_id;
    data.duree = results?.duree;
    data.totalHt = results?.totalHt;
    data.formule = results?.formule;
    data.accessoires = results?.accessoires;
    data.valeur_venale = results?.valeur_venale;
    // data.prime_ttc = results?.primettc;
    data.prime_ttc = parseFloat(results?.primettc.toFixed(0));
    // data.taxes = results?.taxes;
    data.taxes = parseFloat(results.taxes.toFixed(0));
    data.matricule = contract?.contract?.item?.matricule;
    data.chassis = contract?.contract?.item?.chassis;
    data.marque = contract?.contract?.item?.marque;
    data.genre = data?.contract?.item?.genre;
    data.modele = contract?.contract?.item?.type;
    data.energie = contract?.contract?.item?.energie;
    data.puissance = results?.formule;
    data.nbr_place = contract?.contract?.item?.nbr_place;
    data.remorq = contract?.contract?.item?.remorque;
    data.n_quittance = contract?.contract?.item?.n_quittance;
    data.beneficiaire = contract?.contract?.item?.beneficiaire;
    data.fga = results?.fga;
    data.tca = contract?.contract?.item?.tca;
    data.accessoires = results?.accessoires;
    data.primenette = results?.primenette;
    info = {
      user_id: data.id,
      category_id: data?.contract?.item?.category_id,
      company_id: data?.contract?.item?.company_id,
      date_effet: results?.date_effet,
      duree: results?.duree,
      num_facture: data?.contract?.item?.num_facture,
      adresse:
        cite + " " + ligne + " " + region + " , " + pays + ", " + code_postal,
      beneficiaire: data?.contract?.item?.beneficiaire,
      assuree_id: data?.contract?.item?.assuree_id,
    };

    if (info?.category_id !== 8) {
      info.category_id = 4;
      info.company_id = 1;
    }

    

    if (type === "education") {
      addEducationFunction(
        info,
        LISTE_ASSUREE,
        data,
        mobile,
        date,
        {
          type_paiement: dataRes.type_paiement,
        },
        statut,
        externalTransactionId
      );
      if (statut === "success") {
        setdone(true);
      }
    } else if (type === "accident") {
      Object.assign(info, {
        policy_code: code,
        beneficiaire: contract?.item?.beneficiaire,
        num_facture: contract?.item?.contrat?.num_facture,
        category_id:contract?.item?.contrat?.category_id,
        company_id:contract?.item?.contrat?.company_id

      });
      addAccidentFunction(
        info,
        LISTE_ASSUREE,
        data,
        mobile,
        {
          type_paiement: dataRes.type_paiement,
        },
        statut,
        externalTransactionId
      );
      if (statut === "success") {
        setdone(true);
      }
    } else if (type === "voyage") {
      Object.assign(info, { policy_code: code });
      addVoyageFunction(
        info,
        LISTE_ASSUREE,
        data,
        mobile,
        {
          type_paiement: dataRes.type_paiement,
        },
        statut,
        externalTransactionId
      );
      if (statut === "success") {
        setdone(true);
      }
    } else if (type === "vehicule") {
      Object.assign(info, {
        policy_code: code,
        duree: results.duree,
        beneficiaire: contract?.contract?.item?.beneficiaire,
        num_facture: contract?.contract?.item?.n_quittance,
      });
      addAutoFunction(
        info,
        LISTE_ASSUREE,
        data,
        mobile,
        {
          type_paiement: dataRes.type_paiement,
        },
        statut,
        externalTransactionId
      );
      console.log("auto contrat avec successfuly", statut);

      if (statut === "success") {
        setdone(true);
      }
    } else if (type === "retraite") {
      Object.assign(info, { policy_code: code });
      addRetraiteFunction(
        info,
        LISTE_ASSUREE,
        data,
        mobile,
        {
          type_paiement: dataRes.type_paiement,
        },
        statut,
        externalTransactionId
      );
      if (statut === "success") {
        setdone(true);
      }
    } else if (type === "deces") {
      Object.assign(info, { policy_code: code });
      addDecesFunction(
        info,
        LISTE_ASSUREE,
        data,
        mobile,
        {
          type_paiement: dataRes.type_paiement,
        },
        statut,
        externalTransactionId
      );
      if (statut === "success") {
        setdone(true);
        setenableCountDown(false);
      }
    } // Vérifiez si le statut est "success" ou "failed"
    if (statut === "success") {
      console.log("data after saving contract:", info);
      verifStatus(status, intervalId);
      console.log(status);
    }
  };

  const traitement = (externalTransactionId, dataRes, intervalId) => {
    verifPaiement({
      externalTransactionId: externalTransactionId,
    })
      .then((result) => {
        if (result?.data?.code === 2000) {
          var message = result?.data?.msg;
          var status = result?.data?.data?.status;
        
          
          if (status === "FAILLED" || status === "CANCELED") {
            error_message(message);
            history.push({ pathname: "/paytech_fail" });
            setenableCountDown(false);
            // Vérifiez si le statut est "success" ou "failed"
            verifStatus(status, intervalId);
            console.log('Failed hommy!');
          } else if (status === "SUCCESS") {
            success_message(message);
            // web service update contrat
            getContratByPolicy(info?.policy_code).then((result) => {
              if (result?.data?.code === 200) {
                console.log('Our contract that we found !!!: ', result?.data?.data);
                let updatedContract = result?.data?.data[0]
                updatedContract.statut = "SUCCESS"
                updateContrat(updatedContract).then((result) => {
                  if (result?.data?.code === 200) {
                    console.log('Yeaaaaah, it was updated to: ', result?.data?.data);
                  }
                })
              }
            }).catch((err)=>{
              console.log(err)
            })
            console.log("status data of create save contrat", status);
            history.push({ pathname: "/paytech_success" });
          } else {
            // Vérifiez si le statut est "success" ou "failed"
            verifStatus(status, intervalId);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const payerContrat = (dataRes) => {
    //------ Post paiement method
    var card_data = {};
    console.log("dataRes of payer contrat ", dataRes);
    if (dataRes?.type_paiement === "BANK_CARD_API_CASH_OUT") {
      card_data = {
        phone: mobile,
        amount: paiement,
        codeService: dataRes?.type_paiement,
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        bankAuthRedirectUrl: "https://secure-3ds.intech.sn/ping",
        customerFirstName: dataRes?.prenom,
        customerLastName: dataRes?.nom,
        customerEmail: dataRes?.email,
        cardNumber: dataRes?.cardNumber,
        cardExpireMonth: dataRes?.cardExpireMonth,
        cardExpireYear: dataRes?.cardExpireYear,
        cardCVC: dataRes?.cardCVC,
        cardType: dataRes?.cardType,
        merchantName: "CouchTech",
        operationDescription: "Assurance : " + type,
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
      console.log(card_data);
    } else if (dataRes?.type_paiement === "WIZALL_SN_API_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: paiement,
        codeService: "WIZALL_SN_API_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: dataRes?.prenom,
        customerLastName: dataRes?.nom,

        merchantName: "CouchTech",
        operationDescription: "Assurance : " + type,
      };
    } else if (dataRes?.type_paiement === "WAVE_SN_API_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: paiement,
        codeService: "WAVE_SN_API_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: dataRes?.prenom,
        customerLastName: dataRes?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl: "https://paytech.sn/wave_redirect_success",
        errorRedirectUrl: "https://paytech.sn/wave_redirect_error",
      };
    } else if (dataRes?.type_paiement === "ORANGE_SN_API_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: paiement,
        codeService: "ORANGE_SN_API_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: dataRes?.prenom,
        customerLastName: dataRes?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
    } else if (dataRes?.type_paiement === "FREE_SN_WALLET_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: paiement,
        codeService: "FREE_SN_WALLET_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: dataRes?.prenom,
        customerLastName: dataRes?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
    } else if (dataRes?.type_paiement === "EXPRESSO_SN_WALLET_CASH_OUT") {
      card_data = {
        phone: mobile.substring(4),
        amount: paiement,
        codeService: "EXPRESSO_SN_WALLET_CASH_OUT",
        externalTransactionId: geneateTransactionId(),
        callbackUrl: "https://secure-3ds.intech.sn/ping",
        apiKey: "89483A1C-7718-4E57-9B80-0DA18F47D2CC",
        customerFirstName: dataRes?.prenom,
        customerLastName: dataRes?.nom,
        operationDescription: "Assurance : " + type,
        sender: "CouTech",
        successRedirectUrl:
          "https://demo.assurance.clediss.com/paytech_success",
        errorRedirectUrl: "https://demo.assurance.clediss.com/paytech_fail",
      };
    }

    setenableCountDown(true);
    createSaveContract(dataRes, "", "", "processing", "");

    paiementPaytech(card_data)
      .then((result) => {
        if (result?.data?.code === 2000) {
          var externalTransactionId = result?.data?.data?.externalTransactionId;
          let date = new Date();
          let facture = {
            num_facture: info?.policy_code,
            numero_paiement: 0,
            user_id: info?.user_id,
            company_id: info?.company_id,
            category_id: info?.category_id,
            montant: result?.data?.data?.amount,
            remise: remise,
            Date_prevu: info?.date_effet,
            Date_paiement: date,
            statut: "paid",
          };
          console.log("Facture à créé:", facture);
          console.log("It must be created with these values: (amount)", info);
          addFacture(facture)
            .then((result) => {
              if (result?.data?.code === 200) {
                console.log("Facture génerée", result?.data?.data);
              } else {
                console.log("Création facture échouée");
              }
            })
            .catch((err) => {
              console.log("Erreur de facture:", err);
            });
          // Generate next unpaid facture only for cotisation contracts
          if (
            info.category_id == 7 ||
            info.category_id == 10 ||
            info.category_id == 11
          ) {
            let dureeCotisation = 0;
            if (dataRes.periodicite == 12) {
              dureeCotisation = 1;
            } else if (dataRes.periodicite == 4) {
              dureeCotisation = 3;
            } else if (dataRes.periodicite == 2) {
              dureeCotisation = 6;
            } else if (dataRes.periodicite == 1) {
              dureeCotisation = 12;
            }
            console.log("duree cotisation:", dureeCotisation);
            let date_effet = new Date(info.date_effet);
            let nextFactureDate = date_effet.setMonth(
              date_effet.getMonth() + dureeCotisation
            );
            console.log(nextFactureDate);
            function formatTimestamp(timestamp) {
              const date = new Date(timestamp);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
              const day = String(date.getDate()).padStart(2, "0");
              return `${year}-${month}-${day}`;
            }
            const formattedDate = formatTimestamp(nextFactureDate);
            let facture = {
              num_facture: info.policy_code,
              numero_paiement: 0,
              user_id: info.user_id,
              company_id: info.company_id,
              category_id: info.category_id,
              montant: result?.data?.data?.amount,
              Date_prevu: formattedDate,
              Date_paiement: formattedDate,
              statut: "unpaid",
            };
            console.log("Facture à créé:", facture);
            console.log("It must be created with these values: (amount)", info);
            addFacture(facture)
              .then((result) => {
                if (result?.data?.code === 200) {
                  console.log("Facture génerée", result?.data?.data);
                } else {
                  console.log("Création facture échouée");
                }
              })
              .catch((err) => {
                console.log("Erreur de facture:", err);
              });
          }

          let intervalId = setInterval(() => {
            traitement(externalTransactionId, dataRes, intervalId);
          }, 8000); // 8 secondes
        } else {
          error_message(result?.data?.msg);
          setenableCountDown(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {Loading ? (
        <DotLoader />
      ) : (
        <div>
          {done ? (
            <div className="order-success-wrapper mt-0">
              <div className="order-done-content">
                <img
                  className="mb-4"
                  src="img/bg-img/valide.png"
                  style={{ width: "150px" }}
                  alt="NO DATA"
                />
                <h5>{t("Succès!")}</h5>
                <p>
                  {t("Votre N° de contrat")} :
                  <strong className="badge bg-light text-dark shadow-sm me-2 fz-14">
                    {code}
                  </strong>
                </p>
                <p>{t("Merci d'avoir choisi DiokoAssur")}.</p>
                {type === "vehicule" && (
                  <p>
                    <b>
                      {t(
                        "Vos contrats sont livrés dans les 3 heures qui suivent, du lundi au dimanche, uniquement dans la Région de Dakar et entre 08h et 00h."
                      )}
                    </b>
                  </p>
                )}
                <a
                  className="btn btn-success text-white mt-3 col-12"
                  href="/liste_contrats"
                >
                  {t("Voir vos contrats")}
                </a>
              </div>
            </div>
          ) : (
            <div className="shop-pagination mb-3 mt-3">
              <div className="card">
                <div className="card-body">
                  {showCodePIN && (
                    <ModalSaisieCode
                      id={user?.result?.id}
                      t={t}
                      setshowCodePIN={setshowCodePIN}
                    />
                  )}
                  <div className="d-flex align-items-center justify-content-between">
                    <small className="ms-1 mb-3"> {t("Paiement")} </small>
                  </div>
                  <MethodePaiementRenouvellement
                    id={id}
                    handleSubmit={handleSubmit}
                    payerContrat={payerContrat}
                    type={type}
                    data={data}
                    t={t}
                    array_pay={array}
                    register={register}
                    watch={watch}
                    paiement={paiement}
                    setPaiement={setPaiement}
                    setRemise={setRemise}
                    cumul_des_points={cumul_des_points}
                    enableCountDown={enableCountDown}
                    mobile={mobile}
                    setmobile={setmobile}
                    contract={contract}
                    results={results}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
