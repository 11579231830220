import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceItemsBeneficiaire from "./InvoiceItemsBeneficiaire";
import Contrat from "./Contrat";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg ";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#fff",
    color: "#443f52",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 0,
  },
  h2: {
    display: "block",
    fontSize: 30,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
  },
  right: {
    padding: 5,
    width: "50%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,
    textAlign: "center",
  },
  left: {
    padding: 5,
    width: "50%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,
    textAlign: "center",
  },
  all: {
    padding: 5,
    width: "100%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,
    textAlign: "center",
  },
  right_calcul: {
    padding: 5,
    width: "40%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,
    textAlign: "center",
  },
  left_calcul: {
    padding: 5,
    width: "60%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,
    textAlign: "center",
  },
});

const InvoiceItemsTable = ({
  t,
  type,
  info,
  data,
  user_details,
  personnel,
}) =>
// console.log("invoice item table :",type,
// info,
// data,
// user_details,
// personnel,) 
(
  <View style={styles.tableContainer}>
    {type !== "voyage" && (
      <>
        <View style={styles.left}>
          <InvoiceTableHeader title={"Souscripteur"} />
          <InvoiceTableRow
            data={data}
            type={type}
            user_details={user_details}
            personnel={personnel}
          />
        </View>
        <View style={styles.right}>
          {data?.assure !== undefined && (
            <>
              <InvoiceTableHeader title={"Bénèficiaire"} />
              <InvoiceItemsBeneficiaire
                type={type}
                data={data}
                user_details={user_details}
                personnel={personnel}
              />
            </>
          )}
        </View>
      </>
    )}
    {type === "voyage" && (
      <>
        <View style={styles.all}>
          <InvoiceTableHeader title={"Souscripteur"} />
          <InvoiceTableRow
            data={data}
            type={type}
            user_details={user_details}
            personnel={personnel}
          />
        </View>
        {data?.assure?.length >0 && (
        <View style={styles.all}>
          <InvoiceTableHeader title={"Bénèficiaire"} />
          <InvoiceItemsBeneficiaire
            type={type}
            data={data}
            user_details={user_details}
            personnel={personnel}
          />
        </View>)}
      </>
    )}
    <InvoiceTableHeader title={"Prime – Capital – Rente"} />
    <View style={styles.left_calcul}>
      <InvoiceThankYouMsg info={info} data={data} type={type} />
    </View>
    <View style={styles.right_calcul}>
      <Contrat
        info={info}
        t={t}
        type={type}
        data={data}
        user_details={user_details}
        personnel={personnel}
      />
    </View>
  </View>
);

export default InvoiceItemsTable;
