import React from 'react'

export default function EmptyFolder({t}) {
  return (
    <div className="card">
                <div className="card-body px-5 text-center">
                  <h4>
                    {t("Aucun contrat d'assurance")}
                    <br />
                  </h4>
                  <p className="mb-4">
                    {t(
                      "Vous devez au moins souscrire à un contrat d'assurance pour visualiser les détails et télécharger le contrat.Il  vous suffit de choisir un produit d'assurance dans la page suivante"
                    )}
                    <a className="text-purple" href="/nos_produits">
                      {t("Nos produits")}
                    </a>
                  </p>
                  <img
                    className="mb-4"
                    src="img/bg-img/contrat.png"
                    alt="NO DATA"
                  />
                  <a
                    className="btn btn-creative btn-purple col-12"
                    href="/nos_produits"
                  >
                    {t("Nos produits")}
                  </a>
                </div>
              </div>
  )
}
