/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../Auth/useUser';
import { getCategorie } from '../../../services/categories/CategoriesApi';
import { getUser } from '../../../services/users/usersAPI';

export default function PrdouctsCards({t ,language}) {
    
    const user = useUser();
    //LISTE OF PRODUCTS CATEGORIES
    const [LISTE, setLISTE] = useState([])
    //------------- HISTORY -----------------
    const history = useHistory()
    useEffect(() => {
        getCategorie()
            .then((result) => {
                if (result?.data?.code === 200) {
                setLISTE(result?.data?.data)
                }else if (result?.data?.code=== 401 && result?.data?.errorMessage==="invalid token"){
                    var id = user?.result?.id
                    getUser(id).then((result) => {
                        if(result?.data?.code===200){
                          var data = result?.data?.data
                          if(data?.code_PIN !== null){
                            var value = {
                              mobile : user?.result?.mobile,
                              email : user?.result?.email
                            }
                            localStorage.setItem("save_user", JSON.stringify(value));
                            localStorage.removeItem("token");
                             history.push({ pathname: "/signin" });
                          }else{
                           localStorage.removeItem("token");
                           history.push({ pathname: "/login" });
                          }
                        }else{
                          localStorage.removeItem("token");
                          history.push({ pathname: "/login" });
                        }
                      }).catch((err) => {
                        console.log(err);
                      });
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])
  
    return (
        <div>
            <div className="shop-pagination pb-3">
                <div className="container">
                    <div className="card">
                        <div className="card-body p-2">
                            <div className="d-flex align-items-center justify-content-between"><small className="ms-1">{t("Liste des assurances")} </small>
                                <form action="#">
                                    <div className="float-end " > <a href="/nos_produits" className='text-purple fz-12' > <b> {t("Tout afficher")} </b> <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container direction-rtl">
                <div className="row g-3">
                    {LISTE?.map((items, keys) => (
                        <div className="col-4" key={keys}>
                            <a href={"/details_produits/" + items.id} className="btn btn-sq-lg  fz-10">
                                <img src={process.env.REACT_APP_IMAGE + items.logo} alt="assurance" className='mt-2 mb-1' width={"50%"} /><br />
                                <p className="mb-0 text-white fz-12">{language==="fr" ? items.prime : items.prime_en}</p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
