import Icon from '../../../../components/obligatoire/Icon'
import { calculateAge } from '../../../../utils/Comparaison'

export default function Retraite({register, t ,data}) {
  return (
    <div> 
      <div className="form-group mb-3">
          <label className="form-label fz-12" >
            {t("Prime Commerciale")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            type="text"
            placeholder="Prime ..."
            {...register("prime_commercial")}
            required
          />
        </div>
        <div className="form-group mb-3">
        <label className="form-label fz-12" >
            {t("Periodicité")} <Icon />
          </label>
          <select className="form-select  fz-12" {...register("periodicite")}>
          <option value={""}>{t("Choisir")} </option>
            <option value={1}>{t("Annuelle")}</option>
            <option value={2}>{t("Semestrielle")}</option>
            <option value={4}>{t("Trimistrielle")}</option>
            <option value={12}>{t("Mensuelle")}</option>
          </select>
        </div>
        <div className="form-group mb-3">
        <label className="form-label fz-12" >
            {t("Durée de service de la rente (annee)")} <Icon />
          </label>
          <input
            className="form-control  fz-12" 
            type="number"
            placeholder="Durée de la rente .."
            {...register("durre_rente")}
            required
          />
        </div>
        <div className="form-group mb-3">
        <label className="form-label fz-12" >
            {t("Durée du contrat (année)")} <Icon />
          </label>
          <input
            className="form-control  fz-12"
            type="text"
            placeholder="Cotisiation ..."
            {...register("duree_contrat")}
            required
          />
        <label className="fz-12 text-purple mt-2">  {t("L'intervalle d'age permis pour la durée du contrat est de")+"[5 "+t("ans")} - {(65-calculateAge(new Date(data?.date_naissance))+t("ans")+" ]")} </label>

        </div>
    </div>
  )
}
