/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
import React, { useState, useEffect } from "react";
// # UTILS : FUNCTION NUMBER WITH SPACES
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
//---------------- LOADSH -------------
import _ from "lodash";
import { getFormuleAuto } from "../../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";

export default function ResumAuto({ data, t, LISTE_ASSUREE, groupedAssuree, setLISTE_ASSUREE, category_id }) {


  // Results
  const [results, setResults] = useState({
    primenette: 0, // SUM PRIME NETTE
    accessoires: 0, // ACCESSOIRES
    taxes: 0, // TAXES
    fga: 0, // FGA
    primettc: 0, // PRIME TTC
  });

  useEffect(() => {
    var { valeur_venale, puissance, formule, duree } = data;

    const valeur = valeur_venale.replace(/\s+/g, '')
    getFormuleAuto().then((result) => {
      if (result?.data?.code === 200) {
        //------------ GROUPE FORMULE PUISSANCE -----------
        var group_formule = _.groupBy(result?.data?.data, "puissance");
        var table = group_formule[puissance];
        getParamTechByCategorie(category_id)
          .then((res) => {
            if (res?.data?.code === 200) {
              var paramTech = res?.data?.data[0]
              var taux_taxes= parseInt(paramTech?.taxes)/100
              
              var result = table?.filter(
                (el) => el.formule === formule && el.duree === parseInt(duree)
              );
              var object = result[0];
              var total = 0;
              var taxe = 0;
              var fg = 0;
              var vol = 0;
              var incendie = 0;
              var dommages = 0;
              if (formule === "Karangue") {
                total = object?.rc + object?.dr + object?.frais;
                taxe = total *taux_taxes;
                fg = total * parseFloat(object?.fga);;
              } else if (formule === "Aar") {
                total = object?.rc + object?.dr + object?.frais + object?.ar + object?.pt;
                taxe = total *taux_taxes;
                fg = total * parseFloat(object?.fga);;
              } else if (formule === "Noflaye") {
                total =
                  object?.rc +
                  object?.dr +
                  object?.frais +
                  object?.ar +
                  object?.pt +
                  object?.bdg;
                taxe = total * taux_taxes;
                fg = total * parseFloat(object?.fga);;
              } else if (formule === "Diam") {
                vol = object?.vol * valeur;
                incendie = object?.incendie * valeur;
                total =
                  object?.rc +
                  object?.dr +
                  object?.frais +
                  object?.ar +
                  object?.pt +
                  object?.bdg +
                  vol +
                  incendie;
                taxe = total *taux_taxes;
                fg = total * parseFloat(object?.fga);;
              } else if (formule === "Ngalam" || formule === "Wourous") {
                vol = object?.vol * valeur;
                incendie = object?.incendie * valeur;
                dommages = object?.DOMMAGES * valeur;
                total =
                  object?.rc +
                  object?.dr +
                  object?.frais +
                  object?.ar +
                  object?.pt +
                  object?.bdg +
                  vol +
                  incendie +
                  dommages +
                  object?.remorq;
                taxe = total * taux_taxes;
                fg = total * parseFloat(object?.fga);;
              }
              if (LISTE_ASSUREE?.length !== 0) {
                var new_liste_assuree = [];
                LISTE_ASSUREE?.map((items) => {
                  var info_assuree = groupedAssuree[items?.assuree_id] !== undefined ? groupedAssuree[items?.assuree_id][0] : {};
                  var obj = {
                    assuree_id: items?.assuree_id,
                    primenette: total,
                    accessoires: object?.frais,
                    taxes: taxe,
                    fga: fg, // FGA
                    remorq: object?.remorq,
                    nom_assuree: info_assuree?.nom,
                    prenom_assuree: info_assuree?.prenom,
                    data_naissance_assuree: info_assuree?.date_naissance,
                    primettc: total + taxe + fg,
                  };
                  new_liste_assuree.push(obj);
                });
                setLISTE_ASSUREE(_.uniq(new_liste_assuree));
              }
              // DANS CE CAS ON UN SEUL ASSUREE SOIT LE SOUSCRIPTEUR OU L ASSURE
              // DONC LE RESULTAT SERA DANS LES DEUX CAS LE MEME SELON LES INFORMATIONS FOURNIS
              Object.assign(data, {
                primenette: total,
                accessoires: object?.frais,
                taxes: taxe,
                fga: fg, // FGA
                primettc: total + taxe + fg ,
                remorq: object?.remorq,
              });
              setResults({
                primenette: total,
                accessoires: object?.frais,
                taxes: taxe,
                fga: fg, // FGA
                primettc: total + taxe + fg ,
              });
            }
          }).catch((err) => {
            console.log(err);
          });

      }
    }).catch((err) => {
      console.log(err);
    });


  }, [data]);

  return (
    <div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Valeur vénale")} (FCFA)</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-car-front text-purple"
              viewBox="0 0 16 16"
            >
              <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
              <path
                fillRule="evenodd"
                d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"
              />
            </svg>
          </span>
          <input
            type="number"
            className="form-control fz-12"
            placeholder="Valeur Vénale voiture ... "
            disabled
            value={data?.valeur_venale.replace(/\s+/g, '')}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Puissance")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="bi bi-person-fill text-purple" />
          </span>
          <select
            className="form-select  fz-12"
            value={console.log(data?.puissance)}
            disabled
          >
            <option> {t("Choisir")} </option>
            <option value={"categorie1"}> {t("3 à 6 CV")} </option>
            <option value={"categorie2"}> {t("7 à 10 CV")} </option>
            <option value={"categorie3"}> {t("11 à 14 CV")} </option>
            <option value={"categorie4"}> {t("15 à 23 CV")} </option>
            <option value={"categorie5"}> {t("24 à + CV")} </option>
          </select>
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Formules")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="bi bi-ui-checks text-purple" />
          </span>
          <select className="form-select  fz-12" value={data?.formule} disabled>
            <option> {t("Choisir")} </option>
            <option value={"Karangue"}> Karangue </option>
            <option value={"Aar"}> Aar </option>
            <option value={"Noflaye"}> Noflaye </option>
            <option value={"Diam"}> Diam </option>
            <option value={"Ngalam"}> Ngalam </option>
            <option value={"Wourous"}> Wourous </option>
          </select>
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Nombre de place")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="bi bi-people-fill text-purple" />
          </span>
          <input
            type="number"
            className="form-control fz-12"
            disabled
            value={data?.nbr_place}
            // value={"4"}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Marque")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-car-front text-purple"
              viewBox="0 0 16 16"
            >
              <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
              <path
                fillRule="evenodd"
                d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"
              />
            </svg>
          </span>
          <input
            type="text"
            className="form-control fz-12"
            disabled
            value={data?.marque}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Modèle")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="bi bi-wrench text-purple" />
          </span>
          <input
            type="text"
            className="form-control fz-12"
            disabled
            value={data?.modele}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Energie")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-fuel-pump text-purple"
              viewBox="0 0 16 16"
            >
              <path d="M3 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-5Z" />
              <path d="M1 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v8a2 2 0 0 1 2 2v.5a.5.5 0 0 0 1 0V8h-.5a.5.5 0 0 1-.5-.5V4.375a.5.5 0 0 1 .5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 0 0-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 0 1 0-1c.564 0 1.034.11 1.412.336.383.228.634.551.794.907.295.655.294 1.465.294 2.081v3.175a.5.5 0 0 1-.5.501H15v4.5a1.5 1.5 0 0 1-3 0V12a1 1 0 0 0-1-1v4h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V2Zm9 0a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v13h8V2Z" />
            </svg>
          </span>
          <input
            type="text"
            className="form-control fz-12"
            disabled
            value={data?.energie}
          />
        </div>
      </div>

      <div className="row mb-3 mt-3">
        <div className=" card bg-purple mt-0 mb-0 ">
          <div className="card-body">
            <table className="table mb-0 mt-0">
              <tbody className="text-white">
                <tr>
                  <td className="fz-10"> {t("Prime Nette")}+{t("Accessoires")} (FCFA) </td>
                  <td className=" fz-10">
                    {numberWithSpaces(Math.round(results?.primenette))}
                  </td>
                </tr>
                <tr>
                  <td className="fz-10">{t("Accessoires")} (FCFA)</td>
                  <td className=" fz-10">
                    {numberWithSpaces(results?.accessoires)}
                  </td>
                </tr>
                <tr>
                  <td className="fz-10">{t("Taxe")}(FCFA)</td>
                  <td className=" fz-10">
                    {numberWithSpaces(Math.round(results?.taxes))}
                  </td>
                </tr>
                <tr>
                  <td className="fz-10">{t("FGA")} (FCFA)</td>
                  <td className=" fz-10">
                    {numberWithSpaces(Math.round(results?.fga))}
                  </td>
                </tr>
                <tr>
                  <td className="fz-10">
                    {t("Total")} {t("Prime TTC")} (FCFA)
                  </td>
                  <td className=" fz-10">
                    {numberWithSpaces(Math.round(results?.primettc))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
