// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"
import authHeader from "../authentification/AuthHeader";

//GET URL FROM .ENV ELEMENT
import { getUrl } from "../authentification/DotenvConfig"

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
const url = getUrl()

/**
 * GET LISTE OF CONTRATS
 * @returns 
 */
 export async function getListeContrats() {
    try {
        const response = await axios.get(url + "contrats", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * GET CONTRAT BY POKICY CODE 
 * @param {*} policy_code 
 * @returns 
 */
export async function getContratByPolicy(policy_code) {
    try {
        const response = await axios.get(url + "contrats/code/"+policy_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
export async function getContratByCategoriesId(id) {
    try {
        const response = await axios.get(url + "contrats/categories/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * 
 * @param {*} policy_code 
 * @returns 
 */
export async function getpublicContratByPolicy(policy_code) {
    try {
        const response = await axios.get(url + "contrats/policy/"+policy_code, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
export async function addContrat(data) {

    console.log("addContrat, addContrat, addContratdataaaaa => ", data)
    try {
        const response = await axios.post(url + "contrats", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
export async function updateContrat(data) {
    try {
        const response = await axios.patch(url + "contrats", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
export async function deleteContratById(id) {
    try {
        const response = await axios.delete(url + "contrats", id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * CONTRAT EDUCATION
 * @param {*} data 
 * @returns 
 */
export async function addContratEducation(data) {
    try {
        const response = await axios.post(url + "educations", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}



/**
 * CONTRAT RETRAITE
 * @param {*} data 
 * @returns 
 */
export async function addContratRetraite(data) {
    try {
        const response = await axios.post(url + "retraites", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * ADD CONTRAT RETRAITE
 * @param {*} data 
 * @returns 
 */
export async function addContratDeces(data) {
    try {
        const response = await axios.post(url + "deces", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * ADD Contrat accident
 * @param {*} data 
 * @returns 
 */
export async function addContratAccident(data) {
    try {
        const response = await axios.post(url + "accidents", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * Add contrat Voyage
 * @param {*} data 
 * @returns 
 */
export async function addContratVoyage(data) {
    try {
        const response = await axios.post(url + "voyages", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * ADD AUTOS
 * @param {*} data 
 * @returns 
 */
export async function addContratAuto(data) {
    try {
        const response = await axios.post(url + "autos", data , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET ALL CONTRAT BY USER ID 
 * @param {*} id 
 * @returns 
 */
export async function getContratByUser(id) {
    try {
        const response = await axios.get(url + "contrats/users_and_statut/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * GET DETAILS CONTRAT BY ID
 * @param {*} id 
 * @returns 
 */
export async function getContratByID(id) {
    try {
        const response = await axios.get(url + "contrats/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET CONTRAT ACCIDENT 
 * @param {*} id 
 * @returns 
 */
export async function getContratAccident(id) {
    try {
        const response = await axios.get(url + "accidents/contrat/"+ id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET CONTRAT EDUCATION
 * @param {*} id 
 * @returns 
 */
export async function getContratEducation(id) {
    try {
        const response = await axios.get(url + "educations/contrat/"+ id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET CONTRAT RETRAITE 
 * @param {*} id 
 * @returns 
 */
export async function getContratRetraite(id) {
    try {
        const response = await axios.get(url + "retraites/contrat/"+ id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET CONTRAT DECES
 * @param {*} id 
 * @returns 
 */
export async function getContratDeces(id) {
    try {
        const response = await axios.get(url + "deces/contrat/"+ id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET CONTART VOYAGE by POLICY CODE
 * @param {*} id 
 * @returns 
 */
export async function getContratVoyage(id) {
    try {
        const response = await axios.get(url + "voyages/code/"+ id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * GET CONTRAT BY ID
 * @param {*} id 
 * @returns 
 */
export async function getContratAutos(id) {
    try {
        const response = await axios.get(url + "autos/contrat/"+ id , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET CONTRAT collect
 * @param {*} id 
 * @returns 
 */
export async function findContratByUserConnected(id) {
    try {
        const response = await axios.get(url + "contrats/user/"+id, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
