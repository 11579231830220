import {Page,Document,StyleSheet,View,Text,Font} from "@react-pdf/renderer";
import HeadrCP from "./HeadrCP";
import EnteteContrat from "./EnteteContrat";
import BeneficiaireCG from "./BeneficiaireCG";
import TableauGarantie from "./TableauGarantie";
import TableauBeneficiaire from "./TableauBeneficiaire";
import InvoiceTableFooter from "../invoice/InvoiceTableFooter";
import { numberWithSpaces } from "../../../utils/SessionUtiles";
// FONT TIMES NEW ROMAN
import TimesNewRoman_normal from '../styles/TimesNewRoman/times.ttf'
import TimesNewRoman_bold from '../styles/TimesNewRoman/times-new-roman-grassetto.ttf'
import Signatures from "./Signatures";

Font.register(
  {
    family: 'Arial',
    fonts: [
      {
        src: TimesNewRoman_normal,
        fontWeight:400,
        fontStyle:"normal"
      },
      {
        src: TimesNewRoman_bold,
        fontWeight:700,
        fontStyle:"bold"
      },
     
    ],
  });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    border: "5px solid #fff"
  },
  reportTitle: {
    letterSpacing: 3,
    fontSize: 10,
    textAlign: "center",
    marginBottom: 10,
    fontFamily:'Arial',
    fontStyle:"bold",
    color:"#000",
    textTransform: "uppercase",

  },
  title:{
    fontSize:8,
    letterSpacing: 1,
    marginTop:10,
    fontFamily:'Arial',
    fontStyle:"bold"
  },
  text3: {
    marginTop: 5,
    fontSize: 8,
    letterSpacing: 1,
    lineHeight: 1.5,
    textAlign: "justify",
    color: "#4e545d",
    fontFamily:'Arial',
    fontStyle:"normal"
  },
  text4: {
    marginTop: 5,
    fontSize: 8,
    letterSpacing: 1,
    lineHeight: 1.5,
    textAlign: "justify",
    color: "#4e545d",
  },
  bold:{
    textAlign: "justify",
    color: "#000",
    fontFamily:'Arial',
    fontStyle:"bold"
  },
  signature: {
    width: 150,
    height: 70,
    marginTop: -20,
    textAlign: "right",
  },
  descriptionright: {
    width: "100%",
    textAlign: "right",
    paddingLeft: 8,
    letterSpacing: 1,
    fontSize: 8,
    color: "#4e545d",
    fontFamily:'Arial',
    fontStyle:"normal"
  },
});
export default function CPaccident({ signatures, t,type,data, user_details,personnel,info ,paiement}){

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <EnteteContrat data={data} />
        <View style={{ marginTop: "20" }}>
          <Text style={styles.reportTitle}>Conditions Particuliéres</Text>
        </View>
        <View style={{ marginTop: "5" , marginBottom :"15"}}>
          <Text style={styles.reportTitle}>
            POLICE D’ASSURANCE INDIVIDUELLE ACCIDENT
          </Text>
        </View>
        <HeadrCP type={type} data={data} />
        <Text style={styles.title}>ARTICLE 1 – OBJET DU CONTRAT</Text>
        <Text style={styles.text3}>Le présent contrat a pour objet d’assurer le souscripteur en individuelle accident, selon les conditions de l’article 3. Il est complété par des présentes Conditions Particulières et <Text style={styles.bold}>Conditions générales</Text> dont l’Assuré déclare avoir reçu copies et adhérer sans réserve.</Text>
        <Text style={styles.title}>ARTICLE 2 – INFORMATIONS DE L’ASSURE</Text>
        <View style={{ marginTop: "10" }}>
        <BeneficiaireCG  data={data}  type={type} user_details={user_details} personnel={personnel}/>
        <Text style={styles.title}>ARTICLE 3 – TABLEAU DES GARANTIES</Text>
        <Text style={styles.text3}>Formule choisie : {info?.formule} </Text>
        <Text style={styles.text3}>Les garanties prévues dans le cadre de la formule choisie et mentionnée ci-dessus, de même que les montants des capitaux y relatifs, sont définis dans le tableau ci-dessous :</Text>
        <TableauGarantie data={data}  type={type} user_details={user_details} info={info}/>
        <Text style={styles.text3}>Il est précisé que les garanties ci-dessus ne s’appliquent qu’en cas de blessures ou décès accidentels.</Text>
        <Text style={styles.title}>ARTICLE 4 – INFORMATIONS SUR LES BENEFICIAIRES</Text>
        <Text style={styles.text3}>Existence de bénéficiaires désignées, pour le reversement du capital en cas de décès suite à un accident :   Oui /  Non</Text>
        <Text style={styles.text3}>Si oui, vous trouverez ci-après la liste des bénéficiaires désignés :</Text>
        <TableauBeneficiaire data={data}  type={type} user_details={user_details} info={info} />
  
        <Text style={styles.text3} wrap>Dans le cas où aucun bénéficiaire n’est désigné, le capital décès prévu aux présentes conditions particulières sera reversé aux ayants droits de l’assuré dans les conditions et selon les modalités prévues par la réglementation applicable.</Text>
       </View>
       <Text style={{marginTop:"0"}}></Text>
       <View style={{marginTop:"5"}}>
       <Text style={styles.title}>ARTICLE 5 – DECLARATION DE SINITRE</Text>
       </View>
        <Text style={styles.text3}>En cas de sinistre faisant jouer l’une des garanties prévues à l’article 3 des présentes Conditions générales, l’Assureur doit être saisi aux fins de la déclaration du sinistre, par l’intermédiaire de DIOKOTECH.
        </Text>
        <View >
        <Text style={styles.text3}> Cette déclaration de sinistre doit intervenir dans les meilleurs délais, accompagnée des pièces justificatives ou pièces probantes. En cas d’accident, la déclaration doit être faite, sauf cas de force majeure, dans les huit (08) jours de l’accident.</Text>
        </View>
        <Text style={styles.title}>ARTICLE 6 – CONDITIONS DE REGLEMENT</Text>
        <Text style={styles.title}>   a)  Décomposition de la prime :</Text>
        <Text style={styles.text3}> La prime à régler par l’Assuré se décompose comme suit :</Text>
        <Text style={styles.text3}>Prime nette : <Text style={styles.bold}>{numberWithSpaces(Math.round(info?.total_ht))}</Text> FCFA</Text>
        <Text style={styles.text3}>Accessoires : <Text style={styles.bold}>{numberWithSpaces(2000)}</Text> FCFA</Text>
        <Text style={styles.text3}>Taxes : <Text style={styles.bold}>{numberWithSpaces(Math.round(info?.taxe))}</Text> FCFA</Text>
        <Text style={styles.text3}>Prime TTC : <Text style={styles.bold}>{numberWithSpaces(Math.round(info?.prime_ttc))}</Text> FCFA</Text>
        <Text style={styles.title}>   b)	ECHEANCIER :  {info?.duree === 1
                    ? " 1 Mois "
                    : info?.duree === 3
                    ?  " 3 Mois "
                    : info?.duree === 6
                    ? " 6 Mois"
                    :  " 12 Mois "}</Text>
        <Text style={styles.title}>  MONTANT ECHEANCIER (Prime périodique TTC, le cas échéant) : {numberWithSpaces(Math.round(info?.prime_ttc/info?.duree))} FCFA </Text>
        <Text style={styles.text3}>La prise d’effet du contrat est assujettie au premier soit de la prime unique soit du premier échéancier.</Text>
        <Text style={styles.title}>ARTICLE 7 – DUREE DU CONTRAT</Text>
        <Text style={styles.text3}>Le présent contrat est conclu pour une durée d’un (1) an, à compter de la date de souscription mentionnée plus haut aux présentes Conditions Générales, sous réserve du paiement de la prime avant échéancier de paiement de la prime, pour les paiements mensuels, trimestriels ou semestriels.</Text>
        <Text style={styles.text3}>Dans le cas des paiements échéanciers, les garanties sont suspendues de plein droit si la prime correspondante n’est pas réglée avant l’arrivée de l’échéance. L’Assuré pourra procéder à une régularisation dans les cinq (05) jours qui suivent. A défaut, le contrat est considéré comme résilié de plein droit.</Text>
      <Signatures data={data} signatures={signatures} />
       <InvoiceTableFooter />
      </Page>
    </Document>
  );
}
