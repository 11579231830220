/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import _ from "lodash";
import { useEffect, useState } from "react";
import { getCIMADeces } from "../../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";
import { calculateAge } from "../../../../utils/Comparaison";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { calculResultAssuranceDeces } from "../../../devis/components/deces/DecesFunction";

export default function ResumDeces({  category_id,  data,  t,  LISTE_ASSUREE,  groupedAssuree,  setLISTE_ASSUREE}){
    
  // Results
  const [results, setResults] = useState({
    prime_pure: 0, //Prime pure
    frais_gestion: 0, // Frais de gestion
    frais_acquisition: 0, // Farais d'acquisition
    prime_commerciale: 0, //PRIME COMMERCIALE
    prime_annuelle: 0, //Prime Annuelle
  });

  useEffect(() => {
    getParamTechByCategorie(category_id)
      .then((result) => {
        if (result?.data?.code === 200) {
          // PARAMETRE TECHNIQUE
          var paramTech = result?.data?.data[0];
          getCIMADeces().then((result) => {
            if (result?.data?.code === 200) {
              var newCIMA = _.groupBy(result?.data?.data,"age")
              if (LISTE_ASSUREE?.length !== 0) {
                /**
                 * ARRAY : LISTE ASSUREE
                 */
                var new_liste_assuree = [];
                LISTE_ASSUREE?.map((items) => {
                  // INFO ASSUREE (NOM ,PRENOM  )
                  var info_assuree = groupedAssuree[items?.assuree_id] !== undefined ? groupedAssuree[items?.assuree_id][0]: {};
                  var total = calculResultAssuranceDeces(
                    paramTech,
                    data?.capital,
                    data?.cotisation,
                    data?.periodicite,
                    calculateAge(new Date(info_assuree?.date_naissance)),
                    newCIMA
                  );
                  /**
                   * CREATE NEW OBJECT TO PUSH INTO THE NEW ARRAY ASSUREE
                   */
                  var obj = {
                    assuree_id: items?.assuree_id,
                    nom_assuree: info_assuree?.nom,
                    prenom_assuree: info_assuree?.prenom,
                    data_naissance_assuree: info_assuree?.date_naissance,
                    prime_pure: total?.prime_pure, //Prime pure
                    frais_gestion: total?.frais_gestion, // Frais de gestion
                    frais_acquisition: total?.frais_acquisition, // Farais d'acquisition
                    prime_commerciale: total?.prime_commerciale, //PRIME COMMERCIALE
                    prime_annuelle: total?.prime_annuelle, //Prime Annuelle
                  };
                  Object.assign(data, total);
                  setResults(total);
                  new_liste_assuree.push(obj);
                });
                setLISTE_ASSUREE(_.uniq(new_liste_assuree));
               
              } else if (LISTE_ASSUREE?.length === 0) {
                var total = calculResultAssuranceDeces(
                  paramTech,
                    data?.capital,
                    data?.cotisation,
                    data?.periodicite,
                    calculateAge(new Date(data?.date_naissance)),
                    newCIMA
                );
                Object.assign(data, total);
                setResults(total);
              }
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className=" card bg-purple mt-0 mb-3 ">
    
      <div className="card-body">
      <table className="table mb-0 mt-0">
        <tbody className="text-white">
          {/*   <tr>
            <td className=" fz-10">{t("Prime Pure")} </td>
              <td className="fz-10 text-center">
                {numberWithSpaces(
                  parseFloat(results?.prime_pure).toFixed(2)
                )}
              </td>
            </tr>
            <tr>
              <td className=" fz-10">{t("Frais de gestion")} </td>
              <td className="fz-10 text-center">
                {numberWithSpaces(Math.round(results?.frais_gestion))}
              </td>
            </tr>
            <tr>
              <td className=" fz-10">{t("Frais d'acquisation")}</td>
              <td className="fz-10 text-center">
                {numberWithSpaces(Math.round(results?.frais_acquisition))}
              </td>
            </tr> */}
            <tr>
              <td className=" fz-10">{t("Prime commerciale unique")}</td>
              <td className="fz-10 text-center">
                {numberWithSpaces(Math.round(results?.prime_commerciale))}
              </td>
            </tr>
            <tr>
              <td className=" fz-10">{t("Prime Annuelle")}</td>
              <td className="fz-10 text-center">
                {numberWithSpaces(Math.round(results?.prime_annuelle))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
