/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import Loader from '../../components/loading/Loader'
import { getdetailsCategorie } from '../../services/categories/CategoriesApi'
import Cookies from 'js-cookie';

export default function DetailsAssurance() {
  const { id } = useParams("")
  //------- INIT LANGAGE 
  const language = Cookies.get('locale')
  //DATA OF ASSURANCE 
  const [data, setdata] = useState({})
  var { titre, titre_en, description, description_en, services, services_en, garenties, garenties_en, logo, avantages, avantages_en, type } = data?.length !== 0 ? data : null
  //Loader
  const [loading, setloading] = useState(true)
  // --------------- TRADUCTION --------------
  const [t] = useTranslation('global')

  useEffect(() => {
    getdetailsCategorie(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setdata(result?.data?.data);
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setloading(false)
      })
  }, [])

  const splitText = (text) => {
    return (text.split("*").map((item, key) => {
      return <p key={key} className="mt-0 fz-12">{item}<br /></p>
    }))
  }
  return (
    <div className="container">
      {loading ? <Loader /> :
        <div >
          <div className="card mb-3 rounded-2 rounded-bottom ">
            <div className="card-body">
              <div className="text-center">
                <h6 className="mb-4 text-center display-6 ">{language === "fr" ? titre : titre_en} </h6>

                <img
                  className="mb-4 text-center"
                  src={process.env.REACT_APP_IMAGE + logo}
                  width={'100px'}
                  alt="Assurance vie"
                />
              </div>
              <div className="accordion accordion-flush accordion-style-two" id="accordionStyle2">
                {/* Single Accordion */}
                <div className="accordion-item">
                  <div className="accordion-header" id="accordionFour">
                    <h6 data-bs-toggle="collapse" data-bs-target="#accordionStyleFour" aria-expanded="false" aria-controls="accordionStyleFour" className="collapsed fz-14">
                      <i className="bi bi-plus-lg" />{t('Définition')}</h6>
                  </div>
                  <div className="accordion-collapse collapse" id="accordionStyleFour" aria-labelledby="accordionFour" data-bs-parent="#accordionStyle2" style={{}}>
                    <div className="accordion-body">
                      <div className="mb-0">{language === "fr" ? splitText(description) : splitText(description_en)}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Accordion */}
                <div className="accordion-item">
                  <div className="accordion-header" id="accordionFive">
                    <h6 className="collapsed fz-14" data-bs-toggle="collapse" data-bs-target="#accordionStyleFive" aria-expanded="false" aria-controls="accordionStyleFive" >
                      <i className="bi bi-plus-lg" />{t("Les garanties")}</h6>
                  </div>
                  <div className="accordion-collapse collapse" id="accordionStyleFive" aria-labelledby="accordionFive" data-bs-parent="#accordionStyle2">
                    <div className="accordion-body">
                      <div className="mb-0">{language === "fr" ? splitText(garenties) : splitText(garenties_en)}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Single Accordion */}
                <div className="accordion-item">
                  <div className="accordion-header" id="accordionSept">
                    <h6 className="collapsed fz-14" data-bs-toggle="collapse" data-bs-target="#accordionStyleSix" aria-expanded="false" aria-controls="accordionStyleSix"><i className="bi bi-plus-lg" />{t("Les services")} </h6>
                  </div>
                  <div className="accordion-collapse collapse" id="accordionStyleSix" aria-labelledby="accordionSix" data-bs-parent="#accordionStyle2">
                    <div className="accordion-body">
                      <div className="mb-0">{language === "fr" ? splitText(services) : splitText(services_en)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="accordionSept">
                    <h6 className="collapsed fz-14" data-bs-toggle="collapse" data-bs-target="#accordionStyleSept" aria-expanded="false" aria-controls="accordionStyleSept">
                      <i className="bi bi-plus-lg" />{t("Les avantages")} </h6>
                  </div>
                  <div className="accordion-collapse collapse" id="accordionStyleSept" aria-labelledby="accordionSept" data-bs-parent="#accordionStyle2">
                    <div className="accordion-body">
                      <div className="mb-0">{language === "fr" ? splitText(avantages) : splitText(avantages_en)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <a
                  className="btn btn-creative btn-purple  btn-lg text-center col-12 fz-12"
                  href={language==="fr"? "/devis_produits/id=" + id + "&title=" + titre + "&type=" + type :"/devis_produits/id=" + id + "&title=" + titre_en + "&type=" + type }
                >
                  {t("Simulation Devis")}
                </a>
                <a
                  className="btn btn-creative btn-purple  btn-lg text-center col-12 mt-2 fz-12"
                  href={language==="fr"? "/facturation_produits/id=" + id + "&title=" + titre + "&type=" + type :"/facturation_produits/id=" + id + "&title=" + titre_en + "&type=" + type }
                >
                  {t("Souscription")}
                </a>
              </div>
            </div>
          </div>


        </div>
      }

    </div>
  )
}
