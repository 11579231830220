/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React, { useState } from "react";
//-------------FORM bibliothéque -------------
import { useForm } from "react-hook-form";
//------------- COMPONENTS -------------
import Loader from "../../../../components/loading/Loader";
// # UTILS : FUNCTION NUMBER WITH SPACES
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
//----------------- TARIFS DES FORMULES --------------
//import tableTarifs from "../../../../formules/auto/tarifs_assurance_vehicule.json";
//---------------- LOADSH -------------
import _ from "lodash";
import Icon from "../../../../components/obligatoire/Icon";
import { useParams } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { getFormuleAuto } from "../../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";

export default function DevisVehicule({ t }) {
  const { id, title, type } = useParams("");
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, watch } = useForm(); //FORM DATA
  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  const [show, setshow] = useState(false);

  // Results
  const [results, setResults] = useState({
    primenette: 0, // SUM PRIME NETTE
    accessoires: 0, // ACCESSOIRES
    taxes: 0, // TAXES
    fga: 0, // FGA
    primettc: 0, // PRIME TTC
  });
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);

  function onFormSubmit(dataRes) {
    var { valeur_venale, puissance, formule, duree } = dataRes;
    const valeur =
      valeur_venale !== undefined && valeur_venale.replace(/\s+/g, "");

    getFormuleAuto()
      .then((result) => {
        if (result?.data?.code === 200) {
          //------------ GROUPE FORMULE PUISSANCE -----------
          var group_formule = _.groupBy(result?.data?.data, "puissance");
          var table = group_formule[puissance];
           getParamTechByCategorie(id).then((res) => {
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj",res)
               var paramTech = res?.data?.data[0]
              var taux_taxes= parseInt(paramTech?.taxes)/100
                var result = table?.filter(
            (el) => el.formule === formule && el.duree === parseInt(duree)
          );
          var object = result[0];
          if (formule === "Karangue") {
            var total = object?.rc + object?.dr + object?.frais;
            var taxe = total * taux_taxes;
            var fg = total * parseFloat(object?.fga);
          } else if (formule === "Aar") {
            var total =
              object?.rc + object?.dr + object?.frais + object?.ar + object?.pt;
            var taxe = total * taux_taxes;
            var fg = total * object?.fga;
          } else if (formule === "Noflaye") {
            var total =
              object?.rc +
              object?.dr +
              object?.frais +
              object?.ar +
              object?.pt +
              object?.bdg;
            var taxe = total *taux_taxes;
            var fg = total * object?.fga;
          } else if (formule === "Diam") {
            var vol = object?.vol * valeur;
            var incendie = object?.incendie * valeur;
            var total =
              object?.rc +
              object?.dr +
              object?.frais +
              object?.ar +
              object?.pt +
              object?.bdg +
              vol +
              incendie;
            var taxe = total * taux_taxes;
            var fg = total * object?.fga;
          } else if (formule === "Ngalam" || formule === "Wourous") {
            var vol = object?.vol * valeur;
            var incendie = object?.incendie * valeur;
            var dommages = object?.DOMMAGES * valeur;
            var total =
              object?.rc +
              object?.dr +
              object?.frais +
              object?.ar +
              object?.pt +
              object?.bdg +
              vol +
              incendie +
              dommages +
              object?.remorq;
            var taxe = total *taux_taxes;
            var fg = total * object?.fga;
          }
          setResults({
            primenette: total,
            accessoires: object?.frais,
            taxes: taxe,
            fga: fg, // FGA
            primettc: total + taxe + fg  // PRIME TTC
          });
           }).catch((err) => {
            console.log(err);
           });
        
          setshow(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  

  
  }

  return (
    <div>
      <h5 className="mt-4 mb-4 text-center"> {t("Devis Véhicule")} </h5>
      <div className="text-center">
        <img
          className="mb-4 text-center"
          src="../img/bg-img/21.png"
          width={"100px"}
          alt="Assurance vie"
        />
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-group mb-3">
          <label className="form-label fz-12">
            {t("Durée")}
            <Icon />
          </label>
          <select className="form-select fz-12" {...register("duree")}>
            <option value={""}>{t("Choisir")} </option>
            <option value={1}>{t("1 Mois")}</option>
            <option value={3}>{t("3 Mois")}</option>
            <option value={6}>{t("6 Mois")}</option>
            <option value={12}>{t("12 Mois")}</option>
          </select>
        </div>
        <div className="form-group mb-3">
          <label className="form-label fz-12">
            {t("Puissance")}
            <Icon />
          </label>
          <select className="form-select fz-12" {...register("puissance")}>
            <option> {t("Choisir")} </option>
            <option value={"categorie1"}> {t("3 à 6 CV")} </option>
            <option value={"categorie2"}> {t("7 à 10 CV")} </option>
            <option value={"categorie3"}> {t("11 à 14 CV")} </option>
            <option value={"categorie4"}> {t("15 à 23 CV")} </option>
            <option value={"categorie5"}> {t("24 à + CV")} </option>
          </select>
        </div>
        <label className="form-label fz-12">
          {t("Formules")} <Icon />
        </label>
        <div className="mb-3">
          <div
            className={
              watch("formule") === "Karangue"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Karangue"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Karangue:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Aar"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Aar"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Aar:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Noflaye"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Noflaye"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Noflaye:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Diam"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Diam"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Diam:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
                <li className="fz-12">{t("Vol")}</li>
                <li className="fz-12">{t("Incendie")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Ngalam"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Ngalam"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Ngalam:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
                <li className="fz-12">{t("Vol")}</li>
                <li className="fz-12">{t("Incendie")}</li>
                <li className="fz-12">{t("Remorquage")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Wourous"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Wourous"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Wourous:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
                <li className="fz-12">{t("Vol")}</li>
                <li className="fz-12">{t("Incendie")}</li>
                <li className="fz-12">{t("Remorquage")}</li>
              </label>
            </div>
          </div>
        </div>

        {(watch("formule") === "Diam" ||
          watch("formule") === "Ngalam" ||
          watch("formule") === "Wourous") && (
          <div className="form-group mb-3">
            <label className="form-label fz-12">
              {t("Valeur vénale")}
              <Icon />
            </label>
            <CurrencyInput
              name="prime_commercial"
              className="form-control fz-12"
              placeholder="Valeur Vénale voiture ..."
              decimalScale={2}
              {...register("valeur_venale")}
              required
            />
          </div>
        )}
        <div className="row">
          <div className="col-12 ">
          <button type="submit" className="btn btn-purple col-12 fz-12">

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-calculator me-2"
                viewBox="0 0 16 16"
              >
                <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
              </svg>
              {t("Calculer")}
            </button>
          </div>
        </div>
        {show && (
          <div className="row">
            <div className="col-12">
              <div className="card mb-4 ">
                <div className="card-body">
                  <h5 className="text-center"> {t("Total du calcul")} </h5>
                  <table className="table  mb-0 table-striped-primary mt-3">
                    {loaderResult ? (
                      <Loader />
                    ) : (
                      <tbody>
                        <tr>
                          <td>{t("Prime Nette")} + {t("Accessoires")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.primenette))}{" "}
                            (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Accessoires")}</td>
                          <td className="text-center">
                            {numberWithSpaces(results?.accessoires)} (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Taxe")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.taxes))}{" "}
                            (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("FGA")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.fga))} (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Prime TTC")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.primettc))}{" "}
                            (FCFA)
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className=" float-end">
                      <a
                        href={
                          "facturation_produits/id=" +
                          id +
                          "&title=" +
                          title +
                          "&type=" +
                          type
                        }
                        className="btn btn-success col-12 fz-12 mt-3 mb-3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-receipt me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                          <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                        </svg>
                        {t("Souscrire")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
