import { Page, Document, StyleSheet, Image, View, Text } from "@react-pdf/renderer";
import dikotoech from "../conditionParticuliere/logo_dikotech.jpeg";
import InvoiceTableFooter from "../invoice/InvoiceTableFooter";
import logo from "../pdfContrat/logo10_3_161442.jpeg"

const borderColor = "#fff";

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.2,
    fontFamily: "Times-Roman",
    flexDirection: "column",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 75,
    paddingHorizontal: 35,
  },
  enteteRow3: {
    marginTop: 26,
    width: 200,
    fontSize: 18,
  },
  logo: {
    width: 80,
    height: 40,
    marginTop: -20,
  },
  logo2: {
    width: 60,
    height: 40,
    marginTop: -20,
    marginLeft: 30,
    textAlign: "right",
  },
  entete: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
  },
  entete1: {
    flexDirection: "row",
  },
  enteteRow2: {
    width: 100,
    textAlign: "right",
  },
  enteteRow: {
    width: 100,
  },
  headerTitle: {
    letterSpacing: 2,
    fontSize: 8,
    color: "#4e545d",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 0,
  }, 
  reportTitle: {
    letterSpacing: 4,
    fontSize: 16,
    textAlign: "center",
    fontWeight: 800,
    textTransform: "uppercase",
    marginBottom: 0,
  },
  descriptionLeft: {
    width: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontFamily: "Times-Roman",
  },
  descriptionright: {
    width: "100%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontFamily: "Times-Roman",
  },
  signatures: {
    width: 100,
    height: 50,
    marginTop: -20,
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontFamily: "Times-Roman",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#e5e5e5",
    color: "#443f52"

  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
});

export default function ConditionGeneral({ description,data }) {
  console.log("condition generale ")
  console.log("condition generale ")
  console.log("condition generale ")
  console.log("condition generale ",description)
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View style={styles.entete}>
          <View style={styles.enteteRow}>
            <Image style={styles.logo} src={dikotoech} />
          </View>
          <View>
            <Text style={styles.headerTitle}>Partenaire distributeur de </Text>
          </View>
          <View style={styles.enteteRow2}>
          {console.log("image in back end est ",process.env.REACT_APP_IMAGE )}
          {console.log("image front end data est ", data?.company?.logo)}
            <Image
              style={styles.logo2}
              src={process.env.REACT_APP_IMAGE + data?.company?.logo}
              // src={logo}
            />
          </View>
        </View>
        <Text style={styles.text}>{description}</Text>
        <InvoiceTableFooter />

      </Page>
    </Document>)
}
