import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';

export default function MapLeaflet({SAVELISTE,map,setMap}) {
    const mapContainer = useRef(null);
 

  useEffect(() => {
    let isMounted = true;

    const initMap = () => {
      const mapInstance = L.map('map', {
        center: [14.7167, -17.4677], // center on Dakar, Senegal
        zoom: 9,
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
      }).addTo(mapInstance);

      // add search control
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider: provider,
        showMarker: false,
        style: 'bar',
        autoClose: true,
        searchLabel: 'Enter address, city, or country',
        keepResult: true,
      });
      mapInstance.addControl(searchControl);

      if (isMounted) {
        setMap(mapInstance);
      }
    };

    initMap();

    return () => {
      isMounted = false;
      if (map) {
        map.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (map && SAVELISTE.length > 0) {
      SAVELISTE.forEach((store) => {
        const marker = L.marker([store.latitude, store.longitude]).addTo(map);
        marker.bindPopup(`<b>${store.type}</b><br>${store.region}, ${store.libelle !== null ? store.libelle:""} <br>Mobile 1: ${store.mobile_1  !== null ? store.mobile_1:"Aucun"} <br>Mobile 2: ${store.mobile_2 !== null ? store.mobile_2:"Aucun"} <br>Mobile 3: ${store.mobile_3 !== null ? store.mobile_3:"Aucun"}`);
      });
    }
  }, [map, SAVELISTE]);

      return <div className='container'>
        
        <div id="map" style={{ height: '300px' }} />
      </div>

}
