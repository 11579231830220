/**
 * INTERFACE DE Saisie du mail de l'utilisateur pour modifier son mot de passe
 * Page : /forgot-password
 */

import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DotLoader from '../../components/loading/DotLoader';
import { forgot_password } from '../../services/signin/LoginAPI';

export default function ForgetPassword() {
  //History
  const history = useHistory()
  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit} = useForm();
  const handleError = (errors) => { };
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);

  const handleEmail = (data) => {
    var { email } = data
    setLoading(true)
    forgot_password(email).then((result) => {
      if (result?.data?.code === 200) {
        toast(t("Un E-Mail est envoyé pour la modification de mot de passe !"), { type: "success", position: "top-right", })
        history.push({
          pathname: "/verif_mail"
        })

      } else if (result === 500) {
        toast(t("Auncun utilisateur n'est inscrit avec cet E-Mail"), { type: "error", position: "top-right", })
        setLoading(false)
      }
    }).catch((err) => {
      console.log(err)

    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div >
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <div className="text-center px-4"><img className="login-intro-img" src="img/bg-img/43.png" alt="forgotPassword" /></div>
          {/* Register Form */}
          <div className="register-form mt-4">
            <form onSubmit={handleSubmit(handleEmail, handleError)}>
              <h1> {t("Mot de passe oublié")}</h1>
              <div className="form-group text-start mb-3">
                <input className="form-control"  {...register("email")}
                  type="email" placeholder="Enter your email address" required />
              </div>
              {loading ? <button className="btn btn-success w-100" disabled><DotLoader /> </button> : 
              <button className="btn btn-success w-100" type="submit">{t("Réinitialiser le mot de passe")}
              </button>}
            </form>
          </div>
        </div>
      </div>
    </div>


  )
}
