/* eslint-disable no-unused-vars */
import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { useUser } from '../Auth/useUser'

export default function PrivateRoute({
    component: Component,
    layout: Layout,
    ...rest
  }) {
    const user = useUser()
  return (
    <Route
    {...rest}
    render={(props) => (
      <Layout>
        {user !== null ? <Component {...props}></Component> : <Redirect to="/welcome" />}
      </Layout>
    )}
  ></Route>
  )
}
