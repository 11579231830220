// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"
import authHeader from "../authentification/AuthHeader";

//GET URL FROM .ENV ELEMENT
import { getUrl } from "../authentification/DotenvConfig"

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
const url = getUrl()


 export async function getUser(id) {
    try {
        const response = await axios.get(url + "users/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * VERIFY USER
 * @param {*} id 
 * @returns 
 */
export async function verifyUser(id , data) {
    try {
        const response = await axios.post(url + "users/verify-password/"+id,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
export async function updateCodePIN(id , data) {
    try {
        const response = await axios.patch(url + "users/update_codePIN/"+id,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * CREATE CODE PIN
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export async function createPIN(id , data) {
    try {
        const response = await axios.put(url + "users/create-codePin/"+id,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * Verify Identifcal code pin (chiffree , code)
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export async function verifyCodePIN(id , data) {
    try {
        const response = await axios.post(url + "users/verify-codePIN/"+id,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * 
 * @param {*} data 
 * @returns 
 */
export async function updateParrain (data){
    try {      
        const response = await axios.patch(url + "users", data ,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * update user profil informations
 * like birthday , phone number ... ETC
 * @param {*} data 
 * @returns 
 */
export async function updateUser(data) {
    try {
        const formData = new FormData();

        formData.append("id", data.id);
        formData.append("nom", data.nom);
        formData.append("prenom", data.prenom);
        formData.append("email", data.email);
        formData.append("mobile", data.mobile);
        formData.append("gender", data.gender);
        formData.append("date_naissance", data.date_naissance);
        formData.append("lieu_naissance", data.lieu_naissance);
        formData.append("image", data.image);

        const response = await axios.put(url + "users", formData ,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}