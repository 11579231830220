import { View,Text ,StyleSheet} from "@react-pdf/renderer";
import moment from "moment";
import { Fragment } from "react";
const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 10,
        marginBottom:1
    },
    description1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 9,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"

    },
    description: {
        width: "50%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
    description3: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"
    },
});

export default function ReferenceSouscripteur ({ type , data,user_details,personnel}) {
    const rows = (
        <View style={styles.container}>
            <View style={styles.row}>
            <Text style={styles.description3}>Nom & Prénom(s) : {user_details?.prenom+" " + user_details?.nom}</Text>
            </View>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Point de vente : 6400 - DIOKOTECH</Text>
                <Text style={styles.description}> N° Client : {"0000"+user_details?.id}</Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description1}>Date de naissance : {moment(user_details?.date_naissance).format("DD/MM/YYYY")} </Text>
            <Text style={styles.description}>Lieux de Naissance : {user_details?.lieu_naissance} </Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description1}>Tél.Portabl : {user_details?.mobile}</Text>
            <Text style={styles.description}>Tél.Fixe : - </Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description1}>E-Mail : {user_details?.email}</Text>
            <Text style={styles.description}>N° CNI : {personnel?.n_cin}</Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description3}>Adresse : {data?.adresse} </Text>
            </View>
        </View>
    );
    return <Fragment>{rows}</Fragment>;
}
