import { t } from 'i18next'
import React from 'react'

export default function PaiementSuccee() {
  return (
    <div>
        <div className="page-content-wrapper py-3">
          <div className="custom-container">
            <div className="card">
              <div className="card-body px-5 text-center"><img className="mb-4" src="img/bg-img/Ok-amico.png" alt="404"/>
                <h4> <br /> Paiement effectué avec succès</h4>
                <p className="mb-4">...........................</p>
                {/* <a href='/liste_contrats'>test </a> */}
                <a
          className="btn btn-success text-white fz-10 float-end"
          href={"/liste_contrats" }
           >
          {t("Liste Contrat")}
          <i className="bi bi-arrow-right  fz-10" />
        </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
