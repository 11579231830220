/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../Auth/useUser";
import { error_message } from "../../components/alerte/AlerteMessage";
import Loader from "../../components/loading/Loader";
import { getListeAssuree } from "../../services/assuree/assureeAPI";
import DeleteAssuree from "./DeleteAssuree";
import Cookies from 'js-cookie';

export default function Assure() {
  const user = useUser();
  // -------------- LISTE USERS --------
  const [liste, setliste] = useState([]);
  // -------------- Loading -----------
  const [loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //Modal DELete
  const [OK1, setOK1] = useState(false);
  const [deleteCode, setdeleteCode] = useState("");
  const [indexDelete, setindexDelete] = useState(-1);
  const language = Cookies.get('locale')

  {  language === "fr" ? require('moment/locale/fr') : require('moment/locale/en-au') }

  useEffect(() => {
    var data = user?.result;
    getListeAssuree(data?.id)
      .then((result) => {
        const informations = result?.data; //results data
        if (informations?.code === 200) {
          var LISTE = result?.data?.data;
          setliste(LISTE);
        } else {
          error_message(t("Erreur Temporaire !"))
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OK1,indexDelete]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container mt-3">
          {liste?.length === 0 ? (
            <div className="card text-center" >
              <div classNaame="card-body px-5 text-center">
                <h4>
                  {t("Aucun Assuré")} <br />
                </h4>
                <p className="mb-4">
                {t("Veuillez ajouter un ou plusieurs assuré(s) pour s'implifier les procédures de création de vos contrat")}
                </p>
                <img className="mb-4" src="img/bg-img/100.png" width="250px" alt="NO DATA" />
                <div className="row">
                  <div className="col-12">
                  <a className="btn btn-creative btn-purple mb-4 col-12" href="/add_assuree">
                  <i className="bi bi-plus me-2" /> {t("Ajouter Assuré(e)")}
                </a>
                  </div>
                </div>
              
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 ">
                <a
                  className="btn btn-creative btn-purple text-white float-end mb-3 fz-12"
                  href="/add_assuree"
                >
                  <i className="bi bi-plus me-1 fz-12" /> {t("Ajouter Assuré(e)")}
                </a>
              </div>
              {liste?.map((items, keys) => (
                <div className="card timeline-card m-2" key={keys}>
                  <div className="card-body">
                    <h4 className="text-purple text-center mb-3"> </h4>
                    <div className="d-flex justify-content-between">
                      <div className="timeline-text mb-2">
                        <span className="badge mb-2 rounded-pill">
                          {t("Crée le")} :{" "}
                          {moment(items.created_at).format("DD MMMM  YYYY")}
                        </span>
                      </div>
                    </div>
                    <p className="fz-12">
                      <b> {t("Nom & Prénom assuré")}</b> {items.nom + " " + items.prenom}
                    </p>
                    <p className="fz-12">
                      <b>{t("Date naissance assuré")} </b> :
                      {moment(items.date_naissance).format("DD MMMM  YYYY")}
                    </p>
                    <p className="fz-12">
                      <b> {t("N° CIN")} </b> :{" "}
                      {items.cin !== "" ? items.cin : "non affecté"}
                    </p>
                    <p className="fz-12">
                      <b> {t("N° Passeport")} </b> :{" "}
                      {items.passport !== "" ? items.cin : "non affecté"}
                    </p>{" "}
                    <a
                      href="#"
                      className="btn btn-purple float-end me-2 fz-12"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      onClick={() => {
                        setdeleteCode(items.id);
                        setindexDelete(keys);
                      }}
                    >
                      <i className="bi bi-trash fz-12" />
                    </a>
                    <a
                      href={"/edit_assuree/" + items.id}
                      className="btn btn-success text-white me-2 fz-12 float-end"
                    >
                      <i className="bi bi-pencil-square fz-12" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
          <DeleteAssuree
            deleteCode={deleteCode}
            indexDelete={indexDelete}
            setOK1={setOK1}
            OK1={OK1}
            t={t}
          />
        </div>
      )}
    </div>
  );
}
