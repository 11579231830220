import CurrencyInput from "react-currency-input-field";
import Icon from "../../../../components/obligatoire/Icon";
import { calculateAge } from "../../../../utils/Comparaison";

export default function Education({ register, t, groupedAssuree, LISTE_ASSUREE, handleChangeAssuree }) {

  return (
    <div>
      {LISTE_ASSUREE?.length !== 0 ? (
        LISTE_ASSUREE?.map((items, keys) => <div key={keys}>
        <h6 className="text-center mt-2">{groupedAssuree[items.assuree_id][0]?.nom+" "+groupedAssuree[items.assuree_id][0]?.prenom} </h6>
           <div className="form-group mb-3">
             <label className="mb-2 fz-12">
              {t("Periodicité")} <Icon />
            </label>
            <select className="form-select fz-12"    
              name="periodicite"
              value={items?.periodicite}
              onChange={(e) => handleChangeAssuree(e, keys)}>
              <option value={""}>{t("Choisir")} </option>
              <option value={1}>{t("Annuelle")}</option>
              <option value={2}>{t("Semestrielle")}</option>
              <option value={4}>{t("Trimistrielle")}</option>
              <option value={12}>{t("Mensuelle")}</option>
            </select>
          </div>
          <div className="form-group mb-3">
             <label className="mb-2 fz-12">
              {t("Prime Commerciale")} <Icon />
            </label>
            <CurrencyInput
                id="input-example"
                name="prime_commercial"
                className="form-control"
                placeholder="Prime ..."
                decimalsLimit={2}
                defaultValue={parseFloat(items?.prime_commercial)}
                onChange={(e) => handleChangeAssuree(e, keys)}
                required
              />
          </div>
          <div className="form-group mb-3">
          <label className="form-label fz-12">
                {t("Durée de la rente (année)")} <Icon />
              </label>
              <select
                className="form-select fz-12"
                name="duree"
                value={items?.duree}
                onChange={(e) => handleChangeAssuree(e, keys)}
                required
              >
               <option value={""}>{t("Choisir")} </option>
               <option value={0}>{t("Option sans rente")} </option>
                <option value={1}>1 {t("an")}</option>
                <option value={2}>2 {t("ans")}</option>
                <option value={3}>3 {t("ans")}</option>
                <option value={4}>4 {t("ans")}</option>
                <option value={5}>5 {t("ans")}</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
              {t("Durée de cotisation")} 
              </label>
              <input
                className="form-control fz-12"
                type="number"
                name="cotisation"
                placeholder={t("Durée de cotisation")+"..."} 
                onChange={(e) => handleChangeAssuree(e, keys)}
                required
              />
              <label className="fz-12 text-purple mt-2">  {t("L'intervalle d'age permis pour la durée de cotisation est de")} {"[ 5 "+t("ans")} - {(25-calculateAge(new Date(groupedAssuree[items.assuree_id][0]?.date_naissance)))+t("ans")+"]"} </label>
            </div>
        </div>
        )
      ) : (
        <>
          <div className="form-group mb-3">
             <label className="mb-2 fz-12">
              {t("Periodicité")} <Icon />
            </label>
            <select className="form-select fz-12"  {...register("periodicite")}>
              <option value={""}>{t("Choisir")} </option>
              <option value={1}>{t("Annuelle")}</option>
              <option value={2}>{t("Semestrielle")}</option>
              <option value={4}>{t("Trimistrielle")}</option>
              <option value={12}>{t("Mensuelle")}</option>
            </select>
          </div>
          <div className="form-group mb-3">
             <label className="mb-2 fz-12">
              {t("Prime Commerciale")} <Icon />
            </label>
            <input
                name="prime_commercial"
                className="form-control"
                placeholder="Prime ..."
                {...register("prime_commercial")}
                required
              />
          </div>
          <div className="form-group mb-3">
             <label className="mb-2 fz-12">
              {t("Durée de la rente (année)")}
              <Icon />
            </label>
            <input
              className="form-control fz-12"
              type="number"
              placeholder="Cotisiation ..."
              {...register("duree")}
              required
            />
          </div>
        </>
      )}
    </div>
  );
}
