import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#fff";

const styles = StyleSheet.create({
    container :{
        borderBottomColor:"#4e545d",
        borderBottomWidth: 1,
    },
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 8,
        
    },
    description1: {
        width: "25%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily:'Arial',
        fontStyle:"normal"
    },
    description: {
        width: "25%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 8,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:'Arial',
        fontStyle:"normal"
    },
});
export default function TableauBeneficiaire({ type , data,user_details,personnel}) {
    const rows = (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.description1}>Infos Bénéficiaires</Text>
                <Text style={styles.description1}> Bénéficiaire 1</Text>
                <Text style={styles.description1}> Bénéficiaire 2</Text>
                <Text style={styles.description}> Bénéficiaire 3</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Prénoms</Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description}> </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Nom</Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description}> </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Date de naissance</Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description}> </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Lieu de naissance</Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description}> </Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Nationalité</Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description1}> </Text>
                <Text style={styles.description}> </Text>
            </View>
        </View>
    );
    return <Fragment>{rows}</Fragment>;
}
