export function calculResultRetraite (prime_commercial, periodicite, duree_contrat ,durre_rente, paramTech){
    /**
     * CALCUL 
     * CAPITAL GARANTI 
     */

    //TAUX D'INTERT ANNUEL
    var i = 0.035 
    // ip = ((1+i)^(1/p))-1
    var ip = Math.pow((1+i),(1/parseInt(periodicite)))-1
    //(1-FG-FA)
    var step_1 = (1- parseFloat(paramTech?.frais_gestion) - parseFloat(paramTech?.frais_acquisition))
    // (1+ip)
    var step_2 = (1+ip) 
    //(1+ip)^n
    var n = parseFloat(duree_contrat*periodicite)
    var step_3 = Math.pow(step_2,n)
    //(1+ip)^n-1
    var step_4 = step_3-1
    //(1+ip)^n-1/ip
    var step_5 = parseFloat(step_4/ip)
    //(1+ip)*(1+ip)^n-1/ip
    var step_6 = parseFloat(step_5*step_2)
    //c*(1+ip)*(1+ip)^n-1/ip
    var step_7 = step_6*parseFloat(prime_commercial)
    //(1-FA-FG)*c*(1+ip)*(1+ip)^n-1/ip
    var step_8 = step_7*step_1
    //[(1-FA-FG)*c*(1+ip)*(1+ip)^n-1/ip)] - cout police
    var step_9 = step_8- paramTech?.cout_police

    /**
     * CALCUL
     * RENTE ANNUELLE EQUIVALENTE CERTAINE
     */

    //(1- FGR)
    var step_10 = (1- parseFloat(paramTech?.frais_gestion_rente))
    // (1- FGR)* CG
    var step_11 = step_10 * step_9
    //[1-(1/(1+i)]/[1-(1/(1+i)^r]
    var step_12  = (1-(1/(1+i)) ) / ( 1-(1/Math.pow((1+i),parseInt(durre_rente)) ) )
    // (1- FGR)* CG * [1-(1/(1+i)]/[1-(1/(1+i)^r]
    var step_13 = step_11*step_12

    return {
        capital_acquis : step_9,
        total_rente : step_13
    }
}