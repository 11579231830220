import axios from "axios"
import payHeader from "../authentification/payHeader";

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
const url = "https://api.intech.sn/api-services/"
/**
 * 
 * @param {*} data 
 * @returns 
 */
export async function paiementPaytech(data) {
    try {
        const response = await axios.post(url+"operation", data, { headers: payHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function ErrorsPaiementPaytech() {
    try {
        const response = await axios.get(url+"errors", { headers: payHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function verifPaiement(data) {
    try {
        const response = await axios.post(url+"get-transaction-status",data, { headers: { 
            'Secretkey': "89483A1C-7718-4E57-9B80-0DA18F47D2CC"
          }, }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}