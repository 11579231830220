import moment from "moment";
import { useState } from "react";
import {
  error_message,
  success_message,
} from "../../../../components/alerte/AlerteMessage";
import DotLoader from "../../../../components/loading/DotLoader";
import { verifCodePromo } from "../../../../services/promotions/promotionsAPI";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import MoyenPaiement from "./MoyenPaiement";

export default function MethodePaiement({ id, handleSubmit, payerContrat, t, type, data, array_pay, register, watch, paiement, setPaiement, setRemise, cumul_des_points,enableCountDown,mobile,setmobile
}) {
  console.log("****")
  console.log("****")
  console.log("****",id, handleSubmit, payerContrat, t, type, data, array_pay, register, watch, paiement, setPaiement, setRemise, cumul_des_points,enableCountDown,mobile,setmobile)
  // CODE DE PROMOTIONS
  const [code, setCode] = useState("");
  const [message, setmessage] = useState(""); // MESSAGE CODE PROMO
  const [message_parrain, setmessage_parrain] = useState(""); // MESSAGE PARRAINAGE
  const [promotion, setPromotion] = useState(0);
  const [saveCUMUL, setsaveCUMUL] = useState(cumul_des_points);
  const [points_cumuls, setpoints_cumuls] = useState(0);
  var [increment, setincrement] = useState(1);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
 var montantRemise = 0;
  const onClickCodePromo = () => {
    if (increment > 1) {
      error_message(
        t("Vous ne pouvez tirer parti que d'un code coupon à la fois.")
      );
    } else {
      verifCodePromo({
        code_promo: code,
        date: moment(new Date()).format("YYYY-MM-DD"),
        id: id,
      })
        .then((result) => {
          if (result?.data?.code === 200) {
            console.log("result of code promo",result?.data?.data)
            var data_code_promo = result?.data?.data;
            if (data_code_promo?.length === 0) {
              setmessage(t("Votre code promo n'est pas valide !"));
              error_message(t("Votre code promo n'est pas valide !"));
            } else {
              setmessage(
                t("*** Vous avez une réduction de ") +
                  data_code_promo[0]?.taux +
                  "%"
              );
              success_message(
                t("*** Vous avez une réduction de ") +
                  data_code_promo[0]?.taux +
                  "%"
              );
              setincrement(increment + 1);
              var total_reduction = data_code_promo[0]?.taux / 100;
              if (
                type === "education" ||
                type === "retraite" ||
                type === "deces"
              ) {
                
                var reduction = Math.round(
                  paiement - paiement * total_reduction
                );
                montantRemise = paiement * total_reduction
              } else if (type === "accident") {
                var value_ht = data?.accessoires + data?.totalHt;
                montantRemise = value_ht * total_reduction

                var total = value_ht - value_ht * total_reduction + data?.taxes;
                reduction = Math.round(total);
              } else if (type === "voyage") {
                var value_ht_1 = data?.cout_police + data?.primenette;
                montantRemise = value_ht_1 * total_reduction
                var total_1 =
                  value_ht_1 - value_ht_1 * total_reduction + data?.taxe;
                reduction = Math.round(total_1);
              } else if (type === "vehicule") {
                var value_ht_2 = data?.accessoires + data?.primenette;
                console.log("valeur de vehicule dddd",value_ht_2)
                var total_2 =
                  value_ht_2 -
                  value_ht_2 * total_reduction +
                  data?.taxes +
                  data?.remorq +
                  data?.fga;
                reduction = Math.round(total_2);
                console.log(" vehicule reduction ",reduction)
                montantRemise = value_ht_2 * total_reduction
              }
              setPaiement(reduction > 0 ? reduction : 0);
              setRemise(montantRemise)
              setPromotion(data[0]?.taux);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onClickCumulPoints = () => {
    if (points_cumuls > saveCUMUL) {
      error_message(
        t("Vous ne pouvez pas profiter d'un nombre de poins supérieur à ") +
          saveCUMUL
      );
      setmessage_parrain(
        t("Vous ne pouvez pas profiter d'un nombre de poins supérieur à ") +
          saveCUMUL
      );
      setpoints_cumuls(0);
    } else if (isNaN(points_cumuls)) {
      error_message(t("Vous devez saisir un chiffre "));
      setmessage_parrain(t("Vous devez saisir un chiffre "));
    } else if (points_cumuls <= saveCUMUL) {
      var total_reduction = points_cumuls * 50;
      var reduction = 0;
      if (type === "education" || type === "retraite" || type === "deces") {
        reduction = Math.round(paiement - total_reduction);
      } else if (type === "accident") {
        var total =
          data?.accessoires + data?.totalHt - total_reduction + data?.taxes;
        reduction = Math.round(total);
      } else if (type === "voyage") {
        var total_1 =
          data?.cout_police + data?.prime_nette - total_reduction + data?.taxe;
        reduction = Math.round(total_1);
      } else if (type === "vehicule") {
        var total_2 =
          data?.accessoires +
          data?.primenette -
          total_reduction +
          data?.taxes +
          data?.remorq +
          data?.fga;
        reduction = Math.round(total_2);
      }

      setsaveCUMUL(saveCUMUL - points_cumuls);
      setPaiement(reduction > 0 ? reduction : 0);
      setmessage_parrain(
        t("Vous avez profiter d'une réduction de ") + total_reduction + " FCFA"
      );
    }
  };
  return (
    <div>
      <div className="form-check">
        <input
          className="form-check-input text-muted fz-12"
          type="checkbox"
          checked={show1}
          onChange={(e) => setshow1(e.target.checked)}
        />
        <label
          className="form-check-label text-muted fz-12"
          htmlFor="checkedCheckbox"
        >
          {t("Avez-vous un")}
          <b className="fw-bold text-purple"> {t("coupon de réduction")}</b> ?
        </label>
      </div>
      {show1 && (
        <div className="apply-coupon">
          <p className="mb-2 fz-12 text-muted">
            {t("Entrez votre code promo ici et obtenez des réductions incroyables")} !
          </p>
          <div className="coupon-form">
            <div className="form-group">
              <div className="input-group">
                <input
                  className="form-control input-group-text text-start fz-12"
                  type="text"
                  placeholder="Code de promotions.."
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />

                <button
                  className="btn btn-success fz-12"
                  onClick={() => onClickCodePromo()}
                >
                  {t("Confirmer")}
                </button>
              </div>
            </div>
            <p className="mb-2 fz-10 text-danger">{message}</p>
          </div>
        </div>
      )}
      <div className="form-check mt-2">
        <input
          className="form-check-input text-muted fz-12"
          type="checkbox"
          checked={show2}
          onChange={(e) => setshow2(e.target.checked)}
        />
        <label
          className="form-check-label text-muted fz-12"
          htmlFor="checkedCheckbox"
        >
          {t("Vous souhaitez bénéficier d'une remise par rapport à vos points cumulés")}
          <b className="fw-bold text-purple">
            {" "}
            (<b>{saveCUMUL}</b>) {t("points")}
          </b>{" "}
          ?
        </label>
      </div>
      {show2 && (
        <div className="apply-coupon">
          <p className="mb-2 fz-12">
            {t("Votre cumul de points est :")} (<b>{cumul_des_points}</b>) {t("points")} ={" "}
            <b>{numberWithSpaces(parseInt(cumul_des_points) * 50)} FCFA</b>
          </p>
          <span className="text-success fz-12 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-info-circle me-1"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            {t("Vous devez entrer un nombre de points.")}{" "}
          </span>

          <div className="coupon-form">
            <div className="form-group">
              <div className="input-group">
                <input
                  className="form-control input-group-text text-start fz-12 mt-2"
                  type="text"
                  placeholder="Nombre de points"
                  value={points_cumuls}
                  onChange={(e) => setpoints_cumuls(e.target.value)}
                />
                <button
                  className="btn btn-success fz-12"
                  onClick={() => onClickCumulPoints()}
                >
                  {t("Confirmer")}
                </button>
              </div>
            </div>
            <p className="mb-2 fz-10 text-danger">{message_parrain}</p>
          </div>
        </div>
      )}
      <div className="shop-pagination mb-3 mt-3">
        <div className="d-flex align-items-center justify-content-between">
          <small className="ms-1 mb-3"> {t("Moyen de paiement")} </small>
        </div>
      </div>

      <form onSubmit={handleSubmit(payerContrat)}>
        <div className="cc-selector">
          <div className="row mb-3">
            {array_pay?.map((items, keys) => (
              <div className="col-4" key={keys}>
                <input
                  id={items.codeService}
                  type="radio"
                  name="credit-card"
                  value={items.codeService}
                  {...register("type_paiement")}
                />
                <label
                  style={{ backgroundImage: "url(" + items.icon + ")" }}
                  className="drinkcard-cc visa"
                  htmlFor={items.codeService}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <label className="form-label me-2 fz-12" htmlFor="portfolio">
            <b>{t("Montant")} (TTC) </b>
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="bi bi-wallet2 text-primary" />
            </span>
            <input
              className="form-control"
              type="text"
              disabled
              placeholder={t("Montant") + "..."}
              value={Math.round(paiement)}
            />
            <span className="input-group-text">FCFA</span>
          </div>
        </div>
        <MoyenPaiement
          watch={watch}
          register={register}
          data={data}
          t={t}
          paiement={paiement}
          mobile={mobile}
          setmobile={setmobile}
        />

        <div className="mt-3 mb-3">
         {enableCountDown ? <button
            className="btn btn-purple float-end  fz-12 mb-3 text-white "
            disabled
          >
           <DotLoader />
          </button> : <button
            className="btn btn-purple float-end  fz-12 mb-3 text-white "
            type="submit"
          >
            <i className="bi bi-check2-circle  me-1 fz-12" />
            {t("Confirmer")}
          </button>}
          {/*    <button className="btn btn-success float-start fz-12 mb-3 text-white">
            <i className="bi bi-arrow-left me-1 fz-12" />
            {t('Previous Step')}
          </button> */}
        </div>
        {enableCountDown && <div className="col-12">
          <p className="fz-12 mt-3">
            {t("Attention ! Vous avez 5 minutes  pour valider votre paiement en ligne. Veuillez vérifier votre téléphone pour confirmer le paiement avant la fin du délai imparti. Si le code n'est pas saisi dans les 5 minutes, la transaction sera annulée.")}
          </p>
       </div>}
      </form>
    </div>
  );
}
