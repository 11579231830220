import Swal from "sweetalert2";
import { deleteMediaSinistre } from "../../services/sinistre/sistreAPI";
import FileSaver from "file-saver";

export default function CardMedia({ t, getExtension, items }) {
  const download = (file) => {
    var filename = file.split("uploads/").pop();
    FileSaver.saveAs(file, filename);
  };

  const handleButton = (id) => {
    Swal.fire({
      title: t("Êtes-vous sure ?"),
      text: t("Êtes-vous sure de vouloir supprimer , l'objet justificatifs de votre déclaration sinistre ?"),
      icon: "info",
      showDenyButton: true,
      confirmButtonText: t("Oui"),
      denyButtonText: t("Non"),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteMediaSinistre(id)
          .then((result) => {
            if (result?.data?.code === 200) {
              Swal.fire(
                t("Votre Media est supprimé avec succès!"),
                "",
                "success"
              );
              window.location.reload();
            } else {
              Swal.fire(t("Erreur de suppression Media !"), "", "error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        Swal.fire(t("Les modifications ne sont pas enregistrées"), "", "info");
      }
    });
  };

  return (
    <tr className="fz-10 text-center">
      <td>{getExtension(items?.file)}</td>
      <td>
        <a
          className="text-purple mb-1 mt-3 me-2 fz-10"
          href={process.env.REACT_APP_IMAGE + items.file}
          download
          onClick={(e) => download(e)}
        >
          <b>{t("Télècharger le document")}</b>
        </a>
      </td>
      <td>
        <td>
          <button
            className="btn btn-danger fz-10"
            onClick={() => handleButton(items.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </button>
        </td>
      </td>
    </tr>
  );
}
