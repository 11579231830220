/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next'
import MainSlider from './slider/MainSlider'
import PrdouctsCards from './cards/PrdouctsCards'
import TabHome from './tabNewsEvents/TabHome'
import Cookies from 'js-cookie';


export default function HomePage() {
  //------------- Traduction ---------------
  const [t] = useTranslation('global')
  const language = Cookies.get('locale')

  {  language === "fr" ? require('moment/locale/fr') : require('moment/locale/en-au') }

  return (
    <div>
      {/** PUBLICITE SLIDER */}
      <MainSlider  t={t} language={language}/>
      <PrdouctsCards t={t} language={language} />
      <TabHome t={t} language={language}/>
   

    </div>
  );
}
