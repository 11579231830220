/* Import modules */
import { useState } from "react";
import { useHistory } from "react-router-dom";

// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";

// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";
import Prefix from "../../components/select/Prefix";
import ConditionGenerale from "./ConditionGenerale";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite";
import DotLoader from "../../components/loading/DotLoader";
import { register_email, verify_parrain } from "../../services/signup/RegisterAPI";
import { error_message, success_message, warning_message } from "../../components/alerte/AlerteMessage";
import Icon from "../../components/obligatoire/Icon";
import { updateParrain } from "../../services/users/usersAPI";
import { addParrainage } from "../../services/parrainage/parrainageAPI";
import Parrainage from "./Parrainage";

export default function Register() {
  //------------- PASSWORD TYPE ------------
  const [password1, setPassword1] = useState("password");
  const [password2, setPassword2] = useState("password");
  //------------- CONDITIONS POLICY --------
  const [codeParrain, setCodeParrain] = useState(false);//Vous avez un code parrain ?
  const [condition1, setcondition1] = useState(false);
  const [condition2, setcondition2] = useState(false);
  //------------- VERIF VALID PASSWORDS ---------------
  const [validPassword, setvalidPassword] = useState(true);

  // # History CALL
  const history = useHistory();
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);

  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit, watch, formState: { errors }} = useForm();

  const handleError = (errors) => { };

  // UPDATE TYPE OF INPUT PASSWORD
  const updateType = (type) => {
    if (type === "p1") {
      if (password1 === "password") {
        setPassword1("text");
      } else {
        setPassword1("password");
      }
    } else if (type === "p2") {
      if (password2 === "password") {
        setPassword2("text");
      } else {
        setPassword2("password");
      }
    }
  };

  /**
   * REGISTER OPTIONS
   */
  const registerOptions = {
    nom: { required: t("Name is required") },
    prenom: { required: t("Name is required") },
    mobile: {
      required: {
        value: true,
        message: "Please add your mobile phone number!",
      },
      pattern: {
        value: /^[0-9+-]+$/,
        message: "This is not a valid mobile phone, try again!",
      },
    },
    email: { required: t("Email is required") },
    password1: {
      required: t("Password is required"),
      minLength: {
        value: 6,
        message: t("Password must have at least 6 characters"),
      },
    },
    password2: {
      required: t("Password is required"),
      minLength: {
        value: 6,
        message: t("Password must have at least 6 characters"),
      },
    },
  };

  /**
   * Handle Registration  : OnSubmit Form function
   * @param {*} data
   */
  const traitement = (data) => {
    var { nom, prenom, mobile, prefix, email, password1, password2, code_parrain, identifiant_parrain ,options } = data;
    const formulaire = {
      nom: nom,
      prenom: prenom,
      mobile: prefix + mobile,
      email: email,
      password: password1,
      role: "user",
    }
    register_email(formulaire)
      .then((result) => {
        var data = result?.data;
        if (data?.code === 500) {
          warning_message(t("Utilisateur déja inscrit ! "))
        } else if (data?.code === 503) {
          error_message(t("Erreur temporaire de l'application ! Merci pour votre compréhension "))
        } else if (data?.code === 200) {
          setvalidPassword(true);
          if (options!== undefined && code_parrain !== undefined && identifiant_parrain !== undefined) {
            verify_parrain({
              code_parrain: code_parrain,
              identifiant_parrain: identifiant_parrain
            }).then((result) => {
              if (result?.data?.code === 200) {
                var data_res = result?.data?.data
                if (data_res?.length !== 0) {
                  updateParrain({
                    id: data_res[0]?.id,
                    cumul_points: data_res[0]?.cumul_points + 1
                  }).then((result) => {
                    if (result?.data?.code === 200) {
                      success_message(t("Votre parrain vous remercie d'avoir saisie son code parrain"))
                      addParrainage({
                        user_id: data_res[0]?.id,
                        email_parraine: email,
                        mobile_parraine: prefix + mobile
                      })
                    } else {
                      error_message(t("Erreur, nous n'avons pas pu traiter votre demande de parrainage"))
                    }
                  }).catch((err) => {
                    console.log(err);
                  });
                } else {
                  warning_message(t("Vous devez vérifier le code parrain ou l'identifiant!"))

                }
              }
            }).catch((err) => {
              console.log(err)
            }
            );
          }
          success_message(t("Inscription avec succès ! Veuillez vérifier votre boite E-Mail"))
          history.push({
            pathname: "/login",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleRegistration = (data) => {
    //console.log(data);
    setLoading(true);
    var { password1, password2 } = data;
    if (password1 !== password2) {
      warning_message(t("Les mots de passes doivent être identiques"))
      setvalidPassword(false);
    } else if (condition1 && condition2) {
      traitement(data)
    } else {
      warning_message(t("Vous devez accepter les conditions d'utilisation et la polique de confidentialité"))
    }
    setLoading(false);
  };

  return (
    <div className=" d-flex align-items-center justify-content-center">
      <div className="custom-container">
        {/* Register Form */}
        <div >
          <h3 className="mb-3 mt-3 text-center">{t("S'inscrire")} </h3>
          <form onSubmit={handleSubmit(handleRegistration, handleError)}>
            {/** ----------------------------------- INFORMATIONS GENERALES ----------------------------------- */}
            <h5 className="mt-4 mb-4 text-center fz-16">
              {t("Coordonnées générales")}
            </h5>
            <div className="row">
             
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label fz-12">
                    {t("Prénom")} <Icon />
                  </label>
                  <input
                    className="form-control fz-12"
                    type="text"
                    placeholder="Prénom"
                    {...register("prenom", registerOptions.prenom)}
                    required
                  />
                  <small className="text-danger fz-12">
                    {errors?.prenom && errors.prenom.message}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label fz-12">
                    {t("Nom")} <Icon />
                  </label>
                  <input
                    className="form-control fz-12"
                    type="text"
                    name="nom"
                    placeholder="Nom"
                    {...register("nom", registerOptions.nom)}
                    required
                  />
                  <small className="text-danger fz-12">
                    {errors?.nom && errors.nom.message}
                  </small>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleInputpassword">
                {t("E-Mail")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="email"
                required
                {...register("email")}
              />
            </div>
            <label className="form-label fz-12" htmlFor="exampleInputpassword">
              {" "}
              {t("téléphone")} <Icon />
            </label>

            <div className="input-group mb-3">
              <Prefix register={register} />
              <input
                className="form-control fz-12"
                type="number"
                style={{width:"60%"}}
                {...register("mobile", registerOptions.mobile)}
                placeholder={t("Entrez le numéro de téléphone")}
              />
            </div>
            <small className="text-danger">
              {errors?.mobile && errors.mobile.message}
            </small>
            {/** ----------------------------------- SEPARATION ----------------------------------- */}
            {/*   <div className="divider border-purple mt-5" /> */}

            {/** ----------------------------------- IDENTIFICATION  ----------------------------------- */}
            <h5 className="mt-2 mb-4 text-center fz-16">
              {" "}
              {t("Coordonnées d'identification")}
            </h5>

            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleTextarea1">
                {t("Mot de passe")} <Icon />
              </label>
              <div className="form-group position-relative">
                <input
                  className="form-control fz-12"
                  id="psw-input"
                  type={password1}
                  {...register("password1", registerOptions.password1)}
                  placeholder="Mot de passe"
                  required
                />
                <div
                  className="position-absolute fz-12"
                  id="password-visibility"
                  onClick={() => updateType("p1")}
                >
                  {password1 === "password" ? (
                    <i className="bi bi-eye" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
                <small className="text-danger fz-12">
                  {errors?.password1 && errors.password1.message}
                </small>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleTextarea1">
                {t("Confirmer le Mot de passe")}{" "}
                <Icon />
              </label>
              <div className="form-group position-relative">
                <input
                   className="form-control fz-12"
                  id="psw-input"
                  type={password2}
                  {...register("password2", registerOptions.password2)}
                  placeholder="Mot de passe"
                  required
                />
                <div
                  className="position-absolute"
                  id="password-visibility"
                  onClick={() => updateType("p2")}
                >
                  {password2 === "password" ? (
                    <i className="bi bi-eye" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
                <small className="text-danger">
                  {errors?.password2 && errors.password2.message}
                </small>
              </div>
              {!validPassword && (
                <span className="text-danger fz-12">
                  ** {t("Les mots de passes doivent être identiques")}{" "}
                </span>
              )}
            </div>
            {/** ----------------------------------- Parrainage  ----------------------------------- */}
                <Parrainage t={t} register={register} watch={watch} />
            {/** ----------------------------------- ConditionGenerales  ----------------------------------- */}
            <h5 className="mt-2 mb-4  fz-16 text-center">
              {t("Conditions générales")}
            </h5>
            <div className="row">
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input text-muted fz-12"
                    type="checkbox"
                    checked={condition1}
                    onChange={(e) => setcondition1(e.target.checked)}
                  />
                  <label
                    className="form-check-label text-muted fz-12"
                    htmlFor="checkedCheckbox"
                  >
                    {t("Je déclare adhérer aux")}{" "}
                    <b
                      className="fw-bold text-purple"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                    >
                      {t(
                        "conditions générales d’utilisation et de vente Assur 3.0"
                      )}
                    </b>
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input text-muted fz-12"
                    type="checkbox"
                    checked={condition2}
                    onChange={(e) => setcondition2(e.target.checked)}
                  />
                  <label
                    className="form-check-label text-muted fz-12"
                    htmlFor="checkedCheckbox"
                  >
                    {t("Je déclare adhérer à")}{" "}
                    <b
                      className="fw-bold text-purple"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                    >
                      {t("la politique de protection des données Assur3.0")}
                    </b>
                  </label>
                </div>
              </div>
            </div>
            {/** ----------------------------------- Envoyer le resultat  ----------------------------------- */}
            <div className="form-group mb-4">
              {loading ? (
                <button className="btn btn-purple mt-3 col-12 text-center" type="submit">
                  <DotLoader />
                </button>
              ) : (
                <button
                  className="btn btn-purple mt-3 col-12 text-center"
                  type="submit"
                >
                  {t("Créer un compte")}
                  <i className="bi bi-arrow-right me-2" />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/** MODAL -------------> CONDITIONS GENERALES D'utilisation */}
      <ConditionGenerale setcondition1={setcondition1} t={t}/>
      {/** MODAL ------------->POLITIQUE DE CONFIDENTIALITE        */}
      <PolitiqueConfidentialite setcondition2={setcondition2} t={t}/>
    </div>
  );
}
