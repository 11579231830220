/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../components/loading/Loader";
import BackToPreviousPage from "../../components/previouspage/BackToPreviousPage";
import {
    getDetailsTicketByID,
    getDetailsTicketMediaByID,
} from "../../services/tickets/ticketAPI";
import { getExtension } from "../../utils/FileExtension";
import CardMediaContaxt from "./CardMediaContaxt";

export default function DetailsContacts() {
    const { id } = useParams("");
    //---------- DETAILS NEWS -----------
    const [details, setdetails] = useState({});
    const [Liste, setListe] = useState([]);
    //---------- Loading -----------------
    const [Loading, setLoading] = useState(true);
    //------------- Traduction ---------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getDetailsTicketByID(id)
            .then((result) => {
                if (result?.data?.code === 200) {
                    setdetails(result?.data?.data[0]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        getDetailsTicketMediaByID(id)
            .then((result) => {
                if (result?.data?.code === 200) {
                    setListe(result?.data?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setTimeout(() => setLoading(false), 1200);
            });
    }, []);
    return (
        <div className="container">
            <div className="row mb-4">
                <div className="col-12 mb-4 mt-4">
                    <div className="float-start">
                        <BackToPreviousPage />
                    </div>
                    <div className="float-end">
                        <a className="btn btn-purple" href={"/media_contacts/id=" + id}>
                            {" "}
                            {t("Ajouter des médias")}
                        </a>
                    </div>
                </div>
                {Loading ? (
                    <Loader />
                ) : (
                    <div className="container">
                        <h5 className="text-center mb-3">{t("Détails de la réclammation")} </h5>

                        <div className="card">
                            <div className="card-body">
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="portfolio">
                                        {t("Type")}
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={details?.type}
                                        disabled
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="portfolio">
                                        {t("Message")}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        value={details?.description}
                                        disabled
                                    />
                                </div>
                                <div className='row'>
                                    {Liste?.map((items, keys) => (
                                        <CardMediaContaxt t={t} items={items} keys={keys} getExtension={getExtension} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
