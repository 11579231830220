import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { numberWithSpaces } from "../../../utils/SessionUtiles";
import { getTypePaiement } from "../../../utils/SearchUtils";

const borderColor = "#fff";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#4e545d",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    alignItems: "center",
    height: 18,
    fontSize: 10,
  },
  description1: {
    width: "50%",
    textAlign: "left",
    borderRightColor: "#4e545d",
    borderRightWidth: 1,
    paddingLeft: 8,
    letterSpacing: 1,
    fontSize: 9,
    color: "#4e545d",
    fontFamily:"Arial",
    fontStyle:"normal"
  },
  description: {
    width: "50%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 9,
    letterSpacing: 1,
    color: "#4e545d",
    fontFamily:"Arial",
    fontStyle:"normal"
  },
  text:{
    fontFamily:"Arial",
    fontStyle:"bold"
  },
});

const PrimeCPTableau = ({ t, type, info ,paiement,data}) => {
console.log("prime tableau",type, info ,paiement,data)
  const education = (
    <>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Prime Commerciale : 
         <Text style={styles.text}>{" "+numberWithSpaces(Math.round(data?.education?.prime_commercial))} FCFA </Text> 
        </Text>
        <Text style={styles.description}> Mode de paiement:  {getTypePaiement(paiement[0]?.type_paiement)} </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Périodicité de paiement:
          {" "+info?.periodicite === 1
            ? t("Annuelle") + " "
            : info?.periodicite === 2
            ? t("Semestrielle") + " "
            : info?.periodicite === 4
            ? t("Trimistrielle") + " "
            : t("Mensuelle") + " "}
        </Text>
        <Text style={styles.description}>
          Capital garanti : <Text style={styles.text}>{" "+numberWithSpaces(parseFloat(data?.education?.capital_acquis))}{" "}FCFA</Text>
          
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Durée de paiement de la rente: {" "+data?.education?.duree_rente} (ans){" "}
        </Text>
        <Text style={styles.description}>
          Rente : {" "+numberWithSpaces(parseFloat(data?.education?.total_rente))} FCFA
        </Text>
      </View>
    </>
  );
  const deces = (
    <>
      <View style={styles.row}>
        <Text style={styles.description1}>
          {" "}
          Capital Acquise: {numberWithSpaces(
            Math.round(info?.capital_acquis)
          )}{" "}
          FCFA
        </Text>
        <Text style={styles.description}>
          {" "}
          Prime Annuelle: {numberWithSpaces(
            Math.round(info?.prime_annuelle)
          )}{" "}
          FCFA
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          {" "}
          Prime Commerciale:{" "}
          {numberWithSpaces(Math.round(info?.prime_commercial))} FCFA
        </Text>
        <Text style={styles.description}> Mode de paiement: {getTypePaiement(paiement[0]?.type_paiement)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Périodicité de paiement:
          {info?.periodicite === 1
            ? t("Annuelle") + " "
            : info?.periodicite === 2
            ? t("Semestrielle") + " "
            : info?.periodicite === 4
            ? t("Trimistrielle") + " "
            : t("Mensuelle") + " "}
        </Text>
        <Text style={styles.description}>
          Prime Pure : {numberWithSpaces(parseFloat(info?.prime_pure))} FCFA
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          {" "}
          Frais de gestion : {numberWithSpaces(parseFloat(info?.frais_gestion))}
          FCFA{" "}
        </Text>
        <Text style={styles.description}>
          Frais Acquisation :{" "}
          {numberWithSpaces(parseFloat(info?.frais_acquisation))} FCFA
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Coût de police : {numberWithSpaces(
            parseFloat(info?.cout_police)
          )}FCFA{" "}
        </Text>
        <Text style={styles.description}>
          Taux Bancaire : {info?.taux_bancaire}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          {" "}
          Duree Cotisation : {info?.duree_cotisation}{" "}
        </Text>
        <Text style={styles.description}></Text>
      </View>
    </>
  );
  const retraite = (
    <>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Prime Commerciale:
          {numberWithSpaces(Math.round(info?.prime_commercial))} FCFA
        </Text>
        <Text style={styles.description}> Mode de paiement: {getTypePaiement(paiement[0]?.type_paiement)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Périodicité de paiement:
          {info?.periodicite === 1
            ? t("Annuelle") + " "
            : info?.periodicite === 2
            ? t("Semestrielle") + " "
            : info?.periodicite === 4
            ? t("Trimistrielle") + " "
            : t("Mensuelle") + " "}
        </Text>
        <Text style={styles.description}>
          Capital garanti : {numberWithSpaces(parseFloat(info?.capital_garantie))}{" "}
          FCFA
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Durée de paiement de la rente: {info?.duree_rente} (ans){" "}
        </Text>
        <Text style={styles.description}>
          Rente : {numberWithSpaces(parseFloat(info?.total_rente))} FCFA
        </Text>
      </View>  
  </>)
return( <Fragment>{type==="education" ? education :type==="deces" ? deces : retraite }</Fragment>)
  
};
export default PrimeCPTableau;
