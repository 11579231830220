import React from 'react'
import { toast } from 'react-toastify';
import { deleteAdresseUser } from '../../../services/adresses/AdresseAPI';

export default function DeleteAdresse({t,deleteCode, index,setOK1 , OK1}) {
    const deleteFunction = () =>{

        deleteAdresseUser(deleteCode)
        .then((result) => {
            if(result?.data.code === 200){
                toast(t("succée"),{type:'success',position:'top-right'})
                setOK1(!OK1)
            }else{
                toast(t("Erreur"),{type:"error",position:'top-right'})
            }
        }).catch((err) => {
            console.log(err)  
        });
    }
 
    return (
        <div className="modal fade" id="deleteModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="exampleModalLabel">{t("Supprimer Votre adresse")}</h6>
                        <button className="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <h1 className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                        </h1>
                        <h1 className='text-center'> {t("Êtes-vous sure ?")}</h1>
                        <h6 className=" text-center text-muted mb-0">{t("Êtes vous sure de vouloir supprimer votre adresse ?")}</h6>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-sm btn-danger" type="button" data-bs-dismiss="modal"><i className="bi bi-x-circle me-2"></i>{t("Fermer")}</button>
                        <button className="btn btn-sm btn-secondary" type="button" onClick={()=>deleteFunction()}> <i className="bi bi-check-circle me-2"/>{t("Confirmer")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
