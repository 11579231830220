import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../Auth/useUser";
import { getUser } from "../../services/users/usersAPI";
import { getFactureByUser } from "../../services/factures/FactureApi";
import EmptyCotisation from "./EmptyCotisation";
import { t } from "i18next";
import ModalSaisieCode from "../securite/ModalSaisieCode";
import { useHistory } from "react-router-dom";
import accounting from "accounting";
import { useForm } from "react-hook-form";
import Paiement_specifique from "../contrats/components/paiement/Paiement_specifique";
import DotLoader from "../../components/loading/DotLoader";
export default function UnpaidFactures() {
  
  const history = useHistory();
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA
  const { policy_code, idListe } = useParams();
  console.log("policy_code to get factures assoccies:", policy_code);
  const user = useUser();
  var { id, nom, prenom, gender } = user.result;
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  const [factures, setFactures] = useState([]);
  const [paidFactures, setPaidFactures] = useState([]);
  const [unpaidFactures, setUnpaidFactures] = useState([]);
  const [totalCotisation, setTotalCotisation] = useState(0);
  const [data, setdata] = useState({});
  const [LISTE_ASSUREE, setLISTE_ASSUREE] = useState([]); // Liste assurée
  const type = "retraite";
  const [groupedAdresses, setgroupedAdresses] = useState([]);
  const [show5, setShow5] = useState(false);
  const [loading, setloading] = useState(true);

  const africanCurrencyOptions = {
    symbol: "FCFA", // Set the currency symbol
    decimal: ".", // Set the decimal separator
    thousand: " ", // Set the thousand separator
    precision: 0, // Set the number of decimal places
    format: "%v %s", // Set the format pattern
  };
  const [unpaidFactureLength, setunpaidFactureLength] = useState("");
  // Function to format a given value with the African currency options
  const formatCurrency = (value) => {
    return accounting.formatMoney(value, africanCurrencyOptions);
  };
  useEffect(() => {
    getUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
        }
        getFactureByUser(id)
          .then((result) => {
            if (result?.data?.code === 200) {
              console.log("factures of current user", result);
              var L = result?.data?.data?.filter(
                (el) => el.num_facture === policy_code
              );
              // var P = L.filter((el) => el.statut == "paid");
              var U = L.filter((el) => el.statut == "unpaid");
              // console.log("paid invoices: ", P);
              console.log("unpaid invoices: ", U);

              setFactures(L);
              // setPaidFactures(P);
              setUnpaidFactures(U);
              var unpaidFacturelength = U.length.toString().padStart(2, "0");
              setunpaidFactureLength(unpaidFacturelength);
              var totalCotisation = 0;
              // for (let i = 0; i < P.length; i++) {
              //   totalCotisation += P[i].montant;
              //   P[i].montant = formatCurrency(P[i].montant);
              // }
              for (let i = 0; i < U.length; i++) {
                totalCotisation += U[i].montant;
                U[i].montant = formatCurrency(U[i].montant);
              }
              setTotalCotisation(totalCotisation);
              console.log("Results after filter:", L);
              setloading(false);
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
        console.log("Results after filter:", factures);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const redirectToDetails = () => {
    history.push(`/unpaid_factures/${policy_code}`);
  };

  return (
    <div>
      {loading ? <DotLoader /> :  
      <div className="container">
      <div className="shop-pagination pb-3">
      {show5 ? (
        <>
          <Paiement_specifique
            id={7}
            handleSubmit={handleSubmit}
            register={register}
            data={data}
            watch={watch}
            groupedAdresses={groupedAdresses}
            LISTE_ASSUREE={LISTE_ASSUREE}
            type={type}
            t={t}
            unpaidFactures={unpaidFactures}
          />
        </>
      ) : (
        <div className="container">
          {showCodePIN && (
            <ModalSaisieCode id={id} setshowCodePIN={setshowCodePIN} t={t} />
          )}
          <div className="shop-pagination pb-3">
            <div className="container mb-3">
              <div className="card">
                <div className="card-body p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <small className="ms-1">{t("Factures")} </small>
                  </div>
                 
                 
                </div>
              </div>
            </div>
            <div className="row mb-2 d-flex">
             
              {unpaidFactures?.length !== 0 ? (
                <div style={{ marginTop: "-20px" }}>
                  {unpaidFactures.map((items, k) => (
                    <div
                      className="card timeline-card"
                      key={k}
                      style={{ marginTop: "19px" }}
                    >
                      <>
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div className="timeline-text mb-2">
                              <span
                                className="badge mb-2 rounded-pill fz-10"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  height: "30px",
                                }}
                              >
                                Facture N°: {items?.id}
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 d-flex align-items-center">
                              <div>
                                <p className="fz-10">
                                  <b>{t("Montant")} :</b> {items?.montant.toString().split('.')[0]}
                                </p>
                                <p className="fz-10">
                                  <b style={{ color: "red" }}>
                                    <b>{t("Statut")} :</b>
                                    {items?.statut === "unpaid"
                                      ? "  Non payé"
                                      : "payé"}
                                  </b>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-center">
                              <div>
                                <p className="fz-10">
                                  <b>{t("Date d'échéance")} :</b>{" "}
                                  {items.Date_prevu}
                                </p>
                                <p className="fz-10">
                                  <b>{t("Payée le")} :</b> Non payée
                                </p>
                              </div>
                            </div>
                          </div>
                          <button
                                  onClick={() => setShow5(true)}
                                  className="btn btn-success mt-1 float-end"
                                >
                               
                                  {t("Payer")}
                                  <i className="bi bi-arrow-right fz-10" />
                                </button>
                        </div>
                      </>
                    </div>
                  ))}
                </div>
              ) : (
                <EmptyCotisation t={t} />
              )}
            </div>

           
          </div>
        </div>
      )}
      </div>
      </div>}
    </div>
  );
}
