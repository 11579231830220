/* eslint-disable jsx-a11y/heading-has-content */
import moment from "moment";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";

export default function Assure({ t, data, LISTE_ASSUREE, type,groupedAssuree }) {
  return (
    <div>
      <div className="shop-pagination pb-3">
        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14"> {t("Assuré(s)")} </small>
        </div>
        <h5 className="product-title d-block text-truncate mt-0 mb-3"></h5>{" "}
        {LISTE_ASSUREE?.length === 0 ? (
          <>
            <h6 className="text-purple fz-12">
              <i className="bi bi-info-circle me-2"></i>{t("L'assuré est le souscripteur")}
            </h6>
          </>
        ) : (
          <>
            {LISTE_ASSUREE?.map((items, keys) => (
              <div key={keys}>
                <div className="form-group mb-3">
                  <label className="mb-2 fz-12">
                    {t("L'assurée N°")} {keys + 1}
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="bi bi-person-fill text-purple" />
                    </span>
                    <input
                      className="form-control fz-12"
                      type="text"
                      disabled
                      value={groupedAssuree[items.assuree_id][0]?.nom+" "+groupedAssuree[items.assuree_id][0]?.prenom}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-2 fz-12">
                    {t("Date de naissance Assuré")} N° {keys + 1}
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="bi bi-balloon-heart-fill text-purple" />
                    </span>
                    <input
                      className="form-control fz-12"
                      type="date"
                      disabled
                      value={moment(groupedAssuree[items.assuree_id][0]?.date_naissance).format( "YYYY-MM-DD")}
                    />
                  </div>
                </div>
                {/**
                 * ------------------    FORMULAIRE DE TYPE EDUCATION  ----------------------     */}
                {type === "education" && (
                  <>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Periodicité")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar2-week-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={
                            items?.periodicite === "1"
                              ? t("Annuelle")
                              : items?.periodicite === "2"
                              ? t("Semestrielle")
                              : items?.periodicite === "4"
                              ? t("Trimistrielle")
                              : t("Mensuelle")
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Prime Commerciale")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-cash-coin text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={numberWithSpaces(items.prime_commercial)}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Durée de la rente (année)")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar-range-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={items.duree}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Durée Cotisation (année)")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-hourglass-split text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={
                            items.cotisation
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {/**
                 * ---------------------   FORMULAIRE DE TYPE ACCIDENT --------------------------  */}
                {type === "accident" && (
                  <>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Durée")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar2-week-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={
                            items?.duree === "1"
                              ? t("1 Mois")
                              : items?.duree === "3"
                              ? t("3 Mois")
                              : items?.duree === "4"
                              ? t("6 Mois")
                              : t("12 Mois")
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Formules")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar2-week-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={items?.formule}
                        />
                      </div>
                    </div>
                  </>
                )}
                {/**
                 * ---------------------   FORMULAIRE DE TYPE VOYAGE  --------------------------   */}
                {type === "voyage" && (
                  <>
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="Username">
                        {t("N° Passeport")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar-event-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={groupedAssuree[items.assuree_id][0]?.passport}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="Username">
                        {t("Validité Passeport")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar-event-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={items.validite_passeport_assuree}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </>
        )}{" "}
      </div>
    </div>
  );
}
