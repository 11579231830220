import { Page, Document,StyleSheet,View,Text,Font} from "@react-pdf/renderer";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceTableFooter from "./InvoiceTableFooter";
import EnteteContrat from "../conditionParticuliere/EnteteContrat";
// FONT TIMES NEW ROMAN
import TimesNewRoman_normal from '../styles/TimesNewRoman/times.ttf'
import TimesNewRoman_bold from '../styles/TimesNewRoman/times-new-roman-grassetto.ttf'
import Signatures from "../conditionParticuliere/Signatures";

Font.register(
  {
    family: 'Arial',
    fonts: [
      {
        src: TimesNewRoman_normal,
        fontWeight:400,
        fontStyle:"normal"
      },
      {
        src: TimesNewRoman_bold,
        fontWeight:700,
        fontStyle:"bold"
      },
     
    ],
  });
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  reportTitle: {
    letterSpacing: 3,
    fontSize: 12,
    textAlign: "center",
    marginBottom: 10,
    fontFamily:'Arial',
    fontStyle:"bold",
    color:"#000",
    textTransform: "uppercase",
  },
  
 
});

const Invoice = ({ signatures,t,type,data,user_details,personnel,info,facture}) =>
(
  <Document renderMode="svg">
      <Page size="A4" style={styles.body} wrap>
    <EnteteContrat data={data} />
      <View >
        <Text style={styles.reportTitle}>Facture</Text>
      </View>
      <BillTo t={t} data={data} info={info} facture={facture}/>
      <InvoiceItemsTable
        info={info}
        t={t}
        type={type}
        data={data}
        user_details={user_details}
        personnel={personnel}
      />
      <Signatures data={data} signatures={signatures} />
     <InvoiceTableFooter />
    </Page>
  </Document>
);

export default Invoice;
