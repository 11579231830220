import React from 'react'

export default function WelcomePage() {
  return (
    <div className="hero-block-wrapper bg-purple">
      {/* Styles */}
      <div className="hero-block-styles">
        <div className="hb-styles1" />
        <div className="hb-styles3" />
      </div>
      <div className="custom-container">
        <div className="hero-block-content text-center"><img className=" mb-4" src="img/bg-img/19.png" alt="Welcome_Image" />
          <h2 className="display-4 text-white text-center  mb-3">Bienvenue dans DiokoAssur  </h2>
          <p className="text-white text-center fz-14">L'application qui vous permet de souscrire, payer et gérer vos contrats d'assurances.</p>
          <a className="btn btn-warning btn-lg w-100vh text-white col-12 mb-2 fz-12" href="/login">S'authentifier</a>
          <p className='fz-12 text-white mb-2'>(Si vous disposez déjà d'un compte)</p>
          <a className="btn btn-warning btn-lg w-100vh  text-white col-12  fz-12 " href="/choose_register">S'inscrire</a>
          <p className='fz-12 text-white mb-1 mt-1'>(Si vous n'avez pas encore ouvert votre compte)</p>

        </div>
      </div>
    </div>

  )
}
