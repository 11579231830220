/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import DotLoader from '../../components/loading/DotLoader';
import BackToPreviousPage from '../../components/previouspage/BackToPreviousPage';
import Prefix from '../../components/select/Prefix';
import { send_sms } from '../../services/signin/LoginAPI';

export default function AuthSMS() {

    const history = useHistory()
    // # SUBMIT FORM ELEMENTS
    const { register, handleSubmit, formState: { errors }  } = useForm();
    const handleError = (errors) => {};
    //------------- Traduction ---------------
    const [t] = useTranslation("global");
    // -------------- Loading -----------
    const [loading, setLoading] = useState(false);
    /**
     * REGISTER OPTIONS
     */
    const registerOptions = {

        mobile: {
            required: {
                value: true,
                message: t("Please add your mobile phone number!")
            },
            pattern: {
                value: /^[0-9+-]+$/,
                message: "This is not a valid mobile phone,please try again!"
              }
        }
    }
    /**
   * Handle AUTH SMS  : OnSubmit Form function
   * @param {*} data
   */
    const handleAuthSMS = (data) => {
        setLoading(true)
        var {prefix,mobile} = data
        send_sms({mobile:prefix+mobile})
        .then((result) => {
            if(result?.data?.code === 200 ){
                history.push({
                    pathname :"/SMS_Validation/"+prefix+mobile
                })
            }else if (result===500){
                toast(t("Aucun utilistateur n'est inscrit avec le numéro "+prefix+" "+mobile),{type:"error",position: "top-right",})
                setLoading(false)
            }          
            else {
                toast(t("Erreur d'envoi SMS ! Réessayer plus tard"),{type:"error",position: "top-right",})
                setLoading(false)

            }
        }).catch((err) => {
            console.log(err)
        });
    };


    return (
        <div>
           
            <div className="login-wrapper d-flex align-items-center justify-content-center">
                <div className="custom-container">
                    <div className="text-center"><img className="mx-auto mb-4 d-block" src="img/bg-img/37.png" alt="sms" />
                        <h3>{t("Phone Verification")}</h3>
                        <p>{t("We will send you an OTP on this phone number.")}</p>
                    </div>
                    {/* OTP Send Form */}
                    <div className="otp-form mt-4">
                        <form onSubmit={handleSubmit(handleAuthSMS,handleError)}>
                            <div className="input-group mb-3">
                                <Prefix register={register} />
                                <input className="form-control" type="number" {...register("mobile", registerOptions.mobile)} placeholder="Enter phone number" />
                            </div>
                            <small className="text-danger">
                                {errors?.mobile && errors.mobile.message}
                            </small>
                            {loading ? <button className="btn btn-success w-100" disabled><DotLoader /> </button> : <button className="btn btn-success w-100" type="submit">{t("Send Now")}</button>}
                        </form>
                    </div>
             
                </div>
            </div>
        </div>


    )
}
