/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import _ from "lodash";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loading/Loader";
//-------------- Table CIMA H -------------
//import tableCIMA from "../../../../formules/education/Simulateur_Rente_éducation_TABLE_CIMA_H_.json";
import { getCIMA } from "../../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";
import { calculateAge } from "../../../../utils/Comparaison";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";

export default function ResumEducation({ category_id, groupedAssuree, liste, data, t, setLISTE_ASSUREE}) {

  //Loader
  const [loading, setloading] = useState(true);
  //NEW LISTE ASSUREE
  const [liste_assuree, setliste_assuree] = useState([]);
  // Results
  const [results, setResults] = useState({
    capitalacquis: 0, // Capital acquis
    rente: 0, // RENTRE certaine service
  });
  //const [CIMA,setCIMA] = useState([])
  useEffect(() => {
    // CALCUL DE LA DUREE DU CONTRAT COTISATION (si soucripteur = assuré )
    /**
     * GET PARAMETRES TECHNIQUES by categorie code
     */
    getCIMA().then((result) => {
      if(result?.data?.code === 200){
       // setCIMA(result?.data?.data)
        var CIMA =result?.data?.data
        getParamTechByCategorie(category_id)
      .then((result) => {
        if (result?.data?.code === 200) {
          //---- 1- FIRST STEP: GET PARAMETERE TECHNIQUE
          var paratech = result?.data?.data[0];
          //---- 2- SECOND STEP: GET  frais acquisation
          var factAct = 1 / (parseFloat(paratech?.taux_technique) + 1); // FORMULE DE CALCUL DE LA VALEUR DE FACT D ACTUALISATION dans la table parametre technique
          // ---3- FIRST STEP OF CHANGING CIMA TABLE
          var cima = [];
          CIMA?.map((items, keys) => {
            var obj = {
              Age: keys,
              Lx: parseFloat(items.Lx),
              dx: parseFloat(items.dx),
              Dx: parseFloat(items.Lx) * Math.pow(factAct, keys),
              Cx: parseFloat(items.dx) * Math.pow(factAct, keys + 1 / 2),
              Mx: 0,
              Rx: 0,
              Nx: 0,
            };
            cima.push(obj);
          });

          // ---4- FIRST STEP OF CHANGING  the second element into CIMA TABLE
          var newCIMA2 = [];
          cima?.map((items, keys) => {
            //calcul of sum MX by iteration commutateur
            const sumMx = cima.slice(keys).reduce((accumulator, object) => {
              return accumulator + parseFloat(object.Cx);
            }, 0);
            //calcul of sum NX by iteration commutateur
            const sumNx = cima.slice(keys).reduce((accumulator, object) => {
              return accumulator + parseFloat(object.Dx);
            }, 0);
       
            var obj = {
              Age: keys,
              Lx: parseFloat(items.Lx),
              dx: parseFloat(items.dx),
              Dx: parseFloat(items.Dx),
              Cx: parseFloat(items.Cx),
              Mx: sumMx,
              Rx: 0,
              Nx: sumNx,
            };
            newCIMA2.push(obj);
          });
          // 5- CHANGE FORMAT LISTE OF ASSUREE
          var new_liste_assuree = [];
          // IF LISTE ASSUREE NOT NULL => SOUSCRIPTEUR N EST PAS L ASSURE
          if (liste?.length !== 0) {
            liste?.map((items) => {
              var date_naissance = groupedAssuree[items?.assuree_id][0]?.date_naissance
              var info_assuree =groupedAssuree[items?.assuree_id] !== undefined? groupedAssuree[items?.assuree_id][0] : {}
              var age = parseInt( calculateAge(new Date(date_naissance))  );
              var duree_contrat_assuree =parseInt(items?.cotisation);
              var commutateur = {
                nx: newCIMA2[parseInt(age)]?.Nx,
                nxp: newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)]
                  .Nx,
                dx: newCIMA2[parseInt(age)]?.Dx,
                dxp: newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)]
                  .Dx,
                mx: newCIMA2[parseInt(age)]?.Mx,
                mxp: newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)]
                  .Mx,
                xep: parseFloat(
                  newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)].Dx /
                    newCIMA2[parseInt(age)]?.Dx
                ),
                axp:
                  parseFloat(
                    newCIMA2[parseInt(age)]?.Mx -
                      newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)]
                        ?.Mx
                  ) / newCIMA2[parseInt(age)]?.Dx,
                axpp:
                  parseFloat(
                    newCIMA2[parseInt(age)]?.Nx -
                      newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)]
                        ?.Nx
                  ) / newCIMA2[parseInt(age)]?.Dx,
                axpm:
                  parseFloat(
                    newCIMA2[parseInt(age)]?.Nx -
                      newCIMA2[parseInt(age) + parseInt(duree_contrat_assuree)]
                        .Nx
                  ) /
                    newCIMA2[parseInt(age)].Dx -
                  ((items.periodicite - 1) / (2 * items.periodicite)) *
                    (1 -
                      parseFloat(
                        newCIMA2[
                          parseInt(age) + parseInt(duree_contrat_assuree)
                        ].Dx / newCIMA2[parseInt(age)].Dx
                      )),
              };
              var obj = {
                assuree_id : items?.assuree_id,
                nom_assuree: info_assuree?.nom,
                prenom_assuree: info_assuree?.prenom,
                data_naissance_assuree: info_assuree?.date_naissance,
                periodicite: items?.periodicite,
                prime_commercial: parseFloat(items?.prime_commercial).toFixed(2),
                duree: items?.duree,
                capitalacquis:
                  (items?.periodicite *
                    (1 -
                      parseFloat(paratech?.frais_acquisition) -
                      parseFloat(paratech?.frais_gestion)) *
                      parseFloat(items?.prime_commercial) *
                    commutateur?.axpm) /
                  (1 * (commutateur?.axp + commutateur?.xep)),
                rente:
                  ((1 - parseFloat(paratech?.frais_gestion_rente)) *
                    ((items.periodicite *
                      (1 -
                        parseFloat(paratech?.frais_acquisition) -
                        parseFloat(paratech?.frais_gestion)) *
                        parseFloat(items?.prime_commercial) *
                      commutateur?.axpm) /
                      (1 * (commutateur?.axp + commutateur?.xep))) *
                    (1 - factAct)) /
                  (1 - Math.pow(factAct, parseInt(items.duree))),
                cotisation:  items?.cotisation,
              };
              new_liste_assuree.push(obj);
            });
          
            Object.assign(data, {
              prime_commercial: new_liste_assuree.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.prime_commercial);
              }, 0),
              capitalacquis: new_liste_assuree.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.capitalacquis);
              }, 0),
              rente: new_liste_assuree.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.rente);
              }, 0),
            });
            setliste_assuree(_.uniq(new_liste_assuree));
            setLISTE_ASSUREE(_.uniq(new_liste_assuree));
          } 
       
        }
      })
      .catch((err) => {
        console.log(err);
      })
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setTimeout(() => {
        setloading(false);
      }, 1200);
    });
    
      
  }, []);

  /**
   * Calcul de la somme capital ACQUIS assuré
   * param : capitalacquis
   */
  const sumPrimeCommerciale = () => {
    return liste_assuree.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.prime_commercial);
    }, 0);
  };
  const sumArray_capital = () => {
    return liste_assuree.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.capitalacquis);
    }, 0);
  };
  const sumArray_rente = () => {
    return liste_assuree.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.rente);
    }, 0);
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {liste?.length > 0 ? (
            <> 
               <table className="table mt-3">
              <tbody className="text-center">
                {liste?.map((items, keys) => (
                  <>
                    
                    <tr>
                      <th colSpan={2} className="text-purple text-center">
                        <b>
                          {t("Assuré(e)")} {keys + 1} :
                          {items?.nom_assuree + " " + items?.prenom_assuree}
                        </b>
                      </th>
                    </tr>
                    <tr>
                    <td className="text-start">  {t("Prime Commerciale")} </td>
                        <td className="text-center">
                        {items?.prime_commercial}
                        </td>
                      </tr>
                    <tr>
                      <td className="text-start"> {t("Capital Acquis")}</td>
                      <td>
                      {numberWithSpaces(Math.round(parseFloat(items?.capitalacquis)))}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start">{t("Total Rente")}</td>
                      <td>
                      {(items?.rente===Infinity) ? t("Sans rente") :numberWithSpaces(Math.round(parseFloat(items?.rente)))}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <div className="row mb-3">
              <div className=" card bg-purple ">
                <div className="card-body">
                  <table className="table mb-0 mt-0">
                    <tbody className="text-white">
                    <tr>
                        <td className="text-end">
                          <b> {t("Prime Commerciale")} </b>
                        </td>
                        <td className="text-center">
                        {numberWithSpaces(Math.round(sumPrimeCommerciale()))}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end">
                          <b>  {t("Total Capital Acquis")}  </b>
                        </td>
                        <td className="text-center">
                        {numberWithSpaces(Math.round(sumArray_capital()))}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-end">
                          <b> {t("Total Rente (FCFA)")} </b>
                        </td>
                        <td className="text-center">
                        {(sumArray_rente()===Infinity) ? t("Sans rente") :numberWithSpaces(Math.round(sumArray_rente()))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </>
           
          ) : (
            <div className="card  mb-3  mt-3">
              <div className="card-body">
                <table className="table ">
                  <tbody>
                    <tr>
                      <td>{t("Capital Acquis")}</td>
                      <td>
                        {numberWithSpaces(Math.round(results?.capitalacquis))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Rente certaine service")}</td>
                      <td>{(results?.rente===0)? t("Sans rente") :numberWithSpaces(Math.round(results?.rente))}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
