/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import Accordion from "../../components/accordion/Accordion";
import { getContactsUtils } from "../../services/contactUtils/contactUtilsAPI";
import DotLoader from "../../components/loading/DotLoader";
import { SearchItems } from "../../utils/SearchUtils";
import { useTranslation } from "react-i18next";
import MapLeaflet from "./MapLeaflet";
import Cookies from 'js-cookie';

export default function UtilsContacts() {
  // REF : SEARCH
  const inputEl = useRef("");
  // SEARCH : INPUT
  const [search, setSearch] = useState([]);
  // ARRAY OF CONTACTS
  const [Contacts, setContacts] = useState();
  const [SEARCHLISTE, setSEARCHLISTE] = useState([]); // RESULT OF SEARCH LISTE
  const [SAVELISTE, setSAVELISTE] = useState([]); // SAVE LISTE OF COMPAGNY
  const language = Cookies.get('locale')

  var currentListe = search?.length >= 2 ? SEARCHLISTE : Contacts;
  //-------------- LOADER ---------
  const [Loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  const [map, setMap] = useState(null);
  useEffect(() => {
    getContactsUtils()
      .then((result) => {
        if (result?.data?.code === 200) {
          var body =[]
          if(language==="fr"){
             body = _.groupBy(result?.data?.data, "type");
          }else{
            body = _.groupBy(result?.data?.data, "type_en");
          }
          setContacts(body);
          setSAVELISTE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  /**
   * SERACH ITEM FUNCTION
   */
  const getSearchTerm = () => {
    setSearch(inputEl.current.value);
    if (inputEl.current.value !== "") {
      const newList = SearchItems(SAVELISTE, inputEl.current.value);
      var grouped_newList = []
      if(language==="fr"){
        grouped_newList = _.groupBy(newList, "type");
      }else{
        grouped_newList = _.groupBy(newList, "type_en");
      }
      setSEARCHLISTE(grouped_newList);
    } else {
      var grouped_newList2 = []
      if(language==="fr"){
        grouped_newList = _.groupBy(SAVELISTE, "type");
      }else{
        grouped_newList = _.groupBy(SAVELISTE, "type_en");
      }
      setContacts(grouped_newList2);
    }
  };
  // Function to handle clicking on a store card
  const handleStoreCardClick = (store) => {
    // Center the map on the store's location
    map.setView([store.latitude, store.longitude], 18);
}
  return (
    <div className="container">
      
          <div className="text-center">
            <h6 className="mb-4 text-center display-6 ">{t("Contacts Utiles")} </h6>
            {/* <img
              className="mb-4 text-center"
              src={"img/bg-img/20.png"}
              width={"150px"}
              alt="Assurance vie"
            /> */}
          </div>
          <MapLeaflet SAVELISTE={SAVELISTE} map={map} setMap={setMap}/>

          <p className="mt-2 mb-2 text-success p-2 "> <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill={"#45d58d"} className="me-2" width={16} height={16}><path d="M12.5,19c-.28,0-.5-.22-.5-.5v-7c0-.83-.67-1.5-1.5-1.5-.28,0-.5-.22-.5-.5s.22-.5,.5-.5c1.38,0,2.5,1.12,2.5,2.5v7c0,.28-.22,.5-.5,.5Zm-.5-14c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Zm12,14.5V4.5c0-2.48-2.02-4.5-4.5-4.5H4.5C2.02,0,0,2.02,0,4.5v15c0,2.48,2.02,4.5,4.5,4.5h15c2.48,0,4.5-2.02,4.5-4.5ZM19.5,1c1.93,0,3.5,1.57,3.5,3.5v15c0,1.93-1.57,3.5-3.5,3.5H4.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h15Z" /></svg>
 {t("Veiller activer votre géo-localisation pour retrouver sur la carte un contact utile")}</p>
          <div className="card mt-3">
        <div className="card-body">
          <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control mt-0"
            placeholder="Recherche ..."
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            ref={inputEl}
            value={search}
            onChange={() => getSearchTerm()}
          />
          <button className="btn btn-purple"><i className="bi bi-search"/> </button>
          </div>
          {Loading ? (
            <DotLoader />
          ) : (
            <div className="accordion accordion-flush accordion-style-two">
              {Object.keys(currentListe).map((items, keys) => (
                <div key={keys}>
                  <Accordion title={items} content={Contacts[items]} handleStoreCardClick={handleStoreCardClick} />
                </div>
              ))}
            </div>
          )}
        </div>
       
        
        
         
      </div>
    </div>
  );
}
