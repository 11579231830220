import React, { useEffect, useState } from "react";
import Cotisation_card from "./Cotisation_card";
import { getFactureById } from "../../services/factures/FactureApi";

import { useTranslation } from "react-i18next";
import { useUser } from "../../Auth/useUser";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { getUser } from "../../services/users/usersAPI";
import { getListeAssuree } from "../../services/assuree/assureeAPI";
import { getFactureByUser } from "../../services/factures/FactureApi";
import _ from "lodash";
import Paiement from "../contrats/components/paiement/Paiement";
import Paiement_cotisation from "./Paiement_cotisation";

export default function Cotisation_history(props) {
  const { idCategory } = useParams();
  //LISTE DES CONTRATS
  // eslint-disable-next-line no-unused-vars
  const [LISTE, setLISTE] = useState([]); //INITIAL LISTE
  const [SaveLISTE, setSaveLISTE] = useState([]); // SAUVEGARDE DE LA LISTE INIT POUR LA RECUPERATION EN CAS DE FILTRE
  // LISTE OF UNIQ POLICY
  const [policyListe, setpolicyListe] = useState([]);
  const [groupedListe, setgroupedListe] = useState([]);
  //LISTE ASSUREE
  const [ASSUREE, setASSUREE] = useState([]);
  const [num_facture, setNumFacture] = useState("");
  const [montant, setMontant] = useState(Number);
  const [statut, setStatut] = useState("");
  const [Date_paiement, setDate_paiement] = useState("");
  const [Date_prevu, setDate_prevu] = useState("");
  // show Modal CODE PIN -----------
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  //------------- FILTRE CONTRAT-----------
  const [filte, setfilte] = useState("");
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //---------- LOADING -----------------------
  const [loading, setloading] = useState(true);
  //--------INFORMATIONS OF ACTUAL USER---------
  const user = useUser();
  const language = Cookies.get("locale");
  var { id, nom, prenom, gender } = user.result;
  const [total, settotal] = useState(0);
  const { idCotisation } = useParams();
  const [facture, setFacture] = useState(null);
  const [show5, setShow5] = useState(false);

  const {data, ListeAdresses , callbackAdress }= props

  useEffect(() => {
    getUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
          getListeAssuree(id)
            .then((result) => {
              if (result?.data?.code === 200) {
                const liste_assuree = _.groupBy(result?.data?.data, "id");
                setASSUREE(liste_assuree);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          getFactureById(idCotisation)
            .then((result) => {
              if (result?.data?.code === 200) {
                console.log("facture from backend", result);
                console.log("facture in a new var", facture);
                setFacture(result?.data?.data);
                console.log(facture);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          getFactureByUser("5")
            .then((result) => {
              console.log("Trying to fetch invoices by user ID: ", result);
              if (result?.data?.code === 200) {
                console.log(result?.data?.data);
                var L = result?.data?.data?.filter(
                  (el) => el.category_id === Number(idCategory)
                );
                console.log("Results after filters:", L);
                var liste_contart = _.orderBy(L, "created_at", "desc");
                var policy_liste = _.groupBy(liste_contart, "policy_code");
                const res_policy = Object.keys(policy_liste);

                setpolicyListe(Object.keys(policy_liste));
                setgroupedListe(policy_liste);
                setLISTE(result?.data?.data);
                setSaveLISTE(result?.data?.data);
                setNumFacture(result?.data?.data);
                setMontant(result?.data?.data);
                setStatut(result?.data?.data);
                setDate_paiement(result?.data?.data);
                setDate_prevu(result?.data?.data);

                console.log("res_policy res_policy 1:", res_policy);
                console.log("res_policy res_policy 2:", policy_liste);

                _.each(policy_liste, (element) => {
                  _.each(element, (usr) => {
                    settotal(total + usr.montant);
                    setDate_prevu(total + usr.Date_prevu);
                  });
                });
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setloading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <h2 style={{textAlign:"center"}}>c'est l'historique de la facture</h2>
     {show5 ?(
     <>
     <Paiement_cotisation
     id={8}
    
     data={data}
    

  
     t={t}
     />
     </>):( <div>
        {facture?.map((i, keys) => (
          <div className="card-body" key={keys}>
            {console.log(facture)}
            <div className="container">
              <div className="timeline-text mb-2">
                <span
                  className="badge mb-2 rounded-pill fz-10"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: "30px",
                  }}
                >
                  Facture N°: {i?.num_facture}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <div>
                  <span className="fz-10">
                    <b>{t("Montant")} :</b> {i?.montant}
                  </span>
                  <span className="fz-10">
                    <b>
                      Statut De Facture : 

                      {i?.statut === "paid" ? (
                        <span className="text-success">Soldée</span>
                      ) : (
                        <span className="text-danger">Non soldée</span>
                      )}
                    </b>
                  </span>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div>
                  <p className="fz-10">
                    <b>{t("Date d'échéance")} :</b> {i.Date_paiement}
                  </p>
                  <p className="fz-10">
                    <b>{t("Payée le")} :</b> {i.Date_prevu}
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div>
                  <p className="fz-10">
                    <b>{t("Numéro de facture")} :</b> {i.num_facture}
                  </p>
                  <p className="fz-10">
                    <b>{t("numero_paiement")} :</b> {i.numero_paiement}
                  </p>
                </div>
              </div>
            </div>
            <button onClick={()=>setShow5(true)} className="btn btn-success mt-1 float-end">Payer ici</button>

          </div>
        ))}
      </div>)}
    </div>
  );
}
