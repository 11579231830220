import Icon from "../../components/obligatoire/Icon";

export default function FormParrainage({ register, t, uniq_generated_code }) {
  return (
    <div>
      <div className="form-group">
        <label className="form-label fz-12">
          Identifiant
          <Icon />
        </label>
        <input
          className="form-control fz-12"
          placeholder="Identifiant du parrainage ... "
          type="text"
          required
          {...register("identifiant_parrain")}
        />
      </div>

      <div className="form-group">
        <label className="form-label fz-12">CODE (Suggestion : {uniq_generated_code})  <Icon /></label>
        <div className="input-group mb-3">
         <input
          className="form-control fz-12"
          type="text"
          placeholder="xxxx"
          required
          {...register("code_parrain")}
        />
         <button
          type="button"
          className="btn btn-purple fz-12"
          onClick={() => navigator.clipboard.writeText(uniq_generated_code)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-files"
            viewBox="0 0 16 16"
          >
            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
          </svg>
        </button>
      </div>
       
      </div>
    
     
    </div>
  );
}
