import { Text, View, StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: "center",
    color: "#6003a9",
    borderTop: "2px solid #6003a9"
  },
  logoApp :{
    width :50,
    textAlign:"center"
  },
  link: {
    textDecoration: "none",
  },
});

const InvoiceTableFooter = () => {
  return (
    <View style={styles.pageNumber} fixed>
   
      <Text style={{marginTop:"10",  fontFamily:'Times-Roman',
   }}>
      DIOKOTECH, Société par Action Simplifiée au capital de 1 000 000 Francs CFA
      RCCM : SN-DKR-2021-B-21378 | NINEA  : 008658992 2V5
      Tel Fixe : +221 33 827 00 05 |  Tel Mobile : +221 78 192 65 65  |  contact@diokotech.com  | www.diokotech.com 
      I3e étage, lot n16 Cité CCBM, Ouest-Foire Dakar-Sénégal

      </Text>
    </View>
  );
};

export default InvoiceTableFooter;
