import React from 'react'
import Events from '../events/Events'
import News from '../news/News'

export default function TabHome({ t, language }) {
    return (
        <div className="container mt-3">

            <div className="minimal-tab">
                <ul className="nav nav-tabs mb-3" id="affanTab2" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="btn active fz-12"
                            id="sass-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#sass"
                            type="button"
                            role="tab"
                            aria-controls="sass"
                            aria-selected="true"
                        >
                            {t("Actualités")}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="btn fz-12"
                            id="npm-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#npm"
                            type="button"
                            role="tab"
                            aria-controls="npm"
                            aria-selected="false"
                        >
                            {t("Evénements")}
                        </button>
                    </li>
                </ul>
                <div className="tab-content rounded-lg p-3" id="affanTab2Content">
                    <div
                        className="tab-pane fade show active"
                        id="sass"
                        role="tabpanel"
                        aria-labelledby="sass-tab"
                    >
                        <h6 className="mb-0 fz-14">
                            {t("Récentes actualités")}
                            <div className="float-end">
                                <p className="mb-0">
                                    <a href="/allNews">
                                        <b
                                            style={{ cursor: 'pointer' }}
                                            className="text-purple fz-12"
                                        >
                                            {t("Voir tout")}
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-arrow-right-circle-fill m-2" viewBox="0 0 16 16">
                                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                            </svg>

                                        </b>
                                    </a>
                                </p>
                            </div>
                        </h6>
                        <News />
                    </div>
                    <div
                        className="tab-pane fade fz-14"
                        id="npm"
                        role="tabpanel"
                        aria-labelledby="npm-tab"  >
                        <h6>
                            {t("Récents événements")}
                            <div className="float-end">
                                <p className="mb-0 fz-12">
                                    <a href="/allEvents">
                                        <b
                                            style={{ cursor: 'pointer' }}
                                            className="text-purple"
                                        >
                                            {t("Voir tout")}
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-arrow-right-circle-fill m-2" viewBox="0 0 16 16">
                                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                            </svg>
                                        </b>
                                    </a>
                                </p>
                            </div>
                        </h6>
                        <Events />
                    </div>
                </div>
            </div>
        </div>

    )
}
