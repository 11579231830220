import React from "react";
import { useTranslation } from "react-i18next";

export default function VerifMail() {
      //------------- Traduction ---------------
  const [t] = useTranslation("global");
    return (
        <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
                <div className="text-center px-4">
                    <img className="login-intro-img mb-4" src="img/bg-img/40.png" alt="MailBox" />
                    <h3>{t("Vérifiez votre boîte Mail !")}</h3>
                    <p className="mb-4">
                    {t("Un e-mail de confirmation vous est envoyé à l'adresse e-mail que vous avez indiquée.")}
                    </p>
                    {/* Go Back Button */}
                    <a className="btn btn-purple col-12 fz-12" href="/login">
                       {t("S'authentifier")}
                    </a>
                </div>
            </div>
        </div>
    );
}
