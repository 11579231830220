/* eslint-disable array-callback-return */
import _ from "lodash";
import { getSignatureCompagny } from "../../../services/compagny/CompagnyAPI";
import { getConditionGeneralByCategory } from "../../../services/conditions/conditionsAPI";
import { getContratAccident, getContratAutos, getContratByID, getContratByPolicy, getContratDeces, getContratEducation, getContratRetraite, getContratVoyage  } from "../../../services/contrats/ContratApi";
import { getPaiementByPolicyCode } from "../../../services/paiement/paiementAPI";
import { getPersonnelByID } from "../../../services/personnel/personnelAPI";
import { getFactureByCategoryId, getFactureByNumero } from "../../../services/factures/FactureApi";

export const traitement = async (user_details, setsignatures, setpersonnel, type, id, setData, setloading_g, setdata_cg, setInfo, setLoading, policy_code, setpaiement, liste_assures) => {
  var facture = {};
  try {
    const personnelResult = await getPersonnelByID(user_details?.id);
    if (personnelResult?.data?.code === 200) {
      var personnel = personnelResult?.data?.data[0];
      setpersonnel(personnel);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const paiementResult = await getPaiementByPolicyCode(policy_code);
    if (paiementResult?.data?.code === 200) {
      setpaiement(paiementResult?.data?.data);
    }
  } catch (err) {
    console.log(err);
  }

  if (type !== "voyage") {
    try {
      const contratResult = await getContratByID(id);
      if (contratResult?.data.code === 200) {
        setData(contratResult?.data?.data[0]);
        console.log('our contract: ', contratResult?.data?.data[0]);
        var contratType = contratResult?.data?.data[0].category?.type;
        var category_id = contratResult?.data?.data[0]?.category_id;
        var compagny_id = contratResult?.data?.data[0].company_id;
        var policy_code = contratResult?.data?.data[0].policy_code;
        console.log('We went to look for the facture by this policy code:', policy_code);

        try {
          const factureResult = await getFactureByNumero(policy_code);
          if (factureResult?.data?.code === 200) {
            facture = factureResult?.data?.data;
            console.log('Your facture:', facture);
            console.log('Remise in your facture:', facture[0].remise);
          } else {
            console.log('The facture was not found');
          }
        } catch (err) {
          console.log('Because of:', err);
        }

        try {
          const signatureResult = await getSignatureCompagny(compagny_id);
          if (signatureResult?.data?.code === 200) {
            setsignatures(signatureResult?.data?.data);
          }
          console.log("Give me the data of signatures:", signatureResult?.data?.data);
        } catch (err) {
          console.log(err);
        }

        try {
          const conditionGeneralResult = await getConditionGeneralByCategory(category_id);
          if (conditionGeneralResult?.data.code === 200) {
            setloading_g(true);
            setdata_cg(conditionGeneralResult?.data?.data[0]);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setloading_g(false);
        }

        if (contratType === "education") {
          try {
            const contratEducationResult = await getContratEducation(id);
            if (contratEducationResult?.data?.code === 200) {
              let info = contratEducationResult?.data?.data[0];
              info.remise = facture?.remise;
              setInfo(info.remise);
            }
          } catch (err) {
            console.log(err);
          }
        } else if (contratType === "accident") {
          try {
            const contratAccidentResult = await getContratAccident(id);
            if (contratAccidentResult?.data?.code === 200) {
              let info = contratAccidentResult?.data?.data[0];
              info.remise = facture[0].remise;
              console.log("infooo1", contratAccidentResult?.data?.data[0]);
              console.log("facture?.remise", facture.remise);
              setInfo(info);
              console.log("infooo2", info);
            }
          } catch (err) {
            console.log(err);
          }
        } else if (contratType === "deces") {
          try {
            const contratDecesResult = await getContratDeces(id);
            if (contratDecesResult?.data?.code === 200) {
              let data = contratDecesResult?.data?.data[0];
              data.remise = facture.remise;
              setInfo(data);
            }
          } catch (err) {
            console.log(err);
          }
        } else if (contratType === "retraite") {
          try {
            const contratRetraiteResult = await getContratRetraite(id);
            if (contratRetraiteResult?.data?.code === 200) {
              let data = contratRetraiteResult?.data?.data[0];
              data.remise = facture.remise;
              setInfo(data);
            }
          } catch (err) {
            console.log(err);
          }
        } else if (contratType === "vehicule") {
          try {
            const contratAutosResult = await getContratAutos(id);
            if (contratAutosResult?.data?.code === 200) {
              let data = contratAutosResult?.data?.data[0];
              data.remise = facture.remise;
              setInfo(data);
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  } else {
    try {
      const contratResult = await getContratByPolicy(policy_code);
      if (contratResult?.data?.code === 200) {
        var liste_assures = contratResult?.data?.data;
        var compagny_id = contratResult?.data?.data[0].company_id;
        var num_facture = contratResult?.data?.data[0].num_facture;

        try {
          const signatureResult = await getSignatureCompagny(compagny_id);
          if (signatureResult?.data?.code === 200) {
            setsignatures(signatureResult?.data?.data);
          }
        } catch (err) {
          console.log(err);
        }

        var assuree = [];
        liste_assures?.map((items) => {
          assuree.push(items.assure);
        });

        try {
          const contratVoyageResult = await getContratVoyage(policy_code);
          if (contratVoyageResult?.data?.code === 200) {
            const data_voyage = contratVoyageResult?.data?.data;
            const data = {
              category: liste_assures[0].category,
              created_at: data_voyage[0]?.created_at,
              policy_code: policy_code,
              assuree: _.uniqBy(assuree, "id"),
              adresse: liste_assures[0].adresse,
              duree: data_voyage[0]?.duree,
              date_effet: data_voyage[0]?.date_depart,
              company: liste_assures[0].company,
              date_depart: data_voyage[0]?.date_depart,
              date_arrive: data_voyage[0]?.date_arrive,
              pays_alle: data_voyage[0]?.pays_alle,
              pays_destination: data_voyage[0]?.pays_destination,
              prime_nette: data_voyage[0]?.prime_nette,
              prime_ttc: data_voyage[0]?.prime_ttc,
              cout_police: data_voyage[0]?.cout_police,
              taxe: data_voyage[0]?.taxe,
              remise: facture.remise,
              num_facture: num_facture
              };
              setData(data);
              }
              } catch (err) {
              console.log(err);
              }
              }
              } catch (err) {
              console.log(err);
              } finally {
              setLoading(false);
              }
              }
              };
