/* eslint-disable react-hooks/exhaustive-deps */
/**
 * PAGE : ProfilUser.js
 * Consultation des informations génerales du client 
 * Le client peut modifier ses informations via cette interface
 * (Image, date de naissance etc ..)
 */

/* eslint-disable jsx-a11y/img-redundant-alt */
import  { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// # SERVICES 
import { useUser } from '../../../Auth/useUser'
import { getUser, updateUser } from '../../../services/users/usersAPI'

// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";

// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";

// # COMPONENTS
import Loader from '../../../components/loading/Loader'
import DotLoader from '../../../components/loading/DotLoader';

// # TOAST AND ALERT ELEMENTS
import { toast } from "react-toastify";
import { getPreviousPath } from '../../../utils/Comparaison';

export default function ProfilUser() {

    const user = useUser()
    var { id } = user.result
    // ------------ HISTORY --------------------
    const history = useHistory()
    //---------------- PROFIL DATA --------------
    const [data, setData] = useState(null)
    const [image, setImage] = useState({ preview: data?.image === undefined || null ? "" : data?.image, raw: "" })
    const [gender, setgender] = useState("")
    // ---------------USE FORM ELEMENTS ----------
    const { register, handleSubmit, reset } = useForm() //FORM DATA   
    //---------------- LOADING -------------------
    const [loading, setLoading] = useState(true) // Loader Page
    const [loadingForm, setloadingForm] = useState(false) //Loader Form
    //------------- Traduction ---------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getUser(id)
            .then((result) => {
                var info = result?.data?.data
                setTimeout(
                    () =>{
                        setData({
                            id: id,
                            nom: info?.nom,
                            prenom: info?.prenom,
                            mobile: info?.mobile,
                            image: info?.image === null ? null : process.env.REACT_APP_IMAGE + info?.image,
                            date_naissance: info?.date_naissance,
                            lieu_naissance: info?.lieu_naissance,
                            email: info?.email,
                        })
                        setgender(info?.gender)
                    },
                        
                    1200,
                )
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setTimeout(
                    () =>
                        setLoading(false), 1200
                )

            })
    }, [])

    // UPDATE DATA INTO FORM 
    useEffect(() => {
        reset(data)
    }, [data])

    function onFormSubmit(dataRes) {
     
        setloadingForm(true)
        Object.assign(dataRes,{
            gender : gender
        })
        if (image?.raw !== "") {
            Object.assign(dataRes, {
                image: image?.raw
            })
        }
        updateUser(dataRes).then((result) => {
            if (result?.data?.code === 200) {
                toast(t("Modifié avec succès"), { type: "success", position: "top-right" })
                if(getPreviousPath("facturation_produits")!== -1){
                    history.goBack()
                }else{
                    history.push({pathname:"/"})
                }
            } else {
                toast(t("Erreur"), { type: "error", position: "top-right" })
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setTimeout(
                () =>
                 setloadingForm(false), 1200
            )
        })

        return false
    }

    //UPLOAD IMAGE WITH PREVIEW image 
    const selectFile_image = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0]
            })
        }
    }
    return (
        <div>
            <div className="page-content-wrapper mt-0 py-3">
                {loading ? (
                    <Loader />
                ) : (
                    <div className="container">
                        {/* User Meta Data*/}

                        <div >
                            <div className="card-body">
                                <h1 className="display-6 mb-2 text-center">{t("Mon Profil")} </h1>
                                <form onSubmit={handleSubmit(onFormSubmit)}>
                                    <div className="rounded text-center me-3">
                                        <img src={data?.image === null ? "img/bg-img/2.jpg" : data?.image} alt="user image" className="rounded" height={100} width={100} />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="Username">
                                            {t("Nom")}
                                        </label>
                                        <input
                                            className="form-control"
                                            {...register('nom')}
                                            type="text"
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="fullname">
                                            {t("Prénom")}
                                        </label>
                                        <input
                                            className="form-control"
                                            {...register('prenom')}
                                            type="text"
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="email">
                                            {t("E-Mail")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            {...register('email')}

                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="job">
                                            {t("Mobile")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register('mobile')}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="portfolio">
                                            {t("Date de Naissance")}
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            {...register('date_naissance')}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label fz-12">
                                            {t("Lieu de la date de naissance")}
                                        </label>
                                        <input
                                            className="form-control fz-12"
                                            placeholder="Lieu de la date de naissance"
                                            type="text"
                                            required
                                            {...register("lieu_naissance")}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="portfolio">
                                            {t("Genre")}
                                        </label>
                                        <div>
                                            <div className={gender === "femme" ? "single-plan-check shadow-sm active-effect active" : "single-plan-check shadow-sm active-effect"}>
                                                <div className="form-check mb-0">
                                                    <input    className="form-check-input" defaultChecked={gender === "femme" ? true : false} onChange={()=>setgender("femme")} type="radio" name="planChoose" id="Individual" />
                                                    <label className="form-check-label" htmlFor="Individual">{t("Femme")}</label>
                                                </div>
                                                <i className="bi bi-gender-female text-pink" />


                                            </div>
                                            <div className={gender === "homme" ? "single-plan-check shadow-sm active-effect active" : "single-plan-check shadow-sm active-effect"}>
                                                <div className="form-check mb-0">
                                                    <input   className="form-check-input" defaultChecked={gender === "homme" ? true : false} onChange={()=>setgender("homme")} type="radio" name="planChoose" id="Team" />
                                                    <label className="form-check-label" htmlFor="Team">{t("Homme")}</label>
                                                </div>
                                                <i className="bi bi-gender-male text-primary" />
                                            </div>

                                        </div>

                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="portfolio">
                                            {t("Photo de profil")}
                                        </label>
                                        <div className="file-upload-card">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-image text-success" viewBox="0 0 16 16">
                                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                                            </svg>
                                            <h5 className="mt-2 mb-4">Upload your pictures !</h5>
                                            <div className="form-file">
                                                <input className="form-control d-none" id="customFile" type="file" name='image' onChange={(e) => selectFile_image(e)} />
                                                <label className="form-file-label justify-content-center" htmlFor="customFile"><span className="form-file-button btn btn-success shadow w-100">Upload File</span></label>

                                            </div>


                                        </div>
                                        <div className='text-center' >
                                            {image?.preview !== "" ? <img src={image.preview} alt="image_produit" className='text-center mt-3' height={250} /> : ""}

                                        </div>
                                    </div>
                                    {loadingForm ? <button className="btn btn-purple w-100" type="submit" disabled>
                                        {t("En Cours")} <DotLoader />
                                    </button> : <button className="btn btn-purple w-100" type="submit">
                                    {t("Enregistrer")}
                                    </button>}
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
