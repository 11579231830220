/***RECHERCHER UN ELEMENT DANS UNE LISTE */
export function SearchItems(array,search) {
    const newList = array?.filter((el) => {
        return Object.values(el)
        .join(" ")
        .toLowerCase()
        .includes(search.toLowerCase())
        
        })
    return newList
  }
/**
 * SPACE BETWEEN TWO NUMBERS
 * @param {*} x 
 * @returns 
 */
 export function numberWithSpaces(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }
  
  
   /**
   * GET TYPE PAIEMENT 
   * @param {*} type 
   */
   export function getTypePaiement (type ){

    if(type==="FREE_SN_WALLET_CASH_OUT"){return "Free Money" }
    else if(type==="EXPRESSO_SN_WALLET_CASH_OUT") return "E-Money"
    else if(type==="ORANGE_SN_API_CASH_OUT")return "Orange Money"
    else if(type==="WAVE_SN_API_CASH_OUT")return "Wave Money"
    else if(type==="WIZALL_SN_API_CASH_OUT")return "Wizall Money"
    else if(type==="BANK_CARD_API_CASH_OUT")return "Carte Bancaire"


  }