import parse from "html-react-parser";
import Loader from "../../../../components/loading/Loader";

/**
 * MODAL CONDITION GENERALES
 * @returns
 */
export default function ConditionGeneral({ setcondition1 ,data , loading_g ,t}) {


  return (
    <div
      className="modal fade"
      id="exampleModal1"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-md-down">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body"> {loading_g? <Loader/> : parse(data?.description)}  </div>
          <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary fz-12"
            data-bs-dismiss="modal"
          >
            {" "}
            <i className="bi bi-x-lg fz-12" /> {t("Fermer")}
          </button>
          <button
            type="button"
            className="btn btn-primary fz-12"
            onClick={() => setcondition1(true)}
            data-bs-dismiss="modal"
          >
            {" "}
            <i className="bi bi-check2-circle fz-12" /> {t("Confirmer")}
          </button>
        </div>
        </div>
        
      </div>
    </div>
  );
}
