import Icon from "../../../../components/obligatoire/Icon";
import { pays } from "../../../../utils/ListePays";
import Cookies from "js-cookie";

export default function Voyage({ register, t, assuree, LISTE_ASSUREE, watch }) {
  const language = Cookies.get("locale");

  return (
    <div>

      {LISTE_ASSUREE?.length === 0 && (
        <>
          <div className="shop-pagination">
            <div className="d-flex align-items-center justify-content-between">
            <small className="mb-3 fz-14">
                {t("Informations Voyage")}
              </small>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("N° Passeport")}
              </label>
              <input
                className="form-control fz-12"
                type="text"
                {...register("n_passport")}
                placeholder={t("N° Passeport") + "... "}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("Validité Passeport")}
              </label>
              <input
                className="form-control fz-12"
                type="date"
                {...register("validite_passeport")}
                placeholder={t("Validité Passeport") + "... "}
                required
              />
            </div>
          </div>
        </>
      )}

      <div className="form-group mb-3 text-center mt-2">
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input fz-12"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              {...register("etat")}
              defaultValue="option1"
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              {t("N° jours")}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input fz-12"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              {...register("etat")}
              defaultValue="option2"
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              {t("Date")} 
            </label>
          </div>
        </div>
      </div>
      {watch("etat") === "option1" ? (
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Nbr° Jours du séjour")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            type="number"
            placeholder="nombre de jours du séjour ... "
            {...register("nbr_jours")}
            required
          />
        </div>
      ) : (
        watch("etat") === "option2" && (
          <>
            <div className="form-group mb-3">
            <label className="mb-2 fz-12">
                {t("Date départ")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="date"
                {...register("date_depart")}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("Date retour")} <Icon />
              </label>
              <input
                className="form-control fz-12 "
                type="date"
                {...register("date_retour")}
                required
              />
            </div>
          </>
        )
      )}
      {watch("etat") === "option1" && (
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Date Effet")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            {...register("date_effet")}
            type="date"
            placeholder={t("Date Effet")}
            required
          />
        </div>
      )}
        <div className="form-group mb-3">
          <label className="form-label fz-12">{t("Formules")}</label>
          <select
            className="form-select fz-12"
            {...register("formule")}
          >
             <option value={""}>{t("Choisir")} </option>
            <option value={"shengen"}>{t("Schengen")} </option>
            <option value={"monde"}>{t("Monde")} </option>
            <option value={"afrique"}>{t("Afrique")} </option>
          </select>
        </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Pays de départ")}
        </label>
        <select
          className="form-select fz-12"
          value={"Senegal"}
          {...register("pays_depart")}
        >
            <option value={""}>{t("Choisir")} </option>
          {pays?.map((items, keys) => (
            <option key={keys} value={items.name}>
              {language === "fr" ? items.nameFr : items.name}    
          </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Pays de destination")}
        </label>
        <select className="form-select fz-12" {...register("pays_destination")}>
          <option value={""}>{t("Choisir")} </option>
          {pays?.map((items, keys) => (
            <option key={keys} value={items.name}>
               {language === "fr" ? items.nameFr : items.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
