import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCategoriesByTypeOfFacturation } from "../../services/categories/CategoriesApi";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

export default function Cotisation() {
  const history = useHistory();
  //------- INIT LANGAGE
  const language = Cookies.get("locale");
  // LISTE DES CATEGORY DONT LE TYPE DE PAIEMENT EST LA COTISATION
  const [LISTE_FACTURE, setLISTE_FACTURE] = useState([]);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");

  useEffect(() => {
    getCategoriesByTypeOfFacturation("cotisation")
      .then((result) => {
        if (result?.data?.code === 200) {
          console.log(result?.data);
          setLISTE_FACTURE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const redirectToDetails = (id) => {
    history.push(`/cotisation_list/${id}`);
  };
  return (
    <div className="container">
      <div className="shop-pagination pb-3">
        <div className="container mb-3">
          <div className="card">
            <div className="card-body p-2">
              <div className="d-flex align-items-center justify-content-between">
                <small className="ms-1">{t("Choisir Votre Assurance")}</small>
              </div>
            </div>
          </div>
        </div>
        {LISTE_FACTURE?.map((items, keys) => (
          <div className="card mb-3" style={{ alignItems: "center",height:"172px" }}>
            <div className="col-6" key={keys} style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
              <div
                className="card team-member-card shadow"
                style={{ cursor: "pointer",width:"70%",height:"150px" }}
                onClick={() => redirectToDetails(items.id)}
              >
                <div className="card-body">
                  {/* Member Image*/}
                  <img
                    className="mt-2 mb-0"
                    width={"60px"}
                    src={process.env.REACT_APP_IMAGE + items.logo}
                    alt="login_image"
                  />
                  {/* Team Info*/}
                  <div className="team-info">
                    <h6 className="mb-1 mt-3 text-white display-5 fz-16">
                      {language === "fr" ? items.titre : items.titre_en}{" "}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
