import { useEffect, useState } from "react";
import {
  getMessages,
  updateMessagesById,
} from "../../services/messages/messageAPI";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function ModalNotifcations({ t, setOk, LISTE , setLISTE }) {
  const history = useHistory();



  const onClickOnMessage = (id) => {
    updateMessagesById({ id: id, is_read: true })
      .then((result) => {
        if (result?.data?.code === 200) {
          history.push({ pathname: "/messages/" + id });
          setOk(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="modal fade"
      id="notification"
      tabIndex="-1"
      data-bs-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="notification">
              Notifications de messagerie
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setOk(false)}
            ></button>
          </div>
          <div className="modal-body">
            {LISTE?.length === 0 ? (
              <div
                className="alert custom-alert-1 alert-info alert-dismissible fade show"
                role="alert"
              >
                <i className="bi bi-info-circle" />
                Aucun nouveau message n'a été envoyé par l'équipe DiokoAssur
              </div>
            ) : (
              <>
                {LISTE?.map((items, keys) => (
                   <div
                    className="alert custom-alert-3 alert-primary alert-dismissible fade show" style={{cursor:"pointer"}}
                    role="alert" key={keys} onClick={() => onClickOnMessage(items?.id)}
                  >
                    <div className="alert-text">
                      <h6 className="fz-14"> Envoyé Le : {moment(items.created_at).format("DD/MM/YYYY")}</h6>
                      <h6 className="mt-1 fz-14 ">Réponse à votre <b>{items?.type}</b> </h6>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="modal-footer">
            <button className="btn btn-purple" onClick={() => setOk(false)}>
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
