import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: "center",
        height: 18,
        fontSize: 10,
    },
    description1: {
      width: "100%",
      textAlign: "left",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
      fontFamily:'Arial',
      fontStyle:"normal",
      textTransform:"capitalize"
  },
  text: {
    fontFamily:'Arial',
    fontStyle:"bold",
},
});

const InvoiceItemsBeneficiaire = ({ type, data, user_details, personnel }) => {
  const rows = (
    <>
      {type !== "voyage" && data?.assuree_id !== null ? (
        <>
          <View style={styles.row}>
            <Text style={styles.description1}>** {"Bénficiaire 1"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Nom & Prénom(s): </Text> {data?.assure?.prenom + " " + data?.assure?.nom}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Date de Naissance : </Text>  {moment(data?.assure?.date_naissance).format("DD/MM/YYYY")}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>CIN : </Text> {data?.assure?.cin}</Text>
          </View>
          {data?.assure?.passport !== "" && data?.category?.type === "voyage" && (
            <View style={styles.row}>
              <Text style={styles.description1}><Text style={styles.text}>N° Passeport :</Text> {data?.assure?.passport}</Text>
            </View>
          )}
        </>
      ) : (
       ( type !== "voyage" &&  data?.assuree_id === null) && <>
        <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Nom & Prénom(s) : </Text>{user_details?.prenom+" " + user_details?.nom}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Adresse :</Text> {data?.adresse}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Télèphone :</Text> {user_details?.mobile}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>E-Mail :</Text>{user_details?.email}</Text>
        </View>
    </>
      )}
      {type === "voyage" && data?.assure?.length !== 0 ? (
        data?.assuree?.map((items,keys) => (
          <>
          <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Nom & Prénom(s) : </Text>{items?.prenom+" " + items?.nom} ,  <Text style={styles.text}>Date de Naissance :</Text>{moment(items?.date_naissance).format("DD/MM/YYYY")}</Text>
        </View>
         <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>CIN :</Text> {items?.cin}, <Text style={styles.text}>N° Passeport :</Text>{items?.passport}</Text>
        </View>  
      
          </>
        ))
      ) : type === "voyage"&& data?.assure?.length === 0 && (
        <>
         <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Nom & Prénom(s) : </Text>{user_details?.prenom+" " + user_details?.nom}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>Date de Naissance :</Text>{moment(user_details?.date_naissance).format("DD/MM/YYYY")}</Text>
        </View>
        {personnel?.n_cin !== "" && (  <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>CIN :</Text> {personnel?.n_cin}</Text>
        </View>          )}
        {personnel?.n_passport !== "" && (
           data?.category?.type === "voyage" && (
        <View style={styles.row}>
            <Text style={styles.description1}><Text style={styles.text}>N° Passeport :</Text>{personnel?.n_passport}</Text>
        </View>) )}
               </>
      )}
    </>
  );
  return <Fragment>{rows}</Fragment>;
};
export default InvoiceItemsBeneficiaire;
