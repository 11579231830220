/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";
// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";
import DotLoader from "../../components/loading/DotLoader";
import { addSinistre } from "../../services/sinistre/sistreAPI";
import { useHistory } from "react-router-dom";
import { useUser } from "../../Auth/useUser";
import { getUser } from "../../services/users/usersAPI";
import { toast } from "react-toastify";
import Loader from "../../components/loading/Loader";
import { getContratByUser } from "../../services/contrats/ContratApi";
import _ from "lodash";
import Icon from "../../components/obligatoire/Icon";
import Cookies from "js-cookie";
import { error_message, success_message } from "../../components/alerte/AlerteMessage";

export default function DeclarationSinistre() {
    //-----------------HISTORY -------------------
    const history = useHistory("")
    //--------INFORMATIONS OF ACTUAL USER---------
    const user = useUser()
    var { id } = user.result
    // ---------------USE FORM ELEMENTS ----------
    const { register, handleSubmit, reset ,watch } = useForm() //FORM DATA   
    const [data, setData] = useState(null)
    // LISTE TYPE ASSURANCE 
    const [TypeAssurance, setTypeAssurance] = useState([])
    const [ListeNPolicy, setListeNPolicy] = useState([])
    const [saveListePolicy, setsaveListeNPolicy] = useState([])
    const language = Cookies.get("locale");

    //---------------- LOADING -------------------
    const [loading, setLoading] = useState(true); // Loader Page
    const [loadingForm, setloadingForm] = useState(false); //Loader Form
    //------------- Traduction ---------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getUser(id)
        .then((result) => {
            var info = result?.data?.data
            setTimeout(
                () =>
                    setData({
                        nom: info?.nom,
                        prenom: info?.prenom,
                        telephone: info?.mobile,
                        email: info?.email,
                    }),
                1200,
            )
        })
        .catch((err) => {
            console.log(err)
        })
        getContratByUser(id)
        .then((result) => {
          if (result?.data?.code === 200) {
            var Category = result?.data?.data
            var liste_policy_code = []
            var type= []
            Category?.map((items,keys)=>{
                type.push({id : items.category_id , titre : items.category.titre , titre_en :items.category.titre_en });
                liste_policy_code.push({category_id : items.category_id , policy_code :items.policy_code  })
            })
            var type_sinistre = _.uniqBy(type,"id")
            var liste_policy = _.uniqBy(liste_policy_code,"policy_code")

            setTypeAssurance(type_sinistre)
            setListeNPolicy(liste_policy)
            setsaveListeNPolicy(liste_policy)
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
            setTimeout(
                () => setLoading(false), 1200)
        });
  
    }, [])
       // UPDATE DATA INTO FORM 
       useEffect(() => {
        reset(data)
    }, [data])

    const onChangeTypeAssurance = (e) =>{
       var L = saveListePolicy?.filter(el=>el.category_id === parseInt(e.target.value))
        setListeNPolicy(L)
    }

    const handleRegistration = (data) => {
        Object.assign(data,{
            user_id : id
        })

        addSinistre(data)
        .then((result) => {
            if(result?.data?.code === 200){
                var id = result?.data?.data?.id
                success_message(t("Votre déclaration de sinistre a été ajouté avec succès!"))
                history.push({
                    pathname : "/declaration_sinistre_media&id="+id
                })
            }else {
                error_message(t("Erreur"))
                toast("Erreur", {type:"error",position: "top-right"})
            }
        }).catch((err) => {
            console.log(err)
        });
      };
    return (
        <div className="page-content-wrapper mt-0 py-3">
            {loading ? <Loader /> :
                <div className="container">
                    <div >
                        <div className="card-body">
                            <h1 className="display-6  text-center " style={{marginBottom :"50px"}}>
                                {t("Déclarations sinistre")}
                            </h1>
                            <form onSubmit={handleSubmit(handleRegistration)}>
                                <div className="form-group mb-3 ">
                                    <label className="form-label fz-12" htmlFor="Username">
                                        {t("Type assurance")} <Icon/>
                                    </label>
                                    <select className="form-control fz-12" required  onClick={(e)=>onChangeTypeAssurance(e)} 
                                    {...register("type_assurance")}>
                                        <option> {t("Type")}  </option>
                                        {TypeAssurance?.map((items , keys ) =>(
                                        <option value={items.id} key={keys}>{language==="fr"? items.titre : items.titre_en}</option>
                                        ))}
                                    </select>
                                </div>
                                {watch("type_assurance") !== undefined && <div className="form-group mb-3 ">
                                    <label className="form-label fz-12" htmlFor="Username">
                                    {t("N°Police")}  <Icon/>
                                    </label>
                                    <select className="form-control fz-12" required 
                                    {...register("n_police")}>
                                        <option> {t("Type")}  </option>
                                        {ListeNPolicy?.map((items , keys ) =>(
                                        <option value={items.id} key={keys}>{items.policy_code}</option>
                                        ))}
                                    </select>
                                </div>}
                                
                                <div className="row">
                                
                                    <div className="form-group mb-3 col-12">
                                        <label className="form-label fz-12" htmlFor="Username"> 
                                            {t("Nom")}  <Icon/>
                                        </label>
                                        <input
                                            className="form-control fz-12"
                                            {...register("nom")}
                                            type="text"
                                            disabled
                                            placeholder="Nom"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-12">
                                        <label className="form-label fz-12" htmlFor="Username">
                                            {t("Prénom")}  <Icon/>
                                        </label>
                                        <input
                                            className="form-control fz-12"
                                            {...register("prenom")}
                                            type="text"
                                            disabled
                                            placeholder="Prénom"

                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label fz-12" htmlFor="Username">
                                        {t("Mobile")}  <Icon/>
                                    </label>
                                    <input
                                        className="form-control fz-12"
                                        {...register("telephone")}
                                        type="text"
                                        disabled
                                        placeholder="Mobile"

                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label fz-12" htmlFor="Username">
                                        {t("E-Mail")}  <Icon/>
                                    </label>
                                    <input
                                        className="form-control fz-12"
                                        {...register("email")}
                                        type="text"
                                        placeholder="E-Mail"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label fz-12" htmlFor="Username">
                                        {t("Message")}  
                                    </label>
                                    <textarea
                                        className="form-control fz-12"
                                        {...register("description")}
                                        type="text"
                                        placeholder="Message"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label text-success fz-12" style={{lineHeight: '1.6'}}>
                                        <i className="bi bi-info-circle text-success me-2" />
                                        {t("Après la validation des coordonnées saisies à la déclaration du sinistre,vous serez redirigé vers une autre interface pour insérer vos justificatifs du sinistre")}
                                    </label>
                                </div>
                                {loadingForm ? (
                                    <button className="btn btn-purple w-100" type="submit" disabled>
                                        {t("En Cours")} <DotLoader />
                                    </button>
                                ) : (
                                    <button className="btn btn-purple w-100" type="submit">
                                        {t("Ajouter")}
                                    </button>
                                )}
                            </form>
                        </div>
                    </div>
                </div>}

        </div>
    );
}
