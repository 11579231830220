
export default function Souscripteur({t,user_details,moment,personnel,data}) {
  return (
    <div>
      <b className="mb-3 text-warning text-center fz-12">{t("Souscripteur")}</b>
      <p className="mt-3 fz-12">
        <b> {t("Prénom")} </b>
        {user_details?.prenom}, <b> {t("Nom")} </b>
        {" " + user_details?.nom}
      </p>
      <p className="mt-3 fz-12">
        <b> {t("Date de naissance")} </b> :
        {moment(user_details?.date_naissance).format("DD/MM/YYYY")}
      </p>
      {personnel?.n_cin !== "" && (
        <p className="mt-3 fz-12">
          <b> {t("N° CIN")} </b> : {personnel?.n_cin}
        </p>
      )}
      {personnel?.n_passport !== "" && data?.category?.type === "voyage" && (
        <p className="mt-3 fz-12">
          <b> {t("N° Passeport")} </b> : {personnel?.n_passport}
        </p>
      )}
      <p className="mt-3 fz-12">
        <b> {t("Adresse")} </b> : {data?.adresse}
      </p>
      <p className="mt-3 fz-12">
        <b> {t("Mobile")} </b> :
        {user_details?.mobile}
      </p>
    </div>
  );
}
