import{ useState } from "react";
import { useEffect } from "react";
import Loader from "../../../../components/loading/Loader";
import {
  getConditionGeneralByCategory,
  getConditionSpecialesByCategory,
} from "../../../../services/conditions/conditionsAPI";
import ConditionGeneral from "./ConditionGeneral";
import ConditionsSpeciales from "./ConditionsSpeciales";

export default function Compagny({ t, id, register ,Liste_Compagny }) {
  //------------- CONDITIONS POLICY --------
  const [condition1, setcondition1] = useState(false);
  const [condition2, setcondition2] = useState(false);

  // DATA DES CONDITIONS -------------------
  const [data_cg, setdata_cg] = useState({}); // condition generale
  const [data_cp, setdata_cp] = useState({}); // condition particuliere
  // -------------- LOADER
  const [loading_g, setloading_g] = useState(true);
  const [loading_sp, setloading_sp] = useState(true);

  // Loading
  const [loading, setloading] = useState(false);

  useEffect(() => {
  
    getConditionGeneralByCategory(id)
      .then((result) => {
        if (result?.data.code === 200) {
          setloading_g(true);
          setdata_cg(result?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading_g(false);
      });
    getConditionSpecialesByCategory(id)
      .then((result) => {
        if (result?.data.code === 200) {
          setloading_sp(true);
          setdata_cp(result?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading_sp(false);
      });
  }, [id]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <small className="ms-1"> {t("Choix de la compagnie d'assurance")} </small>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div>
              {Liste_Compagny?.map((items, keys) => (
                <div
                  className="single-plan-check shadow-sm active-effect mt-3"
                  key={keys}
                >
                  <div className="form-check mb-0">
                  <input
                      className="form-check-input"
                      type="radio"
                      key={keys}
                      {...register("company_id")}
                      value={items?.id}
                      required
                    />
                      <label className="form-check-label" htmlFor="Individual">
                      {items?.nom}
                      </label>
                    </div>
                    <img
                      src={process.env.REACT_APP_IMAGE + items.logo}
                      className="rounded"
                      width={"50px"}
                      alt=""
                    />
             
                </div>
              ))}
            </div>

            {data_cg !== undefined && data_cp !== undefined && (
              <div className="d-flex align-items-center justify-content-between mt-3">
                <small className="ms-1">
                  {t("Condition Géneral & Condition spécial")}
                </small>
              </div>
            )}

            {data_cg !== undefined && (
              <div className="form-check mt-3">
                <input
                  className="form-check-input text-muted fz-12"
                  type="checkbox"
                  checked={condition1}
                  onChange={(e) => setcondition1(e.target.checked)}
                  required
                />
                <label
                  className="form-check-label text-muted fz-12"
                  htmlFor="checkedCheckbox"
                >
                  {t("Je déclare adhérer aux")}{" "}
                  <b
                    className="fw-bold text-purple "
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                  >
                    {t(
                      "conditions générales d’utilisation et de vente Assur 3.0"
                    )}
                  </b>
                </label>
              </div>
            )}

            {data_cp !== undefined && (
              <div className="form-check">
                <input
                  className="form-check-input text-muted fz-12"
                  type="checkbox"
                  checked={condition2}
                  onChange={(e) => setcondition2(e.target.checked)}
                  required
                />
                <label
                  className="form-check-label text-muted fz-12"
                  htmlFor="checkedCheckbox"
                >
                  {t("Je déclare adhérer à")}{" "}
                  <b
                    className="fw-bold text-purple"
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                  >
                    {t("la politique de protection des données Assur3.0")}
                  </b>
                </label>
              </div>
            )}
          </>
        )}
      </div>
      {/** MODAL -------------> CONDITIONS GENERALES D'utilisation */}

      {data_cg !== undefined && (
        <ConditionGeneral
          setcondition1={setcondition1}
          data={data_cg}
          t={t}
          loading_g={loading_g}
        />
      )}

      {/** MODAL ------------->POLITIQUE DE CONFIDENTIALITE        */}

      {data_cp !== undefined && (
        <ConditionsSpeciales
          setcondition2={setcondition2}
          data={data_cp}
          t={t}
          loading_sp={loading_sp}
        />
      )}
    </div>
  );
}
