import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../Auth/useUser";
import { getUser } from "../../services/users/usersAPI";
import { getFactureByUser } from "../../services/factures/FactureApi";
import EmptyCotisation from "./EmptyCotisation";
import { t } from "i18next";
import Cotisation_total from "./Cotisation_total";
import Cotisation_card from "./Cotisation_card";
import ModalSaisieCode from "../securite/ModalSaisieCode";
import Loader from "../../components/loading/Loader";
import { useHistory } from "react-router-dom";
import Paiement from "../contrats/components/paiement/Paiement";
import accounting from "accounting";
import { useForm } from "react-hook-form";
import Paiement_specifique from "../contrats/components/paiement/Paiement_specifique";
import DotLoader from "../../components/loading/DotLoader";
import { saveAs } from 'file-saver';
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import jsPDF from "jspdf"


export default function Cotisation_details() {
  const [loading, setloading] = useState(true);
  const history = useHistory();
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA
  const { idcategory, policy_code } = useParams();
  console.log("policy_code to get factures assoccies:", policy_code);
  const user = useUser();
  var { id, nom, prenom,email, gender } = user.result;
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  const [factures, setFactures] = useState([]);
  const [paidFactures, setPaidFactures] = useState([]);
  const [unpaidFactures, setUnpaidFactures] = useState([]);
  const [totalCotisation, setTotalCotisation] = useState(0);
  const [data, setdata] = useState({});
  const [LISTE_ASSUREE, setLISTE_ASSUREE] = useState([]); // Liste assurée
  const [type, settype]=useState({});
  const [groupedAdresses, setgroupedAdresses] = useState([]);
  const [show5, setShow5] = useState(false);
  const [montant, setmontant] = useState(0);
  const [downloadLink, setDownloadLink] = useState('');
  const [showFacture, setshowFacture] = useState(false);
  const [paiement, setpaiement] = useState([]);
//--------- info TYPE DE CONTRAT ----------
const [info, setInfo] = useState({});
// PERSONAL INFO
const [personnel, setpersonnel] = useState({});
const user_details = user.result;
  const africanCurrencyOptions = {
    symbol: "FCFA", // Set the currency symbol
    decimal: " ", // Set the decimal separator
    thousand: " ", // Set the thousand separator
    precision: 0, // Set the number of decimal places
    format: "%v %s", // Set the format pattern
  };
  const [unpaidFactureLength, setunpaidFactureLength] = useState("");
  //--------- SIGNATURES --------------
  const [signatures, setsignatures] = useState({});
  // Function to format a given value with the African currency options
  const formatCurrency = (value) => {
    return accounting.formatMoney(value, africanCurrencyOptions);
  };
  const sendData = (facture) => {
    localStorage.setItem("selectedFacture", JSON.stringify(facture));
  };
  const sendDataFacture = (factureCotisation) => {
    localStorage.setItem("selectedFactureCotisation", JSON.stringify(factureCotisation));
  };
  useEffect(() => {
    getUser(id)
      .then((result) => {
        console.log("result of our user :",result)
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
          console.log("object of user :",obj)
        }
        getFactureByUser(id)
          .then((result) => {
            if (result?.data?.code === 200) {
              console.log("factures of current user", result);
              var L = result?.data?.data?.filter(
                (el) => el.num_facture === policy_code
              );
              var P = L.filter((el) => el.statut == "paid");
              var U = L.filter((el) => el.statut == "unpaid");
              console.log("paid invoices: ", P);
              console.log("unpaid invoices: ", U);

              setFactures(L);
              setPaidFactures(P);
              setUnpaidFactures(U);
              var unpaidFacturelength = U.length.toString().padStart(2, "0");
              setunpaidFactureLength(unpaidFacturelength);
              var totalCotisation = 0;
              for (let i = 0; i < P.length; i++) {
                totalCotisation += P[i].montant;
                P[i].montant = formatCurrency(P[i].montant);
              }
              for (let i = 0; i < U.length; i++) {
                totalCotisation += U[i].montant;
                U[i].montantNumber = U[i].montant;
                U[i].montant = formatCurrency(U[i].montant);
              }
              setTotalCotisation(totalCotisation);
              console.log("Results after filter:", L);
              setloading(false);
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
        console.log("Results after filter:", factures);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const redirectToDetails = () => {
    history.push(`/unpaid_factures/${policy_code}`);
  };
  const navigateToInvoice = (factureId) => {
    history.push(`/cotisation_facture/${factureId}/${idcategory}`);
  };

  //   fetch(downloadLink)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Error downloading the invoice');
  //       }
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       const fileName = `facture_${items.id}.pdf`;
  //       saveAs(blob, fileName);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div>
      {loading ? <DotLoader /> : 
      <div className="container">
        <div className="shop-pagination pb-3">
          {show5 ? (
            <>
              <Paiement_specifique
                id={id}
                handleSubmit={handleSubmit}
                register={register}
                data={data}
                watch={watch}
                groupedAdresses={groupedAdresses}
                LISTE_ASSUREE={LISTE_ASSUREE}
                type={type}
                t={t}
                montant={montant}
                unpaidFactures={unpaidFactures}
                totalCotisation={totalCotisation}
              />
            </>
          ) : (
            <div className="container">
              {showCodePIN && (
                <ModalSaisieCode
                  id={id}
                  setshowCodePIN={setshowCodePIN}
                  t={t}
                />
              )}
              <div className="shop-pagination pb-3">
                <div className="container mb-3">
                  <div className="card">
                    <div className="card-body p-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <small className="ms-1">{t("Factures")} </small>
                      </div>
                      <div>
                        <h4 style={{ marginTop: "30px", textAlign: "center" }}>
                          Total Cotisation : {formatCurrency(totalCotisation)}
                        </h4>
                      </div>
                      <div style={{ marginTop: "30px" }}>
                        <button
                          className="btn btn-success text-white fz-10 float-end"
                          onClick={redirectToDetails}
                        >
                          {" "}
                          Consulter factures Impayés:
                          <span
                            className="text-danger"
                            style={{ fontSize: "13px", marginLeft: "6px" }}
                          >
                            {unpaidFactureLength}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2 d-flex">
                  <div className="d-flex justify-content-between">
                    <div className="timeline-text mb-2">
                      <span
                        className="badge mb-2 rounded-pill fz-10"
                        style={{ backgroundColor: "#08c5d1" }}
                      >
                        Prochaine Facture
                      </span>
                    </div>
                  </div>
              
                  {unpaidFactures?.length !== 0 ? (
                    <div style={{ marginTop: "-20px" }}>
                      {unpaidFactures.map((items, k) => (
                        <div
                          className="card timeline-card"
                          key={k}
                          style={{ marginTop: "19px" }}
                        >
                          <>
                            <div className="card-body">
                      
                              <div className="d-flex justify-content-between">
                                <div className="timeline-text mb-2">
                                  <span className="badge mb-2 rounded-pill fz-10">
                                    Facture N°: {items?.id}
                                  </span>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 d-flex align-items-center">
                                  <div>
                                    <p className="fz-10">
                                      <b>{t("Montant")} :</b> {items?.montant.toString().split('.')[0]}
                                    </p>
                                    <p className="fz-10">
                                      <b style={{ color: "red" }}>
                                        <b>{t("Statut")} :</b>
                                        {items?.statut === "unpaid"
                                          ? "  Non payé"
                                          : "payé"}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-center">
                                  <div>
                                    <p className="fz-10">
                                      <b>{t("Date d'échéance")} :</b>{" "}
                             
                                      {items.Date_prevu}
                                      {/* {moment(items.Date_prevu).format("DD/MM/YYYY")} */}
                                    </p>
                                    <p className="fz-10">
                                      <b>{t("Payée le")} :</b> Non payée
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setShow5(true);
                                  sendData(items);
                                }}
                                className="btn btn-success text-white fz-10 float-end"
                              >
                                {t("Payer")}
                                <i className="bi bi-arrow-right fz-10" />
                              </button>

                            </div>
                          </>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <EmptyCotisation t={t} />
                  )}
                </div>

                <div className="row mb-5">
                  {paidFactures?.length !== 0 ? (
                    <div style={{ gap: "15px" }}>
                      <div className="d-flex justify-content-between">
                        <div className="timeline-text mb-2">
                          <span
                            className="badge mb-2 rounded-pill fz-10"
                            style={{ backgroundColor: "#08c5d1" }}
                          >
                            Détails Cotisations effectuées
                          </span>
                        </div>
                      </div>
                 
                      <div style={{ marginTop: "-35px" }}>
                        {paidFactures.map((items, k) => (
                          <div
                            className="card timeline-card"
                            key={k}
                            style={{ marginTop: "40px" }}
                          >
                            <>
                              <div className="card-body">
                              
                                <div className="d-flex justify-content-between">
                                  <div className="timeline-text mb-2">
                                    <span className="badge mb-2 rounded-pill fz-10">
                                      Facture N°: {items?.id}
                                    </span>
                                  </div>                    
                                    <img src="img/bg-img/download.png" 
                                    style={{ height: "30px",cursor:"pointer" }}
                                    alt="" 
                                    onClick={() => 
                                      {
                                        navigateToInvoice(items?.id);
                                      sendDataFacture(items,user,type);}
                                    }
                                    />                
                                 </div>
                                <div className="row">
                                  <div className="col-md-6 d-flex align-items-center">
                                    <div>
                                      <p className="fz-10">
                                        <b>{t("Montant")} :</b> {items?.montant}
                                      </p>
                                      <p className="fz-10">
                                        <b style={{ color: "green" }}>
                                          <b>{t("Statut")} :</b>
                                          {items?.statut === "paid"
                                            ? " Payé"
                                            : "non Payé"}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-6 d-flex align-items-center">
                                    <div>
                                      <p className="fz-10">
                                        <b>{t("Date d'échéance")} :</b>{" "}
                                        {items.Date_paiement}
                                        {/* {moment(items.Date_paiement).format("DD/MM/YYYY")} */}
                                      </p>
                                      <p className="fz-10">
                                        <b>{t("Payée le")} :</b>{" "}
                                        {items.Date_prevu}
                                      </p>
                                    </div>
                                    
                                  </div>
                                
                                </div>
                              </div>
                            </>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <EmptyCotisation t={t} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>}
    </div>
  );
}
