import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 8,
    },
    header: {
        width: "50%",
        textAlign: "center",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily:'Arial',
        fontStyle:"normal"
    },
    header1: {
        width: "50%",
        textAlign: "center",
        borderRightColor: "#fff",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily:'Arial',
        fontStyle:"normal"
    },
    description1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily:'Arial',
        fontStyle:"normal"
    },
    description: {
        width: "50%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 8,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:'Arial',
        fontStyle:"normal"
    },
});
export default function TableauGarantie({ type , data,user_details,info}) {

    const rows = (
        <>
            <View style={styles.row}>
                <Text style={styles.header}>LISTE DES GARANTIES INCLUSES</Text>
                <Text style={styles.header1}> CAPITAUX GARANTIES</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Frais pharmaceutiques : </Text>
                <Text style={styles.description}>{info?.formule === "classique"? " 100 000 FCFA": info?.formule === "diamond"? " 150 000 FCFA": " 175 000 FCFA"}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Hospitalisation  : </Text>
                <Text style={styles.description}>{info?.formule === "classique" ? " 150 000 FCFA": info?.formule === "diamond"? " 200 000 FCFA": " 250 000 FCFA"}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Frais funéraires: </Text>
                <Text style={styles.description}>{info?.formule === "classique" ? " 100 000 FCFA" : info?.formule === "diamond"? " 125 000 FCFA" : " 150 000 FCFA"}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Capital à reverser en cas de décès  </Text>
                <Text style={styles.description}>{info?.formule === "classique" ? " 1 000 000 FCFA" : info?.formule === "diamond"? " 1 500 000 FCFA" : " 2 000 000 FCFA"}</Text>
            </View>
           
        </>
    );
    return <Fragment>{rows}</Fragment>;
  
}
