import React from "react";

export default function Policy() {
  return (
    <div>
      {/* Page Content Wrapper */}
 
          <div className="card">
            <div className="card-body ">
              <h4 className="text-center">Politique de confidentialité </h4>
              <p className="mb-4 mt-4">
                La confidentialité et la sécurité des données de nos
                souscripteurs (ou assurés) sont une de nos priorités majeures. A
                cet égard, les informations que vous fournissez seront traitées
                exclusivement pour la création de votre compte client et dans le
                cadre de la prise en charge de vos besoins d’assurances : devis,
                souscription, diligences liées à vos sinistres, etc. Les données
                à caractère personnel pourront être communiquées, dans la limite
                de ce qui est nécessaire, à nos partenaires assureurs ou
                logistiques (en cas de livraison d’attestation d’assurance
                auto), aux autorités de tutelle et à toute autre autorité
                compétente selon les lois et règlements en vigueur au Sénégal.
                Vos accès sont protégés par un identifiant et un code
                confidentiel dont vous devez vous assurez de son caractère
                secret. Nos applications sont également sécurisées. En
                application des dispositions de la loi n°2008-12 du 25 janvier
                2008 portant sur la protection des données à caractère
                personnel, le traitement portant sur les données collectées a
                été déclaré devant la CDP (« Commission des Données Personnelles
                »). Conformément à la loi précitée, vous pouvez exercer vos
                droits d’accès, d’opposition, de rectification et de suppression
                auprès de DIOKOTECH SAS. L’Application pourrait avoir recours
                aux techniques de "cookies" lui permettant de traiter des
                statistiques et des informations sur le trafic, de faciliter la
                navigation et d’améliorer le service pour le confort de
                l’utilisateur, lequel peut s’opposer à l’enregistrement de ces
                "cookies" en configurant son logiciel de navigation.
              </p>
            </div>
          </div>
        </div>

  );
}
