import React, { Fragment } from "react";
import Paiement from "../contrats/components/paiement/Paiement";
import { useForm } from "react-hook-form";

export default function Paiment_renouvellement() {
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA

  const data = {};
  const groupedAdresses = {};
  const LISTE_ASSUREE = {};
  const t = "";
  const type = "";
  const prime_ttc = "";
  const id = "";
  const results={};
  const contract = {}
const accidents={};
const autoDetailsArray={};
const Liste_Compagny={};
  return (
    <Fragment>
      <Paiement
        id={id}
        handleSubmit={handleSubmit}
        register={register}
        data={data}
        watch={watch}
        groupedAdresses={groupedAdresses}
        LISTE_ASSUREE={LISTE_ASSUREE}
        type={type}
        t={t}
        prime_ttc={prime_ttc}
        results={results}
        accidents={accidents}
        autoDetailsArray={autoDetailsArray}
        contract = {contract}
      />
    </Fragment>
  );
}
