import Sousscripteur from "../components/souscripteur/Sousscripteur";
import Assuree from "../components/souscription/Assuree";

export default function Step1({  setShow1, show1 , setShow , register,  t,  data,ListeAssuree,  ListeAdresses,  assuree,  setassuree,  setautres,  autres,  beneficiaire,  setbeneficiaire,  LISTE_ASSUREE,  nbrAssuree,  createDataNbAssuree,  handleChangeAssuree,  type,
}) {
  return (
    <div>
      <div className="text-center">
        <img
          className="mb-4 text-center"
          src="../img/bg-img/Insurance-cuate.png"
          width={"150px"}
          alt="Assurance vie"
        />
      </div>
      <Sousscripteur
        register={register}
        t={t}
        data={data}
        ListeAdresses={ListeAdresses}
      />
 
      {!assuree && (
        <Assuree
        ListeAssuree={ListeAssuree}
          register={register}
          setautres={setautres}
          autres={autres}
          beneficiaire={beneficiaire}
          setbeneficiaire={setbeneficiaire}
          LISTE_ASSUREE={LISTE_ASSUREE}
          t={t}
          nbrAssuree={nbrAssuree}
          createDataNbAssuree={createDataNbAssuree}
          handleChangeAssuree={handleChangeAssuree}
          type={type}
        />
      )}
   
      <button className="btn btn-purple float-end fz-12 mb-3" type="submit">
        {t("Next Step")}
        <i className="bi bi-arrow-right fz-12 px-1" />
      </button>
      <button
        className="btn btn-success float-start fz-12 text-white"
        onClick={() => {
          setShow1(!show1);
          setShow(true);
        }}
      >
        <i className="bi bi-arrow-left me-1 fz-12" />
        {t("Previous Step")}
      </button>
    </div>
  );
}
