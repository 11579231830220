/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useUser } from "../../../Auth/useUser";
import Loader from "../../../components/loading/Loader";
import Invoice from "../invoice/Invoice";
import { traitement } from "./TraitementDetailsContrat";
import ContratPDF from "../pdfContrat/ContratPDF";
import ChoisirContrat from "../ChoisirContrat";
import guarantee from "./images/001-guarantee.png";
import agreement from "./images/002-agreement.png";
import income from "./images/003-income.png";
import financial from "./images/004-financial-report.png";
import parse from "html-react-parser";
import ConditionP from "../conditionParticuliere/ConditionP";
import designImage from "../gestionContrats/images/Notes-cuate.png";
import ResumContrat from "../resum/ResumContrat";
import ConditionGeneral from "../conditionGenerale/ConditionGeneral";
import CPaccident from "../conditionParticuliere/CPaccident";
import ConditionPAuto from "../conditionParticuliere/ConditionPAuto";
import DownloadImage from "./DownloadImage";
import Renouvellement from "../../renouvellement/Renouvellement";
import SuivieCotisation from "../../suiviCotisation/SuivieCotisation";
import Step3 from "../steps/Step3";
import { useHistory } from 'react-router-dom'
import ResumCard from "../components/resum/ResumCard";
import Souscripteur from "../resum/Souscripteur";
import { getCategoriesByTypeOfFacturation } from "../../../services/categories/CategoriesApi";
import { getContratByID } from "../../../services/contrats/ContratApi";
import { getFactureByNumero } from "../../../services/factures/FactureApi";

export default function DetailsContrat() {
  
  const history = useHistory()
  const { id, type, policy_code } = useParams("");
  //---------- DATA = CONTRAT INFORMATIONS ---
  const [data, setData] = useState({});
  // PERSONAL INFO
  const [personnel, setpersonnel] = useState({});
  //--------- info TYPE DE CONTRAT ----------
  const [info, setInfo] = useState({});
  //--------- SIGNATURES --------------
  const [signatures, setsignatures] = useState({});
  //-------------- LOADING ------------
  const [Loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //------------- CONDITIONS POLICY --------
  //const [condition1, setcondition1] = useState(false);
  // DATA DES CONDITIONS -------------------
  const [data_cg, setdata_cg] = useState({}); // condition generale
  // -------------- LOADER
  const [loading_g, setloading_g] = useState(true);
  const [paiement, setpaiement] = useState([]);
  const [liste_assures, set_liste_assures]=useState("");
  //show interfaces
  const [showInterface, setshowInterface] = useState(true);
  const [showResum, setshowResum] = useState(false); // Show resumé
  const [showFacture, setshowFacture] = useState(false);
  const [showCP, setshowCP] = useState(false);
  const [showContrat, setshowContrat] = useState(false);
  const [showCG, setshowCG] = useState(false);
  // const [showRenouvellementContrat, setshowRenouvellementContrat]=useState(false);
  // const [showSuivieCotisation, setshowSuivieCotisation] = useState(false);
 // ALL ASSUREE OF CARNET ASSUREE OF USER
 const [ListeAssuree, setListeAssuree] = useState([]); // LISTE DES ASSURES
 const [groupedAssuree, setgroupedAssuree] = useState([])
 //------------- ADRESSES of User --------
 const [ListeAdresses, setListeAdresses] = useState([]);
 const [groupedAdresses, setgroupedAdresses] = useState([]);
 //------------------ SHOWS FORMS -----------
 const [show0, setShow0] = useState(true);
 const [show, setShow] = useState(true);
 const [show1, setShow1] = useState(false);
 const [show2, setShow2] = useState(false);
 const [show3, setShow3] = useState(false);
 const [show4, setShow4] = useState(false);
 const [show5, setShow5] = useState(false);
const [showForm, setShowForm] = useState(false);
const [LISTE_ASSUREE, setLISTE_ASSUREE] = useState([]); // Liste assurée
// ---------------FORMULE voyages ---------
const [cout_police, setcout_police] = useState([])
const [prime_nette, setprime_nette] = useState([])
const [paramTech, setparamTech] = useState([])
// LISTE OF UNIQ POLICY
const [policyListe, setpolicyListe] = useState([]);
const [groupedListe, setgroupedListe] = useState([]);
const [ligne, setLigne]=useState("")
 const [region, setRegion]=useState("");                           
const [code_postal, setCode_postal]=useState("");                      
const [pays, setPays]=useState("");                        
const [cite,setCite]=useState("");
const [LISTE_FACTURE, setLISTE_FACTURE] = useState([])
const [facture, setfacture] = useState([])
const [showRenouvellement, setShowRenouvellement]=useState("");
  //--------INFORMATIONS OF ACTUAL USER---------
 
  console.log("$$$$$$$$",data,showCG,showCP,showContrat,showResum,LISTE_FACTURE)
  const user = useUser();
  const user_details = user.result;
  useEffect(() => {
    getCategoriesByTypeOfFacturation("renouvellement").then((result) => {
        if(result?.data?.code===200){
            console.log(result?.data);
            setLISTE_FACTURE(result?.data?.data)
            console.log("facture de details contrat :",facture)
        }
    }).catch((err) => {
        console.log(err);
    });
  }, [])

  useEffect(()=>{
    getContratByID(id)
    .then((result) => {
      if (result?.data.code === 200) {
        setData(result?.data?.data[0]);
        console.log('our contract: ',result?.data?.data[0]);
        var policy_code = result?.data?.data[0].policy_code;
        console.log('We went to look for the facture by this policy code:', policy_code);
        getFactureByNumero(policy_code).then((result)=> {
          if(result?.data?.code === 200){
            const invoice = result?.data?.data
          console.log("result of number invoice ",invoice)
          setfacture(invoice);
          }
          
        }).catch((err)=> {
          console.log('Because of:', err);
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
  },[])

  useEffect(() => {
    traitement(
      user_details,
      setsignatures,
      setpersonnel,
      type,
      id,
      setData,
      setloading_g,
      setdata_cg,
      setInfo,
      setLoading,
      policy_code,
      setpaiement,
      liste_assures
    );
  }, []);

  const previous = () => {
    return (
      <div className="container">
        
        <button
          className="btn btn-success mb-2 fz-10 col-12"
          onClick={() => {
            setshowResum(false);
            setshowInterface(true);
            setshowCP(false);
            setshowCG(false);
            setshowContrat(false);
            setshowFacture(false);
            // setshowSuivieCotisation(false);
            // setShowRenouvellement(false);
          }}
        >
          <i className="bi bi-arrow-left fz-10 me-1" /> {t("Previous Step")}
        </button>
      </div>
    );
  };
  const redirectToDetailsContrat = (id) => {
    history.push({
        // pathname: "/details_produits/"+id
        pathname:"/facturation_produits/id" +
        liste_assures?.id +
        "&title=" +
        liste_assures?.title +
        "&type=" +
        liste_assures?.type
    })
  }
  return (
    <div className="container">
      <div className="card">
        <div className="card-body ">
          {Loading ? (
            <Loader />
          ) : (
            <div className="row">
              {showInterface && (
                <ChoisirContrat
                  data={data}
                  guarantee={guarantee}
                  agreement={agreement}
                  income={income}
                  type={type}
                  financial={financial}
                  setshowInterface={setshowInterface}
                  setshowResum={setshowResum}
                  setshowFacture={setshowFacture}
                  setshowCP={setshowCP}
                  setshowContrat={setshowContrat}
                  setshowCG={setshowCG}
                  paiement={paiement}
                  t={t}
                />
              )}
              {showResum && (
                <>
                  <ResumContrat
                    t={t}
                    type={type}
                    data={data}
                    user_details={user_details}
                    personnel={personnel}
                    info={info}
                    designImage={designImage}
                    moment={moment}
                    paiement={paiement}
                  />
                  {previous()}
                </>
              )}
              {showFacture && (
                <>
                  <div>
                    {!isMobile && (
                      <PDFViewer width="1000" height="600" className="app">
                        <Invoice
                          signatures={signatures}
                          t={t}
                          type={type}
                          data={data}
                          user_details={user_details}
                          personnel={personnel}
                          info={info}
                          paiement={paiement}
                          facture={facture}
                        />
                      </PDFViewer>
                    )}
                    {isMobile && (
                      <>
                        <DownloadImage t={t} />
                      </>
                    )}
                    {isMobile && (
                      <>
                        <PDFDownloadLink
                          document={
                            <Invoice
                              signatures={signatures}
                              t={t}
                              type={type}
                              data={data}
                              user_details={user_details}
                              personnel={personnel}
                              info={info}
                              paiement={paiement}
                              facture={facture}
                            />
                          }
                          
                          fileName="Facture.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <button className="btn btn-purple mb-2 fz-10 col-12 ">
                                {t("Télecharger la Facture")}
                              </button>
                            )
                          }
                        </PDFDownloadLink>
                      </>
                    )}
                  </div>
                  {previous()}
                </>
              )}
              {showContrat && (
                <>
                  <div className="row">
                    {!isMobile && type === "  " && (
                      <PDFViewer width="1000" height="600" className="app">
                        <ContratPDF
                          signatures={signatures}
                          data={data}
                          user_details={user_details}
                          personnel={personnel}
                        />
                      </PDFViewer>
                    )}
                    {isMobile && (
                      <>
                        <DownloadImage t={t} />
                      </>
                    )}
                    {isMobile && (
                      <>
                        {type === "voyage" && (
                          <div className="row">
                            <PDFDownloadLink
                              document={
                                <ContratPDF
                                  signatures={signatures}
                                  data={data}
                                  user_details={user_details}
                                  personnel={personnel}
                                />
                              }
                              fileName="Contrat_assurance.pdf"
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  "Loading document..."
                                ) : (
                                  <button className="btn btn-purple mb-2 fz-10 col-12 ">
                                  {t("Télécharge le contrat")}
                                  </button>
                                )
                              }
                            </PDFDownloadLink>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {previous()}
                </>
              )}
              {showCP && (
                <>
                  <div className="row">
                    {!isMobile && (
                      <PDFViewer
                        width="1000"
                        height="600"
                        className="app"
                        fileName="Condition_Particuliére"
                      >
                        {type === "accident" && (
                          <CPaccident
                            signatures={signatures}
                            t={t}
                            type={type}
                            data={data}
                            user_details={user_details}
                            personnel={personnel}
                            info={info}
                            paiement={paiement}
                          />
                          
                        )}
                        {(type === "education" ||
                          type === "deces" ||
                          type === "retraite") && (
                          <ConditionP
                            signatures={signatures}
                            t={t}
                            type={type}
                            data={data}
                            user_details={user_details}
                            personnel={personnel}
                            info={info}
                            paiement={paiement}
                          />
                        )}
                        {type === "vehicule" && (
                          <ConditionPAuto
                            signatures={signatures}
                            t={t}
                            type={type}
                            data={data}
                            user_details={user_details}
                            personnel={personnel}
                            info={info}
                            paiement={paiement}
                          />
                        )}
                      </PDFViewer>
                    )}
                    {isMobile && (
                      <>
                        <DownloadImage t={t} />
                      </>
                    )}
                    <PDFDownloadLink
                      document={
                        <>
                          {type === "accident" && (
                            <CPaccident
                              signatures={signatures}
                              t={t}
                              type={type}
                              data={data}
                              user_details={user_details}
                              personnel={personnel}
                              info={info}
                              paiement={paiement}
                            />
                          )}
                          {(type === "education" ||
                            type === "deces" ||
                            type === "retraite") && (
                            <ConditionP
                              signatures={signatures}
                              t={t}
                              type={type}
                              data={data}
                              user_details={user_details}
                              personnel={personnel}
                              info={info}
                              paiement={paiement}
                            />
                          )}
                          {type === "vehicule" && (
                            <ConditionPAuto
                              signatures={signatures}
                              t={t}
                              type={type}
                              data={data}
                              user_details={user_details}
                              personnel={personnel}
                              info={info}
                              paiement={paiement}
                            />
                          )}
                        </>
                      }
                      fileName="Contrat_assurance.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <button className="btn btn-purple mb-2 fz-10 col-12 ">
                          {t("Télécharge le contrat")}
                          </button>
                        )
                      }
                    </PDFDownloadLink>
                    {previous()}
                  </div>
                </>
              )}
              {showCG && (
                <>
                  {data_cg !== undefined &&
                    _.isEqual(data_cg, {}) === false && (
                      <div>
                        
                        {!isMobile && (
                          <PDFViewer
                            width="700"
                            height="600"
                            className="app"
                            fileName="Condition_General"
                          >
                            <ConditionGeneral
                              description={parse(data_cg?.description)}
                              paiement={paiement}
                              data={data}
                            />
                          </PDFViewer>
                        )}
                        {isMobile && (
                          <>
                            <DownloadImage t={t} />
                          </>
                        )}
                        <PDFDownloadLink
                          document={
                            <ConditionGeneral
                              description={parse(data_cg?.description)}
                               type={type}
                              paiement={paiement}
                              data={data}
                            />
                          }
                          fileName="Condition_General.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <button className="btn btn-purple mb-2 fz-10 col-12 ">
                                {t("Télécharge le contrat")}
                              </button>
                            )
                          }
                        </PDFDownloadLink>
                      </div>
                    )}
                  {previous()}
                  
             
                  
                </>
              )}
            </div>
            
            
          )}
               
        </div>
        
      </div>
    </div>
  );
}
