// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"
import authHeader from "../authentification/AuthHeader";

//GET URL FROM .ENV ELEMENT
import { getUrl } from "../authentification/DotenvConfig"

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
const url = getUrl()

/**
 * ADD Ticket
 * @param {*} data 
 * @returns 
 */
export async function addTicket(data) {
    try {
        const response = await axios.post(url + "tickets", data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * ADD MEDIA TICKET
 * @param {*} data 
 * @returns 
 */
export async function addMediaTicket(data) {
    try {
        const formData = new FormData();
        formData.append("ticket_id",data.ticket_id)
        for (const key of Object.keys(data.file)) {
            formData.append('file',data.file[key])
        }
        const response = await axios.post(url + "ticket-medias", formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * Get LISTE TICKETS BY USER ID
 * @returns 
 */
export async function getListeTicketsbyID(id) {
    try {
        const response = await axios.get(url + "tickets/users/" + id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET DETAILS TICKET BY ID
 * @param {*} id 
 * @returns 
 */
export async function getDetailsTicketByID(id) {
    try {
        const response = await axios.get(url + "tickets/" + id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET DETAILS TICKET BY MEDIA BY ID ticket
 * @param {*} id 
 * @returns 
 */
export async function getDetailsTicketMediaByID(id) {
    try {
        const response = await axios.get(url + "ticket-medias/tickets/" + id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * DELETE TICKET MEDIA BY ID
 * @param {*} id 
 * @returns 
 */
export async function deleteTicketMediaByID(id) {
    try {
        const response = await axios.delete(url + "ticket-medias/" + id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

