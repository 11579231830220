import axios from "axios";
import { getUrl } from "./authentification/DotenvConfig";

const api_url = getUrl()
const config = {
  headers: {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOnsiY3JlYXRlZF9hdCI6IjIwMjItMDgtMjUgMTU6Mzg6NTkiLCJ1cGRhdGVkX2F0IjoiMjAyMi0wOC0zMCAyMTozNzozMyIsImlkIjoxLCJub20iOiJhbWluZSIsInByZW5vbSI6ImJyYWhtaSIsImRhdGVfbmFpc3NhbmNlIjoiMjAyMi0wOC0zMCIsImVtYWlsIjoieWJyYWhtaTY2QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJiJDEwJEFCNXZ4a2ROSFBwV3YudjQ5Q0ZJR3VGLlJvYTdabEFrTUQ2cVlLN1FiYlFsaEVBWmR0TTBhIiwibW9iaWxlIjoiKzIxNjI0OTI4NDEyIiwiaW1hZ2UiOiJ1cGxvYWRzLzIwMjItMDgtMzBUMjAtMzctMDkuMTAxWi1pNmdlbWguanBnIiwicm9sZSI6bnVsbCwiZ2VuZGVyIjpudWxsLCJpc19hY3RpdmUiOiIxIiwiaXNfdmVyaWZpZWQiOiIxIiwiY29kZV92ZXJpZmljYXRpb25fZW1haWwiOiJiNjk3ZmNkMS04ZDZmLTQ3NTEtOGNiZS02NWRiODQyODIxZTIiLCJjb2RlX3ZlcmlmaWNhdGlvbl9tb2JpbGUiOiIiLCJjb2RlX3ZlcmlmaWNhdGlvbl9wYXNzd29yZCI6IjdjODYzMTJjLWI1NDYtNGE5NC1iYjkyLTY4YWM2ODQ2YmE1ZSIsInJlZnJlc2hfdG9rZW4iOiJiOWI5YmY0MS1lZWNiLTRlYzAtOWQzNS1hMzc4MDE5NjNiOGUifSwiaWF0IjoxNjYxOTM1MjA3LCJleHAiOjE2NjI3OTkyMDd9.RDBEGN3LEIdE71GGE2U8rKv9Gpvze6it3AOKh1kFYSw`,
  },
};
/**
 * It gets all the messages from the database.
 * @returns The response is an array of objects. Each object contains the message and the time it was
 * created.
 */
export async function getAllMessages(id) {
  try {
    const response = await axios.get(api_url + "messages/users/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getMessageyID(id) {
  try {
    const response = await axios.get(api_url + "messages/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function addMessage(sender, receiver, text) {
  const credentiel = {
    sender_id: sender,
    receiver_id: receiver,
    text: text,
  };

  try {
    const response = await axios.post(api_url + "messages", credentiel, config);
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  }
}

export async function deleteMessageById(id) {
  try {
    const response = await axios.delete(api_url + "messages/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
