import { View,Text ,StyleSheet} from "@react-pdf/renderer";
import moment from "moment";
import { Fragment } from "react";
const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
    fontSize: 9,
        marginBottom:1

    },
    description1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 9,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"

    },
    descriptionred1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 9,
        color: "red",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"

    },
    description: {
        width: "50%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 10,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
    description3: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"

    },
});
export default function RefererenceQuitance({ type , data,user_details,personnel}) {
var date_effet = moment(data?.date_effet).subtract(1, "days").format("YYYY-MM-DD");
var expiration = moment(date_effet).add(data?.auto?.duree, "months").format("DD/MM/YYYY")

    const rows = (
        <View style={styles.container}>
            <View style={styles.row} wrap>
                <Text style={styles.description1}>N° Police  : {data?.policy_code} </Text>
                <Text style={styles.description}>N° Quittance : : {data?.num_facture}</Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description3}> Assuré : {data?.assuree_id !== null? data?.assure?.prenom : user_details?.prenom}  {data?.assuree_id !== null
                ? data?.assure?.nom
                : user_details?.nom}</Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description3}>Adresse Assuré:  : {data?.adresse} </Text>
            </View>
            <View style={styles.row} wrap>
                <Text style={styles.description1}>Avenant : {"-"}</Text>
                <Text style={styles.description}>Date Emission : {moment(data?.created_at).format("DD/MM/YYYY")}</Text>
            </View>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Date Effet : {moment(data?.date_effet).format("DD/MM/YYYY")} </Text>
            <Text style={styles.descriptionred1}>Date Expiration :  {expiration}</Text>
            </View>

        </View>
    );
    return <Fragment>{rows}</Fragment>;
}
