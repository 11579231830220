export default function NoCompagny({ title, t }) {
  return (
    <div className="card">
      <div className="card-body text-center">
        <img
          className="mb-4"
          src="img/bg-img/members-pana.png"
          style={{ width: "200px" }}
          alt="NO DATA"
        />
        <h6 className="text-purple"> {t("Revenez dans quelques jours")} </h6>
        <p className="mb-4">
          {t(
            "Nous sommes désolés de vous informer que nous n'avons pas encore d'accords avec les compagnies d'assurance pour"
          )}{" "}
          {title}{" "}
        </p>
        <a
          className="btn btn-success text-white mt-3 col-12"
          href="/nos_produits"
        >
          {t("Voir les autres produits d'assurance")}
        </a>
      </div>
    </div>
  );
}
