import { Page, Document, StyleSheet, View, Text, Font,} from "@react-pdf/renderer";
import InvoiceTableFooter from "../invoice/InvoiceTableFooter";
import EnteteContrat from "./EnteteContrat";
import IdentificationVehicule from "./IdentificationVehicule";
import Quitance from "./Quitance";
import TableReferenceConatainer from "./TableReferenceConatainer";
// FONT TIMES NEW ROMAN
import TimesNewRoman_normal from "../styles/TimesNewRoman/times.ttf";
import TimesNewRoman_bold from "../styles/TimesNewRoman/times-new-roman-grassetto.ttf";
import TableauCategories from "./TableauCategories";
import Signatures from "./Signatures";

Font.register({
  family: "Arial",
  fonts: [
    {
      src: TimesNewRoman_normal,
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: TimesNewRoman_bold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    border: "5px solid #fff",
  },
  reportTitle: {
    letterSpacing: 3,
    fontSize: 10,
    textAlign: "center",
    marginBottom: 5,
    fontFamily: "Arial",
    fontStyle: "bold",
    color: "#000",
    textTransform: "uppercase",
  },
  title: {
    fontSize: 9,
    letterSpacing: 1,
    marginTop: 10,
    fontFamily: "Arial",
    fontStyle: "bold",
  },
  text3: {
    marginTop: 5,
    fontSize: 9,
    letterSpacing: 1,
    lineHeight: 1.5,
    textAlign: "justify",
    color: "#4e545d",
    fontFamily: "Arial",
    fontStyle: "normal",
  },
  text4: {
    marginTop: 5,
    fontSize: 9,
    letterSpacing: 1,
    lineHeight: 1.5,
    textAlign: "justify",
    color: "#4e545d",
  },
  bold: {
    textAlign: "justify",
    color: "#000",
  },
  signature: {
    width: 150,
    height: 70,
    marginTop: -20,
    textAlign: "right",
  },

  row: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 0,
  },
  h2: {
    display: "block",
    fontSize: 30,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
  },
  right: {
    padding: 5,
    width: "50%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,
    textAlign: "center",
  },
  left: {
    padding: 5,
    width: "50%", //<- working alternative
    flexShrink: 1,
    marginBottom: 0,

    textAlign: "center",
  },
  descriptionright: {
    width: "100%",
    textAlign: "right",
    paddingLeft: 9,
    letterSpacing: 1,
    fontSize: 8,
    color: "#4e545d",
    fontFamily: "Arial",
    fontStyle: "normal",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    marginBottom: 0,
    borderColor:"#fff"

  },
});

export default function ConditionPAuto({
  signatures,
  t,
  type,
  data,
  user_details,
  personnel,
  info,
}) {
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <EnteteContrat data={data} />
        <View style={{ marginTop: "20" }}>
          <Text style={styles.reportTitle}>Conditions Particuliéres</Text>
        </View>
        <View style={{ marginTop: "2", marginBottom: "15" }}>
          <Text style={styles.reportTitle}>POLICE D’ASSURANCE AUTOMOBILE</Text>
        </View>
        <Text style={styles.title}>ARTICLE 1 – Référence Souscripteur</Text>
        <TableReferenceConatainer
          data={data}
          type={type}
          user_details={user_details}
          personnel={personnel}
        />
        <Text style={styles.title}>ARTICLE 2 – Réference de la Quittance</Text>
        <Quitance
          data={data}
          type={type}
          user_details={user_details}
          personnel={personnel}
        />
        <Text style={styles.title}>ARTICLE 3 – Caractéristiques du véhicule </Text>
        <IdentificationVehicule
          data={data}
          type={type}
          user_details={user_details}
          personnel={personnel}
        />
        <Text style={styles.title}>ARTICLE 4 – Décomposition de la prime </Text>
        <Text style={styles.text3}>
          La prime à régler par l’Assuré se décompose comme suit :
        </Text>
        <View style={styles.tableContainer}>
          <TableauCategories  info={info}
          type={type}
          user_details={user_details}
          personnel={personnel}/>
          </View>
          <Signatures data={data} signatures={signatures} />
        <InvoiceTableFooter />
      </Page>
    </Document>
  );
}
