/* eslint-disable no-unused-vars */
import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "../../../../components/loading/Loader";
//import tableFormule from "../../../../formules/accident/tarifs_assurance_accident.json";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { toast } from "react-toastify";
import { getFormuleAccident } from "../../../../services/formules/FormulesAPI";

export default function DevisAccidents({ categorie_id, t }) {
  const { id, title, type } = useParams("");
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, watch } = useForm(); //FORM DATA
  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  const [show, setshow] = useState(false);
  // Results
  const [results, setResults] = useState({
    totalHt: 0, // sum total hors taxes
    taxes: 0, // taxes
    cout_de_police: 0, //Cout de police
    accessoires: 0, // taxes
    primettc: 0, //PRIME EN TTC
  });
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);

  function onFormSubmit(dataRes) {
    setshow(true);
    // setloaderResult(true);
    var { duree, formule, age } = dataRes;
    getFormuleAccident().then((result) => {
      if (result?.data?.code === 200) {
        var groupedFormule = _.groupBy(result?.data?.data, "FORMULE");

        var table = groupedFormule[formule]?.filter(
          (el) => el.DUREE === parseInt(duree)
        );
        var PRIME_NETTE = table[0]?.PRIME_NETTE;
        var accessoires = table[0]?.ACCESSOIRES;
        setResults({
          totalHt: parseFloat(PRIME_NETTE), // sum total hors taxes
          taxes: (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, // taxes
          accessoires: accessoires,
          primettc:
            parseFloat(PRIME_NETTE) +
            parseFloat(accessoires) +
            (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, //PRIME EN TTC
        });
      }
    });
  }
  return (
    <div>
      <h5 className="mt-4 mb-4 text-center">
        {t("Devis Assurance Accident")}
      </h5>
      <div className="text-center">
        <img
          className="mb-4 text-center"
          src="../img/bg-img/21.png"
          width={"100px"}
          alt="Assurance vie"
        />
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-group mb-3">
          <label className="form-label text-purple fz-12">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-info-circle me-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            {t("Produit disponible que pour les assurés âgés de moins de 60 ans")}
          </label>
      
        </div>
        <div className="form-group mb-3">
          <label className="form-label fz-12">{t("Durée")}</label>
          <select className="form-select fz-12" {...register("duree")} required>
            <option value={""}>{t("Choisir")} </option>
            <option value={1}>{t("1 Mois")}</option>
            <option value={3}>{t("3 Mois")}</option>
            <option value={6}>{t("6 Mois")}</option>
            <option value={12}>{t("12 Mois")}</option>
          </select>
        </div>
        <label className="form-label fz-12">{t("Formule")}</label>
        <div>
          <div
            className={
              watch("formule") === "CLASSIQUE"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"CLASSIQUE"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                {t("FORMULE CLASSIQUE")} :
                <li className="fz-10 mt-2 mb-1">
                  {t("Frais Pharmaceutiques")} : <b> 100 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Hospitalisation")} : <b> 150 000 FCFA </b>
                </li>
                <li className="fz-10 mb-1">
                  {t("Frais funéraires")} : <b> 100 000 FCFA </b>
                </li>
                <li className="fz-10 mb-1">
                  {t("Capital décès et invalidité")} : <b> 1 000 000FCFA </b>
                </li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "DIAMOND"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"GOLD"}
                
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Team">
              {t("FORMULE GOLD")} :
                <li className="fz-10 mt-2 mb-1">
                  {t("Frais Pharmaceutiques")} : <b> 150 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Hospitalisation")} : <b> 200 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Frais funéraires")} : <b> 125 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Capital décès et invalidité")} : <b> 1 500 000 FCFA </b>
                </li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "GOLD"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"DIAMOND"}
               {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Company">
                {t("FORMULE DIAMOND")} :
                <li className="fz-10 mt-2 mb-1">
                  {t("Frais Pharmaceutiques")} : <b> 175 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Hospitalisation")} : <b> 250 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Frais funéraires")} : <b> 150 000 FCFA </b>
                </li>
                <li className="fz-10  mb-1">
                  {t("Capital décès et invalidité")} : <b> 2 000 000 FCFA </b>
                </li>
              </label>
            </div>
          </div>
        </div>
        <div className="  mt-3">
          <button type="submit" className="btn btn-purple col-12 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-calculator me-2"
              viewBox="0 0 16 16"
            >
              <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
              <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
            </svg>
            {t("Calculer")}
          </button>
        </div>
        {show && (
          <div className="card mb-4 ">
            <div className="card-body">
              <h5 className="text-center"> {t("Total du calcul")} </h5>
              <table className="table  mb-0 table-striped-primary mt-3">
                {loaderResult ? (
                  <Loader />
                ) : (
                  <tbody>
                    <tr>
                      <td>{t("Total Prime Nette")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.totalHt))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Accessoires")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.accessoires))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Taxe")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.taxes))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Prime TTC")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.primettc))}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              <div className="row">
                <div className="col-12">
                  <div className=" float-end">
                    <a
                      href={
                        "facturation_produits/id=" +
                        id +
                        "&title=" +
                        title +
                        "&type=" +
                        type
                      }
                      className="btn btn-success col-12 fz-12 mt-3 mb-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-receipt me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                      </svg>
                      {t("Souscrire")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
