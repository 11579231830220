import React from "react";
import { getTypePaiement } from "../../../utils/SearchUtils";
import { numberWithSpaces } from "../../../utils/SessionUtiles";
import accounting from "accounting"
export default function PrimeContrat({ data, t, info, type , paiement}) {
  console.log("++++++++++++")
  console.log("++++++++++++")
  console.log("++++++++++++")
  console.log("++++++++++++",data,paiement)
  const africanCurrencyOptions = {
    symbol: "", // Set the currency symbol
    decimal: ".", // Set the decimal separator
    thousand: " ", // Set the thousand separator
    precision: 0, // Set the number of decimal places
    format: "%v %s", // Set the format pattern
  };
// Function to format a given value with the African currency options
const formatCurrency = (value) => {
return accounting.formatMoney(value, africanCurrencyOptions);
};
console.log("info")
  return (
    <div>
      {data?.category?.type === "education" && (
        <div className="mt-3 fz-12">
          <p className="fz-12">
            <b> {t("Prime")} : </b>
            {formatCurrency(parseFloat(data?.education?.prime_commercial))}
            {console.log("type of",typeof(info?.prime_commercial))}
            <b>FCFA </b>
          </p>
          <p className="fz-12">
            <b> {t("Périodicité de paiement")} : </b>
            {data?.education?.periodicite === 1
              ? t("Annuelle")
              : data?.education?.periodicite === 2
              ? t("Semestrielle")
              : data?.education?.periodicite === 4
              ? t("Trimistrielle")
              : t("Mensuelle")}
          </p>
          <p className="fz-12">
            <b> {t("Mode de paiement")} : </b> {getTypePaiement(paiement[0]?.type_paiement)}
          </p>
          <p className="fz-12">
            <b> {t("Capital garanti")} : </b>
            {formatCurrency(parseFloat(data?.education?.capital_acquis))}
            <b> FCFA </b>
          </p>
          <p className="fz-12">
            <b> {t("Durée de paiement de la rente")} : </b>
            {data?.education?.duree_rente} (ans)
          </p>
          <p className="fz-12">
            <b>{t("Rente")} : </b>
            {formatCurrency(parseFloat(data?.education?.total_rente))}
            <b> FCFA </b>
          </p>
        </div>
      )}
      {data?.category?.type === "deces" && (
        <div className="mt-3 fz-12">
          <p className="fz-12">
            <b> {t("Durée de cotisation")} : </b>
            {info?.duree_cotisation}
          </p>
          <p className="fz-12">
            <b> {t("Frais d'acquisation")} : </b>
            {numberWithSpaces(Math.round(info?.frais_acquisation))}
          </p>
          <p className="fz-12">
            <b>{t("Frais de gestion")}: </b>
            {numberWithSpaces(Math.round(info?.frais_gestion))}
          </p>
          <p className="fz-12">
            <b> {t("Prime Annuelle")} : </b>
            {numberWithSpaces(Math.round(info?.prime_annuelle))}
          </p>
          <p className="fz-12">
            <b> {t("Prime Commerciale")} : </b>
            {numberWithSpaces(Math.round(info?.prime_commercial))}
          </p>
          <p className="fz-12">
            <b> {t("Prime pure")} : </b>
            {numberWithSpaces(Math.round(info?.prime_pure))}
          </p>
          <p className="fz-12">
            <b> {t("Taux Bancaire")} : </b>
            {info?.taux_bancaire} (%)
          </p>
        </div>
      )}
      {type === "accident" && (
        <>
          <p className="mt-3 fz-12">
            <b>{t("Prime Nette")} </b> :
            {numberWithSpaces(Math.round(data?.accident?.total_ht))}
          </p>
          <p className="mt-3 fz-12">
            {/* <b>{t("Accessoires")} </b> :{0} */}
            <b>{t("Accessoires")} </b> :{2000} 
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Taxe")} </b> :{numberWithSpaces(Math.round(data?.accident?.taxe))}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Prime TTC")} </b> :
            {numberWithSpaces(Math.round(data?.accident?.prime_ttc))}
          </p>
        </>
      )}
      {type === "retraite" && (
        <>
          <p className="mt-3 fz-12">
            <b>{t("Prime Commerciale")} </b> :
            {numberWithSpaces(Math.round(info?.prime_commercial))}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Periodicité")} </b> :
            {info?.periodicite === 1
              ? t("Annuelle")
              : info?.periodicite === 2
              ? t("Semestrielle")
              : info?.periodicite === 4
              ? t("Trimistrielle")
              : t("Mensuelle")}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Durée Rente (année)")} </b> :
            {numberWithSpaces(Math.round(info?.duree_rente))}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Capital Garantie")} </b> :
            {numberWithSpaces(Math.round(info?.capital_garantie))}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Total Rente")} </b> :
            {numberWithSpaces(Math.round(info?.total_rente))}
          </p>
        </>
      )}
      {type === "voyage" && (
        <div>
          <p className="mt-3 fz-12">
            <b>{t("Prime Nette")} </b> :{data?.prime_nette}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Coût de police")} </b> :{data?.cout_police}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Taxe")} </b> :{data?.taxe}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Prime TTC")} </b> :{data?.prime_ttc}
          </p>
        </div>
      )}
      {type === "vehicule" && (
        <>
          <p className="mt-3 fz-12">
            <b>{t("Durée (Mois)")} </b> :{info?.duree}
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Prime Nette")} </b> :
            <strong>{formatCurrency(parseFloat(info?.prime_nette))} FCFA </strong>
          </p>
          <p className="mt-3 fz-12">
            <b> {t("Valeur Vénale")}  </b> :
            <strong>{formatCurrency(parseFloat(info?.valeur_venale))} FCFA </strong>
          </p>
          <p className="mt-3 fz-12">
            <b> {t("FGA")} </b> :
            <strong>{formatCurrency(parseFloat(info?.fga))} FCFA </strong>
          </p>
          <p className="mt-3 fz-12">
            <b> {t("TCA")} </b> :
            {/* <strong>{numberWithSpaces(parseFloat(info?.tca).toFixed(2))} FCFA </strong> */}
            <strong>{formatCurrency((info?.tca))} FCFA </strong>
          </p>
          <p className="mt-3 fz-12">
            <b> {t("Accessoires")} </b> :
            <strong>{formatCurrency(parseFloat(info?.accessoires))} FCFA </strong>
          </p>
          <p className="mt-3 fz-12">
            <b>{t("Prime TTC")} </b> :
            <strong>{formatCurrency(parseFloat(info?.prime_ttc))} FCFA </strong>
          </p>
        </>
      )}
    </div>
  );
}
