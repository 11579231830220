/* eslint-disable no-unused-vars */
import Icon from "../../../../components/obligatoire/Icon";
import CurrencyInput from "react-currency-input-field";

export default function Vehicule({ register, t, watch }) {

  return (
    <div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Durée du contrat")}
          <Icon />
        </label>
        <select className="form-select fz-12" {...register("duree")}>
        <option value={1}>{t("1 Mois")}</option>
        <option value={3}>{t("3 Mois")}</option>
        <option value={6}>{t("6 Mois")}</option>
        <option value={12}>{t("12 Mois")}</option>
        </select>
      </div>
      <div className="shop-pagination">
        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14">{t("Informations Véhicules")}</small>
        </div>
        <div className="form-group mb-3 text-center mt-2">
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input fz-12"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                {...register("etat")}
                defaultValue="option1"
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                {t("Immat. N°")}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input fz-12"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                {...register("etat")}
                defaultValue="option2"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                {t("N° Chassis")}
              </label>
            </div>
          </div>
        </div>
        {watch("etat") === "option1" ? (
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("Immat. N°")}</label>
            <input
              type="text"
              className="form-control fz-12"
              placeholder="Immat. N° "
              {...register("matricule")}
            />
          </div>
        ) : (
          watch("etat") === "option2" && (
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("N° Chassis")}</label>
              <input
                type="text"
                className="form-control fz-12"
                placeholder="Chassis"
                {...register("chassis")}
              />
            </div>
          )
        )}

        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Marque")} <Icon />
          </label>
          <input
            className="form-control  fz-12"
            type="text"
            {...register("marque")}
            placeholder={t("Marque") + " ... "}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Modèle")} <Icon />
          </label>
          <input
            className="form-control  fz-12"
            type="text"
            {...register("modele")}
            placeholder={t("Modèle") + " ... "}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Energie")} <Icon />
          </label>
          <select className="form-select  fz-10" {...register("energie")}>
            <option value={"Essence"}> Essence </option>
            <option value={"Diesel"}> Diesel</option>
            {/*      <option value={"electrique"}> Electrique </option>
            <option value={"Gaz naturel"}> Gaz naturel </option>
            <option value={"Hybride Diesel"}> Hybride Diesel </option>
            <option value={"Hybride Diesel plug-in"}>
              Hybride Diesel plug-in
            </option>
            <option value={"Hybride essence"}> Hybride essence </option>
            <option value={"Hybride essence plug-ing"}>
              Hybride essence plug-ing
            </option>
            <option value={"Hydrogéne"}> Hydrogéne </option> */}
          </select>
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Nombre de place")} <Icon />
          </label>
          <select
            className="form-select fz-12"
            {...register("nbr_place")}
            required
          >
            <option value={"4"}> 4 </option>
            <option value={"5"}> 5 </option>
            <option value={"7"}> 7 </option>
            <option value={"9"}>9 </option>
          </select>
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Valeur vénale")} (FCFA)
            <Icon />
          </label>
          <CurrencyInput
            name="valeur_venale"
            className="form-control fz-12"
            placeholder={t("Valeur vénale")+"..."}
            decimalScale={2}
            {...register("valeur_venale")}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Puissance")} <Icon />
          </label>
          <select className="form-select  fz-12" {...register("puissance")}>
          <option> {t("Choisir")} </option>
            <option value={"categorie1"}> {t("3 à 6 CV")} </option>
            <option value={"categorie2"}> {t("7 à 10 CV")} </option>
            <option value={"categorie3"}> {t("11 à 14 CV")} </option>
            <option value={"categorie4"}> {t("15 à 23 CV")} </option>
            <option value={"categorie5"}> {t("24 à + CV")} </option>
          </select>
        </div>
        <div className="shop-pagination">
          <div className="d-flex align-items-center justify-content-between">
            <small className="mb-3 fz-14">{t("Formule et periodicité")} </small>
          </div>
          <label className="form-label fz-12">
            {t("Formules")}
            <Icon />
          </label>
          <div className="mb-3">
            <div
              className={
                watch("formule") === "Karangue"
                  ? "single-plan-check shadow-sm active-effect active"
                  : "single-plan-check shadow-sm active-effect"
              }
            >
              <div className="form-check  mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formule"
                  value={"Karangue"}
                  {...register("formule", { required: true })}
                />
                <label className="form-check-label" htmlFor="Individual">
                  Karangue:
                  <li className="fz-12">
                    {t(" RC (Responsabilité Civile)")}
                  </li>
                  <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                </label>
              </div>
            </div>
            <div
              className={
                watch("formule") === "Aar"
                  ? "single-plan-check shadow-sm active-effect active"
                  : "single-plan-check shadow-sm active-effect"
              }
            >
              <div className="form-check  mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formule"
                  value={"Aar"}
                  {...register("formule", { required: true })}
                />
                <label className="form-check-label" htmlFor="Individual">
                  Aar:
                  <li className="fz-10">
                    {t(" RC (Responsabilité Civile)")}
                  </li>
                  <li className="fz-10">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-10">{t("PT (Personne transportée)")}</li>
                  <li className="fz-10"> {t("AR(Avance sur Recours)")}</li>
                </label>
              </div>
            </div>
            <div
              className={
                watch("formule") === "Noflaye"
                  ? "single-plan-check shadow-sm active-effect active"
                  : "single-plan-check shadow-sm active-effect"
              }
            >
              <div className="form-check  mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formule"
                  value={"Noflaye"}
                  {...register("formule", { required: true })}
                />
                <label className="form-check-label" htmlFor="Individual">
                  Noflaye:
                  <li className="fz-10">
                    {t(" RC (Responsabilité Civile)")}
                  </li>
                  <li className="fz-10">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-10">{t("PT (Personne transportée)")}</li>
                  <li className="fz-10"> {t("AR(Avance sur Recours)")}</li>
                  <li className="fz-10">{t("BDG (Brise de Glace)")}</li>
                </label>
              </div>
            </div>
            <div
              className={
                watch("formule") === "Diam"
                  ? "single-plan-check shadow-sm active-effect active"
                  : "single-plan-check shadow-sm active-effect"
              }
            >
              <div className="form-check  mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formule"
                  value={"Diam"}
                  {...register("formule", { required: true })}
                />
                <label className="form-check-label" htmlFor="Individual">
                  Diam:
                  <li className="fz-10">
                    {t(" RC (Responsabilité Civile)")}
                  </li>
                  <li className="fz-10">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-10">{t("PT (Personne transportée)")}</li>
                  <li className="fz-10"> {t("AR(Avance sur Recours)")}</li>
                  <li className="fz-10">{t("BDG (Brise de Glace)")}</li>
                  <li className="fz-10">{t("Vol")}</li>
                  <li className="fz-10">{t("Incendie")}</li>
                </label>
              </div>
            </div>
            <div
              className={
                watch("formule") === "Ngalam"
                  ? "single-plan-check shadow-sm active-effect active"
                  : "single-plan-check shadow-sm active-effect"
              }
            >
              <div className="form-check  mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formule"
                  value={"Ngalam"}
                  {...register("formule", { required: true })}
                />
                <label className="form-check-label" htmlFor="Individual">
                  Ngalam:
                  <li className="fz-10">{t("Tous risques")}</li>
                  <li className="fz-10">{t("Avec Franchise")} (50.000 FCFA)</li>
                  <li className="fz-10">{t("Remorquage Gratuit")} (25.000 FCFA)</li>
                </label>
              </div>
            </div>
            <div
              className={
                watch("formule") === "Wourous"
                  ? "single-plan-check shadow-sm active-effect active"
                  : "single-plan-check shadow-sm active-effect"
              }
            >
              <div className="form-check  mb-0">
                <input
                  className="form-check-input"
                  type="radio"
                  name="formule"
                  value={"Wourous"}
                  {...register("formule", { required: true })}
                />
                <label className="form-check-label" htmlFor="Individual">
                  Wourous:
                  <li className="fz-10">{t("Tous risques")}</li>
                  <li className="fz-10">{t("Sans Franchise")}</li>
                  <li className="fz-10">{t("Remorquage Gratuit")} (25.000 FCFA)</li>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
