/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getSinistreById,
  getSinistreMediaUser,
} from "../../services/sinistre/sistreAPI";
import { getExtension } from "../../utils/FileExtension";
import CardMedia from "./CardMedia";
import Loader from "../../components/loading/Loader";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function DetailsDeclaration() {
  const { sinistre_id } = useParams("");
  //--------- INFO DECLARATION SINISTRE
  const [data, setdata] = useState({});
  //----------------LISTE --------------
  const [Liste, setListe] = useState([]); // Liste des medias
  const [SaveListe, setSaveListe] = useState([]); // Sauvegarde de la liste des medias

  //-------------- LOADING ------------
  const [Loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");

  useEffect(() => {
    getSinistreById(sinistre_id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setdata(result?.data?.data[0]);
          console.log(result?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getSinistreMediaUser(sinistre_id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListe(result?.data?.data); // REsult of Media
          setSaveListe(result?.data?.data[0]); // REsult of Media
          console.log(result?.data?.data[0]);

        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row mb-4">
          <div className="col-12 mt-2">
          <h6 className="display-6 fs-12 text-center">{t("Déclaration Sinistre")} </h6>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="float-end">
                <h6 className="mt-4">
                  <b className="text-purple fz-12 ">{t("Le")} : {moment(data?.created_at).format("DD/MM/YYYY")} </b>
                </h6>
                </div>
                <h6 className="mt-4">
                  <b className="text-secondary fz-12">{t("Objet")} </b>
                </h6>
                <input type="text" className="form-control fs-12" value={data?.category?.titre} disabled/>
                <h6 className="mt-4">
                  <b className="text-secondary fz-12">{t("Nom et prénom(s)")} </b>
                </h6>
                <input type="text" className="form-control fs-12" value={data?.nom+" "+data?.prenom} disabled/>
                <h6 className="mt-4">
                  <b className="text-secondary fz-12">{t("E-Mail")} </b>
                </h6>
                <input type="text" className="form-control fs-12" value={data?.email} disabled/>
                <h6 className="mt-4">
                  <b className="text-secondary fz-12">{t("Mobile")} </b>
                </h6>
                <input type="text" className="form-control fs-12" value={data?.telephone} disabled/>
                <h6 className="mt-4">
                  <b className="text-secondary fz-12">  {t("Description de la déclaration")} </b>
                </h6>
                <textarea type="text" className="form-control fs-12" value={data?.description} disabled/>
                  <div className="float-end mt-2">
                    <a className="btn btn-purple fz-10" href={"/declaration_sinistre_media&id=" + sinistre_id}> {t("Ajouter Justificatif(s)")} </a>
                  </div>
              </div>
            </div>
          </div>
          {Loading ? (
          <Loader />
        ) : ( <div className="col-12">
        <table className="table table-responsive">
          <thead>
            <tr className="fz-10 text-center">
              <td> {t("Fichier")} </td>
              <td> {t("Document")} </td>
              <td> {t("Action")} </td>
            </tr>
          </thead>
          <tbody>
          {Liste?.map((items, keys) => (
            <CardMedia
            t={t}
            items={items}
            key={keys}
            getExtension={getExtension}
          />
        ))}
        </tbody>

        </table>
      </div>)}
        </div>

      </div>
    </div>
  );
}
