import { useUser } from "../../Auth/useUser";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DotLoader from "../../components/loading/DotLoader";
import { useState } from "react";
import FormParrainage from "./FormParrainage";
import { generateCodeParrainage } from "../../utils/FileExtension";
import { updateParrain } from "../../services/users/usersAPI";
import { error_message, success_message } from "../../components/alerte/AlerteMessage";

export default function Add_Parrainage() {

  const user = useUser();
  // # History CALL
  const history = useHistory();
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  var uniq_generated_code = generateCodeParrainage()
  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit } = useForm();
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);
  /**
   * OnSubmit Function : ADD PARRAINAGE
   */
  const ajouter_parraine = (data) => {
    var user_id = user?.result?.id;
    setLoading(true);
  
    Object.assign(data,{id : user_id})  

    updateParrain(data)
    .then((result) => {
      if(result?.data?.code===200){
        success_message("Parrainé(e) ajouté avec succès !")
      history.push({pathname:"/parrainage"})
      }else{
        error_message("Erreur Temporaire !")
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  };
  return <div className="container">
  <h4 className="mb-3  mt-3 text-center"> Ajouter un code de parrainage</h4>

  <div className="row">
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(ajouter_parraine)}>
            <FormParrainage register={register} t={t} uniq_generated_code={uniq_generated_code}/>
            {loading ? (
              <button
                className="btn btn-purple w-100 d-flex align-items-center justify-content-center fz-12"
                disabled
                type="submit"
              >
                <DotLoader />{" "}
              </button>
            ) : (
              <button
                className="btn btn-purple w-100 d-flex align-items-center justify-content-center fz-12"
                type="submit"
              >
                Ajouter
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  </div>
</div>;
}
