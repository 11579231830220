/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
// # SEND AN OBJECT OF FORM DATA
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// # TRANSLATION LIBRARY
import { useTranslation } from 'react-i18next'

// # COMPONENTS
import Loader from '../../../components/loading/Loader'
import DotLoader from '../../../components/loading/DotLoader'

// # TOAST AND ALERT ELEMENTS
import { toast } from 'react-toastify'
import { useUser } from '../../../Auth/useUser'
import {addPersonnel,getPersonnelByID, updatePersonnelByID} from '../../../services/personnel/personnelAPI'
import { getPreviousPath } from '../../../utils/Comparaison'

export default function PersonnelInfo() {
  const user = useUser()
  var { id } = user.result
  //---------------- HISTORY -----------------
  const history = useHistory()
  //---------------- PROFIL DATA --------------
  const [data, setData] = useState(null)
  //---------------- VERIF DATA OBJECT
  const [empty, setEmpty] = useState(false) // Verif if initial get of data object (n_cin exist or not ), if data exist we update the date else we add a new object
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset } = useForm() //FORM DATA

  //---------------- LOADING -------------------
  const [loading, setLoading] = useState(true) // Loader Page
  const [loadingForm, setloadingForm] = useState(false) //Loader Form
  //------------- Traduction ---------------
  const [t] = useTranslation('global')

  useEffect(() => {
    getPersonnelByID(id)
      .then((result) => {
        if(result?.data?.code===200){
            if(result?.data?.data?.length === 0){
                setEmpty(true)
                setData({
                  user_id: id,
                  n_cin: "",
                  n_passport: "",
                })
            }else{
            setEmpty(false)
            var info = result?.data?.data[0]
            setTimeout(
              () =>
                setData({
                  id: info?.id,
                  user_id: info?.user_id,
                  n_cin: info?.n_cin,
                  n_passport: info?.n_passport,
                }),
              1200,
            )}
        }
         
      }) 
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1200)
      })
  }, [])

  // UPDATE DATA INTO FORM
  useEffect(() => {
    reset(data)
  }, [data])

  function onFormSubmit(dataRes) {
    setloadingForm(true)
    Object.assign(dataRes, {
      user_id: id,
    })
    if (empty) {
      addPersonnel(dataRes)
        .then((result) => {
          if (result?.data?.code === 200) {
            toast(t('Modifié avec succès'), {
              type: 'success',
              position: 'top-right',
            })
            if(getPreviousPath("facturation_produits")!== -1){
              history.goBack()
             }
          } else {
            toast(t('Erreur'), { type: 'error', position: 'top-right' })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setTimeout(() => setloadingForm(false), 1200)
        })
    } else {
      updatePersonnelByID(dataRes)
        .then((result) => {
          if (result?.data?.code === 200) {
            toast(t('Modifié avec succès'), {
              type: 'success',
              position: 'top-right',
            })
            if(getPreviousPath("facturation_produits")!== -1){
              history.goBack()
             }
          } else {
            toast(t('Erreur'), { type: 'error', position: 'top-right' })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setTimeout(() => setloadingForm(false), 1200)
        })
    }

    return false
  }

  return (
    <div>
      <div className="page-content-wrapper mt-0 py-3">
        {loading ? (
          <Loader />
        ) : (
          <div className="container">
            {/* User Meta Data*/}
            <h1 className="display-6 mb-2 text-center">
              {t("Pièces d'identité")}
            </h1>

            <div>
              <div className="card-body">
                <h1 className="display-6 mb-2 text-center"> </h1>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="form-group mb-3">
                    <label className="form-label" htmlFor="portfolio">
                      {t("N° CIN")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="N° CIN"
                      {...register('n_cin')}
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label" htmlFor="portfolio">
                      {t("N° Passeport")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="N° de passeport"
                      {...register('n_passport')}
                    />
                  </div>

                  {loadingForm ? (
                    <button
                      className="btn btn-purple w-100"
                      type="submit"
                      disabled
                    >
                      {t('En Cours')} <DotLoader />
                    </button>
                  ) : (
                    <button className="btn btn-purple w-100" type="submit">
                      {t('Modifier')}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
