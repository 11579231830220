import axios from "axios"
import authHeader from "../authentification/AuthHeader";

//GET URL FROM .ENV ELEMENT
import { getUrl } from "../authentification/DotenvConfig"


/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
const url = getUrl()

/**
 * GET CONTACTS UTILS
 * @returns 
 */
export async function getContactsUtils() {
    try {
        const response = await axios.get(url + "contacts", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}


/**
 * ADD CONTACTS UTILS
 * @returns 
 */
export async function addcontacts(data){

    const formData = new FormData();
    formData.append("region", data?.region);
    formData.append("adresse", data?.adresse);
    formData.append("libelle", data?.libelle);
    formData.append("mobile_1", data?.mobile_1);
    formData.append("mobile_2", data?.mobile_2);
    formData.append("mobile_3", data?.mobile_3);
    formData.append("type", data?.type);

    try {
        const response = await axios.post(url + "contacts/",formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        return error;
    }
}

/**
 * UPDATES CONTACTS UTILS
 * @param {*} data 
 * @returns 
 */
export async function updatecontacts(data){

    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("region", data?.region);
    formData.append("adresse", data?.adresse);
    formData.append("latitude", data?.latitude);
    formData.append("longitude", data?.longitude);
    formData.append("libelle", data?.libelle);
    formData.append("mobile", data?.mobile);
    formData.append("image", data?.image);
    formData.append("description", data?.description);

    try {
        const response = await axios.patch(url + "contacts/",formData, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        return error;
    }
}
/**
 * GET BANNIERE BY ID
 * @param {*} id 
 * @returns 
 */
export async function getcontactsByid(id){
    try {
        const response = await axios.get(url + "contacts/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        return error;
    }
}


/**
 * DELETE BANNIERE BY ID
 * @param {*} id 
 * @returns 
 */
export async function deletecontactsByid(id){
    try {
        const response = await axios.delete(url + "contacts/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        return error;
    }
}