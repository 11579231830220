
// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"

//GET URL FROM .ENV ELEMENT
import { getAuthUrl } from "../authentification/DotenvConfig"

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
 const url = getAuthUrl()


 /**
 * SERVICE AUTHENTIFICATION WITH E-MAIL AND PASSWORD
 * @param {*} data  
 * @returns 
 */
export async function register_email (data){
    try{
        const response = await axios.post(url+"users/register-email",data,{
            IsEssential: true
          });
          return response;
    }catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
         // console.log(error.response.data);
         // console.log(error.response.message);
         //console.log(error.response.headers);
         return(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
      }
}  
/**
 * Verifier un parrain
 * @param {*} data 
 * @returns 
 */
export async function verify_parrain (data){
  try{
      const response = await axios.post(url+"users/verify_parrain",data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
       return(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  
/**
 * Register Mobile
 * @param {*} data 
 * @returns 
 */
export async function register_mobile (data){
  try{
      const response = await axios.post(url+"users/register-mobile",data,{
          IsEssential: true
        });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
       // console.log(error.response.data);
       // console.log(error.response.message);
       //console.log(error.response.headers);
       return(error.response);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  

