/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DotLoader from '../../components/loading/DotLoader';
import { forget_password } from '../../services/signin/LoginAPI';

/*
  INTERFACE DE MODIFICIATION MOT DE PASSE QUAND L UTILISATEUR EST DEJA SUR SON COMPTE
 * @returns 
 */

export default function ChangePasswordConnected() {
   
    //-------------- HISTORY -------------
    const history = useHistory()
    //-------------- PARAMETRE ------------
    const { id } = useParams('')
    //------------- PASSWORD TYPE ------------
    const [password1, setPassword1] = useState("password");
    const [password2, setPassword2] = useState("password");
    //------------- VERIF VALID PASSWORDS ---------------
    const [validPassword, setvalidPassword] = useState(true)

    // # SUBMIT FORM ELEMENTS
    const { register, handleSubmit, formState: { errors } } = useForm();
    const handleError = (errors) => { };
    //------------- Traduction -------------
    const [t] = useTranslation("global");
    // ------------- Loading ---------------
    const [loading, setLoading] = useState(false);



    const updateType = (type) => {
        if (type === "p1") {
            if (password1 === "password") {
                setPassword1("text");
            } else {
                setPassword1("password");
            }
        } else if (type === "p2") {
            if (password2 === "password") {
                setPassword2("text");
            } else {
                setPassword2("password");
            }
        }

    };

    const handlePassword = (data) => {
        var { password1, password2 } = data
        setLoading(true)
        if (password1 === password2) {
            forget_password(id,{
                password : password1
            })
                .then((result) => {
                    if (result?.data?.code === 200) {
                        toast(t("Votre mot de passe est modifié"), { type: "success", position: "top-right" })
                        history.push({ pathname: "/login" })
                    } else {
                        toast(t("Erreur de modification Mot de passe "), { type: "error", position: "top-right" })
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false)
                })
        }

    }
        /**
       * REGISTER OPTIONS
       */
        const registerOptions = {
            password1: {
                required: t("Password is required"),
                minLength: {
                    value: 6,
                    message: t("Password must have at least 6 characters")
                }
            },
            password2: {
                required: t("Password is required"),
                minLength: {
                    value: 6,
                    message: t("Password must have at least 6 characters")
                }
            }
        };
    return (
        <div>
            <div className="custom-container">
                <div className="text-center px-4">
                    <img width={"150px"}
                        className="login-intro-img"
                        src="img/bg-img/50.png" alt="logo" />
                    <h3>{t("Create New Password")}</h3>
                    <p>{t("Your new password must be different from previous used password.")}</p>
                </div>
                <div className="register-form mt-4">
                    <form onSubmit={handleSubmit(handlePassword, handleError)}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="exampleTextarea1">{t("Mot de passe")} <span className='text-danger'>*</span></label>
                            <div className="form-group position-relative">
                                <input className="form-control" id="psw-input" type={password1} {...register("password1", registerOptions.password1)} placeholder="Mot de passe" required />
                                <div className="position-absolute" id="password-visibility" onClick={() => updateType("p1")}>
                                    {password1 === "password" ? (
                                        <i className="bi bi-eye" />
                                    ) : (
                                        <i className="bi bi-eye-slash-fill" />
                                    )}
                                </div>
                                <small className="text-danger">
                                    {errors?.password1 && errors.password1.message}
                                </small>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="exampleTextarea1">{t("Confirmer le Mot de passe")} <span className='text-danger'>*</span></label>
                            <div className="form-group position-relative">
                                <input className="form-control" id="psw-input" type={password2}  {...register("password2", registerOptions.password2)} placeholder="Mot de passe" required />
                                <div className="position-absolute" id="password-visibility" onClick={() => updateType("p2")}>
                                    {password2 === "password" ? (
                                        <i className="bi bi-eye" />
                                    ) : (
                                        <i className="bi bi-eye-slash-fill" />
                                    )}
                                </div>
                                <small className="text-danger">
                                    {errors?.password2 && errors.password2.message}
                                </small>
                            </div>
                            {!validPassword && <span className='text-danger fz-12'>** {t("Les mots de passes doivent être identiques")} </span>}
                        </div>
                        {loading ? <button className="btn btn-secondary w-100" disabled><DotLoader /> </button> : <button className="btn btn-purple w-100" type="submit">{t("Update Password")}</button>}
                    </form>
                </div>
                
            </div>
        </div>
    )
}
