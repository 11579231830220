import parse from "html-react-parser";
import Loader from "../../../../components/loading/Loader";
 /* MODAL CONDITION SPECIALE
 * @returns 
 */
export default function ConditionsSpeciales({ setcondition2 ,data , loading_sp,t}) {
    return (
        <div
        className="modal fade"
        id="exampleModal2"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen-md-down">
          <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Politique de protection des données
            </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">{ loading_sp ? <Loader /> : parse(data?.description) }</div>
            <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              <i className="bi bi-x-lg m-1" /> {t("Fermer")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setcondition2(true)}
              data-bs-dismiss="modal"
            >
              {" "}
              <i className="bi bi-check2-circle m-1" /> {t("Confirmer")}
            </button>
          </div>
          </div>
          
        </div>
      </div>
  )
}
