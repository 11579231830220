import { StyleSheet, Image, View, Text } from "@react-pdf/renderer";
import logo from "./logo_dikotech.jpeg";
import QRCode from "qrcode-svg";
import PlacardQRCode from "./PlacardQRCode";

const styles = StyleSheet.create({
  logo: {
    width: 80,
    height: 40,
    marginTop: -20,
  },
  logo2: {
    width: 60,
    height: 40,
    marginTop: -20,
    marginLeft: 30,
    textAlign: "right",
  },
  entete: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
  },
  enteteRow: {
    width: "35%",
    alignContent:"center"
  },
  enteteRow3: {
    width: "20%",
  },
  enteteRow1: {
    width: "10%",
    marginTop: -20,
    padding:0
  },
  enteteRow2: {
    width: "20%",
    textAlign: "right",
  },
  headerTitle: {
    letterSpacing: 2,
    fontSize: 8,
    color: "#4e545d",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 0,
    fontFamily: "Arial",
    fontStyle: "normal",
  },
});
const generateQrCodePath = (content, size) => {
  const htmlStr = new QRCode({
    content,
    join: true,
    padding: 0,
    width: 40,
    height: 40,
  }).svg({ container: "none" });
  const parsableHtml = new DOMParser().parseFromString(htmlStr, "text/html");
  const dElement =
    parsableHtml
      .querySelector("html > body > rect > path")
      ?.getAttribute("d") ?? "";
  return dElement;
};
export default function EnteteContrat({ data }) {
  return (
    <View style={styles.entete} fixed>
      <View style={styles.enteteRow1}>
        <PlacardQRCode dPath={generateQrCodePath("https://diokoassur.com/policy/"+data?.policy_code, 50)} />
      </View>
      <View style={styles.enteteRow3}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.enteteRow}>
        <Text style={styles.headerTitle} > Partenaire distributeur de </Text>
      </View>
      <View style={styles.enteteRow2}>
       <Image
          style={styles.logo2}
          src={process.env.REACT_APP_IMAGE + data?.company?.logo}
        />
      </View>

    </View>
  );
}
