
export default function CardChoosr({ image, titre, url }) {

  return (
    <a href={url} className="btn btn-sq-lg  fz-10">
      <img src={image} alt="assurance" className='mt-2 mb-1' width={"50%"} /><br />
      <p className="mb-0 text-white fz-12">{titre}</p>
    </a>
  );
}
