import React from "react";
import Icon from "../../../../components/obligatoire/Icon";
export default function DevisDeces({ t, categorie_id , register }) {
  return (
    <div>
      <div className="form-group mb-3">
          <label className="form-label fz-12">
            {t("Periodicité")} <Icon />
          </label>
          <select className="form-select  fz-12" {...register("periodicite")}>
            <option value={""}>{t("Choisir")} </option>
            <option value={1}>{t("Annuelle")}</option>
            <option value={2}>{t("Semestrielle")}</option>
            <option value={4}>{t("Trimistrielle")}</option>
            <option value={12}>{t("Mensuelle")}</option>
          </select>
        </div>
    {/*     <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Cout de la police")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            {...register("cout_police")}
            type="number"
            placeholder={t("Cout de la police")+"..."}
            required
          />
        </div> */}
      {/*   <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Taux Bancaire annuel")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            type="text"
            placeholder={t("Définir le taux bancaire annuel ... ")}
            {...register("taux_bancaire")}
            required
          />
        </div> */}
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Capital")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            type="number"
            placeholder="Capital  ..."
            {...register("capital")}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Durée Cotisation (année)")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            type="number"
            placeholder="Durée de Cotisiation ..."
            {...register("cotisation")}
            required
          />
        </div>
        
 
    </div>
  );
}
