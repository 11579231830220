
// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"
import authHeader from "../authentification/AuthHeader";

//GET URL FROM .ENV ELEMENT
import { getUrl } from "../authentification/DotenvConfig"

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
 const url = getUrl()
/**
 * Create session login in system
 * @param {*} token 
 * @returns 
 */
export async function create_session (data){
    try{
        const response = await axios.post(url+"sessions", data, { headers: authHeader() }, {
          IsEssential: true
      });
          return response;
    }catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
         return(error.response.status);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
      }
}  

/**
 * Update session in system
 * @param {*} token 
 * @param {*} data 
 * @returns 
 */
export async function update_session (data){
  try{
      const response = await axios.patch(url+"sessions", data, { headers: authHeader() }, {
        IsEssential: true
    });
        return response;
  }catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
       return(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return error;
    }
}  

