/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import ModalLanguages from './ModalLanguages'
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

export default function ChooseLg({isDark}) {
    // ----- Language global selectionné
    const language = Cookies.get('locale')
    const [lg, setLg] = useState(language === 'fr' ? 'Français' : language === "ar" ? "Arabe" : "English")
    // ---- outil de Traduction ----------
    const [t, i18n] = useTranslation('global')

    // SELECT CHANGE LANGUAGES 
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
        if (language === 'fr') {
            setLg('Français')
        } else if (language === 'en') {
            setLg('English')
        }
        Cookies.set('locale', language)
    }
    return (
        <>
            <button className="btn me-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill={isDark ? "#fff" : "#1f0757"} width={20} height={20}><path d="m24,12C24,5.5,18.897,0,12,0,5.383,0,0,5.383,0,12s5.383,12,12,12c7.179,0,12-5.667,12-12Zm-1.031.833l-3.183-1.287c-.069-.035-.12-.097-.139-.173l-.627-2.506c-.028-.113.024-.237.14-.304l2.606-1.623c.789,1.516,1.235,3.237,1.235,5.06,0,.28-.011.558-.031.833ZM12,1c.095,0,.191.001.285.004-.643.862-1.64,2.204-1.825,2.49-.393.608-.405,1.374-.032,1.997l.733,1.222-.699-.35c-.696-.349-1.53-.212-2.075.337l-.529.529c-.34.339-.526.791-.526,1.271,0,.171.023.339.07.5h-1.8c-.109,0-.216-.045-.294-.128l-2.625-2.712C4.63,3.063,8.079,1,12,1Zm0,22c-6.065,0-11-4.935-11-11,0-1.772.422-3.447,1.169-4.932l2.416,2.495c.264.277.634.437,1.017.437h1.948c.29,0,.563.113.769.318l.372.373c.197.197.31.469.31.746v.963c0,.389.151.753.425,1.025l1.189,1.19c.249.249.386.58.386.931v2.365c0,.601.489,1.09,1.09,1.09h.213c.424,0,.812-.249.989-.633l2.241-4.855c.267-.577.062-1.265-.478-1.601l-2.562-1.603c-.323-.201-.695-.308-1.076-.308h-1.431c-.31,0-.612-.125-.83-.344l-.592-.592c-.15-.15-.233-.351-.233-.564s.083-.414.233-.564l.53-.53c.242-.243.613-.303.92-.148l.879.439c.364.184.804.112,1.085-.176.307-.307.366-.779.145-1.149l-.838-1.396c-.176-.294-.17-.654.015-.94.191-.295,1.497-2.047,2.164-2.939,3.271.437,6.092,2.316,7.8,4.976l-2.619,1.631c-.486.28-.731.857-.596,1.403l.627,2.505c.089.36.331.662.699.843l3.467,1.403c-.886,5.183-5.41,9.14-10.842,9.14Zm-.679-9.093l-1.19-1.191c-.084-.084-.131-.196-.131-.317v-.963c0-.147-.017-.294-.048-.437.012,0,.022,0,.034,0h1.431c.193,0,.383.054.548.156l2.562,1.603c.112.07.154.213.099.333l-2.322,4.908h-.213c-.049,0-.09-.041-.09-.09v-2.365c0-.618-.241-1.2-.679-1.638Z" /></svg>

            </button>

            <ModalLanguages changeLanguage={changeLanguage} t={t} />
        </>

    )
}
