import { StyleSheet, View } from "@react-pdf/renderer";
import Identification from "./Identification";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    marginBottom: 0,
    borderColor:"#fff"

  },
});

export default function IdentificationVehicule({ t, type, info, data, user_details, personnel}) {
  return (
    <View style={styles.tableContainer}>
      <Identification
        type={type}
        data={data}
        user_details={user_details}
        personnel={personnel}
      />
    </View>
  );
}
