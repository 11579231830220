import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getMessageyID } from "../../services/chatApi";
import moment from "moment";

export default function DetailsMessages() {
  const { id } = useParams("");

    //--------- INFO DECLARATION SINISTRE
    const [data, setdata] = useState({});
    //----------------LISTE --------------
    const [Liste, setListe] = useState([]); // Liste des medias
    const [SaveListe, setSaveListe] = useState([]); // Sauvegarde de la liste des medias
  
    //-------------- LOADING ------------
    const [Loading, setLoading] = useState(true);
    //------------- Traduction ---------------
    const [t] = useTranslation("global");
    
  useEffect(() => {
    getMessageyID(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setdata(result?.data?.data[0]);
          console.log(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      }) 
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="container">
    <div className="row mb-4">
      <div className="col-12 mt-2">
      <h6 className="display-6 fs-12 text-center"> Détails Message  </h6>
      </div>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="float-end">
            <h6 className="mt-4">
              <b className="text-purple fz-12 ">Le : {moment(data?.created_at).format("DD/MM/YYYY")} </b>
            </h6>
            </div>
            <h6 className="mt-4">
              <b className="text-secondary fz-12">{t("Objet")} </b>
            </h6>
            <input type="text" className="form-control fs-12" value={data?.type} disabled/>
           
            <textarea type="text" className="form-control fs-12 mt-2" value={data?.text} disabled/>
            
          </div>
        </div>
      </div>
    
    </div>

  </div>
  )
}
