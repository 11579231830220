import moment from "moment";
import Icon from "../../../../components/obligatoire/Icon";

export default function Sousscripteur({ register, t, data, ListeAdresses }) {
  return (
    <div className="shop-pagination">
      <div className="d-flex align-items-center justify-content-between">
        <small className="mb-3 fz-14" >
          {t("Souscripteur")}
        </small>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Nom")}  <Icon />
        </label>
        <input
          className="form-control fz-12"
          type="text"
          value={data?.nom}
          disabled
        />
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Prénom")} <Icon />
        </label>
        <input
          className="form-control fz-12"
          type="text"
          value={data?.prenom}
          disabled
        />
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("N° CIN")}  <Icon />
        </label>
        <input
          className="form-control fz-12"
          type="text"
          placeholder="CIN ... "
          value={data?.n_cin}
          disabled
        />
      </div>

      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Mobile")}  <Icon />
        </label>
        <input
          className="form-control fz-12"
          type="text"
          value={data?.mobile}
          disabled
        />
      </div>
      {data?.email !== "" && (
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">{t("E-Mail")}</label>
          <input className="form-control fz-12" type="text" {...register("email")} />
        </div>
      )}
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Adresse Souscripteur")} <Icon />
        </label>
        <select className="form-select fz-12" required {...register("adresse_id")}>
          <option value="">{t("Choisir")} ...</option>
          {ListeAdresses?.map((items, keys) => (
            <option value={items.id} key={keys}>
              {items.pays +
                "," +
                items.region +
                " ," +
                items.ligne +
                "," +
                items.cite +
                " " +
                items.code_postal}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Date de Naissance")} <Icon />
        </label>
        <input
          className="form-control fz-12"
          type="date"
          placeholder="DD/MM/YYYY"
          value={moment(data?.date_naissance).format("YYYY-MM-DD")}
          disabled
        />
      </div>
    </div>
  );
}
