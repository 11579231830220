import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Page404() {

    //------------- Traduction ---------------
    const [t] = useTranslation("global");

  return (
    <div>
      <div>
       
        {/* Page Content Wrapper */}
        <div className="page-content-wrapper py-3">
          <div className="custom-container">
            <div className="card">
              <div className="card-body px-5 text-center"><img className="mb-4" src="img/bg-img/39.png" alt="404"/>
                <h4>OOPS... <br /> {t("Page not found!")}</h4>
                <p className="mb-4">{t("We couldn't find any results for your search. Try again.")}</p><a className="btn btn-creative btn-success col-12" href="/">{t("Go to Home")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
