import Accidents from "../components/accidents/Accidents";
import DevisDeces from "../components/deces/DevisDeces";
import DetailsContrats from "../components/detailscontrats/DetailsContrats";
import Education from "../components/education/Education";
import Retraite from "../components/retraite/Retraite";
import Vehicule from "../components/vehicule/Vehicule";
import Voyage from "../components/voyage/Voyage";

export default function Step2({  register, groupedAssuree,  t,  type,  watch,  handleChangeAssuree,  LISTE_ASSUREE,  data,  show2,  setShow2,  setShow1,  assuree,
}) {
  console.log(register, groupedAssuree,t,type,watch,handleChangeAssuree)
  return (
    <>
      {type!=="voyage" &&  <DetailsContrats t={t} register={register} type={type} />}
      {type === "vehicule" && (
        <Vehicule register={register} t={t} watch={watch} groupedAssuree={groupedAssuree}/>
      )}
      {type === "education" && (
        <Education
          register={register}
          t={t}
          groupedAssuree={groupedAssuree}
          handleChangeAssuree={handleChangeAssuree}
          LISTE_ASSUREE={LISTE_ASSUREE}
          data={data}
        />
      )}
      {type === "retraite" && 
      <Retraite register={register} t={t} data={data}/>}
      {type === "accident" && (
        <Accidents
          register={register}
          t={t}
          handleChangeAssuree={handleChangeAssuree}
          groupedAssuree={groupedAssuree}
          LISTE_ASSUREE={LISTE_ASSUREE}
        />
      )}
      {type === "deces" && <DevisDeces register={register} t={t} groupedAssuree={groupedAssuree}/>}
      {type === "voyage" && (
        <Voyage
          register={register}
          t={t}
          watch={watch}
          LISTE_ASSUREE={LISTE_ASSUREE}
          assuree={assuree}
          groupedAssuree={groupedAssuree}
        />
      )}
      <button className="btn btn-purple float-end fz-12" type="submit">
        {t("Next Step")}
        <i className="bi bi-arrow-right me-1 fz-12" />
      </button>
      <button
        className="btn btn-success float-start fz-12 text-white"
        onClick={() => {
          setShow2(!show2);
          setShow1(true);
        }}
      >
        <i className="bi bi-arrow-left me-1 fz-12" />
        {t("Previous Step")}
      </button>
    </>
  );
}
