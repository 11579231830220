export default function Beneficiaires({ t, type, data, moment, user_details, personnel }) {
    console.log(data);
    return (
        <div>
            <b className="mb-3 fz-14 text-warning">
                {t("Béneficiaire du contrat")} :
            </b>
            {type !== "voyage" && data?.assuree_id !== null ? (
                <>
                    <p className="mt-3 fz-12">
                        <b> {t("Prénom")} </b> {data?.assure?.prenom},
                        <b> {t("Nom")} </b>{" " + data?.assure?.nom}
                    </p>
                    <p className="mt-3 fz-12">
                        <b> {t("Date de naissance")} </b> :
                        {moment(data?.assure?.date_naissance).format(
                            "DD/MM/YYYY"
                        )}
                    </p>
                    {data?.assure?.cin !== "" && (
                        <p className="mt-3 fz-12">
                            <b> {t("N° CIN")} </b> : {data?.assure?.cin}
                        </p>
                    )}
                    {data?.assure?.passport !== "" &&
                        data?.category?.type === "voyage" && (
                            <p className="mt-3 fz-12">
                                <b> {t("N° Passeport")} </b> :
                                {data?.assure?.passport}
                            </p>
                        )}
                </>
            ) : type !== "voyage" && (
                <>
                    <p className="mt-3 fz-12">
                        <b> {t("Prénom")} </b>
                        {user_details?.gender === "femme" ? "Mme." : "Mr"}
                        {user_details?.prenom}, <b> {t("Nom")} </b>
                        {" " + user_details?.nom}
                    </p>
                    <p className="mt-3 fz-12">
                        <b>{t("Date de naissance")}</b> :
                        {moment(user_details?.date_naissance).format(
                            "DD/MM/YYYY"
                        )}
                    </p>
                    {personnel?.n_cin !== "" && (
                        <p className="mt-3 fz-12">
                            <b>{t("N° CIN")} </b> : {personnel?.n_cin}
                        </p>
                    )}
                    {personnel?.n_passport !== "" &&
                        data?.category?.type === "voyage" && (
                            <p className="mt-3 fz-12">
                                <b> {t("N° Passeport")} </b> :
                                {personnel?.n_passport}
                            </p>
                        )}
                </>
            )}
            {type === "voyage" && data?.assure?.length !== 0 ?
                data?.assuree?.map((items, keys) => (
                    <div key={keys}>
                        <p className="mt-3 fz-12"> <b className="text-purple"> Béneficiaire N°{keys + 1}</b>  </p>
                        <p className="mt-3 fz-12">
                            <b> {t("Prénom")} </b>
                            {items?.prenom}, <b> {t("Nom")} </b>
                            {" " + items?.nom}
                        </p>
                        <p className="mt-3 fz-12">
                            <b>{t("Date de naissance")}</b> :
                            {moment(items?.date_naissance).format(
                                "DD/MM/YYYY"
                            )}
                        </p>
                        {personnel?.cin !== "" && (
                            <p className="mt-3 fz-12">
                                <b>{t("N° CIN")} </b> : {items?.cin}
                            </p>
                        )}
                        {personnel?.passport !== "" &&
                            data?.category?.type === "voyage" && (
                                <p className="mt-3 fz-12">
                                    <b> {t("N° Passeport")} </b> :
                                    {items?.passport}
                                </p>
                            )}
                    </div>
                ))
                : <>
                    <p className="mt-3 fz-12">
                        <b> {t("Prénom")} </b>
                        {user_details?.gender === "femme" ? "Mme." : "Mr"}
                        {user_details?.prenom}, <b> {t("Nom")} </b>
                        {" " + user_details?.nom}
                    </p>
                    <p className="mt-3 fz-12">
                        <b>{t("Date de naissance")}</b> :
                        {moment(user_details?.date_naissance).format(
                            "DD/MM/YYYY"
                        )}
                    </p>
                    {personnel?.n_cin !== "" && (
                        <p className="mt-3 fz-12">
                            <b>{t("N° CIN")} </b> : {personnel?.n_cin}
                        </p>
                    )}
                    {personnel?.n_passport !== "" &&
                        data?.category?.type === "voyage" && (
                            <p className="mt-3 fz-12">
                                <b> {t("N° Passeport")} </b> :
                                {personnel?.n_passport}
                            </p>
                        )}
                </>}
        </div>
    )
}
