import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 10,
    },
    description1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 9,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
    description: {
        width: "50%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
});

const SouscripteurCG= ({ type , data,user_details,personnel}) => {
    const rows = (
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.description1}>Nom & Prénom(s) : {user_details?.prenom+" " + user_details?.nom}</Text>
                <Text style={styles.description}> Adresse :{data?.adresse}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Date de naissance : {moment(user_details?.date_naissance).format("DD/MM/YYYY")} </Text>
                <Text style={styles.description}>Télèphone : {user_details?.mobile}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Lieux de Naissance : {user_details?.lieu_naissance} </Text>
                <Text style={styles.description}>E-Mail : {user_details?.email}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>N° CNI : {personnel?.n_cin}</Text>
                <Text style={styles.description}>N° Passeport : {personnel?.n_passport}</Text>
            </View>
        </View>
    );
    return <Fragment>{rows}</Fragment>;
}


export default SouscripteurCG;
