import moment from "moment";

export function getExtension (fileName) {
return fileName.split('.').pop(); //"pdf"
}

/**
 * Generate Policy Code => CODE CONTRAT UNIQUE POUR CHAQUE ENTETE
 * @returns 
 */
export function generatePolicyCode (){
    return 'xxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

/**
 * GENERATE POLICY BY TYPE
 */
export function generatePolicyCodeByType(type ,date){

    if(type==="education"){return '220AE'+date+generatePolicyCode()}
    else if(type==="vehicule"){return '590AA'+date+generatePolicyCode()}
    else if(type==="retraite"){return '110AR'+date+generatePolicyCode()}
    else if(type==="voyage"){return '420AV'+date+generatePolicyCode()}
    else if(type==="accident"){return '430IAV'+date+generatePolicyCode()}
    else {return '330AD'+date+generatePolicyCode()}
  
}

/**
 * GENERATE N°Facture
 * @returns 
 */
  // export function generateNFacture (){
  //   return 'FACT-xxxxxxx-xxxx'.replace(/[xy]/g, function(c) {
  //     var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
  //     return v.toString(16);
  //   });
  //   //Create new facture
    
  // }
  export function generateNFacture() {
    const numFacture = 'FACT-xxxxxxx-xxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0;
      var v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  
    // const nouvelleFacture = {
    //   id: id,
    //   user_id: userId,
    //   num_facture: numFacture,
    //   numero_paiement: numeroPaiement,
    //   company_id: companyId,
    //   category_id: categoryId,
    //   montant: montant,
    //   Date_prevu: datePrevu,
    //   Date_paiement: datePaiement
    // };
  
    // fetch('http://localhost:3001/api/v1/factures/', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(nouvelleFacture)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     // Traitement de la réponse de l'API ici
    //     console.log(data);
    //   })
    //   .catch(error => {
    //     // Gestion des erreurs ici
    //     console.error(error);
    //   });
  }
  
  /**
   * GENARATE TRANSACTION ID
   * @returns 
   */
  export function geneateTransactionId (){
    return 'C-xxxxx.xxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }
  
  export function generateCodeParrainage (){
    return 'xxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }