import React from 'react'

export default function PaiementFail() {
  return (
    <div className="page-content-wrapper py-3">
    <div className="custom-container">
      <div className="card">
        <div className="card-body px-5 text-center"><img className="mb-4" src="img/bg-img/no.png" alt="404"/>
          <h4> <br /> Echec de paiement  </h4>
          <p className="mb-4">Nous sommes désolés de vous informer que le temps imparti pour effectuer votre paiement est expiré. Si vous souhaitez toujours effectuer votre achat, veuillez passer une nouvelle commande et assurez-vous de compléter le paiement avant la fin du délai imparti. Si vous avez des questions ou si vous souhaitez plus d'informations, n'hésitez pas à nous contacter. Nous sommes là pour vous aider.</p>
        </div>
      </div>
    </div>
  </div>
  )
}
