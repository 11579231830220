import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify';
import Footer from '../components/shared/Footer'
import Header from '../components/shared/Header'
import SideBar from '../components/shared/SideBar'


export default function MainLayout(props) {
    // # THEME : SET THEME TO LIGHT VERSION 
    const [isDark, setisDark] = useState(Cookies.get("theme")==="light" ? false : true);
    const html = document.querySelector("html");
    isDark ? html.setAttribute("data-theme", "dark") : html.setAttribute("data-theme", "light");

    const handleDark = (isDark, setisDark) => {
        Cookies.set("theme",!isDark===true ? "dark" : "light")
        setisDark(!isDark);
       };
       
    return (
        <div>
             {/* # This code for showing internet connection status */}
            <div className="internet-connection-status" id="internetStatus" />
            <Header isDark={isDark} setisDark={setisDark} handleDark={handleDark}/>
            <SideBar isDark={isDark} setisDark={setisDark} handleDark={handleDark}/>
     
            <div className="page-content-wrapper " style={{marginTop:'70px'}}>
            <ToastContainer />
                <React.Fragment>{props.children}</React.Fragment>
            </div>
            <Footer isDark={isDark} />
        </div>)
}
