/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUser } from "../../Auth/useUser";
import DotLoader from "../../components/loading/DotLoader";
import { getAssureeByID, patchAssuree } from "../../services/assuree/assureeAPI";
import FormAssuree from "./FormAssuree";

export default function EditAssuree() {
  const user = useUser();
  //------------- PARAMETRE -----------
  const { id } = useParams("");
  // # History CALL
  const history = useHistory();
  // -------------- Loading -----------
  const [loading, setLoading] = useState(true);
  const [loadingForm, setloadingForm] = useState(false) //Loader Form

   // ---------------USE FORM ELEMENTS ----------
   const { register, handleSubmit, reset } = useForm() //FORM DATA   
  //--------------- DATA --------------
  const [data, setdata] = useState({})
  //------------- Traduction ---------------
  const [t] = useTranslation("global");

  useEffect(() => {
    getAssureeByID(id).then((result) => {
        const informations = result?.data; //results data
        if (informations?.code === 200) {
            setdata(informations?.data)
        }
    })
        .catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
}, [id])
  // UPDATE DATA INTO FORM 
  useEffect(() => {
    reset(data)
}, [data])

function onFormSubmit(dataRes) {
  setloadingForm(true)
 
  patchAssuree(dataRes).then((result) => {
      if (result?.data?.code === 200) {
          toast(t("Modifié avec succès"), { type: "success", position: "top-right" })
      } else {
          toast(t("Erreur"), { type: "error", position: "top-right" })
      }
  }).catch((err) => {
      console.log(err)
  }).finally(() => {
      setTimeout(
          () =>
              setloadingForm(false), 1200
      )
  })

  return false
}
  return    <div className="container">
  <h4 className="mb-3  mt-3 text-center"> {t("Modifier un(e) assuré(e)")}</h4>

  <div className="row">
    <div className="col-12">
      <div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <FormAssuree register={register} />
            {loading ? (
              <button
                className="btn btn-purple w-100 d-flex align-items-center justify-content-center fz-12"
                disabled
                type="submit"
              >
                <DotLoader />{" "}
              </button>
            ) : (
              <button
                className="btn btn-purple w-100 d-flex align-items-center justify-content-center fz-12"
                type="submit"
              >
                {t("Modifier Assuré(e)")}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  </div>
</div>;
}
