import { useState } from "react";
import Icon from "../../../../components/obligatoire/Icon";

export default function Accidents({
  register,
  t,
  groupedAssuree,
  LISTE_ASSUREE,
  handleChangeAssuree,

}) {

  return (
    <div>
      {LISTE_ASSUREE?.length !== 0 ? (
        <>
          {LISTE_ASSUREE?.map((items, keys) => (
            <div key={keys}>
              <h6 className="text-center mt-2">
                {groupedAssuree[items.assuree_id][0]?.nom +
                  " " +
                  groupedAssuree[items.assuree_id][0]?.prenom}
              </h6>

              <div className="form-group mb-3">
                <label className="mb-2 fz-12">{t("Durée")}</label>
                <select
                  className="form-select fz-12"
                  name="duree"
                  value={items?.duree}
                  onChange={(e) => handleChangeAssuree(e, keys)}
                  required
                >
                  <option value={""}>{t("Choisir")} </option>
                  <option value={1}>{t("1 Mois")}</option>
                  <option value={3}>{t("3 Mois")}</option>
                  <option value={6}>{t("6 Mois")}</option>
                  <option value={12}>{t("12 Mois")}</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <label className="mb-2 fz-12">{t("Formules")}</label>

                <div>
                  <div className="single-plan-check shadow-sm active-effect">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="formule"
                        value={"CLASSIQUE"}
                        onChange={(e) => handleChangeAssuree(e, keys)}
                      />
                      <label className="form-check-label" htmlFor="Individual">
                        {t("FORMULE CLASSIQUE")} :
                        <li className="fz-10 mt-2 mb-1">
                          {t("Frais Pharmaceutiques")} : <b> 100 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Hospitalisation")} : <b> 150 000 FCFA </b>
                        </li>
                        <li className="fz-10 mb-1">
                          {t("Frais funéraires")} : <b> 100 000 FCFA </b>
                        </li>
                        <li className="fz-10 mb-1">
                          {t("Capital décès et invalidité")} :{" "}
                          <b> 1 000 000FCFA </b>
                        </li>
                      </label>
                    </div>
                  </div>
                  <div className="single-plan-check  shadow-sm active-effect">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="formule"
                        value={"GOLD"}
                        onChange={(e) => handleChangeAssuree(e, keys)}
                      />
                      <label className="form-check-label" htmlFor="Team">
                        {t("FORMULE GOLD")}:
                        <li className="fz-10 mt-2 mb-1">
                          {t("Frais Pharmaceutiques")} : <b> 150 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Hospitalisation")} : <b> 200 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Frais funéraires")} : <b> 125 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Capital décès et invalidité")} :{" "}
                          <b> 1 500 000 FCFA </b>
                        </li>
                      </label>
                    </div>
                  </div>
                  <div className="single-plan-check shadow-sm active-effect">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="formule"
                        
                        value={"DIAMOND"}
                        onChange={(e) => handleChangeAssuree(e, keys)}
                      />
                      <label className="form-check-label" htmlFor="Company">
                      {t("FORMULE DIAMOND")} :
                        <li className="fz-10 mt-2 mb-1">
                          {t("Frais Pharmaceutiques")} : <b> 175 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Hospitalisation")} : <b> 250 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Frais funéraires")} : <b> 150 000 FCFA </b>
                        </li>
                        <li className="fz-10  mb-1">
                          {t("Capital décès et invalidité")} :{" "}
                          <b> 2 000 000 FCFA </b>
                        </li>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">
              {t("Durée")} <Icon />{" "}
            </label>
            <select
              className="form-select fz-12"
              {...register("duree")}
              required
            >
              <option value={""}>{t("Choisir")} </option>
              <option value={1}>{t("1 Mois")}</option>
              <option value={3}>{t("3 Mois")}</option>
              <option value={6}>{t("6 Mois")}</option>
              <option value={12}>{t("12 Mois")}</option>
            </select>
          </div>
          <div className=" mb-3">
            <label className="form-label fz-12">Formule</label>
            <div>
              <div className="single-plan-check shadow-sm active-effect">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formule"
                    value={"CLASSIQUE"}
                    {...register("formule", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="Individual">
                    {t("FORMULE CLASSIQUE")} :
                    <li className="fz-10 mt-2 mb-1">
                      {t("Frais Pharmaceutiques")} : <b> 100 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Hospitalisation")} : <b> 150 000 FCFA </b>
                    </li>
                    <li className="fz-10 mb-1">
                      {t("Frais funéraires")} : <b> 100 000 FCFA </b>
                    </li>
                    <li className="fz-10 mb-1">
                      {t("Capital décès et invalidité")} :{" "}
                      <b> 1 000 000FCFA </b>
                    </li>
                  </label>
                </div>
              </div>
              <div className="single-plan-check  shadow-sm active-effect">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formule"
                    value={"GOLD"}
                    {...register("formule", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="Team">
                  {t("FORMULE GOLD")}:
                    <li className="fz-10 mt-2 mb-1">
                      {t("Frais Pharmaceutiques")} : <b> 150 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Hospitalisation")} : <b> 200 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Frais funéraires")} : <b> 125 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Capital décès et invalidité")} :{" "}
                      <b> 1 500 000 FCFA </b>
                    </li>
                  </label>
                </div>
              </div>
              <div className="single-plan-check shadow-sm active-effect">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formule"
                    value={"DIAMOND"}
                    {...register("formule", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="Company">
                  {t("FORMULE DIAMOND")}:
                    <li className="fz-10 mt-2 mb-1">
                      {t("Frais Pharmaceutiques")} : <b> 175 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Hospitalisation")} : <b> 250 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Frais funéraires")} : <b> 150 000 FCFA </b>
                    </li>
                    <li className="fz-10  mb-1">
                      {t("Capital décès et invalidité")} :{" "}
                      <b> 2 000 000 FCFA </b>
                    </li>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
