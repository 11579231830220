import React, { Fragment, useState } from "react";
import Paiement from "../contrats/components/paiement/Paiement";
import { useForm } from "react-hook-form";
import Paiement_specifique from "../contrats/components/paiement/Paiement_specifique";

export default function Cotisation_payment() {
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA
  const data = {};
  const groupedAdresses = {};
  const LISTE_ASSUREE = {};
  const t = "";
  const type = "";
  const id = "";
  const [unpaidFactures, setUnpaidFactures] = useState([]);
  const totalCotisation=""
  return (
    <div className="container">
      <div className="shop-pagination pb-3">
      <div className="container mb-3">
      <div className="card">
    <Fragment>
      <Paiement_specifique
        id={id}
         handleSubmit={handleSubmit}
         register={register}
        data={data}
         watch={watch}
        groupedAdresses={groupedAdresses}
        LISTE_ASSUREE={LISTE_ASSUREE}
        type={type}
         t={t}
         unpaidFactures={unpaidFactures}
         totalCotisation={totalCotisation}
      />
    </Fragment>
    </div>
    </div>
    </div>
    </div>
  );
}
