/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import  { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../Auth/useUser'
import { error_message, success_message } from '../../components/alerte/AlerteMessage'
import DotLoader from '../../components/loading/DotLoader'
import Loader from '../../components/loading/Loader'
import { addTicket } from '../../services/tickets/ticketAPI'
import { getUser } from '../../services/users/usersAPI'

export default function Contacts() {
    const user = useUser()
    var { id } = user.result
    //-----------------HISTORY -------------------
    const history = useHistory("")
    //---------------- PROFIL DATA --------------
    const [data, setData] = useState(null)
    // ---------------USE FORM ELEMENTS ----------
    const { register, handleSubmit, reset } = useForm() //FORM DATA   
    //---------------- LOADING -------------------
    const [loading, setLoading] = useState(true) // Loader Page
    const [loadingForm, setloadingForm] = useState(false) //Loader Form
    //------------- Traduction ---------------
    const [t] = useTranslation("global");
    useEffect(() => {
        getUser(id)
            .then((result) => {
                var info = result?.data?.data
                setTimeout(
                    () =>
                        setData({
                            id: id,
                            nom: info?.nom,
                            prenom: info?.prenom,
                            email: info?.email,
                        }),
                    1200,
                )
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setTimeout(
                    () =>
                        setLoading(false), 1000
                )

            })
    }, [])
    // UPDATE DATA INTO FORM 
    useEffect(() => {
        reset(data)
    }, [data])

    function onFormSubmit(dataRes) {
        setloadingForm(true)
        const element = {}
        Object.assign(element, {
            user_id: dataRes.id,
            description: dataRes.description,
            type: dataRes.type
        })
        addTicket(element).then((result) => {
            if (result?.data?.code === 200) {
                var id = result?.data?.data?.id
                success_message(t("Votre demande est envoyé !"))
                history.push({
                    pathname: "/media_contacts/id=" + id
                })
            } else {
                error_message(t("Erreur"))
            }
        }).catch((err) => {
            console.log(err)
        });


        setloadingForm(false)
    }

    return (
        <div>
            <div className="page-content-wrapper py-3">
                <div className="container">
                    {/* Contact Form */}
                    <div className="card bg-transparent mb-3">
                        {loading ? <Loader /> :
                            <div className="card-body">
                                <h5 className="mb-3 text-center">{t("Contactez-nous")}</h5>
                                <div className="text-center">
                                    <img
                                        className="mb-4 text-center"
                                        src="../img/bg-img/contact.png"
                                        width={"200px"}
                                        alt="Assurance vie"
                                    />
                                </div>
                                <div className="contact-form">
                                    <form onSubmit={handleSubmit(onFormSubmit)}>
                                        <div className="form-group mb-3">
                                            <label className="form-label fz-12">
                                                {t("Nom")}
                                            </label>
                                            <input
                                                className="form-control fz-12"
                                                {...register('nom')}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label fz-12">
                                                {t("Prénom")}
                                            </label>
                                            <input
                                                className="form-control fz-12"
                                                {...register('prenom')}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label fz-12">
                                                {t("E-Mail")}
                                            </label>
                                            <input
                                                className="form-control fz-12"
                                                type="email"
                                                {...register('email')}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="form-label fz-12 " htmlFor="Username">
                                                {t("Type Service")}
                                            </label>
                                            <select className="form-select fz-12" name="topic"  {...register('type')} required>
                                                <option value={"support"}>{t("Support/assistance")}</option>
                                                <option value={"reclamation"}>{t("Réclamation")}</option>
                                                <option value={"service"}>{t("Conseils et informations")}</option>
                                            </select>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label fz-12" htmlFor="email">
                                                {t("Message")}
                                            </label>
                                            <textarea
                                                className="form-control fz-12"
                                                type="text"
                                                cols={30} rows={10}
                                                placeholder="Message ... "
                                                required
                                                {...register('description')}
                                            />
                                        </div>
                                        {loadingForm ? <button className="btn btn-success fz-12 w-100" disabled >{t("En Cours")} <DotLoader />  </button> : <button className="btn btn-purple fz-12 w-100" type='submit'>{t("Envoyer")}   </button>}
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="container">
                    {/* Google Maps */}
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <h5 className="mb-4">{t("Contactez notre call center")} :</h5>
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-telephone me-2" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                    </svg>
                                    {t("Tel Fixe")} : <b className="text-purple">+221 33 827 00 05 </b>  </p>
                                <p> <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-telephone me-2" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>
                                    {t("Tel Mobile")} : <b className="text-purple">+221 78 192 65 65 </b></p>
                                <p className='mb-0'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope-at me-2" viewBox="0 0 16 16">
                                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                                    </svg>
                                    {t("E-Mail")} :
                                    <b className="text-purple">  contact@diokotech.com  </b>
                                </p>
                            </div>
                          
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                        <div className="google-maps">
                                <h5 className="mb-4">{t("Notre localisation")}</h5>
                                <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15435.603137017972!2d-17.4691085!3d14.718201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1177e8884e0aa72d!2sDIOKOTECH!5e0!3m2!1sfr!2stn!4v1658130085024!5m2!1sfr!2stn" allowFullScreen aria-hidden="false" tabIndex={0} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
