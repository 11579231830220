import React from 'react'

export default function DotLoader() {
  return (
   <div className="dot-loader">
  <div className="dot1" />
  <div className="dot2" />
  <div className="dot3" />
</div>
 )
}
