import React from 'react'

export default function Icon() {
  return (
    <> <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    className="bi bi-dot text-danger"
    viewBox="0 0 16 16"
  >
    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
  </svg></>
  )
}
