/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useEffect, useState } from "react";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { calculateAge, comparaisonBetweenTwoDates,} from "../../../../utils/Comparaison";
import { sumCoutPolice, sumPrimeNette, sumPrimeTTC, sumTaxe} from "./FunctionVoyage";
import moment from "moment";
import _ from "lodash";

export default function CalculVoyage({
  category_id,
  groupedAssuree,
  liste,
  dataRes,
  t,
  setLISTE_ASSUREE,
  cout_police,
  prime_nette,
  paramTech,
}) {
  // Results
  const [results, setResults] = useState({
    prime_nette: 0, // PRIME NETTE
    cout_police: 0, // Cout police
    taxe: 0, // taxe
    prime_ttc: 0, //PRIME TTC
  });

  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  var {
    data_naissance_assuree,
    date_depart,
    date_retour,
    pays_destination,
    formule,
  } = dataRes;

  useEffect(() => {
    var age = calculateAge(new Date(data_naissance_assuree));
    /**
     * IF ASSUREE == SOUSCRIPTEUR
     * VERIFIER LA DATE DE DEPART / RETOUR
     * VERIFIER L AGE DU SOUSCRIPTEUR
     */
    if (
      moment(date_depart).format("YYYY-MM-DD") <=
      moment(date_retour).format("YYYY-MM-DD") &&
      parseInt(age) >= 18 &&
      parseInt(age) <= 80 &&
      liste?.length === 0
    ) {
      // CALCULER LA PRIME NETTE / TAXE / PRIME TTC / COUT POLICE

      var duree = comparaisonBetweenTwoDates(
        moment(date_depart).format("YYYY-MM-DD"),
        moment(date_retour).format("YYYY-MM-DD")
      );

      var value_cout = 0;
      var value_prime = 0;

      const found_duree = cout_police?.filter(
        (element) =>
          parseInt(element.duree) >= parseInt(duree) && element.type === formule
      );
      const found_primenette = prime_nette?.filter(
        (element) =>
          element.type === formule && parseInt(element.duree) >= parseInt(duree)
      );
      var taxes = parseFloat(paramTech[0]?.taxes) / 100;

      if (parseInt(age) >= 0 && parseInt(age) <= 17) {
        value_cout = found_duree[0]?.age1;
        value_prime = found_primenette[0]?.age1;
      } else if (parseInt(age) >= 18 && parseInt(age) <= 65) {
        value_cout = found_duree[0]?.age2;
        value_prime = found_primenette[0]?.age2;
      } else if (parseInt(age) >= 66 && parseInt(age) <= 75) {
        value_cout = found_duree[0]?.age3;
        value_prime = found_primenette[0]?.age3;
      } else if (parseInt(age) >= 76 && parseInt(age) <= 80) {
        value_cout = found_duree[0]?.age4;
        value_prime = found_primenette[0]?.age4;
      }
      /**
       * UPDATE TOTAL INTO DATA
       * THIS DATA FOR THE PAYEMENT ELEMENT
       * TOTAL PRIME TTC => LA VALEUR A PAYEE
       */
      Object.assign(dataRes, {
        prime_nette: value_prime,
        cout_police: value_cout,
        taxe: (value_prime + value_cout) * taxes,
        prime_ttc:
          parseFloat(value_prime + value_cout) +
          parseFloat((value_prime + value_cout) * taxes),
      });
      /**
       * RESULTS OF DATA TO DISPLAY INTO TABLE
       * ONLY FOR DISPLAY
       */
      setResults({
        prime_nette: value_prime,
        cout_police: value_cout,
        taxe: (value_prime + value_cout) * taxes,
        prime_ttc:
          parseFloat(value_prime + value_cout) +
          parseFloat((value_prime + value_cout) * taxes),
      });
    } else {
      /**
       * IF ASSUREE IS NOT SOUSCRIPTEUR => SOUSCRIPTEUR VA SOUSCRIRE PLUSIEURS ASSUREES
       * VERIFIER LA DATE DE DEPART / RETOUR
       * VERIFIER L AGE DU SOUSCRIPTEUR
       */

      //CREATE NEW LISTE OF AUSSREE TO DISPLAY DATA INTO CARD AND TO UPDATES STRUCTURE
      var new_liste_assuree = [];

      liste?.map((items) => {
        // GET INFORMATIONS OF ASSUREE
        var info_assuree =
          groupedAssuree[items?.assuree_id] !== undefined
            ? groupedAssuree[items?.assuree_id][0]
            : {};

        // CALCULATE AGE OF EACH ASSUREE (INSURED )
        var age = parseInt(
          calculateAge(new Date(info_assuree?.date_naissance))
        );

        // CALCULER LA PRIME NETTE / TAXE / PRIME TTC / COUT POLICE

        var duree = comparaisonBetweenTwoDates(
          moment(date_depart).format("YYYY-MM-DD"),
          moment(date_retour).format("YYYY-MM-DD")
        );

        var value_cout = 0;
        var value_prime = 0;

        const found_duree = cout_police?.filter(
          (element) =>
            parseInt(element.duree) >= parseInt(duree) &&
            element.type === formule
        );
        const found_primenette = prime_nette?.filter(
          (element) =>
            element.type === formule &&
            parseInt(element.duree) >= parseInt(duree)
        );
        var taxes =
          parseFloat(paramTech[0]?.taxes) / 100;

        if (parseInt(age) >= 0 && parseInt(age) <= 17) {
          value_cout = found_duree[0]?.age1;
          value_prime = found_primenette[0]?.age1;
        } else if (
          parseInt(age) >= 18 &&
          parseInt(age) <= 65
        ) {
          value_cout = found_duree[0]?.age2;
          value_prime = found_primenette[0]?.age2;
        } else if (
          parseInt(age) >= 66 &&
          parseInt(age) <= 75
        ) {
          value_cout = found_duree[0]?.age3;
          value_prime = found_primenette[0]?.age3;
        } else if (
          parseInt(age) >= 76 &&
          parseInt(age) <= 80
        ) {
          value_cout = found_duree[0]?.age4;
          value_prime = found_primenette[0]?.age4;
        }
        //CREATE THE NEW OBJECT TO PUSH INTO NEW LISTE ASSUREE
        var obj = {
          assuree_id: items?.assuree_id,
          nom_assuree: info_assuree?.nom,
          prenom_assuree: info_assuree?.prenom,
          data_naissance_assuree:
            info_assuree?.date_naissance,
          n_passport_assuree: items.n_passport_assuree,
          validite_passeport_assuree:
            items.validite_passeport_assuree,
          prime_nette: value_prime, // TOTAL PRIME NETTE OF INSURED (assuree)
          cout_police: value_cout, // TOTAL COUT POLICE
          taxe: (value_prime + value_cout) * taxes, // TOTAL TAXE
          prime_ttc:
            parseFloat(value_prime + value_cout) +
            parseFloat((value_prime + value_cout) * taxes), // TOTAL PRIME TTC OF INSURED (assuree)
        };
        //CREATE THE NEW LISTE OF ASSUREE
        new_liste_assuree.push(obj);
      })


      setLISTE_ASSUREE(_.uniq(new_liste_assuree));
      // TOTAL PRIME NETTE
      var total_nette = new_liste_assuree.reduce((accumulator, object) => {
        return accumulator + object.prime_nette;
      }, 0);
      // TOTAL TOAL COUT POLICE
      var total_cout = new_liste_assuree.reduce((accumulator, object) => {
        return accumulator + object.cout_police;
      }, 0);
      //TOTAL TAXE
      var total_taxe = new_liste_assuree.reduce((accumulator, object) => {
        return accumulator + object.taxe;
      }, 0);
      // TOTAL PRIME TTC
      var total_ttc = new_liste_assuree.reduce((accumulator, object) => {
        return accumulator + object.prime_ttc;
      }, 0);

      /**
       * UPDATE RESULTS OF DATA
       * GET THE SUM OF PRIME / TAXE / PRIME TTC
       */
      Object.assign(dataRes, {
        prime_nette: total_nette,
        cout_police: total_cout,
        taxe: total_taxe,
        prime_ttc: total_ttc,
      });
    }
  }, []);

  return (
    <div>
      {liste?.length > 0 ? (
        <div>
          <table className="table mt-3">
            <tbody className="text-center">
              {liste?.map((items, keys) => (
                <>
                  <tr>
                    <th colSpan={2} className="text-purple text-center">
                      <b>
                        {t("Assuré(e)")} {keys + 1} :
                        {items?.nom_assuree + " " + items?.prenom_assuree}
                      </b>
                    </th>
                  </tr>

                  <tr>
                    <td className="text-start">{t("Prime Nette")}</td>
                    <td>{numberWithSpaces(Math.round(parseFloat(items?.prime_nette)))}</td>
                  </tr>
                  <tr>
                    <td className="text-start"> {t("Accessoires")} </td>
                    <td>{numberWithSpaces(Math.round(parseFloat(items?.cout_police)))}</td>
                  </tr>
                  <tr>
                    <td className="text-start">{t("Taxe")}</td>
                    <td>{Math.round(items?.taxe)}</td>
                  </tr>
                  <tr>
                    <td className="text-start">{t("Prime TTC")}</td>
                    <td>{numberWithSpaces(Math.round(parseFloat(items?.prime_ttc)))}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <div className="row mb-3">
            <div className=" card bg-purple ">
              <div className="card-body">
                <table className="table mb-0 mt-0">
                  <tbody className="text-white">
                    <tr>
                      <td className="text-end">
                        <b> {t("Total Prime Nette (FCFA)")} </b>
                      </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(sumPrimeNette(liste)))}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">
                        <b> {t("Accessoires")} </b>
                      </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(sumCoutPolice(liste)))}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">
                        <b> {t("Taxe")} </b>
                      </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(sumTaxe(liste)))}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">
                        <b> {t("Total Prime TTC (FCFA)")} </b>
                      </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(sumPrimeTTC(liste)))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mb-3 mt-3">
          <div className=" card bg-purple mt-0 mb-0 ">
            <div className="card-body">
              <table className="table mb-0 mt-0">
                <tbody className="text-white">
                  <tr>
                    <td>
                      <b> {t("Total Prime Nette (FCFA)")} </b>
                    </td>
                    <td className="text-center">
                      {numberWithSpaces(parseInt(results?.prime_nette))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b> {t("Accessoires")} </b>
                    </td>
                    <td className="text-center">
                      {numberWithSpaces(parseInt(results?.cout_police))}
                    </td>
                  </tr>
                  <tr>
                    <td> {t("Total Taxes")}</td>
                    <td className="text-center">
                      {numberWithSpaces(parseInt(results?.taxe))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b> {t("Total Prime TTC (FCFA)")} </b>
                    </td>
                    <td className="text-center">
                      {numberWithSpaces(parseInt(results?.prime_ttc))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
