/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToken } from "../../Auth/useToken";
import ButtonWithIcon from "../../components/buttons/ButtonWithIcon";
import { login_google } from "../../services/signin/LoginAPI";
import { useQueryParams } from "../../utils/useQueryParams";

export default function InterfaceChooseRegister() {
  // # History CALL
  const history = useHistory();
  const [googleOauthUrlt, setGoogleOauthUrl] = useState(null);
  const [token, setToken] = useToken();
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  const { token: oauthToken } = useQueryParams();
  useEffect(() => {
    if (oauthToken) {
      setToken(oauthToken);
      history.push({
        pathname: "/",
      });
    }
  }, [oauthToken, token, history]);

  useEffect(() => {
    login_google()
      .then((result) => {
        const { url } = result?.data?.data;
        setGoogleOauthUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      {/* Login Wrapper Area */}
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          {/** IMAGE LOGIN  */}
          <div className="text-center px-4">
            <img
              className="login-intro-img"
              src="img/bg-img/Sign up-pana.png"
              alt="login_image"
            />
          </div>
          {/* Register Form */}
          <div className="register-form mt-4">
            <h5 className="mb-3 text-center">{t("S'inscrire")}</h5>
            <p className="text-center fz-12">
            {t("La solution")} <b>DiokoAssur</b> {t("vous permet de vous inscrire en trois méthodes:")}
            </p>
            {/** AUTHENTIFICATION WITH GOOGLE / SMS  */}
            <div className="register-form mt-4">
              <ButtonWithIcon
                title={t("S'inscrire par E-Mail")}
                link="/register"
                styles="btn btn-purple text-white  mb-3 w-100"
              />
              {/*                         <h6 className="mb-3 text-center"> {t("Ou")} </h6>
               */}{" "}
              <div className="row">
                <div className="col-12">
                  <ButtonWithIcon
                    title={t("S'inscire par SMS")}
                    link="/register_mobile"
                    styles="btn btn-purple text-white  mb-3 w-100"
                  />

                  {googleOauthUrlt && (
                    <button
                      className="btn btn-purple mb-3 w-100"
                      disabled={!googleOauthUrlt}
                      type="button"
                      onClick={() => {
                        window.location.href = googleOauthUrlt;
                      }}
                    >
                      <i className="bi bi-google me-3" />

                      {t("S'inscrire avec Google")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Login Meta */}
          <div className="login-meta-data text-center">
            <p className="mb-0 mt-4">
              {t("Vous avez déja un compte ?")}{" "}
              <a className=" text-success" href="/login">
                {t("S'authentifier")}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
