/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState } from "react";
//import tableFormule from "../../../../formules/accident/tarifs_assurance_accident.json";
import _ from "lodash";
import Loader from "../../../../components/loading/Loader";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { useEffect } from "react";
import { getFormuleAccident } from "../../../../services/formules/FormulesAPI";

export default function ResumAccident({ category_id, liste, data, t, groupedAssuree, setLISTE_ASSUREE,
}) {
  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  const [show, setshow] = useState(false);
  //NEW LISTE ASSUREE
  const [liste_assuree, setliste_assuree] = useState([]);
  // Results
  const [results, setResults] = useState({
    totalHt: 0, // sum total hors taxes
    taxes: 0, // taxes
    accessoires: 0, // taxes
    primettc: 0, //PRIME EN TTC
  });
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);
  useEffect(() => {
    setshow(true);
    setloaderResult(true);
    var { duree, formule } = data;
    getFormuleAccident().then((result) => {
      if(result?.data?.code === 200){
        var groupedFormule = _.groupBy(result?.data?.data, "FORMULE");
        if (liste?.length !== 0) {
          // 1- CHANGE FORMAT LISTE OF ASSUREE
          var new_liste_assuree = [];
          liste?.map((items) => {
            var table = groupedFormule[items.formule]?.filter(
              (el) => el.DUREE === parseInt(items.duree)
            );
            var PRIME_NETTE = table[0]?.PRIME_NETTE;
            var accessoires = table[0]?.ACCESSOIRES;
            var info_assuree =
              groupedAssuree[items?.assuree_id] !== undefined
                ? groupedAssuree[items?.assuree_id][0]
                : {};
            var obj = {
              assuree_id: items?.assuree_id,
              nom_assuree: info_assuree?.nom,
              prenom_assuree: info_assuree?.prenom,
              data_naissance_assuree: info_assuree?.date_naissance,
              formule: items.formule,
              duree: items.duree,
              totalHt: parseFloat(PRIME_NETTE), // sum total hors taxes
              taxes: (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, // taxes
              accessoires: table[0]?.ACCESSOIRES,
              primettc:
                parseFloat(PRIME_NETTE) +
                parseFloat(accessoires) +
                (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, //PRIME EN TTC
            };
            new_liste_assuree.push(obj);
          });
    
          Object.assign(data, {
            totalHt: new_liste_assuree.reduce((accumulator, object) => {
              return accumulator + object.totalHt;
            }, 0),
            taxe: new_liste_assuree.reduce((accumulator, object) => {
              return accumulator + object.taxe;
            }, 0),
            accessoires: new_liste_assuree.reduce((accumulator, object) => {
              return accumulator + object.accessoires;
            }, 0),
            primettc: new_liste_assuree.reduce((accumulator, object) => {
              return accumulator + object.primettc;
            }, 0),
          });
          setliste_assuree(_.uniq(new_liste_assuree));
          setLISTE_ASSUREE(_.uniq(new_liste_assuree));
        } else {
          var table = groupedFormule[formule]?.filter(
            (el) => el.DUREE === parseInt(duree)
          );
          var PRIME_NETTE = table[0]?.PRIME_NETTE;
          var accessoires = table[0]?.ACCESSOIRES;
          setResults({
            totalHt: parseFloat(PRIME_NETTE) , // sum total hors taxes
            taxes: (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, // taxes
            accessoires: accessoires,
            primettc:
              parseFloat(PRIME_NETTE) +
              parseFloat(accessoires) +
              (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, //PRIME EN TTC
          });
          Object.assign(data, {
            totalHt: parseFloat(PRIME_NETTE), // sum total hors taxes
            taxes: (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, // taxes
            accessoires: accessoires,
            primettc:
              parseFloat(PRIME_NETTE) +
              parseFloat(accessoires) +
              (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, //PRIME EN TTC
          });
        }
      }
    }).catch((err) => {
      console.log(err)
    });

    setloaderResult(false);
  }, []);

  return (
    <div>
      {show && (
        <>
          {liste?.length > 0 ? (
            <>
              <table className="table mt-3">
                {liste_assuree?.map((items, keys) => (
                  <tbody className="text-center" key={keys}>
                    <tr>
                      <th colSpan={2} className="text-purple text-center">
                        <b>
                          {t("Assuré(e)")} {keys + 1} :
                          {items?.nom_assuree + " " + items?.prenom_assuree}
                        </b>
                      </th>
                    </tr>
                    <tr>
                      <td className="text-start">{t("Total HT")} </td>
                      <td>{items.totalHt}</td>
                    </tr>
                    <tr>
                      <td className="text-start">{t("Accessoires")} </td>
                      <td>{items.accessoires}</td>
                    </tr>
                    <tr>
                      <td className="text-start">{t("Taxes")}</td>
                      <td>{Math.round(items.taxes)}</td>
                    </tr>
                    <tr>
                      <td className="text-start">{t("Prime TTC")}</td>
                      <td>{items.primettc}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </>
          ) : (
            <>
              <table className="table  mb-0 table-striped-primary mt-3 mb-3">
                {loaderResult ? (
                  <Loader />
                ) : (
                  <tbody>
                    <tr>
                      <td>{t("Total HT")}</td>
                      <td className="text-center">
                        {numberWithSpaces(results?.totalHt)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Accessoires")} </td>
                      <td className="text-center">
                        {numberWithSpaces(results?.accessoires)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Taxes")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.taxes))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Prime TTC")}</td>
                      <td className="text-center">
                        {numberWithSpaces(results?.primettc)}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </>
          )}
        </>
      )}
    </div>
  );
}
