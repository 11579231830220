/* eslint-disable array-callback-return */
import moment from "moment";
import {
  error_message,
  success_message,
} from "../../../../components/alerte/AlerteMessage";
// #SERVICE
import {
  addContrat,
  addContratAccident,
  addContratAuto,
  addContratDeces,
  addContratEducation,
  addContratRetraite,
  addContratVoyage,
} from "../../../../services/contrats/ContratApi";
import { addPaiement } from "../../../../services/paiement/paiementAPI";
import { sendSMS } from "../../../../services/sms/smsAPI";
import {
  calculateAge,
  comparaisonBetweenTwoDates,
} from "../../../../utils/Comparaison";
import {
  generatePolicyCode,
  generatePolicyCodeByType,
} from "../../../../utils/FileExtension";

/**
 * CALL WEB SERVICE ADD EDUCATION
 * @param {*} data // insert data form education
 */
export function addEducationContrat(data, statut) {
  addContratEducation(data)
    .then((result) => {
      if (result?.data?.code === 200) {
        if (statut === "success") {
          success_message("ajoutée avec succee");
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * ADD CONTRAT RETRAITE
 * @param {*} data
 */
export function addRetraiteContrat(data, statut) {
  addContratRetraite(data)
    .then((result) => {
      if (statut === "success") {
        success_message("ajoutée avec succee");
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
/**
 * CALL WEB SERVICE ADD FORM ACCIDENT
 * @param {*} data
 */
export function addAccidentContrat(data, statut) {
  addContratAccident(data)
    .then((result) => {
      if (result?.data?.code === 200) {
        if (statut === "success") {
          success_message("ajoutée avec succee");
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * CALL WEB SERVICE
 * @param {*} data
 */
export function addAutoContrat(data, statut) {
  addContratAuto(data)
    .then((result) => {
      console.log("web service contrat auto",result)
      if (result?.data?.code === 200) {
        if (statut === "success") {
          success_message("ajoutée avec succee");
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
// FUNCTION CALL WEB SERVICE OF ACCIDENT
export const addAccidentFunction = (
  info,
  LISTE_ASSUREE,
  data,
  mobile,
  type_paiement,
  statut,externalTransactionId
) => {
  Object.assign(info, {
    policy_code: generatePolicyCode(),
    statut: statut,
  });
  if (LISTE_ASSUREE?.length === 0) {
    addContrat(info)
      .then((result) => {
        if (result?.data?.code === 200) {
          addPaiementtoDatabase(
            info?.category_id,
            info,
            null,
            "accident",
            data,
            info?.policy_code,
            type_paiement,
            statut,
            externalTransactionId
          );

          addAccidentContrat({
            contrat_id: result?.data?.data?.id,
            duree: data.duree,
            total_ht: data.totalHt,
            formule: data.formule,
            taxe: data.taxes,
            prime_ttc: data.primettc,
            accessoires : data.accessoires
          },statut)
            .then((result) => {
              if (result?.data?.code === 200) {
                if (statut === "success") {
                  sendSMS({
                    message: "Votre Contrat Accident est payé avec succès ",
                    number: mobile,
                  })
                    .then((result) => {
                      success_message("Contrat Accident crée avec succès ! ");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              } else {
                error_message("Erreur de création du contrat Accident! ");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    LISTE_ASSUREE?.map((items) => {
      Object.assign(info, {
        assuree_id: items.assuree_id,
        beneficiaire: data?.beneficiaire_contrat,
      });
      addContrat(info)
        .then((result) => {
          if (result?.data?.code === 200) {
            addPaiementtoDatabase(
              info?.category_id,
              "accident",
              data,
              info?.policy_code,
              type_paiement,
              statut,externalTransactionId
            );

            addAccidentContrat({
              contrat_id: result?.data?.data?.id,
              duree: items.duree,
              total_ht: items.totalHt,
              formule: items.formule,
              taxe: items.taxes,
              prime_ttc: items.primettc,
            },statut)
              .then((result) => {
                if (result?.data?.code === 200) {
                  sendSMS({
                    message: "Votre Contrat Accident est payé avec succès ",
                    number: mobile,
                  })
                    .then((result) => {
                      success_message("Contrat Accident crée avec succès ! ");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  error_message("Erreur de création du contrat Accident! ");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
};
/**
 * ADD PAIEMENT TO DATA BASE
 */
export const addPaiementtoDatabase = (
  category_id,
  info,
  periodicite,
  type,
  data,
  policy_code,
  type_paiement,
  statut,externalTransactionId
) => {
  addPaiement({
    user_id: data.id,
    category_id: parseInt(category_id),
    policy_code: policy_code,
    company_id: info?.company_id,
    transactionId:externalTransactionId,
    montant:
      type === "education"
        ? data?.capitalacquis
        : type === "accident" || type === "vehicule"
        ? data?.primettc
        : type === "voyage"
        ? data?.prime_ttc
        : type === "retraite"
        ? data?.capitalacquis
        : type === "deces"
        ? data?.prime_annuelle
        : 0,
      status: statut,   
      period_paiement:
      type === "education" || type === "deces" || type === "retraite"
        ? parseInt(periodicite)
        : null,
    type_paiement: type_paiement?.type_paiement,
    data_echeance:
      type === "education" ||
      type === "deces" ||
      type === "retraite" ||
      type === "accident"
        ? moment(data?.date_effet).add(info?.duree, "y").format("YYYY-MM-DD")
        : type === "voyage"
        ? moment(data?.date_depart).add(
            comparaisonBetweenTwoDates(
              moment(data.date_depart).format("YYYY-MM-DD"),
              moment(data.date_retour).format("YYYY-MM-DD")
            ) + 1,
            "d"
          )
        : type === "vehicule"
        ? moment(data?.date_effet)
            .add(data?.duree, "months")
            .format("YYYY-MM-DD")
        : null,
  })
    .then((result) => {
      if (result?.data?.code === 200) {
        //console.log('its ok');
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// FUCTION CALL WEB SERVICE OF EDUCATION
export const addEducationFunction = ( info, LISTE_ASSUREE, data, mobile, date, type_paiement, statut ,externalTransactionId
) => {
  LISTE_ASSUREE?.map((items) => {
    Object.assign(info, {
      assuree_id: items.assuree_id,
      duree: 25 - calculateAge(new Date(items.data_naissance_assuree)),
      policy_code: generatePolicyCodeByType("education", date),
      beneficiaire: data?.beneficiaire_contrat,
      statut: statut,
    });
    addContrat(info)
      .then((result) => {
        if (result?.data?.code === 200) {
          var contrat_id = result?.data?.data?.id;
          addPaiementtoDatabase(
            info?.category_id,
            info,
            items.periodicite,
            "education",
            data,
            info?.policy_code,
            type_paiement,
            statut,externalTransactionId
          );
          addEducationContrat(
            {
              contrat_id: contrat_id,
              periodicite: items.periodicite,
              prime_commercial: items.prime_commercial,
              capital_acquis: items.capitalacquis,
              total_rente: items.rente,
              duree_rente: items.duree,
            },
            statut
          )
            .then((res) => {
              if (res?.data?.code === 200) {
                if (statut === "success") {
                  sendSMS({
                    message: "Votre Contrat education est payé avec succès ",
                    number: mobile,
                  })
                    .then((result) => {
                      success_message("Contrat Education crée avec succès !");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              } else {
                error_message("Erreur de création du contrat education! ");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const ajouter_contrat = (
  info,
  mobile,
  sms,
  message_success,
  message_error,statut
) => {
  addContrat(info)
    .then((result) => {
      if (result?.data?.code === 200) {
        if(statut==="success"){
          sendSMS({
            message: sms,
            number: mobile,
          })
            .then((result) => {
              success_message(message_success);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      
      } else {
        error_message(message_error);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
// FUNCTION CALL WEB SERVICE OF VOYAGE
export const addVoyageFunction = (
  info,
  LISTE_ASSUREE,
  data,
  mobile,
  type_paiement,
  statut,externalTransactionId
) => {
  addContratVoyage({
    //contrat_id: "",
    policy_code: info.policy_code,
    duree:
      comparaisonBetweenTwoDates(
        moment(data.date_depart).format("YYYY-MM-DD"),
        moment(data.date_retour).format("YYYY-MM-DD")
      ) + 1,
    date_depart: moment(data.date_depart).format("YYYY-MM-DD"),
    date_arrive: moment(data.date_retour).format("YYYY-MM-DD"),
    pays_alle: "Sénégal",
    pays_destination: data.pays_destination,
    prime_nette: data.prime_nette,
    cout_police: data.cout_police,
    taxe: data.taxe,
    prime_ttc: data.prime_ttc,
    statut: statut,
  })
    .then((result) => {
      if (result?.data?.code === 200) {
        addPaiementtoDatabase(
          info?.category_id,
          info,
          null,
          "voyage",
          data,
          info?.policy_code,
          type_paiement,
          statut,externalTransactionId
        );
        if (LISTE_ASSUREE.length !== 0) {
          LISTE_ASSUREE?.map((items) => {
            Object.assign(info, {
              assuree_id: items.assuree_id,
              beneficiaire: data?.beneficiaire_contrat,
              duree:
                comparaisonBetweenTwoDates(
                  moment(data.date_depart).format("YYYY-MM-DD"),
                  moment(data.date_retour).format("YYYY-MM-DD")
                ) + 1,
            });
            ajouter_contrat(
              info,
              mobile,
              "Votre Contrat Voyage est payé avec succès ",
              "Contrat Voyage crée avec succès !",
              "Erreur de création du contrat Accident! ",statut
            );
          });
        } else {
          ajouter_contrat(
            info,
            mobile,
            "Votre Contrat Voyage est payé avec succès ",
            "Contrat Voyage crée avec succès !",
            "Erreur de création du contrat Accident! ",statut
          );
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// FUNCTION CALL WEB SERIVCE OF AUTO
export const addAutoFunction = (
  info,
  LISTE_ASSUREE,
  data,
  mobile,
  type_paiement,
  statut,externalTransactionId,
  contract
) => {
  console.log("++++++")
  console.log("++++++")
  console.log("++++++",
  info,
  data,
  statut,
  LISTE_ASSUREE,
  contract)
  Object.assign(info, {
    policy_code: generatePolicyCode(),
  });
  if (LISTE_ASSUREE?.length !== 0) {
    LISTE_ASSUREE?.map((items) => {
      Object.assign(info, {
        assuree_id: items.assuree_id,
        beneficiaire: data?.beneficiaire_contrat,
        statut: statut,
      });
      addContrat(info)
        .then((result) => {
          if (result?.data?.code === 200) {
            addPaiementtoDatabase(
              info?.category_id,
              info,
              null,
              "vehicule",
              data,
              info?.policy_code,
              type_paiement,
              statut,externalTransactionId
            );
              console.log("add paiement in database :",  info?.category_id,
              info,
              null,
              "vehicule",
              data,
              info?.policy_code,
              type_paiement,
              statut,externalTransactionId)
            var contrat_id = result?.data?.data?.id;
            var valeur_venale = parseFloat(
              data?.valeur_venale?.replace(/\s+/g, "")
            );
              console.log("valeur vinale poue automobile:",valeur_venale,statut)
            addAutoContrat({
              contrat_id: contrat_id,
              duree: data.duree,
              prime_ttc: items.primettc,
              matricule: data?.matricule !== undefined && data?.matricule,
              chassis: data?.chassis !== undefined && data?.chassis,
              marque: data?.marque,
              genre: data?.genre,
              type: data?.modele,
              energie: data?.energie,
              puissance: data?.puissance,
              nbr_place: data?.nbr_place,
              remorque: data?.remorq,
              valeur_venale: valeur_venale,
              fga: data?.fga,
              tca: data?.taxes,
              accessoires: data?.accessoires,
              prime_nette: data?.primenette,
            },statut)
              .then((res) => {
                if (res?.data?.code === 200) {
                  sendSMS({
                    message: "Votre Contrat Auto est payé avec succès ",
                    number: mobile,
                  })
                    .then((result) => {
                      success_message("Contrat auto crée avec succès ! ");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  error_message("Erreur de création du contrat auto! ");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          console.log("add auto contrat ")
          console.log("add auto contrat ")
          console.log("add auto contrat ",statut)
        })
        .catch((err) => {
          console.log(err);
        });
    });
  } else {
    addContrat(info)
      .then((result) => {
        if (result?.data?.code === 200) {
          addPaiementtoDatabase(
            info?.category_id,
            info,
            null,
            "vehicule",
            data,
            info?.policy_code,
            type_paiement,
            statut,externalTransactionId
          );
            console.log("add contrat automobile version 2", info?.category_id,
            info,
            null,
            "vehicule",
            data,
            info?.policy_code,
            type_paiement,
            statut,externalTransactionId)
          var contrat_id = result?.data?.data?.id;
          var valeur_venale = parseFloat(
            data?.valeur_venale?.replace(/\s+/g, "")
          );
          addAutoContrat({
            contrat_id: contrat_id,
            duree: data.duree,
            prime_ttc: data.primettc,
            matricule: data?.matricule !== undefined && data?.matricule,
            chassis: data?.chassis !== undefined && data?.chassis,
            marque: data?.marque,
            genre: data?.genre,
            type: data?.modele,
            energie: data?.energie,
            puissance: data?.puissance,
            nbr_place: data?.nbr_place,
            remorque: data?.remorq,
            valeur_venale: valeur_venale,
            fga: data?.fga,
            tca: data?.taxes,
            accessoires: data?.accessoires,
            prime_nette: data?.primenette,
          },statut)
            .then((res) => {
              if (res?.data?.code === 200) {
                sendSMS({
                  message: "Votre Contrat Auto est payé avec succès ",
                  number: mobile,
                })
                  .then((result) => {
                    success_message("Contrat auto crée avec succès ! ");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                error_message("Erreur de création du contrat auto! ");
              }
            }
            )
            .catch((err) => {
              console.log(err);
            });
            console.log("accessoire of auto",data?.accessoires)
            console.log("valeur_venale of auto",data?.valeur_venale)
            console.log("fga of auto",data?.fga)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

/**
 * FUNCTION ADD RETRAITE WITH DIFFEREENT FORM
 * @param {*} info
 * @param {*} LISTE_ASSUREE
 * @param {*} data
 */
export const addRetraiteFunction = (
  info,
  LISTE_ASSUREE,
  data,
  mobile,
  type_paiement,
  statut,externalTransactionId
) => {
  Object.assign(info, {
    policy_code: generatePolicyCode(),
    duree: data.duree_contrat,
    statut: statut,
  });
  if (LISTE_ASSUREE?.length === 0) {
    addContrat(info)
      .then((result) => {
        if (result?.data?.code === 200) {
          addPaiementtoDatabase(
            info?.category_id,
            info,
            data?.periodicite,
            "retraite",
            data,
            info?.policy_code,
            type_paiement,
            statut,externalTransactionId
          );

          addRetraiteContrat({
            contrat_id: result?.data?.data?.id,
            prime_commercial: parseFloat(data.prime_commercial).toFixed(2),
            periodicite: data.periodicite,
            duree_rente: data.durre_rente,
            capital_garantie: data?.capitalacquis,
            total_rente: data?.rente,
          }, statut)
            .then((result) => {
              if (result?.data?.code === 200) {
                if (statut === "success") {
                sendSMS({
                  message: "Votre Contrat Accident est payé avec succès ",
                  number: mobile,
                })
                  .then((result) => {
                    success_message("Contrat Accident crée avec succès ! ");
                  })
                  .catch((err) => {
                    console.log(err);
                  });}
              } else {
                error_message("Erreur de création du contrat Accident! ");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    LISTE_ASSUREE?.map((items) => {
      Object.assign(info, {
        assuree_id: items.assuree_id,
        beneficiaire: data?.beneficiaire_contrat,
      });
      addContrat(info)
        .then((result) => {
          if (result?.data?.code === 200) {
            addPaiementtoDatabase(
              info?.category_id,
              info,
              data?.periodicite,
              "retraite",
              data,
              info?.policy_code,
              type_paiement,
              statut,externalTransactionId
            );

            addRetraiteContrat({
              contrat_id: result?.data?.data?.id,
              prime_commercial: parseFloat(data.prime_commercial).toFixed(2),
              periodicite: data.periodicite,
              duree_rente: data.durre_rente,
              duree_cotisation: data.duree_contrat,
              capital_garantie: data?.capitalacquis,
              total_rente: data?.rente,
            }, statut)
              .then((result) => {
                if (result?.data?.code === 200) {
                  if (statut === "success") {
                  sendSMS({
                    message: "Votre Contrat Retraite est payé avec succès ",
                    number: mobile,
                  })
                    .then((result) => {
                      success_message("Contrat Retraite crée avec succès ! ");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  }
                } else {
                  error_message("Erreur de création du contrat Retraite! ");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
};

/**
 * ADD DECES FUNCTION
 * @param {*} info
 * @param {*} LISTE_ASSUREE
 * @param {*} data
 */
export const addDecesFunction = (
  info,
  LISTE_ASSUREE,
  data,
  mobile,
  type_paiement,
  statut,externalTransactionId
) => {
  Object.assign(info, {
    policy_code: generatePolicyCode(),
    statut: statut,
  });
  if (LISTE_ASSUREE?.length !== 0) {
    LISTE_ASSUREE?.map((items) => {
      Object.assign(info, {
        assuree_id: items.assuree_id,
        beneficiaire: data?.beneficiaire_contrat,
      });
      addContrat(info).then((result) => {
        if (result?.data?.code === 200) {
          addPaiementtoDatabase(
            info?.category_id,
            info,
            items.periodicite,
            "deces",
            data,
            info?.policy_code,
            type_paiement,
            statut,
            externalTransactionId
          );
          addContratDeces({
            contrat_id: result?.data?.data?.id,
            cout_police: data.cout_police,
            taux_bancaire: data.taux_bancaire,
            duree_cotisation: data.cotisation,
            capital_acquis: data?.capital,
            prime_pure: data?.prime_pure,
            frais_gestion: data?.frais_gestion,
            frais_acquisation: data?.frais_acquisition,
            prime_commercial: data?.prime_commerciale,
            prime_annuelle: data?.prime_annuelle,
            periodicite: parseInt(items?.periodicite),
          })
            .then((result) => {
              if (result?.data?.code === 200) {
                if (statut === "success") {
                  sendSMS({
                    message: "Votre Contrat Décès est payé avec succès ",
                    number: mobile,
                  })
                    .then((result) => {
                      success_message("Contrat Décès crée avec succès ! ");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              } else {
                error_message("Erreur de création du contrat Décès! ");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    });
  } else {
    addContrat(info).then((result) => {
      if (result?.data?.code === 200) {
        addPaiementtoDatabase(
          info?.category_id,
          info,
          data?.periodicite,
          "deces",
          data,
          info?.policy_code,
          type_paiement,
          statut,externalTransactionId
        );

        addContratDeces({
          contrat_id: result?.data?.data?.id,
          cout_police: data.cout_police,
          taux_bancaire: data.taux_bancaire,
          duree_cotisation: data.cotisation,
          capital_acquis: data?.capital,
          prime_pure: data?.prime_pure,
          frais_gestion: data?.frais_gestion,
          frais_acquisation: data?.frais_acquisition,
          prime_commercial: data?.prime_commerciale,
          prime_annuelle: data?.prime_annuelle,
          periodicite: parseInt(data?.periodicite),
        })
          .then((result) => {
            if (result?.data?.code === 200) {
              if (statut === "success") {
                sendSMS({
                  message: "Votre Contrat Décès est payé avec succès ",
                  number: mobile,
                })
                  .then((result) => {
                    success_message("Contrat Décès crée avec succès ! ");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
             
            } else {
              error_message("Erreur de création du contrat Décès! ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }
};
