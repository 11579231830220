/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../../components/loading/Loader'
import { getEventById } from '../../../services/evenements/eventAPI'
import parse from "html-react-parser";
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie';

export default function DetailsEvents() {
    const { id } = useParams("")
    //---------- DETAILS NEWS -----------
    const [details, setdetails] = useState({})
    var { titre,titre_en, description,description_en, url, image, date } = details
    // ----- Language global selectionné
    const language = Cookies.get('locale')
    //---------- Loading -----------------
    const [Loading, setLoading] = useState(true)

    //------------- Traduction -------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getEventById(id)
            .then((result) => {
                if (result?.data?.code === 200) {
                    setdetails(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setTimeout(
                    () =>
                        setLoading(false), 1200
                )
            })
    }, [])

    return (
        <div className='container'>

            {Loading ? <Loader /> :
                <div className="page-content-wrapper">
                    <div className="container">
                        <div className="pt-3 d-block" />
                        <div className="blog-details-post-thumbnail position-relative">
                            <img className="w-100 rounded-lg" src={image === null ? "img/bg-img/6274.jpg" : process.env.REACT_APP_IMAGE + image} alt="blog" />
                        </div>
                    </div>
                    <div className="blog-description py-3">
                        <div className="container">
                            <span className="badge bg-purple rounded-pill mb-2 d-inline-block">{moment(date).format("DD MMMM YYYY ")}</span>
                            <h3 className="mb-3 fz-12">{language==="fr" ? titre : titre_en}</h3>
                            <p className="fz-12">{language==="fr" ? parse(description) : parse(description_en) }</p>
                            {url !== null && <div className='float-end'>
                                <a className='text-purple' href={url}><b> {t("Lien de l'article")} </b>   </a>
                            </div>}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
