import { View,Text ,StyleSheet} from "@react-pdf/renderer";
import { Fragment } from "react";
import { numberWithSpaces } from "../../../utils/SessionUtiles";

const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 9,
        marginBottom:1

    },
    description1: {
        width: "30%",
        textAlign:"left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
    fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"

    },
    description: {
        width: "40%",
        textAlign:"justify",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"
    },
    description3: {
        width: "30%",
        textAlign:"justify",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"
    },
});

export default function Identification({ data}) {
    const rows = (
        <View style={styles.container}>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Immat. N°: {data?.auto?.matricule}</Text>
            <Text style={styles.description}>Carrosserie: {"-"} </Text>
            <Text style={styles.description3}>Charge Utile:{"-"}</Text>
            </View>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Chassis: {data?.auto?.chassis}</Text>
            <Text style={styles.description}>Energie:  {data?.auto?.energie} </Text>
            <Text style={styles.description3}>Poids Vide: {"-"}</Text>
            </View>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Marque: {data?.auto?.marque}</Text>
            <Text style={styles.description}>Puissance: {data?.auto?.puissance} </Text>
            <Text style={styles.description3}>Val. à Neuf : {"-"}</Text>
            </View>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Genre: {"-"}</Text>
            <Text style={styles.description}>Nbre Place : {data?.auto?.nbr_place} </Text>
            <Text style={styles.description3}>VAl. Vénale: {numberWithSpaces(data?.auto?.valeur_venale)}</Text>
            </View>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Modéle: {data?.auto?.type}</Text>
            <Text style={styles.description}>Remorque: {data?.auto?.remorque} </Text>
            </View>
        </View>
    );
    return <Fragment>{rows}</Fragment>;
}
