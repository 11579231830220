import { View,Text ,StyleSheet} from "@react-pdf/renderer";
import { Fragment } from "react";
import { numberWithSpaces } from "../../../utils/SessionUtiles";
const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 9,
        marginBottom:1
    },
    description1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 9,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"

    },
    description: {
        width: "50%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
    description3: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal",
        textTransform:"capitalize"
    },
});
export default function TableauCategories({ type , info,user_details,personnel}) {
    const rows = (
        <View style={styles.container}>
            <View style={styles.row} wrap>
            <Text style={styles.description1}>Prime Nette :{numberWithSpaces(Math.round(info?.prime_nette))}</Text>
                <Text style={styles.description}> Prime TTC :  {numberWithSpaces(Math.round(info?.prime_ttc))}</Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description1}>FGA : {numberWithSpaces(Math.round(info?.fga))} </Text>
            <Text style={styles.description1}>TCA : {numberWithSpaces(Math.round(info?.tca))} </Text>
            </View>
            <View style={styles.row}>
            <Text style={styles.description}>Accessoires : {numberWithSpaces(Math.round(info?.accessoires))}</Text>
            </View>
        </View>
    );
    return <Fragment>{rows}</Fragment>;
}
