import React from "react";
import ChooseLg from "../languages/ChooseLg";
import BackToPreviousPage from "../previouspage/BackToPreviousPage";

export default function HeaderEmptyLayout({ isDark }) {
  return (
    <>
      <div className="header-content  d-flex align-items-center justify-content-between">
        {/* Back Button */}
        <BackToPreviousPage />
        {/* Page Title */}
        <div className="page-heading">
          {isDark ? <div className="logo-wrapper mt-3"><div className="text-center"><img src="img/core-img/logo-dark.png" alt="logo" width={"30%"} /></div></div> : <div className="logo-wrapper mt-3"><div className="text-center"><img src="img/core-img/logo.png" alt="logo" width={"30%"} /></div></div>}
        </div>
        {/* Settings */}
        <div className="setting-wrapper">
          <ChooseLg />
          <span />
        </div>
      </div>
    </>


  );
}
