import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { NumberToLetter } from "convertir-nombre-lettre";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 12,
    fontFamily:'Arial',
    fontStyle:"normal"
  },
  reportTitle: {
    fontSize: 9,
    textAlign: "left",
    fontFamily:'Arial',
    fontStyle:"normal",
    letterSpacing: 1,
    width: "100%",
    marginBottom :5
  },
  text : {
    textDecoration:"underline"
  }
});

const InvoiceThankYouMsg = ({ type, data, info }) => (
  <>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>
        Arrêtée la présente facture à la somme de:
       <Text style={styles.text} >{type === "education" &&
          NumberToLetter(parseFloat(info?.capital_acquis))}
        {type === "deces" && NumberToLetter(Math.round(info?.prime_pure))}
        {type === "accident" && NumberToLetter(Math.round(info?.prime_ttc))}
        {type === "retraite" &&
          NumberToLetter(Math.round(info?.capital_garantie))}
        {type === "voyage" && NumberToLetter(Math.round(data?.prime_ttc))}
        {type === "vehicule" &&
          NumberToLetter(Math.round(data?.auto?.prime_ttc))}{" "}
          </Text>
        FCFA
      </Text>
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>
        Terme et conditions: Conformément à l'Article 13 du Code CIMA, "la prime
        est payable au comptant. La prise d'effet du contrat est subordonnée au
        paiement de la prime par le souscripteur." Conformément à l’Article 516
        du Code des impôts relatif au timbre des quittances, "Tout règlement en
        espèces supérieur à 100 000 FCFA supportera un timbre de 1%."
      </Text>
    </View>
  </>
);

export default InvoiceThankYouMsg;
