import { useTranslation } from 'react-i18next';
import Produits from './Produits';
import Cookies from 'js-cookie';

export default function NosProduits({titre}) {
    //------- INIT LANGAGE 
    const language = Cookies.get('locale')
    //------------- Traduction ---------------
    const [t] = useTranslation("global");


    return (
        <div className="page-content-wrapper py-3">
            <div className="container">
                <h3 className='text-center mb-2 display-3'> {t("Nos produits")}</h3>
             {/*    <p className="lead mb-0 text-center fz-14">
                    DIOKOTECH offre des réponses adaptées à ces besoins, au profit des porteurs de projets et entreprises opérant au Sénégal et de la région.
                </p> */}
                <Produits t={t} language={language} titre={titre}/>
            </div>
        </div>

    )
}
