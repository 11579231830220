/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useToken } from '../../Auth/useToken';
import DotLoader from '../../components/loading/DotLoader';
import BackToPreviousPage from '../../components/previouspage/BackToPreviousPage';
import { login_phone } from '../../services/signin/LoginAPI';

export default function ValidationSMS() {
    //URL PARAMS 
    const { mobile } = useParams("")
    //# HISTORY
    const history = useHistory()
    // # SUBMIT FORM ELEMENTS
    const { register, handleSubmit, formState: { errors } } = useForm();
    //----------- is valid or invalid code -----------------
    const handleError = (errors) => { };
    //-----------VERIF AND SAVE TOKEN -------
    const [token, setToken] = useToken()
    //------------- Traduction ---------------
    const [t] = useTranslation("global");
    // -------------- Loading -----------
    const [loading, setLoading] = useState(false);
    /**
     * Handle AUTH SMS  : OnSubmit Form function
     * @param {*} data
     */
    const handleAuthSMS = (data) => {
        setLoading(true)
        var { code } = data
        login_phone({ mobile: mobile, code: code })
            .then((result) => {
                if (result?.data?.code === 200) {
                    toast(t("Bienvenue "), {
                        type: "success",
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    // Redirect To home page
                    setToken(result?.data?.data?.token)
                    history.push({
                        pathname: "/",
                    });
                } else if (result === 500) {
                    toast(t("Code Invalide"), {
                        type: "error",
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false)
                }

            }).catch((err) => {
                console.log(err)
            });
    };
    /**
     * REGISTER OPTIONS
     */
    const registerOptions = {

        code: {
            required: {
                value: true,
                message: t("Please add correct code number!")
            },
            pattern: {
                value: /^[0-9]/,
                message: "Format of First code is not valid (only Number),please try again"
            }
        },
        code2: {
            required: {
                value: true,
                message: t("Please add correct code number!")
            },
            pattern: {
                value: /^[0-9]/,
                message: "Format of Second code is not valid (only Number), please try again!"
            }
        },
        code3: {
            required: {
                value: true,
                message: t("Please add correct code number!")
            },
            pattern: {
                value: /^[0-9]/,
                message: "Format of Third code is not valid (only Number),  please try again!"
            }
        },
        code4: {
            required: {
                value: true,
                message: t("Please add correct code number!")
            },
            pattern: {
                value: /^[0-9]/,
                message: "Format of Fourth code is not valid (only Number), please try again!"
            }
        },
        code5: {
            required: {
                value: true,
                message: t("Please add correct code number!")
            },
            pattern: {
                value: /^[0-9]/,
                message: "Format of Fifth code is not valid (only Number), pleasetry again!"
            }
        },
        code6: {
            required: {
                value: true,
                message: t("Please add correct code number!")
            },
            pattern: {
                value: /^[0-9]/,
                message: "Format of Sixth code is not valid, pleasetry again!"
            }
        }
    }
    return (
        <div>

            <div className="login-wrapper d-flex align-items-center justify-content-center">
                <div className="custom-container">
                    <div className="text-center"><img className="mx-auto mb-4 d-block" src="img/bg-img/38.png" alt />
                        <h3>{t("Verify Phone Number")}</h3>
                        <p className="mb-4">{t("Enter the OTP code sent to")}<strong className="ms-1">{mobile}</strong></p>
                    </div>
                    {/* OTP Verify Form */}
                    <div className="otp-verify-form mt-4">
                        <form onSubmit={handleSubmit(handleAuthSMS, handleError)}>
                           {/*  <div className="input-group mb-3 otp-input-group">
                                <input className="form-control" type="text" {...register("code1", registerOptions.code1)} placeholder="-" maxLength="1" />
                                <input className="form-control" type="text" {...register("code2", registerOptions.code2)} placeholder="-" maxLength="1" />
                                <input className="form-control" type="text" {...register("code3", registerOptions.code3)} placeholder="-" maxLength="1" />
                                <input className="form-control" type="text" {...register("code4", registerOptions.code4)} placeholder="-" maxLength="1" />
                                <input className="form-control" type="text" {...register("code5", registerOptions.code5)} placeholder="-" maxLength="1" />
                                <input className="form-control" type="text" {...register("code6", registerOptions.code6)} placeholder="-" maxLength="1" />

                            </div> */}
                            <div id="divOuter">
                                <div id="divInner">
                                    <div className="input-group mb-3 otp-input-group">
                                        <input className="form-control" id="partitioned" {...register("code", registerOptions.code)}  type="text" maxLength={6} />
                                    </div>
                                </div>
                            </div>


                            <small className="text-danger fz-14 mb-2">
                                {errors?.code1 ? errors.code1.message : errors?.code2 ? errors.code2.message : errors?.code3 ? errors.code3.message : errors?.code4 ? errors.code4.message : errors?.code5 ? errors.code5.message : errors?.code6 && errors.code6.message}
                            </small>
                            {loading ? <button className="btn btn-purple w-100" disabled><DotLoader /> </button> : <button className="btn btn-purple w-100" type="submit">{t("Vérifier le code")}</button>}
                        </form>
                    </div>
                    {/* Term & Privacy Info */}
                    <div className="login-meta-data text-center">
                        <p className="mt-3 mb-0">{t("Don't received the OTP?")}<span className="otp-sec ms-1" id="resendOTP" /></p>
                    </div>
                </div>
            </div>
        </div>


    )
}
