import React, { useState } from "react";
import Icon from "../../components/obligatoire/Icon";

export default function CodePIN({ t ,register }) {
    //------------- PASSWORD TYPE ------------
    const [password1, setPassword1] = useState("password");
    const [password2, setPassword2] = useState("password");

    // UPDATE TYPE OF INPUT PASSWORD
    const updateType = (type) => {
        if (type === "p1") {
            if (password1 === "password") {
                setPassword1("text");
            } else {
                setPassword1("password");
            }
        } else if (type === "p2") {
            if (password2 === "password") {
                setPassword2("text");
            } else {
                setPassword2("password");
            }
        }
    };

    return <div>
         {/** ----------------------------------- IDENTIFICATION  ----------------------------------- */}
         <h5 className="mt-2 mb-4 text-center fz-16">
              {t("Authentification par Code PIN")}
            </h5>

            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleTextarea1">
                {t("Code PIN")} <Icon />
              </label>
              <div className="form-group position-relative">
                <input
                  className="form-control fz-12"
                  id="psw-input"
                  type={password1}
                  {...register("code_PIN_1")}
                  placeholder="Code PIN"
                  required
                />
                <div
                  className="position-absolute fz-12"
                  id="password-visibility"

                  onClick={() => updateType("p1")}
                >
                  {password1 === "password" ? (
                    <i className="bi bi-eye" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleTextarea1">
               {t("Confirmer Code PIN")}
                <Icon />
              </label>
              <div className="form-group position-relative">
                <input
                   className="form-control fz-12"
                  id="psw-input"
                  type={password2}
                  {...register("code_PIN_2")}
                  placeholder="Code PIN"
                  required
                />
                <div
                  className="position-absolute"
                  id="password-visibility"
                  onClick={() => updateType("p2")}
                >
                  {password2 === "password" ? (
                    <i className="bi bi-eye" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
              </div>
            </div>
    </div>;
}
