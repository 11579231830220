import { useState } from "react";
import { useUser } from "../../../../Auth/useUser";

export default function MoyenPaiement({ watch, register, data ,t ,paiement,mobile,setmobile }) {
  const user = useUser();
  const [phone, setPhone] = useState("");
  const handleInputChange = (e) => {
    setPhone(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Faire quelque chose avec le numéro de téléphone mobile
    console.log("Numéro de téléphone mobile :", mobile);
  };
  // const handleChange = (e) => {
  //   setPhone((prevPhone) => prevPhone + e.target.value);
  // };
  var id_user = user?.result?.id; //id of connected user
  var {  nom, prenom, gender } = user.result;
  const BankCardForm = () =>  <div style={{ border: "1 solid #000" }}>
  <div className="form-group mb-3">
    <label className="form-label me-2" htmlFor="portfolio">
      <b>{t("Numéro de la carte")}</b>
    </label>
    <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1">
        <i className="bi bi-credit-card text-primary" />
      </span>
      <input
        className="form-control"
        type="text"
        {...register("cardNumber")}
        placeholder="numero de la carte"
        required
        
      />
    </div>
  </div>
  <div className="form-group mb-3">
    <label className="form-label me-2" htmlFor="portfolio">
      <b>{t("Expiration")} </b>
    </label>
    <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1">
        <i className="bi bi-clock-history text-primary" />
      </span>
      <input
        className="form-control"
        type="text"
        {...register("cardExpireMonth")}
        placeholder="MM"
        required
      />
      <span className="input-group-text" id="basic-addon1">
        /
      </span>
      <input
        className="form-control"
        type="text"
        {...register("cardExpireYear")}
        placeholder="YY"
        required
      />
    </div>
  </div>
  <div className="form-group mb-3">
    <label className="form-label me-2" htmlFor="portfolio">
      <b>{t("CVC")}</b>
    </label>
    <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1">
        <i className="bi bi-credit-card-2-front text-primary" />
      </span>
      <input
        className="form-control"
        type="text"
        {...register("cardCVC")}
        required
        placeholder="CVC"
      />
    </div>
  </div>
  <div className="form-group mb-3">
    <label className="form-label me-2" htmlFor="portfolio">
      <b>{t("Card Type")}</b>
    </label>
    <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1">
        <i className="bi bi-credit-card-2-front text-primary" />
      </span>
      <select
      className="form-select fz-12"
      {...register("cardType")}
      required
    >
      <option value={""}>{t("Choisir")} </option>
      <option value={"VISA"}>Visa</option>
      <option value={"MASTERCARD"}>MASTERCARD </option>
      <option value={"VISA_ELECTRON"}>Visa Electron</option>
    </select>
    
    </div>
  </div>
  </div>

const OthersCardForm = () =>   <div style={{ border: "1 solid #000" }}>
<div className="form-group mb-3">
  <label className="form-label me-2" htmlFor="portfolio">
    <b>{t("Phone")}</b>
  </label>
  <div className="input-group mb-3">
    <span className="input-group-text" id="basic-addon1">
      <i className="bi bi-credit-card text-primary" />
    </span>
    <input
       className="form-control"
      type="text"
     
       {...register("phone")}
      value={mobile}
      onChange={(e) => setPhone(e.target.value)}
      // onChange={(e) => {
      //   const newValue = e.target.value;
      //   if (newValue.length === mobile.length + 5) {
      //     setmobile(newValue);
      //   }
      // }}
     
    />
  </div>
</div>
 {/* <input type="text" 
 value={mobile}
  onChange={(e) => setPhone(e.target.value)}/> */}
<div className="form-group mb-3">
  <label className="form-label me-2" htmlFor="portfolio">
    <b>{t("Souscripteur")}</b>
  </label>
  <div className="input-group mb-3">
    <span className="input-group-text" id="basic-addon1">
      <i className="bi bi-credit-card text-primary" />
    </span>
    <input
      className="form-control"
      type="text"
      {...register("Name")}
      value={nom + " " + prenom}
      placeholder=""
      disabled
    />
  </div>
</div>
</div>
  return (
    <div>
      {watch("type_paiement") === "BANK_CARD_API_CASH_OUT" && (
       <BankCardForm />
      )}
      {watch("type_paiement") === "WIZALL_SN_API_CASH_OUT" && (
      <OthersCardForm />
      )}
      {watch("type_paiement") === "WAVE_SN_API_CASH_OUT" && (
       <OthersCardForm />
      )}
      {watch("type_paiement") === "ORANGE_SN_API_CASH_OUT" && (
      <OthersCardForm />
      )}
      {watch("type_paiement") === "FREE_SN_WALLET_CASH_OUT" && (
      <OthersCardForm />
      )}
      {watch("type_paiement") === "EXPRESSO_SN_WALLET_CASH_OUT" && (
       <OthersCardForm />
      )}
    </div>
  );
}
