/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
// # Traduction Library
import { useTranslation } from "react-i18next";
// EXTRACT INFO USER FROM LOCAL STORAGE
import Loader from "../../../components/loading/Loader";
import _ from "lodash";
import { useUser } from "../../../Auth/useUser";
import ModalSaisieCode from "../../securite/ModalSaisieCode";
// # SERVICES
import { getUser } from "../../../services/users/usersAPI";
import { getListeAssuree } from "../../../services/assuree/assureeAPI";
import { deleteContratById, getContratByUser } from "../../../services/contrats/ContratApi";
import CardContrats from "./CardContrats";
import EmptyFolder from "./EmptyFolder";
import Cookies from "js-cookie";

export default function GestionsContrat() {
  //LISTE DES CONTRATS
  // eslint-disable-next-line no-unused-vars
  const [LISTE, setLISTE] = useState([]); //INITIAL LISTE
  const [SaveLISTE, setSaveLISTE] = useState([]); // SAUVEGARDE DE LA LISTE INIT POUR LA RECUPERATION EN CAS DE FILTRE
  // LISTE OF UNIQ POLICY
  const [policyListe, setpolicyListe] = useState([]);
  const [groupedListe, setgroupedListe] = useState([]);
  //LISTE ASSUREE
  const [ASSUREE, setASSUREE] = useState([]);
  // show Modal CODE PIN -----------
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  //------------- FILTRE CONTRAT-----------
  const [filte, setfilte] = useState("");
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //---------- LOADING -----------------------
  const [loading, setloading] = useState(true);
  //--------INFORMATIONS OF ACTUAL USER---------
  const user = useUser();
  const language = Cookies.get("locale");

  {
    language === "fr"
      ? require("moment/locale/fr")
      : require("moment/locale/en-au");
  }

  var { id, nom, prenom, gender } = user.result;

  // useEffect(() => {
  //   getUser(id)
  //     .then((result) => {
  //       if (result?.data?.code === 200) {
  //         var obj = result?.data?.data;
  //         if (obj?.gestion_contrat_code_pin === 1) {
  //           setshowCodePIN(true);
  //         }
  //         getListeAssuree(id)
  //           .then((result) => {
  //             if (result?.data?.code === 200) {
  //               const liste_assuree = _.groupBy(result?.data?.data, "id");
  //               setASSUREE(liste_assuree);
  //             }
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //         getContratByUser(id)
  //           .then((result) => {
  //             if (result?.data?.code === 200) {
  //               console.log(result?.data?.data);
  //               var L = result?.data?.data?.filter(
  //                 (el) => el.statut === "success" 
  //               );
  //               var liste_contart = _.orderBy(L, "created_at", "desc");
  //                var policy_liste = _.groupBy(liste_contart, "policy_code");  
  //                Object.keys(policy_liste).forEach((policyCode) => {
  //                 const contracts = policy_liste[policyCode];
  //                 if (contracts.length > 1) {
  //                   const sortedContracts = _.orderBy(contracts, "created_at", "desc");
  //                   const olderContracts = sortedContracts.slice(1);
  //                   olderContracts.forEach(async (contract) => {
  //                     try {
  //                       // Delete the older contract using the deleteContrat function
  //                       await deleteContratById(contract.id);
  //                       // Optionally, you can handle the success or error response here
  //                     } catch (error) {
  //                       // Handle the error response here
  //                       console.log(error);
  //                     }
  //                   });
  //                   policy_liste[policyCode] = sortedContracts.slice(0, 1);
  //                 }
  //               });
                        
  //               setpolicyListe(Object.keys(policy_liste));
  //               setgroupedListe(policy_liste);
  //               setLISTE(result?.data?.data);
  //               setSaveLISTE(result?.data?.data);
  //             }
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           })
  //           .finally(() => {
  //             setloading(false);
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  useEffect(() => {
    getUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
          getListeAssuree(id)
            .then((result) => {
              console.log("result of liste assure :",result)
              if (result?.data?.code === 200) {
                const liste_assuree = _.groupBy(result?.data?.data, "id");
                setASSUREE(liste_assuree);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          getContratByUser(id)
            .then((result) => {
              if (result?.data?.code === 200) {
                console.log(result?.data?.data);
                var L = result?.data?.data?.filter(
                  (el) => el.statut === "success" 
                );
                var liste_contart = _.orderBy(L, "created_at", "desc");
                 var policy_liste = _.groupBy(liste_contart, "id");  
             
                        
                setpolicyListe(Object.keys(policy_liste));
                setgroupedListe(policy_liste);
                setLISTE(result?.data?.data);
                setSaveLISTE(result?.data?.data);
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setloading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  /*   const onHandleChangeFiltre = (e) => {
    var type_category = e.target.value;
    setfilte(type_category);
    if (type_category === "all") {
      setLISTE(SaveLISTE);
    } else {
      const L = SaveLISTE?.filter((el) => el?.category?.type === type_category);
      setLISTE(L);
    }
  }; */
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          {showCodePIN && (
            <ModalSaisieCode id={id} setshowCodePIN={setshowCodePIN} t={t} />
          )}
          <div className="shop-pagination pb-3">
            <div className="container mb-3">
              <div className="card">
                <div className="card-body p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <small className="ms-1">{t("Gestion des contrats")} </small>

                    {/*  <form action="#">
                      <div className="float-end ">
                        <div className="input-group">
                          <select
                            className="form-select fz-12 me-2"
                            value={filte}
                            onChange={(e) => onHandleChangeFiltre(e)} >
                            <option value=""> {t("Choisir")} </option>
                            <option value="all">
                              {t("Tous les contrats")}
                            </option>
                            <option value="accident">
                              {t("Assur. Accident")}
                            </option>
                            <option value="deces"> {t("Assur. Décès")} </option>
                            <option value="education">
                              {t("Assur. Education")}
                            </option>
                            <option value="retraite">
                              {t("Assur. Retraite")}
                            </option>
                            <option value="vehicule">
                              {t("Assur. Véhicule")}
                            </option>
                            <option value="voyage">
                              {t("Assur. Voyage")}
                            </option>
                          </select>
                          <b>
                            <i className="bi bi-filter mt-2 text-purple" />
                          </b>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2 ">
   
            </div>
            {policyListe?.length !== 0 ? (
              <div>
                {console.log("policy list est :",policyListe)}
                {policyListe?.map((i, keys) => (
                  
                  <CardContrats
                    keys={keys}
                    i={i}
                    groupedListe={groupedListe}
                    t={t}
                    gender={gender}
                    nom={nom}
                    prenom={prenom}
                    ASSUREE={ASSUREE}
                  />
                ))}
              </div>
            ) : (
              <EmptyFolder t={t} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
