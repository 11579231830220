import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getCategorie,
  getCategoriesByTypeOfFacturation,
  getdetailsCategorie,
} from "../../services/categories/CategoriesApi";
import Cookies from "js-cookie";
import {
  getContratByUser,
} from "../../services/contrats/ContratApi";
import authHeader from "../../services/authentification/AuthHeader";
import { getUrl } from "../../services/authentification/DotenvConfig";

import { useParams } from "react-router-dom";
import {
  error_message,
  warning_message,
} from "../../components/alerte/AlerteMessage";
import { useUser } from "../../Auth/useUser";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getUser } from "../../services/users/usersAPI";
import { getListeAssuree } from "../../services/assuree/assureeAPI";
import _ from "lodash";

import { getPersonnelByID } from "../../services/personnel/personnelAPI";
import { getAdressesOfUser } from "../../services/adresses/AdresseAPI";
import { getCompagnyCategory } from "../../services/assignCategorieCompagny/assignCCApi";
import { getParamTechByCategorie } from "../../services/paramtech/paramTechAPI";
import {
  getCoutPolice,
  getFormuleAccident,
  getPrimeNette,
} from "../../services/formules/FormulesAPI";

import Paiement from "../contrats/components/paiement/Paiement";

import { getFormuleAuto } from "../../services/formules/FormulesAPI";
// # UTILS : FUNCTION NUMBER WITH SPACES
import { numberWithSpaces } from "../../utils/SessionUtiles";
import Loader from "../../components/loading/Loader";
import axios from "axios";
import Icon from "../../components/obligatoire/Icon";
import CurrencyInput from "react-currency-input-field";
// import AssuranceAccident1 from "./AssuranceAccident1";
import AssuranceAutomobile from "./AssuranceAutomobile";
export default function AssuranceRenouvellement() {
 
  const history = useHistory();
  // Parametre id = id categorie
  // const { id, title, type } = useParams("");
  //------- INIT LANGAGE
  const { id, type, title, policy_code,categorie_id } = useParams("");
  const language = Cookies.get("locale");
  // LISTE DES CATEGORY DONT LE TYPE DE PAIEMENT EST LA COTISATION
  const [LISTE_FACTURE, setLISTE_FACTURE] = useState([]);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  const [valid, setvalid] = useState(true);
  const [empty, setEmpty] = useState(false); // Verif if initial get of data object (n_cin exist or not ), if data exist we update the date else we add a new object

  const [LISTE_ASSUREE, setLISTE_ASSUREE] = useState([]); // Liste assurée
  // LISTE DES COMPAGNIES D'assurances
  const [Liste_Compagny, setListe_Compagny] = useState([]);
 
  //------------- ADRESSES of User --------
  const [ListeAdresses, setListeAdresses] = useState([]);
  const [groupedAdresses, setgroupedAdresses] = useState([]);
  // ALL ASSUREE OF CARNET ASSUREE OF USER
  const [ListeAssuree, setListeAssuree] = useState([]); // LISTE DES ASSURES
  const [groupedAssuree, setgroupedAssuree] = useState([]);
  // LISTE OF UNIQ POLICY
  const [policyListe, setpolicyListe] = useState([]);
  const [groupedListe, setgroupedListe] = useState([]);
  //------------------ SHOWS FORMS -----------
  const [show, setshow] = useState(false);
  
  const [show5, setShow5] = useState(false);
  const [currentCollapse, setCurrentCollapse] = useState(null);
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);
  // const [showFormAccident, setShowFormAccident] = useState(false);
  // ------------ LOADER ------------------
  const [Loading, setLoading] = useState(true);
  const [data, setdata] = useState({});
  const user = useUser();
  var id_user = user?.result?.id; //id of connected user

  const url = getUrl();
  const [LISTE, setLISTE] = useState([]);
  // ---------------FORMULE voyages ---------
  const [cout_police, setcout_police] = useState([]);
  const [prime_nette, setprime_nette] = useState([]);
  const [paramTech, setparamTech] = useState([]);
  const [SaveLISTE, setSaveLISTE] = useState([]); // SAUVEGARDE DE LA LISTE INIT POUR LA RECUPERATION EN CAS DE FILTRE
  const [loading, setloading] = useState(true);
  const [categorie, setcategorie] = useState({});
  
  const [accident, setAccident] = useState("");
  const [adress_id, setAdress_id] = useState("");


  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA

  useEffect(() => {
 
// bech tjib les contrats by id user ==> var lfou9 tal9aha

// bech testaaml fn _.groupBy(result?.data?.data, "id")


  }, []);

  useEffect(() => {
    getCategoriesByTypeOfFacturation("renouvellement")
      .then((result) => {
        if (result?.data?.code === 200) {
  
          console.log(result?.data);
          setLISTE_FACTURE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Results
  const [results, setResults] = useState({
    primenette: 0, // SUM PRIME NETTE
    accessoires: 0, // ACCESSOIRES
    taxes: 0, // TAXES
    fga: 0, // FGA
    primettc: 0, // PRIME TTC
  });

 

  useEffect(() => {
    getCategorie()
      .then((result) => {
        if (result?.data?.code === 200) {
          setLISTE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    if (type === "voyage") {
      // Recuperer la formule de la prime nette
      getPrimeNette()
        .then((result) => {
          if (result?.data?.code === 200) {
            // RECUPERER LA FORMULE DU COUT DE POLICE
            getCoutPolice().then((res) => {
              if (result?.data?.code === 200) {
                setprime_nette(result?.data?.data);
                setcout_police(res?.data?.data);
                // Recuperer les PARAM TECHNIQUES
                getParamTechByCategorie(categorie_id)
                  .then((result) => {
                    if (result?.data?.code === 200) {
                      setparamTech(result?.data?.data);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getCompagnyCategory(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var C = [];
          result?.data?.data?.map((items) => C.push(items.company));
          setListe_Compagny(_.uniqBy(C, "id"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getAdressesOfUser(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListeAdresses(result?.data?.data);
          setgroupedAdresses(_.groupBy(result?.data?.data, "id"));
        

          
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getdetailsCategorie(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setcategorie(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getListeAssuree(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListeAssuree(result?.data?.data);
          setgroupedAssuree(_.groupBy(result?.data?.data, "id"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getUser(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          var data = result?.data?.data;
          setdata(data);
          var { nom, prenom, email, mobile, date_naissance } = data;
          if (
            nom === null ||
            prenom === null ||
            date_naissance === null ||
            mobile === null ||
            email === null
          ) {
            setvalid(false);
          } else {
            setvalid(true);
            getPersonnelByID(id_user)
              .then((result) => {
                if (result?.data?.code === 200) {
                  if (result?.data?.data?.length === 0) {
                    setEmpty(true);
                  } else {
                    setEmpty(false);
                    var info = result?.data?.data[0];
                    Object.assign(data, {
                      n_cin: info?.n_cin,
                      n_passport: info?.n_passport,
                    });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      });
  }, []);
  // UPDATE DATA INTO FORM
  useEffect(() => {
    reset(data);
  }, [data]);

  
  useEffect(() => {
    getContratByUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          console.log(result?.data?.data);
          var L = result?.data?.data?.filter((el) => el.statut === "success");
          var liste_contart = _.orderBy(L, "created_at", "desc");
          var policy_liste = _.groupBy(liste_contart, "policy_code");
          setpolicyListe(Object.keys(policy_liste));
          setgroupedListe(policy_liste);
          setLISTE(result?.data?.data);
          setSaveLISTE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);



  const [listeContrats, setListeContrats] = useState([]);
  useEffect(() => {
    const fetchListeContrats = async () => {
      try {
        // Remplacez `id` par l'ID de l'utilisateur approprié
        const id = "votre_id_utilisateur";
        const response = await axios.get(
          url + "assures/users/" + id,
          { headers: authHeader() },
          { IsEssential: true }
        );
        setListeContrats(response.data); // Supposons que la réponse contient un tableau de contrats d'assurance
      } catch (error) {
        console.error(error);
      }
    };

    fetchListeContrats();
  }, []);
  

  function onFormSubmitV(dataRes) {
    var { valeur_venale, puissance, formule, duree } = dataRes;
    const valeur =
      valeur_venale !== undefined && valeur_venale.replace(/\s+/g, "");

    getFormuleAuto()
      .then((result) => {
        if (result?.data?.code === 200) {
          //------------ GROUPE FORMULE PUISSANCE -----------
          var group_formule = _.groupBy(result?.data?.data, "puissance");
          var table = group_formule[puissance];
           getParamTechByCategorie(id).then((res) => {
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj")
            console.log("jjjjjjjjjjjjj",res)
               var paramTech = res?.data?.data[0]
              var taux_taxes= parseInt(paramTech?.taxes)/100
                var result = table?.filter(
            (el) => el.formule === formule && el.duree === parseInt(duree)
          );
          var object = result[0];
          if (formule === "Karangue") {
            var total = object?.rc + object?.dr + object?.frais;
            var taxe = total * taux_taxes;
            var fg = total * parseFloat(object?.fga);
          } else if (formule === "Aar") {
            var total =
              object?.rc + object?.dr + object?.frais + object?.ar + object?.pt;
            var taxe = total * taux_taxes;
            var fg = total * object?.fga;
          } else if (formule === "Noflaye") {
            var total =
              object?.rc +
              object?.dr +
              object?.frais +
              object?.ar +
              object?.pt +
              object?.bdg;
            var taxe = total *taux_taxes;
            var fg = total * object?.fga;
          } else if (formule === "Diam") {
            var vol = object?.vol * valeur;
            var incendie = object?.incendie * valeur;
            var total =
              object?.rc +
              object?.dr +
              object?.frais +
              object?.ar +
              object?.pt +
              object?.bdg +
              vol +
              incendie;
            var taxe = total * taux_taxes;
            var fg = total * object?.fga;
          } else if (formule === "Ngalam" || formule === "Wourous") {
            var vol = object?.vol * valeur;
            var incendie = object?.incendie * valeur;
            var dommages = object?.DOMMAGES * valeur;
            var total =
              object?.rc +
              object?.dr +
              object?.frais +
              object?.ar +
              object?.pt +
              object?.bdg +
              vol +
              incendie +
              dommages +
              object?.remorq;
            var taxe = total *taux_taxes;
            var fg = total * object?.fga;
          }
          setResults({
            primenette: total,
            accessoires: object?.frais,
            taxes: taxe,
            fga: fg, // FGA
            primettc: total + taxe + fg  // PRIME TTC
          });
           }).catch((err) => {
            console.log(err);
           });
        
          setshow(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  

  
  }



  function callbackAdress(adressId) {
    let newData=data
    newData.adresse_id=adressId
    setdata(newData)
  }
  const onChangeAdress=(e)=>{
    setAdress_id(e.target.value)
    callbackAdress(e.target.value)
  }
 
  return (
    <div className="container">
    <div className="shop-pagination pb-3">
      {show5 ? (
        <>
          <Paiement
            id={id}
            handleSubmit={handleSubmit}
            register={register}
            data={data}
            watch={watch}
            groupedAdresses={groupedAdresses}
            LISTE_ASSUREE={LISTE_ASSUREE}
            type={accident}
            t={t}
          />
        </>
      ) : (
        <>
          {LISTE_FACTURE?.map((items, keys) => (
            <div
              className="col-6"
              key={keys}
              style={{ margin: "auto", width: "100%" }}
            >
              <div className="accordion-item">
                
                  <div className="mb-0">
                  <div className="shop-pagination">
        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14 mt-3"> {t("Souscripteur")} </small>
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">{t("Nom & Prénom souscripteur")}</label>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="bi bi-person-fill text-purple" />
            </span>
            <input
              className="form-control fz-12"
              type="text"
              disabled
              value={data?.nom + " " + data?.prenom}
            />
          </div>
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("téléphone")}</label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-telephone-fill text-purple" />
              </span>
              <input
                className="form-control fz-12"
                type="text"
                disabled
                value={data?.mobile}
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("E-Mail")}</label>
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="bi bi-envelope-fill text-purple" />
            </span>
            <input
              className="form-control fz-12"
              type="text"
              disabled
              value={data?.email}
            />
          </div>
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("Date de naissance")}</label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-balloon-fill text-purple" />
              </span>
              <input
                className="form-control fz-12"
                type="date"
                disabled
                value={moment(data?.date_naissance).format("YYYY-MM-DD")}
              />
            </div>
          </div>
        </div>
      

        <div className="form-group mb-3">
        <label className="mb-2 fz-12">
          {t("Adresse Souscripteur")} <Icon />
        </label>
        <select className="form-select fz-12" required onChange={(e)=> onChangeAdress(e)} >
          <option value="">{t("Choisir")} ...</option>
          {ListeAdresses?.map((items, keys) => (
            <option value={items.id} key={keys}>
              {items.pays +
                "," +
                items.region +
                " ," +
                items.ligne +
                "," +
                items.cite +
                " " +
                items.code_postal}
            </option>
          ))}
        </select>
      </div>
        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14"> {t("Assuré(s)")} </small>
        </div>

        <h6 className="text-purple fz-12">
          <i className="bi bi-info-circle me-2"></i>
          {t("L'assuré est le souscripteur")}
        </h6>

        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14">{t("Informations Contrat")}</small>
        </div>

        <form onSubmit={handleSubmit(onFormSubmitV)}> 
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("Date Effet")}</label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-calendar-event text-purple" />
              </span>
              <input
                className="form-control fz-12"
                {...register("date_effet")}
                type="date"
                placeholder="DD/MM/YYYY"
                required
              />
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="form-label text-purple fz-12">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-info-circle me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
              {t(
                "Produit disponible que pour les assurés âgés de moins de 60 ans"
              )}
            </label>
          </div>
          <div className="form-group mb-3">
            <label className="form-label fz-12">{t("Durée")}</label>
            <select
              className="form-select fz-12"
              {...register("duree")}
              required
            >
              <option value={""}>{t("Choisir")} </option>
              <option value={1}>{t("1 Mois")}</option>
              <option value={3}>{t("3 Mois")}</option>
              <option value={6}>{t("6 Mois")}</option>
              <option value={12}>{t("12 Mois")}</option>
            </select>
          </div>
          <div className="form-group mb-3 text-center mt-2">
                    <div>
                    <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14">{t("Informations Véhicules")}</small>
        </div>
        <div className="form-group mb-3 text-center mt-2">
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input fz-12"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                {...register("etat")}
                defaultValue="option1"
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                {t("Immat. N°")}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input fz-12"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                {...register("etat")}
                defaultValue="option2"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                {t("N° Chassis")}
              </label>
            </div>
          </div>
        </div>
        {watch("etat") === "option1" ? (
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("Immat. N°")}</label>
            <input
              type="text"
              className="form-control fz-12"
              placeholder="Immat. N° "
              {...register("matricule")}
            />
          </div>
        ) : (
          watch("etat") === "option2" && (
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("N° Chassis")}</label>
              <input
                type="text"
                className="form-control fz-12"
                placeholder="Chassis"
                {...register("chassis")}
              />
            </div>
          )
        )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2 fz-12">
                      {t("Valeur vénale")} (FCFA)
                      <Icon />
                    </label>
                    <CurrencyInput
                      name="valeur_venale"
                      className="form-control fz-12"
                      placeholder={t("Valeur vénale") + "..."}
                      decimalScale={2}
                      {...register("valeur_venale")}
                      required
                    />
                  </div>
              <div className="form-group mb-3">
          <label className="form-label fz-12">
            {t("Puissance")}
            <Icon />
          </label>
          <select className="form-select fz-12" {...register("puissance")}>
            <option> {t("Choisir")} </option>
            <option value={"categorie1"}> {t("3 à 6 CV")} </option>
            <option value={"categorie2"}> {t("7 à 10 CV")} </option>
            <option value={"categorie3"}> {t("11 à 14 CV")} </option>
            <option value={"categorie4"}> {t("15 à 23 CV")} </option>
            <option value={"categorie5"}> {t("24 à + CV")} </option>
          </select>
        </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Nombre de place")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="bi bi-people-fill text-purple" />
          </span>
          <input
            type="number"
            className="form-control fz-12"
            disabled
            value={data?.nbr_place}
          />
        </div>
      </div>
     
       <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Marque")} <Icon />
          </label>
          <input
            className="form-control  fz-12"
            type="text"
            {...register("marque")}
            placeholder={t("Marque") + " ... "}
            required
          />
        </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Modèle")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="bi bi-wrench text-purple" />
          </span>
          <input
            type="text"
            className="form-control fz-12"
            disabled
            value={data?.modele}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="mb-2 fz-12">{t("Energie")}</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-fuel-pump text-purple"
              viewBox="0 0 16 16"
            >
              <path d="M3 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-5Z" />
              <path d="M1 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v8a2 2 0 0 1 2 2v.5a.5.5 0 0 0 1 0V8h-.5a.5.5 0 0 1-.5-.5V4.375a.5.5 0 0 1 .5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 0 0-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 0 1 0-1c.564 0 1.034.11 1.412.336.383.228.634.551.794.907.295.655.294 1.465.294 2.081v3.175a.5.5 0 0 1-.5.501H15v4.5a1.5 1.5 0 0 1-3 0V12a1 1 0 0 0-1-1v4h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V2Zm9 0a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v13h8V2Z" />
            </svg>
          </span>
          <input
            type="text"
            className="form-control fz-12"
            disabled
            value={data?.energie}
          />
        </div>
      </div>
          <label className="form-label fz-12">{t("Formule")}</label>
          <div className="mb-3">
          <div
            className={
              watch("formule") === "Karangue"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Karangue"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Karangue:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Aar"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Aar"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Aar:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Noflaye"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Noflaye"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Noflaye:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Diam"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Diam"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Diam:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
                <li className="fz-12">{t("Vol")}</li>
                <li className="fz-12">{t("Incendie")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Ngalam"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Ngalam"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Ngalam:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
                <li className="fz-12">{t("Vol")}</li>
                <li className="fz-12">{t("Incendie")}</li>
                <li className="fz-12">{t("Remorquage")}</li>
              </label>
            </div>
          </div>
          <div
            className={
              watch("formule") === "Wourous"
                ? "single-plan-check shadow-sm active-effect active"
                : "single-plan-check shadow-sm active-effect"
            }
          >
            <div className="form-check  mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="formule"
                value={"Wourous"}
                {...register("formule", { required: true })}
              />
              <label className="form-check-label" htmlFor="Individual">
                Wourous:
                <li className="fz-12">{t("Garantie : RC (Responsabilité Civile)")}</li>
                <li className="fz-12">{t("DR (Défense et Recours)")}</li>
                  <li className="fz-12">{t("PT (Personne transportée)")}</li>
                <li className="fz-12"> {t("AR(Avance sur Recours)")}</li>
                <li className="fz-12">{t("BDG (Brise de Glace)")}</li>
                <li className="fz-12">{t("Vol")}</li>
                <li className="fz-12">{t("Incendie")}</li>
                <li className="fz-12">{t("Remorquage")}</li>
              </label>
            </div>
          </div>
        </div>
        {(watch("formule") === "Diam" ||
          watch("formule") === "Ngalam" ||
          watch("formule") === "Wourous") && (
          <div className="form-group mb-3">
            <label className="form-label fz-12">
              {t("Valeur vénale")}
              <Icon />
            </label>
            <CurrencyInput
              name="prime_commercial"
              className="form-control fz-12"
              placeholder="Valeur Vénale voiture ..."
              decimalScale={2}
              {...register("valeur_venale")}
              required
            />
          </div>
        )}
          <div className="  mt-3">
            <button type="submit" className="btn btn-purple col-12 mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-calculator me-2"
                viewBox="0 0 16 16"
              >
                <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
              </svg>
              {t("Calculer")}
            </button>
          </div>
          {show && (
          <div className="row">
            <div className="col-12">
              <div className="card mb-4 ">
                <div className="card-body">
                  <h5 className="text-center"> {t("Total du calcul")} </h5>
                  <table className="table  mb-0 table-striped-primary mt-3">
                    {loaderResult ? (
                      <Loader />
                    ) : (
                      <tbody>
                        <tr>
                          <td>{t("Prime Nette")} + {t("Accessoires")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.primenette))}{" "}
                            (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Accessoires")}</td>
                          <td className="text-center">
                            {numberWithSpaces(results?.accessoires)} (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Taxe")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.taxes))}{" "}
                            (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("FGA")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.fga))} (FCFA)
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Prime TTC")}</td>
                          <td className="text-center">
                            {numberWithSpaces(Math.round(results?.primettc))}{" "}
                            (FCFA)
                          </td>
                        </tr>
                        <button onClick={()=>setShow5(true)} className="btn btn-success mt-1 float-end">Suivant</button>

                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        </form>

      </div>
                  </div>
                </div>
            
            </div>
          ))}
        </>
      )}
    </div>
  </div>
  
  );
}
