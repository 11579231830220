import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/loading/Loader";
import { getpublicContratByPolicy } from "../../services/contrats/ContratApi";

export default function InformationsQR_Code() {
  const { policy_contrat } = useParams("");
  // DATA INFORMATIONS
  const [Informations, setInformations] = useState([]);
  const [valid, setvalid] = useState(false);
  const [loader, setloader] = useState(true);
  useEffect(() => {
    getpublicContratByPolicy(policy_contrat)
      .then((result) => {
        if (result?.data?.code === 200) {
          setInformations(result?.data?.data);
          if (result?.data?.data?.length === 0) {
            setvalid(false);
          } else {
            setvalid(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloader(false);
      });
  }, []);

  return (
    <div className="page-content-wrapper py-3">
      <div className="container">
        {loader ? (
          <Loader />
        ) : (
          <div className="card bg-transparent mb-3">
            <div className="card-body">
              <h5 className="mb-3 text-center display-5">
                Vérification de la validité du contrat d'assurance
              </h5>
              <div className="text-center">
                <img
                  className="mb-4 text-center"
                  src="img/bg-img/contrat.png"
                  width={"150px"}
                  alt="Assurance vie"
                />
              </div>
              {valid ? (
                <h6 className=" text-center  text-purple fz-16 ">
                  Contrat valide
                </h6>
              ) : (
                <h6 className=" text-center  text-danger display-6 ">
                  Contrat Invalide
                </h6>
              )}
              {Informations?.length !== 0 && (
                <>
                  <h6 className="fz-12 mt-4">
                    ✅ Le numéro de la police : <b> {policy_contrat}</b>
                  </h6>
                  <h6 className="fz-12 mt-3">
                    ✅ Le Souscritpeur :{" "}
                    <b>
                      {Informations[0]?.nom_souscripteur +
                        " " +
                        Informations[0]?.prenom_souscripteur}
                    </b>
                  </h6>
                  <h6 className="fz-12 mt-3">
                    ✅ Assurance : <b>{Informations[0]?.titre}</b>
                  </h6>
                  {Informations?.map((items, keys) => (
                    <h6 className="fz-12 mt-3" key={keys}>
                      ✅ Assuré N° {keys + 1} :{" "}
                      <b>{items?.nom_assuree + " " + items?.prenom_assuree}</b>
                    </h6>
                  ))}
                </>
              )}
              {Informations[0]?.type === "voyage" ? (
                <>
                  <h6 className="fz-12 mt-3">
                    ✅ Date de départ et date d'arrivée :{" "}
                    <b>
                      {moment(Informations[0]?.date_depart).format(
                        "DD-MM-YYYY"
                      ) +
                        " / " +
                        moment(Informations[0]?.date_arrive).format(
                          "DD-MM-YYYY"
                        )}
                    </b>
                  </h6>
                  <h6 className="fz-12 mt-3">
                    ✅Pays de destination et de depart :{" "}
                    <b>
                      {Informations[0]?.pays_destination +
                        " / " +
                        Informations[0]?.pays_alle}
                    </b>
                  </h6>
                  <h6 className="fz-12 mt-3">
                    ✅Date de souscription :{" "}
                    <b>
                      {moment(Informations[0]?.date_effet).format("DD-MM-YYYY")}
                    </b>
                  </h6>
                </>
              ) : Informations?.length!==0 && Informations[0]?.type !== "voyage" ? (
                <>
                  <h6 className="fz-12 mt-3">
                    ✅Date de souscription :{" "}
                    <b>
                      {moment(Informations[0]?.date_effet).format("DD-MM-YYYY")}
                    </b>
                  </h6>
                </>
              ): null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
