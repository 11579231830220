import React, { useEffect, useState } from 'react'
import { getUser } from '../../services/users/usersAPI';
import { getAllFacture, getFactureByCategoryId, getFactureByUser } from '../../services/factures/FactureApi';
import { useUser } from '../../Auth/useUser';
import { useParams } from "react-router-dom";
import accounting from "accounting";
import DotLoader from '../../components/loading/DotLoader';
import { t } from 'i18next';
import EmptyCotisation from '../cotisation/EmptyCotisation';
import jsPDF from 'jspdf';
import { useHistory } from "react-router-dom";

export default function ListeFacturesCategries() {
  const history = useHistory();

    const user = useUser();
    var { id } = user.result;
    const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
    const { categoryId } = useParams();
    const [factures, setFactures] = useState([]);
    const [paidFactures, setPaidFactures] = useState([]);
   
    const [loading, setloading] = useState(true);
    const [type, settype]=useState({});

    const africanCurrencyOptions = {
        symbol: "FCFA", // Set the currency symbol
        decimal: ".", // Set the decimal separator
        thousand: " ", // Set the thousand separator
        precision: 0, // Set the number of decimal places
        format: "%v %s", // Set the format pattern
      };
// Function to format a given value with the African currency options
const formatCurrency = (value) => {
    return accounting.formatMoney(value, africanCurrencyOptions);
  };

//   useEffect(() => {
//     getUser(id)
//       .then((result) => {
//         if (result?.data?.code === 200) {
//           var obj = result?.data?.data;
//           if (obj?.gestion_contrat_code_pin === 1) {
//             setshowCodePIN(true);
//           }
//         }
//         getFactureByUser(id)
//           .then((result) => {
//             if (result?.data?.code === 200) {
//               console.log("factures of current user", result);
//               var L = result?.data?.data?.filter(
//                 (el) => el.num_facture === policy_code
//               );
//               var P = L.filter((el) => el.statut == "paid");
//               var U = L.filter((el) => el.statut == "unpaid");
//               console.log("paid invoices: ", P);
//               console.log("unpaid invoices: ", U);

//               setFactures(L);
//               setPaidFactures(P);
//               setUnpaidFactures(U);
//               var unpaidFacturelength = U.length.toString().padStart(2, "0");
//               setunpaidFactureLength(unpaidFacturelength);
//               var totalCotisation = 0;
//               for (let i = 0; i < P.length; i++) {
//                 totalCotisation += P[i].montant;
//                 P[i].montant = formatCurrency(P[i].montant);
//               }
//               for (let i = 0; i < U.length; i++) {
//                 totalCotisation += U[i].montant;
//                 U[i].montantNumber = U[i].montant;
//                 U[i].montant = formatCurrency(U[i].montant);
//               }
//               setTotalCotisation(totalCotisation);
//               console.log("Results after filter:", L);
//               setloading(false);
//             }
//           })
//           .catch((err) => {
//             console.log("error", err);
//           });
//         console.log("Results after filter:", factures);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);
  
useEffect(() => {
    getUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
        }
        getFactureByUser(id)
          .then((result) => {
            if (result?.data?.code === 200) {
              console.log("factures of current user", result);
              var L = result?.data?.data?.filter(
                (el) => el.category_id === Number(categoryId)
              );
              console.log("aaaa",L)
              var P = L.filter((el) => el.statut == "paid");
              console.log("paid invoices: ", P);
              getFactureByCategoryId(id).then((result)=>{
                if (result?.data?.code === 200) {
                    console.log("newwwwww",result?.data?.data)
                }
            })
              setFactures(L);
              setPaidFactures(P);             
              setloading(false);
            }
          })
          .catch((err) => {
            console.log("error", err);
          });
        console.log("Results after filter:", factures);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const sendDataFacture = (factureCotisation) => {
    localStorage.setItem("selectedFactureCotisation", JSON.stringify(factureCotisation));
  };
 

  const navigateToInvoice = (factureId) => {
    history.push(`/cotisation_facture/${factureId}/${categoryId}`);
  };
return (
    <div>
        {loading ? <DotLoader /> :
     <div className="container">
     
     <div className="shop-pagination pb-3">
       <div className="container mb-3">
         <div className="card">
           <div className="card-body p-2">
             <div className="d-flex align-items-center justify-content-between">
               <small className="ms-1">{t("Factures payées ")} </small>
             </div>
           </div>
         </div>
       </div>
      

       <div className="row mb-5">
         {paidFactures?.length !== 0 ? (
           <div style={{ gap: "15px" }}>
             <div className="d-flex justify-content-between">
               <div className="timeline-text mb-2">
                 <span
                   className="badge mb-2 rounded-pill fz-10"
                   style={{ backgroundColor: "#08c5d1" }}
                 >
                  Liste Des Factures Payées par Catégories 
                 </span>
               </div>
             </div>
        
             <div style={{ marginTop: "-35px" }}>
               {paidFactures.map((items, k) => (
                 <div
                   className="card timeline-card"
                   key={k}
                   style={{ marginTop: "40px" }}
                 >
                   <>
                     <div className="card-body">
                     {console.log("invoice paid :",paidFactures)}
                       <div className="d-flex justify-content-between">
                         <div className="timeline-text mb-2">
                           <span className="badge mb-2 rounded-pill fz-10">
                             Facture N°: {items?.id}
                           </span>
                         </div>
                         <img src="img/bg-img/download.png" 
                                    style={{ height: "30px",cursor:"pointer" }}
                                    alt="" 
                                    onClick={() => 
                                      {
                                        navigateToInvoice(items?.id);
                                      sendDataFacture(items,user,type);}
                                    }
                                    /> 
                       </div>
                      
                       <div className="row">
                         <div className="col-md-6 d-flex align-items-center">
                           <div>
                             <p className="fz-10">
                             <b>{t("Montant")} :</b> {formatCurrency(items?.montant)}
                             </p>
                             <p className="fz-10">
                               <b style={{ color: "green" }}>
                                 <b>{t("Statut")} :</b>
                                 {items?.statut === "paid"
                                   ? " Payé"
                                   : "non Payé"}
                               </b>
                             </p>
                           </div>
                         </div>
                         <div className="col-md-6 d-flex align-items-center">
                           <div>
                             <p className="fz-10">
                               <b>{t("Date d'échéance")} :</b>{" "}
                               {items.Date_paiement}
                             </p>
                             <p className="fz-10">
                               <b>{t("Payée le")} :</b>{" "}
                               {items.Date_prevu}
                             </p>
                           </div>
                         </div>
                       </div>
                     </div>
                   </>
                 </div>
               ))}
             </div>
           </div>
         ) : (
           <EmptyCotisation t={t} />
         )}
       </div>
     </div>
   </div>
    
    }
    </div>
  )
}
