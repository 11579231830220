/* eslint-disable jsx-a11y/role-supports-aria-props */
import InforamtionsContrats from "./InforamtionsContrats";
import PrimeContrat from "./PrimeContrat";
import Souscripteur from "./Souscripteur";
import Beneficiaires from "./Beneficiaires";

export default function ResumContrat({ data, t, info, designImage, moment, type, user_details, personnel , paiement}) {
  console.log("Resum Contrat :", 
  data, t, info, designImage, moment, type, user_details, personnel , paiement)
  return (
    <div className="shop-pagination pb-3  mt-2">
      <div
        className="accordion accordion-flush accordion-style-two"
        id="accordionStyle2"
      >
        <div className="container">
          <h4 className="text-center mb-2 display-3">
            {data?.category?.titre}
          </h4>
          <div className="text-center">
            <img
             src="../img/bg-img/files-cuate.png"
              className="text-center"
              alt="design"
              width="150"
            />
          </div>
          {/* Single Accordion */}
          <div className="accordion-item">
            <div className="accordion-header" id="accordionFour">
              <h6
                data-bs-toggle="collapse"
                data-bs-target="#accordionStyleFour"
                aria-expanded="false"
                aria-controls="accordionStyleFour"
                className="collapsed fz-10"
              >
                <i className="bi bi-plus-lg" />
                {t("Détails Contrat")}
              </h6>
            </div>
            <div
              className="accordion-collapse collapse"
              id="accordionStyleFour"
              aria-labelledby="accordionFour"
              data-bs-parent="#accordionStyle2"
              style={{}}
            >
              <div className="accordion-body">
                <InforamtionsContrats
                  t={t}
                  data={data}
                  moment={moment}
                  type={type}
                />
              </div>
            </div>
          </div>
          {/* Single Accordion */}
          <div className="accordion-item">
            <div className="accordion-header" id="accordionFive">
              <h6
                className="collapsed fz-10"
                data-bs-toggle="collapse"
                data-bs-target="#accordionStyleFive"
                aria-expanded="false"
                aria-controls="accordionStyleFive"
              >
                <i className="bi bi-plus-lg" />
                {t("Le Souscripteur et le(s) Bénéficiaire(s)")}
              </h6>
            </div>
            <div
              className="accordion-collapse collapse"
              id="accordionStyleFive"
              aria-labelledby="accordionFive"
              data-bs-parent="#accordionStyle2"
            >
              <div className="accordion-body">
                {/** ---------------------------------- SOUSCRIPTEUR ------------------------------------------- */}
                <Souscripteur
                  t={t}
                  user_details={user_details}
                  moment={moment}
                  personnel={personnel}
                  data={data}
                />

                {/** ---------------------------------- BENEFICIAIRE ------------------------------------------- */}
                {data?.assure !== undefined  && 
                 <Beneficiaires
                  type={type}
                  t={t}
                  user_details={user_details}
                  moment={moment}
                  personnel={personnel}
                  data={data}
                />
                }
               
              </div>
            </div>
          </div>
          {/* Single Accordion */}
          <div className="accordion-item">
            <div className="accordion-header" id="accordionSept">
              <h6
                className="collapsed fz-10"
                data-bs-toggle="collapse"
                data-bs-target="#accordionStyleSix"
                aria-expanded="false"
                aria-controls="accordionStyleSix"
              >
                <i className="bi bi-plus-lg" />
                {t("Prime – Capital – Rente")}{" "}
              </h6>
            </div>
            <div
              className="accordion-collapse collapse"
              id="accordionStyleSix"
              aria-labelledby="accordionSix"
              data-bs-parent="#accordionStyle2"
            >
              <div className="accordion-body">
                <PrimeContrat t={t} type={type} data={data} info={info} paiement={paiement} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
