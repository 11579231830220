/* eslint-disable jsx-a11y/heading-has-content */
import moment from "moment";

export default function Souscripteur({
  t,
  data,
  cite,
  ligne,
  region,
  code_postal,
  pays,
  LISTE_ASSUREE,
  type,
}) {
  return (
    <div>
      <div className="shop-pagination pb-3 mt-3">
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <small className="mb-3 fz-14 mt-3"> {t("Souscripteur")} </small>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("Nom & Prénom souscripteur")}
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-person-fill text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.nom + " " + data?.prenom}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("téléphone")}</label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-telephone-fill text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.mobile}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("E-Mail")}</label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-envelope-fill text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.email}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("Date de naissance")}</label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-balloon-fill text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="date"
                  disabled
                  value={moment(data?.date_naissance).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            {type === "voyage" && LISTE_ASSUREE?.length === 0 && (
              <>
                <div className="form-group mb-3">
                  <label className="mb-2 fz-12">{t("N° Passeport")}</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="bi bi-123 text-purple" />
                    </span>
                    <input
                      className="form-control fz-12"
                      type="text"
                      disabled
                      value={data?.n_passport}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2 fz-12">
                      {t("Validité Passeport")}
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-file-earmark-check text-purple"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                        </svg>
                      </span>
                      <input
                        className="form-control fz-12"
                        type="text"
                        disabled
                        value={data?.validite_passeport}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("Adresse")}</label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="bi bi-pin-map-fill text-purple" />
                </span>
                <textarea
                  className="form-control fz-12"
                  disabled
                  value={
                    cite +
                    " " +
                    ligne +
                    " " +
                    region +
                    " , " +
                    pays +
                    ", " +
                    code_postal
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
