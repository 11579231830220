import React from 'react'
import './suivieCotisation.css'


function SuivieCotisation() {
  return (
    <div>
      <div className='container' style={{display:"flex", gap:"50%",marginBottom:"2.8rem"}}>
    <div className='card left-card' style={{width: "300px",border: "2px solid gainsboro" ,height: "182px"}}>
        <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>Total Cotisation</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-danger">
            {/* {sumEncaissement(data)} */}
            
            </h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
        </div>
      </div>
    
    </div>
    <div className='card right-card' style={{width: "300px",border: "2px solid gainsboro" ,height: "182px"}}>
    <div className="card text-bg-light mb-3" style={{maxWidth: '18rem'}}>
        <div className="card-header"style={{fontSize:"meduim",fontWeight:"600"}}>Facture Non Payée</div>
        <div className="card-body" style={{display:"flex",gap:"20", alignItems:"center",justifyContent:"center"}}>
          <h5 className="card-title text-danger">
            {/* {sumEncaissement(data)} */}
                  
                 </h5>
          <div className="icon1 bg-success-transparent " style={{marginLeft:"30px",marginBottom:"13px"}}>
            <i className="fa fa-money" />
          </div>
         
        </div>
      </div>
      </div>
    </div>
    <div className='container'>
    <div className="card">
  <div className="card-header">
    <h3 className="card-title" style={{textAlign:"center"}}>
      Le délais de chaque paiement
    </h3>
  </div>
  <div className="card-body">
  <div className="d-flex mb-6 mt-5">
                      <div className="mr-3">
                        <label className="form-label">Note:</label>
                      </div>
                      <div>
                        <span className="badge badge-success-light mr-2">
                        <i className="feather feather-x-circle text-danger" />Non échue 
                        </span>
                        <span className="badge badge-danger-light mr-2">                   
                          <i className="feather feather-check-circle text-success" /> Payé
                        </span>
                        <span className="badge badge-warning-light mr-2">
                          <i className="fa fa-star text-warning" /> Impayée
                        </span>
                        {/* <span className="badge badge-orange-light mr-2">
                    <i className="fa fa-adjust text-orange" /> ---&gt; Half Day
                  </span> */}
                      </div>
                    </div>
    <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap4">
      <div className="row">
        <div className="col-sm-12 col-md-6" />
        <div className="col-sm-12 col-md-6" />
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            id="example2"
            className="table table-bordered table-hover dataTable dtr-inline"
            aria-describedby="example2_info"
          >
            <thead>
              <tr>
                <th
                  className="sorting sorting_asc"
                  tabIndex={0}
                  aria-controls="example2"
                  rowSpan={1}
                  colSpan={1}
                  aria-sort="ascending"
                  aria-label="Rendering engine: activate to sort column descending"
                >
                  Referance paiement
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example2"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Browser: activate to sort column ascending"
                >
                  date d'échéance
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example2"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Platform(s): activate to sort column ascending"
                >
                  date de paiement
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example2"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Engine version: activate to sort column ascending"
                >
                  montant de paiement
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="example2"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="CSS grade: activate to sort column ascending"
                >
                 état cotisation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="odd">
                <td className="dtr-control sorting_1" tabIndex={0}>
                  test
                </td>
                <td>10-10-2023</td>
                <td>10-10-2023</td>
                <td>1.7</td>
                <td>Payée</td>
              </tr>
          
            </tbody>
            <tfoot>
              <tr>
                <th rowSpan={1} colSpan={1}>
                  Referance paiement	
                </th>
                <th rowSpan={1} colSpan={1}>
                  date d'échéance
                </th>
                <th rowSpan={1} colSpan={1}>
                  date de paiement
                </th>
                <th rowSpan={1} colSpan={1}>
                  montant de paiement
                </th>
                <th rowSpan={1} colSpan={1}>
                  état de cotisation
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      
    </div>
  </div>
</div>
</div>
    </div>
   
  )
}

export default SuivieCotisation