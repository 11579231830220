import {  Page,  Document,StyleSheet,View,Text, Font} from "@react-pdf/renderer";
import InvoiceTableFooter from "../invoice/InvoiceTableFooter";
import SouscripteurCG from "./SouscripteurCG";
import HeadrCP from "./HeadrCP";
import BeneficiaireCG from "./BeneficiaireCG";
import EnteteContrat from "./EnteteContrat";
import PrimeCPTableau from "./PrimeCPTableau";

// FONT TIMES NEW ROMAN
import TimesNewRoman_normal from '../styles/TimesNewRoman/times.ttf'
import TimesNewRoman_bold from '../styles/TimesNewRoman/times-new-roman-grassetto.ttf'
import Signatures from "./Signatures";


Font.register(
{
  family: 'Arial',
  fonts: [
    {
      src: TimesNewRoman_normal,
      fontWeight:400,
      fontStyle:"normal"
    },
    {
      src: TimesNewRoman_bold,
      fontWeight:700,
      fontStyle:"bold"
    },
   
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  reportTitle: {
    letterSpacing: 4,
    fontSize: 14,
    textAlign: "center",
    marginBottom: 0,
    fontFamily:'Arial',
    fontStyle:"bold",
    color:"#000",
    textTransform: "uppercase",

  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    marginTop:10,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
 
  title: {
    fontSize: 8,
    textAlign: "left",
    margin: 10,
    letterSpacing: 2,
    fontFamily:"Arial",
    fontStyle:"bold",
        textTransform: "uppercase",
    },
  title2: {
    fontSize: 8,
    textAlign: "left",
    margin: 10,
    marginBottom :0,
    letterSpacing: 2,
    textTransform: "uppercase",
    fontFamily:"Arial",
    fontStyle:"bold"
  },
  titleA:{
    fontSize:10,
    letterSpacing: 1,
    marginTop:10,
    fontFamily:"Arial",
    fontStyle:"bold"
  },


});
export default function ConditionP({ signatures,t, type,
  data,
  user_details,
  personnel,
  info,
  paiement
}) {

  return (
    <Document >
      <Page size="A4" style={styles.body} wrap>
        <EnteteContrat data={data} />
        <View style={{ marginTop: "20" }}>
          <Text style={styles.reportTitle}>Conditions Particuliéres</Text>
        </View>
        <View style={{ marginTop: "20" }}>
          <HeadrCP type={type} data={data} />
          <View style={styles.container}>
          <Text style={styles.titleA}>ARTICLE 1 – Informations sur le souscripteur et le(s) bénéficaire(s)</Text>
          </View>
          <Text style={styles.title}>I) Souscripteur </Text>
          <SouscripteurCG
            data={data}
            type={type}
            user_details={user_details}
            personnel={personnel}
          />
          <Text style={styles.title2}>II) Assuré(s) Bénéficiaire(s) :</Text>
          <Text style={styles.title2}>a) Bénéficiaire N°1</Text>
          <View style={{marginTop:"10"}}>
          <BeneficiaireCG  data={data}
            type={type}
            user_details={user_details}
            personnel={personnel}/>
          </View>    
          <View style={styles.container}>
          <Text style={styles.titleA}>ARTICLE 2 – Prime - Capital - Rente</Text>
          </View>
          <View style={{marginTop:"10"}}>
          <PrimeCPTableau t={t} type={type} info={info}  paiement={paiement} data={data}/>
          </View>
          <Signatures data={data} signatures={signatures} />
        </View>
        <InvoiceTableFooter />
      </Page>
    </Document>
 
  );
}
