import moment from "moment";

export default function ResumVoyage({ data, t }) {
  console.log(data);
  return (
    <div>
      <div className="form-group mb-3">
        <label className="form-label me-2" htmlFor="portfolio">
          {t("Date de départ")}
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi bi-calendar-event text-purple" />
          </span>
          <input
            className="form-control"
            type="date"
            disabled
            value={moment(data?.date_depart).format("YYYY-MM-DD")}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="form-label me-2" htmlFor="portfolio">
          {t("Date de retour")}
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi bi-calendar-event text-purple" />
          </span>
          <input
            className="form-control"
            type="date"
            disabled
            value={moment(data?.date_retour).format("YYYY-MM-DD")}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="form-label me-2" htmlFor="portfolio">
          {t("Pays de départ")}
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi bi-geo text-purple" />
          </span>
          <input
            className="form-control"
            type="text"
            disabled
            value={data?.pays_depart}
          />
        </div>
      </div>
      <div className="form-group mb-3">
        <label className="form-label me-2" htmlFor="portfolio">
          {t("Date de retour")}
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            <i className="bi bi-geo text-purple" />
          </span>
          <input
            className="form-control"
            type="text"
            disabled
            value={data?.pays_destination}
          />
        </div>
      </div>
    </div>
  );
}
