import { comparaisonBetweenTwoDates } from "../../../../utils/Comparaison";
// # FORMULES
import { getCoutPolice, getPrimeNette } from "../../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";

export function calculResultVoyage(  category_id, age,  date_depart,  date_retour ,formule) {
 
  var duree = comparaisonBetweenTwoDates(date_depart, date_retour) + 1;
  
  getPrimeNette().then((result) => {
    if(result?.data?.code === 200){
      getCoutPolice().then((res) => {
        if(result?.data?.code === 200){
          var prime_nette = result?.data?.data
          var cout_police = res?.data?.data
          var value_cout = 0;
          var value_prime = 0;
          getParamTechByCategorie(category_id).then((result) => {
            if(result?.data?.code === 200){
              const found_duree =cout_police?.filter((element)=> (parseInt(element.duree) >= parseInt(duree)) && (element.type===formule) )
              const found_primenette =prime_nette?.filter((element)=>(element.type===formule) && (parseInt(element.duree) >= parseInt(duree)))
              var taxes = parseFloat(result?.data?.data[0]?.taxes)/100
           
              if (parseInt(age) >= 0 && parseInt(age) <= 17) {
                value_cout = found_duree[0]?.age1;
                value_prime = found_primenette[0]?.age1;
                return ({value_cout : value_cout,
                  value_prime : value_prime,
                  taxes :taxes
                })
              } else if (parseInt(age) >= 18 && parseInt(age) <= 65) {
                value_cout = found_duree[0]?.age2;
                value_prime = found_primenette[0]?.age2;
                return ({value_cout : value_cout,
                  value_prime : value_prime,
                  taxes :taxes
                })
              } else if (parseInt(age) >= 66 && parseInt(age) <= 75) {
                value_cout = found_duree[0]?.age3;
                value_prime = found_primenette[0]?.age3;
                return ({ value_cout : value_cout,
                  value_prime : value_prime,
                  taxes :taxes
                })
              } else if (parseInt(age) >= 76 && parseInt(age) <= 80) {
                value_cout = found_duree[0]?.age4;
                value_prime = found_primenette[0]?.age4;
                return ({ value_cout : value_cout,
                  value_prime : value_prime,
                  taxes :taxes
                })
              }
            }
          }).catch((err) => {
            console.log(err)
          });
        }
      }).catch((err) => {
        console.log(err)
      });
    }
  }).catch((err) => {
    console.log(err)
  });

}
/**
 * CALCUL PRIME NETTE
 * @param {*} array 
 * @returns 
 */
export function sumPrimeNette ( array ) {
    return array.reduce((accumulator, object) => {
        return accumulator + object.prime_nette;
      }, 0);
}
/**
 * CALCUL PRIME TTC
 * @param {*} array 
 * @returns 
 */
export function sumPrimeTTC ( array ) {
    return array.reduce((accumulator, object) => {
        return accumulator + object.prime_ttc;
      }, 0);
}

/**
 * CALCUL COUT POLICE
 * @param {*} array 
 * @returns 
 */
export function sumCoutPolice ( array ) {
    return array.reduce((accumulator, object) => {
        return accumulator + object.cout_police;
      }, 0);
}

/**
 * Calcul total of taxe
 * @param {*} array 
 * @returns 
 */
export function sumTaxe ( array ) {
    return array.reduce((accumulator, object) => {
        return accumulator + object.taxe;
      }, 0);
}

