import { View, StyleSheet } from "@react-pdf/renderer";
import ReferenceSouscripteur from "./ReferenceSouscripteur";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    marginBottom: 0,
    borderColor:"#fff"

  },
});
export default function TableReferenceConatainer({ t, type, info, data, user_details, personnel,
}) {
  return (
    <View style={styles.tableContainer}>
      <ReferenceSouscripteur
        type={type}
        data={data}
        user_details={user_details}
        personnel={personnel}
      />
    </View>
  );
}
