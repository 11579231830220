import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loading/Loader";
import { getBannieresByid } from "../../../services/publicite/bannerAPI";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

export default function DetailsBanner() {

  const { id } = useParams("");
  //---------- DETAILS NEWS -----------
  const [details, setdetails] = useState({});
  const language = Cookies.get('locale')
 //------------- Traduction ---------------
 const [t] = useTranslation("global");
  //---------- Loading -----------------
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    getBannieresByid(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setdetails(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      {Loading ? (
        <Loader />
      ) : (
        <div className="page-content-wrapper">
          <div className="container">
            <div className="pt-3 d-block" />
            <div className="blog-details-post-thumbnail position-relative">
              <img
                className="w-70 rounded-lg"
                src={process.env.REACT_APP_IMAGE + details?.image}
                alt="blog"
              />
            </div>
          </div>
          <div className="blog-description py-3">
            <div className="container">
              <h3 className="mb-3 display-6  text-purple ">{language==="fr" ? details?.titre : details?.titre_en}</h3>
              <p className="fz-12">{language==="fr" ? details?.description : details?.description_en }</p>
              
              {(details?.url !== "" ||details?.url !== undefined ||details?.url !== null) && 
              <div className="float-end">
                <a className="btn btn-success fz-12" href={details?.url}>
                  {t("Cliquez-ici")}
                </a>
              </div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
