import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#fff";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 20,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#fff",
    color: "#443f52",
  },
  billTo: {
    letterSpacing: 1,
    paddingBottom: 3,
    fontSize: 10,
    fontFamily:'Arial',
    fontStyle:"normal"  },
  description2: {
    width: "70%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontFamily:'Arial',
    fontStyle:"bold"  },
  description1: {
    width: "70%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontFamily: "Times-Roman",
    paddingLeft: 8,
  },
  description: {
    width: "30%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontFamily:'Arial',
    fontStyle:"normal",
        paddingLeft: 8,
  },
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 16,
    fontFamily:'Arial',
    fontStyle:"bold",
    fontSize: 10,
  },
  text: {
    fontFamily:'Arial',
    fontStyle:"bold",
    color:"red"
},
});

const BillTo = ({ t, data,info,facture }) => (
  <Fragment>
    <View style={styles.tableContainer}>
      <View style={styles.row}>
        <Text style={styles.description2}>{data?.category?.titre}</Text>
        <Text style={styles.description}>
          {t("Police N°")}: {data?.policy_code}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          {t("Compagnie d'assurance")} : {data?.company?.nom}
        </Text>
        <Text style={styles.description}>N°Facture: {facture[0]?.id} </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Adresse Compagnie : {data?.company?.adresse}{" "}
        </Text>
        <Text style={styles.description}>
          {t("Date Effet")}: {moment(data?.date_effet).format("DD/MM/YYYY")}{" "}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Télèphone Compagnie : {data?.company?.mobile}
        </Text>
        <Text style={styles.description}>
        <Text style={styles.text} > {t("Date Echéance")}:{" "}
          { data?.auto !== null && moment(data?.date_effet).subtract(1, "days").add(data?.auto?.duree, "months").format("DD/MM/YYYY") }
          { data?.education !== null && moment(data?.date_effet).subtract(1, "days").add(data?.duree, "y").format("DD/MM/YYYY") }
          { data?.dece !== null && moment(data?.date_effet).subtract(1, "days").add(data?.dece?.duree_cotisation, "y").format("DD/MM/YYYY") }
          { data?.retraite !== null && moment(data?.date_effet).subtract(1, "days").add(data?.duree, "y").format("DD/MM/YYYY") }
          { data?.accident !== null && moment(data?.date_effet).subtract(1, "days").add(data?.accident?.duree, "months").format("DD/MM/YYYY") }
          {data?.category?.type === "voyage" && moment(data?.date_effet).subtract(1, "days").add(data?.duree,"day").format("DD/MM/YYYY") }

          </Text> 
       
        </Text>
      </View>
    </View>
  </Fragment>
);

export default BillTo;
