/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/loading/Loader";
import Icon from "../../../../components/obligatoire/Icon";
//import tableCIMA from "../../../../formules/education/Simulateur_Rente_éducation_TABLE_CIMA_H_.json";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import CurrencyInput from "react-currency-input-field";
import { getCIMA } from "../../../../services/formules/FormulesAPI";
import { warning_message } from "../../../../components/alerte/AlerteMessage";

export default function DevisEducation({ categorie_id, t }) {
  const { id, title, type } = useParams("");
  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  const [show, setshow] = useState(false);
  // Results
  const [results, setResults] = useState({
    capitalacquis: 0, // Capital acquis
    rente: 0, // RENTRE certaine service
  });

  //OBJET des parametres techniques selon la catégorie (with id categorie dans les params)
  const [paramTech, setparamTech] = useState({});
  //Fact d'actualisation pour la formule du commutateur
  const [fraisActualisation, setfraisActualisation] = useState(0);
  //TABLE DE CIMA H
  const [cima, setcima] = useState([]);
  //TABLEAU COMMUTATEUR
  var commutateur = {};

  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, watch } = useForm(); //FORM DATA

  useEffect(() => {
    getCIMA().then((result) => {
      if (result?.data?.code === 200) {
        // setCIMA(result?.data?.data)
        var CIMA = result?.data?.data
        getParamTechByCategorie(categorie_id)
          .then((result) => {
            if (result?.data?.code === 200) {
              //---- 1- FIRST STEP: GET PARAMETERE TECHNIQUE
              var data = result?.data?.data[0];
              setparamTech(data);
              //---- 2- SECOND STEP: GET  frais acquisation
              var factAct = 1 / (parseFloat(data?.taux_technique) + 1); // FORMULE DE CALCUL DE LA VALEUR DE FACT D ACTUALISATION dans la table parametre technique
              setfraisActualisation(factAct);

              // ---3- FIRST STEP OF CHANGING CIMA TABLE
              var newCIMA = [];
              CIMA?.map((items, keys) => {
                var obj = {
                  Age: keys,
                  Lx: parseFloat(items.Lx),
                  dx: parseFloat(items.dx),
                  Dx: parseFloat(items.Lx) * Math.pow(factAct, keys),
                  Cx: parseFloat(items.dx) * Math.pow(factAct, keys + 1 / 2),
                  Mx: 0,
                  Rx: 0,
                  Nx: 0,
                };
                newCIMA.push(obj);
              });
              setcima(newCIMA);
            }
          })
          .catch((err) => {
            console.log(err);
          });

      }
    })

  }, []);

  function onFormSubmit(dataRes) {
    setloaderResult(true);
    var { age, prime_commercial, periodicite, duree, cotisation } = dataRes;
    const prime = prime_commercial.replace(/\s+/g, '')
    var newCIMA = [];
    if (age > 20 || age < 0) {
      warning_message(t("L'age du béneficiaire doit être inférieur ou égale à 20 ans "))
      setshow(false);
    } else if (parseInt(duree) > 5) {
      warning_message(t("La durée de la rente doit être inférieur ou égale a 5 ans  "))
      setshow(false);
    } else if (cotisation < 5 || cotisation > (25 - age)) {
      warning_message(t("La durée de cotisation doit être dans l'intervalle [5 ans - ") + (25 - age) + t(" ans]"))
      setshow(false);
    } else {
      setshow(true);

      cima?.map((items, keys) => {
        //calcul of sum MX by iteration commutateur
        const sumMx = cima.slice(keys).reduce((accumulator, object) => {
          return accumulator + (object.Cx);
        }, 0);
        //calcul of sum NX by iteration commutateur
        const sumNx = cima.slice(keys).reduce((accumulator, object) => {
          return accumulator + (object.Dx);
        }, 0);
        var obj = {
          Age: keys,
          Lx: parseFloat(items.Lx),
          dx: parseFloat(items.dx),
          Dx: parseFloat(items.Dx),
          Cx: parseFloat(items.Cx),
          Mx: sumMx,
          Rx: 0,
          Nx: sumNx,
        };
        newCIMA.push(obj);
      });
      setcima(newCIMA);
      // COMMUTATEUR
      Object.assign(commutateur, {
        nx: newCIMA[parseInt(age)]?.Nx,
        nxp: newCIMA[parseInt(age) + parseInt(cotisation)].Nx,
        dx: newCIMA[parseInt(age)]?.Dx,
        dxp: newCIMA[parseInt(age) + parseInt(cotisation)].Dx,
        mx: newCIMA[parseInt(age)]?.Mx,
        mxp: newCIMA[parseInt(age) + parseInt(cotisation)].Mx,
        xep: parseFloat(
          newCIMA[parseInt(age) + parseInt(cotisation)].Dx /
          newCIMA[parseInt(age)]?.Dx
        ),
        axp:
          parseFloat(
            newCIMA[parseInt(age)]?.Mx -
            newCIMA[parseInt(age) + parseInt(cotisation)].Mx
          ) / newCIMA[parseInt(age)]?.Dx,
        axpp:
          parseFloat(
            newCIMA[parseInt(age)]?.Nx -
            newCIMA[parseInt(age) + parseInt(cotisation)]?.Nx
          ) / newCIMA[parseInt(age)]?.Dx,
        axpm:
          parseFloat(
            newCIMA[parseInt(age)]?.Nx -
            newCIMA[parseInt(age) + parseInt(cotisation)].Nx
          ) /
          newCIMA[parseInt(age)].Dx -
          ((periodicite - 1) / (2 * periodicite)) *
          (1 -
            parseFloat(
              newCIMA[parseInt(age) + parseInt(cotisation)].Dx /
              newCIMA[parseInt(age)].Dx
            )),
      });

      setResults({
        capitalacquis:
          (periodicite *
            (1 -
              parseFloat(paramTech?.frais_acquisition) -
              parseFloat(paramTech?.frais_gestion)) *
            parseFloat(prime) *
            commutateur?.axpm) /
          (1 * (commutateur?.axp + commutateur?.xep)),
        rente: parseFloat(duree) > 0 ?
          ((1 - parseFloat(paramTech?.frais_gestion_rente)) *
            ((periodicite *
              (1 -
                parseFloat(paramTech?.frais_acquisition) -
                parseFloat(paramTech?.frais_gestion)) *
              parseFloat(prime) *
              commutateur?.axpm) /
              (1 * (commutateur?.axp + commutateur?.xep))) *
            (1 - fraisActualisation)) /
          (1 - Math.pow(fraisActualisation, duree)) : 0,
      });
    }
    setloaderResult(false);
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="card mb-2  bg-transparent m-1 rounded-lg">
          <div className="card-body">
            <h5 className=" mb-4 text-center"> {t("Devis Education")} </h5>
            <div className="text-center">
              <img
                className="mb-4 text-center"
                src="../img/bg-img/21.png"
                width={"100px"}
                alt="Assurance vie"
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Age de l'assuré")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="number"
                placeholder={"Age Assuré"}
                {...register("age")}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Periodicité")} <Icon />
              </label>
              <select
                className="form-select fz-12"
                {...register("periodicite")}
              >
                <option value={""}>{t("Choisir")} </option>
                <option value={1}>{t("Annuelle")}</option>
                <option value={2}>{t("Semestrielle")}</option>
                <option value={4}>{t("Trimistrielle")}</option>
                <option value={12}>{t("Mensuelle")}</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Prime Commerciale")} (FCFA) <Icon />
              </label>

              <CurrencyInput
                name="prime_commercial"
                className="form-control fz-12"
                placeholder="Prime ..."
                decimalScale={2}
                {...register("prime_commercial")}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Durée de la rente (année)")} <Icon />
              </label>
              <select
                className="form-select fz-12"
                {...register("duree")}
                required
              >
                <option value={""}>{t("Choisir")} </option>
                <option value={0}>{t("Option sans rente")}</option>
                <option value={1}>1 {t("an")}</option>
                <option value={2}>2 {t("ans")}</option>
                <option value={3}>3 {t("ans")}</option>
                <option value={4}>4 {t("ans")}</option>
                <option value={5}>5 {t("ans")}</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Durée de cotisation")}
              </label>
              <input
                className="form-control fz-12"
                type="number"
                placeholder="Durée de Cotisation"
                {...register("cotisation")}
                required
              />
              <label className="fz-12 text-purple mt-2"> {((watch("age") !== "" && watch("age") <= 25) ? "L'intervalle d'age permis pour la durée de cotisation est de [ 5 ans - " + (25 - watch("age")) + "ans ]" : "")}</label>
            </div>
            <button type="submit" className="btn btn-purple col-12 fz-12">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-calculator me-2"
                viewBox="0 0 16 16"
              >
                <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
              </svg>
              {t("Calculer")}
            </button>
          </div>
        </div>
        {show && (
          <div className="card mb-4 " >
            <div className="card-body">
              <h5 className="text-center" id="section-2"> {t("Total du calcul")} </h5>
              <table className="table  mb-0 table-striped-primary mt-3">
                {loaderResult ? (
                  <Loader />
                ) : (
                  <tbody className="fz-12">

                    <tr>
                      <td>{t("Capital Acquis")} (FCFA) </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.capitalacquis))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Rente certaine service")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.rente))}{" "}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" float-end">
                  <a
                    href={
                      "facturation_produits/id=" +
                      id +
                      "&title=" +
                      title +
                      "&type=" +
                      type
                    }
                    className="btn btn-success col-12 fz-12 mt-3 mb-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-receipt me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                    </svg>
                    {t("Souscrire")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

      </form>
    </div>
  );
}
