
export default function Prefix({register}) {
    return (
        <select className="form-select fz-12"  name="dialingcode"  {...register("prefix")}
        required>
            <option value="+221" selected>+221</option>
            <option value="+225">+225</option>
            <option value="+223">+223 </option>
            <option value="+216">+216 </option>
        </select>
    )
}
