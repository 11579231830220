/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ModalLanguages from '../languages/ModalLanguages';
import { useHistory } from 'react-router-dom';
import ModalNotifcations from './ModalNotifcations';
import { getMessages } from '../../services/messages/messageAPI';

export default function Header({ isDark, setisDark, handleDark }) {

  const history = useHistory();
  const [ok, setOk] = useState(false)
  // LISTE NOTIFCIATIONS
  const [LISTE, setLISTE] = useState([]);
  // ----- Language global selectionné
  const language = Cookies.get('locale')
  const [lg, setLg] = useState(language === 'fr' ? 'Français' : "English")
  // ---- outil de Traduction ----------
  const [t, i18n] = useTranslation('global')

  // SELECT CHANGE LANGUAGES 
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    if (language === 'fr') {
      setLg('Français')
    } else if (language === 'en') {
      setLg('English')
    }
    Cookies.set('locale', language)
  }
  useEffect(() => {
    getMessages()
      .then((result) => {
        if (result?.data?.code === 200) {
          var L = result?.data?.data?.filter((el) => el.is_read === 0)
          setLISTE(L);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ok]);

  return (
    <div className="header-demo-bg shadow-sm" >
      <div className="container">
        {/* # Header Two Layout */}
        {/* Header Content */}
        <div className="header-content header-style-two position-relative d-flex align-items-center justify-content-between" >
          {/* Logo Wrapper */}
          <div className="logo-wrapper">
            {history.location.pathname !== "/" ? <button onClick={() => history.goBack()} className="btn ">
              <b><i className="bi bi-arrow-left text-purple fz-18" /></b>
            </button> : <div>
              <a href="/" className='text-secondary'>
                {isDark ? <img src="img/core-img/logo-dark.png" alt="logo" width={"80%"} /> : <img src="img/core-img/logo.png" alt="logo" width={"50%"} />}
              </a>
            </div>}
          </div>
          <div className="navbar-content-wrapper d-flex align-items-center">
            <div style={{ marginRight: "20px" }}>
              <div className="setting-wrapper ml-2" onClick={() => setOk(true)} data-bs-toggle="modal" data-bs-target="#notification" style={{ marginRight: "10px !important" }}>
                <div id="settingTriggerBtn">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "relative", color: isDark ? "#fff" : "#1f0757" }} id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={20} height={20}>
                    <path d="M22.94,15.88l-1.46-8.18c-.38-2.15-2.25-3.71-4.43-3.71h-.56c.31-.42,.5-.94,.5-1.5,0-1.38-1.12-2.5-2.5-2.5h-5c-1.38,0-2.5,1.12-2.5,2.5,0,.56,.19,1.08,.5,1.5h-.56c-2.18,0-4.05,1.56-4.43,3.71L1.06,15.88c-.18,1.02,.1,2.07,.76,2.86,.67,.8,1.65,1.25,2.68,1.25h3.5c0,2.21,1.79,4,4,4s4-1.79,4-4h3.5c1.04,0,2.02-.46,2.68-1.25,.67-.8,.94-1.84,.76-2.86ZM8,2.5c0-.83,.67-1.5,1.5-1.5h5c.83,0,1.5,.67,1.5,1.5s-.67,1.5-1.5,1.5h-5c-.83,0-1.5-.67-1.5-1.5Zm4,20.5c-1.65,0-3-1.35-3-3h6c0,1.65-1.35,3-3,3Zm9.42-4.9c-.48,.57-1.18,.9-1.92,.9H4.5c-.74,0-1.44-.33-1.92-.9-.48-.57-.67-1.31-.54-2.04L3.5,7.88c.3-1.67,1.75-2.88,3.45-2.88h10.11c1.7,0,3.15,1.21,3.45,2.88l1.46,8.18c.13,.73-.07,1.48-.54,2.04Z" fill={isDark ? "#fff" : "#1f0757"} />
                  </svg>
                  <span class=" badge rounded-pill bg-danger" style={{ fontSize: "8px", position: "absolute", top: "4px", marginLeft: "-5px" }}>
                    {LISTE?.length}
                  </span>
                </div>
              </div>
            </div>
            <div className="search-wrapper me-2">
              <button className="btn search-trigger-btn" onClick={() => handleDark(isDark, setisDark)}>
                {isDark ? <i className="bi bi-moon" style={{color: isDark ? "#fff" : "#1f0757" }} /> : <i className="bi bi-sun" style={{color: isDark ? "#fff" : "#1f0757" }} />}
              </button>
            </div>
            {/* Search */}
               <div className="search-wrapper me-2">
              <a className="search-trigger-btn" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" fill={isDark ? "#fff" : "#1f0757"} width={20} height={20}><path d="m24,12C24,5.5,18.897,0,12,0,5.383,0,0,5.383,0,12s5.383,12,12,12c7.179,0,12-5.667,12-12Zm-1.031.833l-3.183-1.287c-.069-.035-.12-.097-.139-.173l-.627-2.506c-.028-.113.024-.237.14-.304l2.606-1.623c.789,1.516,1.235,3.237,1.235,5.06,0,.28-.011.558-.031.833ZM12,1c.095,0,.191.001.285.004-.643.862-1.64,2.204-1.825,2.49-.393.608-.405,1.374-.032,1.997l.733,1.222-.699-.35c-.696-.349-1.53-.212-2.075.337l-.529.529c-.34.339-.526.791-.526,1.271,0,.171.023.339.07.5h-1.8c-.109,0-.216-.045-.294-.128l-2.625-2.712C4.63,3.063,8.079,1,12,1Zm0,22c-6.065,0-11-4.935-11-11,0-1.772.422-3.447,1.169-4.932l2.416,2.495c.264.277.634.437,1.017.437h1.948c.29,0,.563.113.769.318l.372.373c.197.197.31.469.31.746v.963c0,.389.151.753.425,1.025l1.189,1.19c.249.249.386.58.386.931v2.365c0,.601.489,1.09,1.09,1.09h.213c.424,0,.812-.249.989-.633l2.241-4.855c.267-.577.062-1.265-.478-1.601l-2.562-1.603c-.323-.201-.695-.308-1.076-.308h-1.431c-.31,0-.612-.125-.83-.344l-.592-.592c-.15-.15-.233-.351-.233-.564s.083-.414.233-.564l.53-.53c.242-.243.613-.303.92-.148l.879.439c.364.184.804.112,1.085-.176.307-.307.366-.779.145-1.149l-.838-1.396c-.176-.294-.17-.654.015-.94.191-.295,1.497-2.047,2.164-2.939,3.271.437,6.092,2.316,7.8,4.976l-2.619,1.631c-.486.28-.731.857-.596,1.403l.627,2.505c.089.36.331.662.699.843l3.467,1.403c-.886,5.183-5.41,9.14-10.842,9.14Zm-.679-9.093l-1.19-1.191c-.084-.084-.131-.196-.131-.317v-.963c0-.147-.017-.294-.048-.437.012,0,.022,0,.034,0h1.431c.193,0,.383.054.548.156l2.562,1.603c.112.07.154.213.099.333l-2.322,4.908h-.213c-.049,0-.09-.041-.09-.09v-2.365c0-.618-.241-1.2-.679-1.638Z" /></svg>
              </a>
            </div> 

            {/* Navbar Toggler */}
            <div className="navbar--toggler" id="affanNavbarToggler" data-bs-toggle="offcanvas" data-bs-target="#affanOffcanvas" aria-controls="affanOffcanvas">
<svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" fill={isDark ? "#fff" : "#1f0757"} width={20} height={20}><rect y={11} width={24} height={2} rx={1} /><rect y={4} width={24} height={2} rx={1} /><rect y={18} width={24} height={2} rx={1} /></svg>
            </div>
          </div>
        </div>
      </div>
      {/* # Notification */}
      {ok && <ModalNotifcations t={t} setOk={setOk} LISTE={LISTE} setLISTE={setLISTE} />}
      {/* # Header Two Layout End */}
      <ModalLanguages changeLanguage={changeLanguage} lg={lg} t={t} />
    </div>

  )
}
