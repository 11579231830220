/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState ,useEffect} from "react";
// # Traduction Library 
import { useTranslation } from "react-i18next";
// EXTRACT INFO USER FROM LOCAL STORAGE
import { useUser } from "../../Auth/useUser";
// Components
import Loader from "../../components/loading/Loader";
// # DATE AND TIME BIB 
import moment from "moment";
// # SERVICES
import { getSinistreUser } from "../../services/sinistre/sistreAPI";
import Cookies from 'js-cookie';

export default function ListeDeclarations() {
  const [LISTE, setLISTE] = useState([]); //LISTE DES DECLARATIONS SINSITRES
  //---------- LOADING -----------------------
  const [loading, setloading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //--------INFORMATIONS OF ACTUAL USER---------
  const user = useUser();
  var { id } = user.result;
  const language = Cookies.get('locale')
  { language === "ar" ? require('moment/locale/ar-tn') :language === "fr"  ? require('moment/locale/fr') :require('moment/locale/en-au') }

  useEffect(() => {
  
    getSinistreUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setLISTE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          {LISTE?.length !== 0 ? (
            <>
              <h3 className="text-center fz-16 mb-3 mt-3">
                {t("Liste des déclarations Sinistres")}
              </h3>
              {LISTE?.map((items, keys) => (
                <div className="card timeline-card" key={keys}>
                  <div className="card-body">
                  <span className="badge bg-purple rounded-pill mb-2 d-inline-block">
                    {moment(items.created_at).format("DD MMMM YYYY ")}
                  </span>
                  {items.status === "opened" ? (
                    <span className="badge bg-success rounded-pill mb-2 d-inline-block float-end">
                      {t("Consulté")}
                    </span>
                  ) : (
                    <span className="badge bg-warning rounded-pill mb-2 d-inline-block float-end">
                      {t("Non Traité")}
                    </span>
                  )}
                    <p>{t("Assurance")} : <b>{items.category?.titre}</b> </p>
                    <p>{t("N° Police")} : <b>{items.n_police}</b> </p>

                    <p >
                      {items.description !== ""
                        ? (items.category.description).slice(0,50)
                        : t("Aucune description")}
                    </p>
                    <div className="timeline-tags">
                      <span className="badge bg-light ">
                        <a className="text-purple fz-12" href={"/details_sinistea&id=" + items.id}>
                          {t("Voir les détails de la déclaration")}
                          <i className="bi bi-arrow-right me-2" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <a
                className="btn btn-creative btn-purple fz-12 col-12"
                href="/declaration_sinistre"
              >
                {t("Ajouter une déclaration sinistre")}
              </a>
            </>
          ) : (
            <div className="card">
              <div className="card-body px-5 text-center">
                <h4>
                  {t("Aucune Déclaration Sinistre")}
                  <br />
                </h4>
                <p className="mb-4 fz-12">
                  {t("Vous n'avez envoyé aucune déclaration sinistre , si vous souhaitez faire une déclaration veuillez cliquer sur le bouton ajouter" )}
                </p>
                <img className="mb-4" src="img/bg-img/100.png" alt="NO DATA" />
                <a
                  className="btn btn-creative btn-purple fz-12 col-12"
                  href="/declaration_sinistre"
                >
                  {t("Ajouter une déclaration sinistre")}
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
