
/**
 * GET SESSION 
 * @param {*} value 
 * @returns 
 */
 export function getSession(value) {
    return localStorage.getItem(value);
  }

/**
 * Check If user connected
 * @returns 
 */
export const isLogin = () => {
  if (getSession('user') !== null) {
    return true;
  }
  else{ return false};
};

/**
 * SPACE BETWEEN TWO NUMBERS
 * @param {*} x 
 * @returns 
 */
export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}


