/** 
 * AUTH HEADER AND STORE JWT TOKEN
 */
export default function authHeader() {
  
    //GET USER FROM LOCAL STORAGE
    const user = localStorage.getItem('token');
    if (user ) {
      // for Node.js Express back-end
      return { 'Authorization' : 'Bearer '+ user};
    } else {
      return {};
    }
    
  }