import React from "react";
import Compagny from "../components/compagny/Compagny";

export default function Step4({ t, register, id, setShow4, setShow3, show4 ,Liste_Compagny }) {
  return (
    <div className="shop-pagination pb-3">
      <Compagny t={t} register={register} id={id} Liste_Compagny={Liste_Compagny}/>
      <div className="mt-3 mb-3">
        <button className="btn btn-purple float-end fz-12 mb-3" type="submit">
          {t("Paiement")}
          <i className="bi bi-arrow-right me-1 fz-12" />
        </button>
        <button
          className="btn btn-success float-start fz-12 mb-3 text-white"
          onClick={() => {
            setShow4(!show4);
            setShow3(true);
          }}
        >
          <i className="bi bi-arrow-left me-1 fz-12" />
          {t("Previous Step")}
        </button>
      </div>
    </div>
  );
}
