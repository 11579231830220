
import { useEffect, useState } from "react"
import { useUser } from "../../../Auth/useUser"
import Loader from "../../../components/loading/Loader"
import { getBannierePubByDate } from "../../../services/publicite/bannerAPI"
import _ from "lodash"

export default function MainSlider({ t, language }) {
  const [Liste, setListe] = useState([])
  const user = useUser()
  const [Loading, setLoading] = useState(true)
  var { nom, prenom } = user?.result !== undefined ? user?.result : ""

  useEffect(() => {
    getBannierePubByDate()
      .then((result) => {
        if (result?.data?.code === 200) {
          setListe(_.orderBy(result?.data?.data, "created_at", ['desc']))
        }
      })
      .catch((err) => {
        console.log(err);
      })
     
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (Liste.length > 0) {
        const activeIndex = Liste.findIndex((item) => item.isActive)
        const nextIndex = activeIndex === Liste.length - 1 ? 0 : activeIndex + 1
        const updatedList = Liste.map((item, index) => ({
          ...item,
          isActive: index === nextIndex
        }))
        setListe(updatedList)
      
          setLoading(false)
        
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [Liste])

  return (
    <div className="container">
      <h6 className="display-6 mb-2" style={{ fontSize: "18px" }}>
        {t("Bienvenue")}{" "}
        {nom !== "null" && prenom !== "null" &&
          prenom.charAt(0).toUpperCase() + prenom.slice(1) + " " + nom.charAt(0).toUpperCase() + nom.slice(1)},</h6>
      {Loading ? (
        <Loader />
      ) : (
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators" style={{ borderRadius: "20px" }}>
            {Liste?.map((items, keys) => (
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to={keys}
                aria-current="true"
                className={keys === 0 ? "active text-white" : "text-white"}
              />
            ))}
          </div>
          <div className="carousel-inner">
            {Liste?.map((items, keys) => (
              <div
                className={items.isActive ? "carousel-item active" : "carousel-item"}
                data-bs-interval="5000"
                key={keys}
              >
                <div
                  className="card card-bg-img bg-img bg-overlay mb-3"
                  style={{
                    backgroundImage: "url(" + process.env.REACT_APP_IMAGE + items.image + ")",
                  }}
                >
                  <div className="card-body direction-rtl p-5">
                    <h2 style={{ color: "#f3d55b" }}>
                      {language === "fr" ? items.titre : items.titre_en}
                    </h2>
                    <p className="mb-4 text-white fs-10">
                      {language === "fr"
                        ? items.description.substring(0, 80):(items.description_en).substring(0,80)  } ...</p>
                  <a style={{ color: "#f3d55b" }} className=" fs-10 float-end" href={"/details_publicite/" + items.id} ><b>{t("Plus de détails")}</b></a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>)}

    </div>
  )
}
