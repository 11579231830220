import { useForm } from "react-hook-form";
import {
  error_message,
  success_message,
} from "../../components/alerte/AlerteMessage";
import Icon from "../../components/obligatoire/Icon";
import { verifyCodePIN } from "../../services/users/usersAPI";
/**
 * VERIFICATION DE LA COMPATIBILTE DU CODE PIN PAR RAPPORT AU CODE CHIFFREE DANS LA BASE DE DONNEE
 *
 * 1- CETTE INTERFACE EST OUVERTE PAR DEFAUT ET NE PEUT ETRE FERME QUE SI L UTILISATEUR SAISIE LE BON CODE PIN
 *
 * @returns
 */

export default function ModalSaisieCode({ id, setshowCodePIN, t }) {
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset } = useForm(); //FORM DATA

  const verificationPIN = (data) => {
    verifyCodePIN(id, { code_PIN: data?.code_PIN })
      .then((result) => {
        if (result?.data?.code === 200) {
          success_message(t("Vous avez saisie le bon Code PIN"));
          setshowCodePIN(false);
        } else {
          error_message(t("Vous devez saisir un code PIN valide!"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className="modal fade show"
      id="bootstrapBasicModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" >
              {t("Saisie du Code PIN")}
            </h6>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(verificationPIN)}>
              <div className="text-center">
                <img
                  className="mb-4 text-center"
                  src="../img/bg-img/Wavy_Tech-30_Single-03.png"
                  width={"150px"}
                  alt="Assurance vie"
                />
              </div>
              <div className="form-group">
                <label className="form-label fz-12" htmlFor="exampleTextarea1">
                  {t("Code PIN")} <Icon />
                </label>
                <input
                  className="form-control fz-12"
                  id="psw-input"
                  type="password"
                  {...register("code_PIN")}
                  placeholder="Code PIN"
                  required
                />
              </div>
              <div className="float-end">
                <button className="btn btn-sm btn-success" type="submit">
                  <i className="bi bi-file-earmark-lock me-1" />
                  {t("Vérifier")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
