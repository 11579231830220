import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUser } from "../../Auth/useUser";
import DotLoader from "../../components/loading/DotLoader";
import {
  getUser,
  updateCodePIN,
  verifyUser,
} from "../../services/users/usersAPI";
import ModalPassword from "./ModalPassword";
import { useHistory } from "react-router-dom";
import { success_message } from "../../components/alerte/AlerteMessage";

/**
 * CODE PIN security interface
 * @returns
 */
export default function Security() {
  //-------------- HISTORY -------------
  const history = useHistory();

  const user = useUser();
  var { id } = user?.result !== undefined ? user?.result : "";

  const [password, setpassword] = useState("");
  //------------ ETAT DE PERMISSION SETTINGS ----------
  const [verif_authentification, setverif_authentification] = useState(false);
  const [gestion_contrat, setgestion_contrat] = useState(false);
  const [verif_paiement, setverif_paiement] = useState(false);
  //------------- Traduction -------------
  const [t] = useTranslation("global");
  // ------------- Loading ---------------
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var data = result?.data?.data;
          setverif_paiement(
            data?.verification_paiement_avec_code_pin === 0 ? false : true
          );
          setgestion_contrat(
            data?.gestion_contrat_code_pin === 0 ? false : true
          );
          setverif_authentification(
            data?.authentification_code_pin === 0 ? false : true
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifPassword = () => {
    // verifier si le mot de passe est identitque
    verifyUser(id, { password: password })
      .then((result) => {
        if (result?.data?.code === 200) {
          success_message()
          toast(t("Mot de passe valide!"), {
            type: "success",
            position: "top-right",
          });
          history.push({ pathname: "/create_code_pin" });
        } else {
          toast(t("Mot de passe Invalide,Veuillez vérifier votre mot de passe"), {
            position: "top-right",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateConfigParametrage = () => {
    /**
     * UPDATE CODE PIN
     */
    var obj ={
        verification_paiement_avec_code_pin: verif_paiement===true? 1 : 0,
        gestion_contrat_code_pin: gestion_contrat===true? 1 : 0,
        authentification_code_pin: verif_authentification===true? 1 : 0,
    }
    updateCodePIN(id,obj)
      .then((result) => {
        if (result?.data?.code === 200) {
            toast(t("Le paramétrage du code PIN est sauvegardé avec succès!"),{type:"success", position:"top-right"})
        }else {
            toast(t("Le paramétrage n'est pas sauvegardé ! erreur temporaire"),{type:"error",position: "top-right",})
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12"></div>
            </div>

            <h5 className="mb-3 text-center display-5">
              {t("Paramétrage de code PIN")}
            </h5>
            <div className="text-center">
              <img
                className="mb-4 text-center"
                src="../img/bg-img/Wavy_Tech-30_Single-03.png"
                width={"150px"}
                alt="Assurance vie"
              />
            </div>
            {loading ? (
              <DotLoader />
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="single-setting-panel">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="availabilityStatus"
                          defaultChecked={verif_authentification}
                          onChange={(e) =>
                            setverif_authentification(e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="availabilityStatus"
                        >
                          {t("Authentification avec code PIN")}
                        </label>
                      </div>
                    </div>
                    <div className="single-setting-panel">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendMeNotifications"
                          defaultChecked={gestion_contrat}
                          onChange={(e) => setgestion_contrat(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sendMeNotifications"
                        >
                          {t("Gestion des contrats par Code PIN")}
                        </label>
                      </div>
                    </div>
                    <div className="single-setting-panel">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="darkSwitch"
                          defaultChecked={verif_paiement}
                          onChange={(e) => setverif_paiement(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="darkSwitch"
                        >
                          {t("Verification de paiement par Code PIN")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <button
                      className="btn btn-purple col-12 fz-10 mb-3 col-6"
                      onClick={() => updateConfigParametrage()}
                    >
                      {t("Ajouter")}
                    </button>
                 
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    
    </div>
  );
}
