/* eslint-disable no-unused-vars */
import { Page, Document, StyleSheet, Image, Text, View, Font } from '@react-pdf/renderer';
import moment from 'moment';

import InvoiceTableFooter from '../invoice/InvoiceTableFooter';
import EnteteContrat from '../conditionParticuliere/EnteteContrat';

// FONT TIMES NEW ROMAN
// import TimesNewRoman_normal from '../styles/TimesNewRoman/times.ttf'
import TimesNewRoman_normal from "../styles/TimesNewRoman/times.ttf"
import TimesNewRoman_bold from '../styles/TimesNewRoman/times-new-roman-grassetto.ttf'

const borderColor = '#fff'
const headerColor = "#7e02b0";

Font.register(
    {
        family: 'Arial',
        fonts: [
            {
                src: TimesNewRoman_normal,
                fontWeight: 400,
                fontStyle: "normal"
            },
            {
                src: TimesNewRoman_bold,
                fontWeight: 700,
                fontStyle: "bold"
            },

        ],
    });
const styles = StyleSheet.create({
    page: {
        fontSize: 10,
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        lineHeight: 1.5,
        fontFamily: 'Times-Roman',
        flexDirection: 'column',
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#e5e5e5',
        backgroundColor: headerColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    titleContainer: {
        flexDirection: "row",
        marginTop: 0,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'

    },
    headerPage: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    header: {
        width: '100%',
        borderRightColor: headerColor,
        borderRightWidth: 1,
        fontFamily: 'Times-Roman',
        backgroundColor: headerColor,
        color: "#fff",
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    reportTitle: {
        letterSpacing: 2,
        fontSize: 14,
        textAlign: "center",
        fontWeight: 800,
        textTransform: "uppercase",
        marginBottom: 0,
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
        borderWidth: 1,
        borderColor: "#fff",
    },
    description3: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 4,
        fontFamily: 'Times-Roman',
        marginTop: 0
    },
    description2: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: 'Times-Roman',

    },
    description1: {
        width: "25%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontFamily: 'Times-Roman',
        paddingLeft: 8,
    },
    description: {
        width: "75%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: 'Arial',
        fontStyle: "normal",
        textTransform: "capitalize"
    },
    descriptionright: {
        width: "100%",
        textAlign: "right",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: 'Arial',
        fontStyle: "normal",
    },
    descriptionLeft: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: 'Arial',
        fontStyle: "normal",
    },
    row: {
        flexDirection: "row",
        borderBottomColor: "#fff",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 16,
        fontFamily: 'Arial',
        fontStyle: "bold",
    },
    text: {
        margin: 12,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Arial',
        fontStyle: "normal",
        color: "#4e545d",
    },
    entete: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
    },
    entete1: {
        flexDirection: "row",
    },

    enteteRow2: {
        width: 100,
        textAlign: "right",
    },
    enteteRow3: {
        marginTop: 26,
        width: 200,
        fontSize: 18
    },
    certImage: { height: 80, width: 250 },
    invoiceTitle: {
        color: "#000",
        marginTop: 5,
        fontSize: 15,
        fontFamily: 'Arial',
        fontStyle: "bold",
    },
    headerTitle: {
        letterSpacing: 2,
        fontSize: 10,
        textAlign: "center",
        textTransform: "uppercase",
        marginBottom: 0,
    },
    logo2: {
        width: 60,
        height: 40,
        marginTop: -20,
        marginLeft: 30,
        textAlign: "right",
    },
    logo: {
        width: 80,
        height: 40,
        marginTop: -20,
    },
    enteteRow: {
        width: 100,
    },
    signatures: {
        width: 100,
        height: 50,
        marginTop: -20,
        textAlign: "right",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: "Times-Roman",
    },
});



export default function ContratPDF({ signatures, data, user_details, personnel }) {
    var url = process.env.REACT_APP_IMAGE + signatures[0]?.image
    return (
        <Document>
            
            <Page size="A4" style={styles.page}>
                <EnteteContrat data={data} />
                <View style={{ marginTop: "20" }}>
                    <Text style={styles.reportTitle}>Attestation d'assurance Voyage</Text>
                </View>
                <View style={styles.tableContainer}>
                    <View style={styles.row}>
                        <Text style={styles.text} widows={5}>Nous soussignée {data?.company?.nom} {data?.company?.adresse}, certifions que :</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Mr / Mme /Melle :</Text>
                        <Text style={styles.description}> {user_details?.prenom + " " + user_details?.nom}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Né(e) le  :</Text>
                        <Text style={styles.description}>  {moment(user_details?.date_naissance).format("DD/MM/YYYY")}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Passeport N°  :</Text>
                        <Text style={styles.description}>  {personnel?.n_passport}</Text>
                    </View>
                    <View  >
                        <Text style={styles.text}>
                            A souscrit dans notre compagnie un contrat d'ASSURANCE VOYAGE couvrant les FRAIS DE SOINS
                            MEDICAUX ET HOSPITALIERS D'URGENCE (par suite de maladie grave ou d'accidents survenus durant le
                            séjour assuré) ET/OU DE RAPATRIEMENT POUR RAISON MEDICALE OU POUR DECES.
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Voyage et séjour  :</Text>
                        <Text style={styles.description}> {data?.pays_alle} - {data?.pays_destination} - {data?.pays_alle}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Prime Nette  :</Text>
                        <Text style={styles.description}>  {data?.prime_nette} FCFA</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Coût de police :</Text>
                        <Text style={styles.description}>  {data?.cout_police} FCFA</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Taxe :</Text>
                        <Text style={styles.description}>  {data?.taxe} FCFA</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>Prime TTC :</Text>
                        <Text style={styles.description}>  {data?.prime_ttc} FCFA</Text>
                    </View>
                    <View  >
                        <Text style={styles.text}>
                            La dite police enregistrée sous le n° {data?.policy_code} est valable Du {moment(data?.date_depart).format("DD/MM/YYYY")} au {moment(data?.date_arrive).format("DD/MM/YYYY")}. Cette assurance est valable dans tous les pays de l'espace SCHENGEN et le monde entier
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.text}>En foi de quoi, nous lui délivrons la présente pour servir et valoir ce que de droit.
                        </Text>
                    </View>
                    <View style={styles.tableContainer}>
                        <View style={styles.container}>
                            <Text style={styles.header}>PRESTATAIRE</Text>
                        </View>
                    </View>
                    <View  >
                        <Text style={styles.text}>
                            En cas de sinistre, le bénéficiaire du présent contrat ou toute personne agissant pour son compte doit
                            contacter dans les 24 heures {data?.company?.nom}, TELEPHONE: {data?.company?.fax} / {data?.company?.mobile}
                        </Text>
                    </View>
                    <View >
                        <Text style={styles.descriptionLeft}> Pour le client, </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.descriptionright}>
                            Fait à Dakar le, {moment(data?.created_at).format("DD/MM/YYYY")}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.descriptionright}> Pour la Compagnie, </Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.descriptionright}>
                            <Image
                                style={styles.signatures}
                                src={url}
                            />
                        </Text>
                    </View>
                </View>
                <InvoiceTableFooter />
            </Page>
        </Document>
    )
}
