/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Icon from "../../../../components/obligatoire/Icon";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";
import _ from "lodash";
import Loader from "../../../../components/loading/Loader";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { calculResultAssuranceDeces } from "./DecesFunction";
import { useParams } from "react-router-dom";
import { getCIMADeces } from "../../../../services/formules/FormulesAPI";

export default function Deces({ t, categorie_id }) {
  const { id, title, type } = useParams("");
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit } = useForm(); //FORM DATA
  // Parametere techniques
  const [paramTech, setparamTech] = useState({});
  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  const [show, setshow] = useState(false);
  // TABLE CIMA
  const [tableCIMA, settableCIMA] = useState([])
  // Results
  const [results, setResults] = useState({
    prime_pure: 0, //Prime pure
    frais_gestion: 0, // Frais de gestion
    frais_acquisition: 0, // Farais d'acquisition
    prime_commerciale: 0, //PRIME COMMERCIALE
    prime_annuelle: 0, //Prime Annuelle
  });
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);
  var taux_annuel = 0.035;

  useEffect(() => {
    getParamTechByCategorie(categorie_id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var data = result?.data?.data[0];
          setparamTech(data);
          getCIMADeces().then((result) => {
            if (result?.data?.code === 200) {
              var newCIMA = _.groupBy(result?.data?.data,"age")
              settableCIMA(newCIMA)
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormSubmit(dataRes) {
    setshow(true);
    var { capital,periodicite, cotisation, age } = dataRes;
    if (age > 59 || age < 0) {
      toast("L'age du béneficiaire doit être inférieur ou égale à 59 ans", {
        type: "warning",
        position: "top-right",
      });
      setshow(false);
    } else {
      var total = calculResultAssuranceDeces(
        paramTech,
        capital,
        periodicite,
        cotisation,
        age,tableCIMA
      );
      setResults({
        prime_pure: total?.prime_pure, //Prime pure
        frais_gestion: total?.frais_gestion, // Frais de gestion
        frais_acquisition: total?.frais_acquisition, // Farais d'acquisition
        prime_commerciale: total?.prime_commerciale, //PRIME COMMERCIALE
        prime_annuelle: total?.prime_annuelle, //Prime Annuelle
      });
    }
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="card mb-2  bg-transparent m-1 rounded-lg">
          <div className="card-body">
            <h5 className=" mb-4 text-center">
              {t("Devis Temporaire Décès")}
            </h5>
            <div className="text-center">
              <img
                className="mb-4 text-center"
                src="../img/bg-img/21.png"
                width={"100px"}
                alt="Assurance vie"
              />
            </div>
            <div className="form-group mb-3">
          <label className="form-label fz-12">
            {t("Periodicité")} <Icon />
          </label>
          <select className="form-select  fz-12" {...register("periodicite")}>
            <option value={""}>{t("Choisir")} </option>
            <option value={1}>{t("Annuelle")}</option>
            <option value={2}>{t("Semestrielle")}</option>
            <option value={4}>{t("Trimistrielle")}</option>
            <option value={12}>{t("Mensuelle")}</option>
          </select>
        </div>
          
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("Capital")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="number"
                placeholder="Capital  ..."
                {...register("capital")}
                required
              />
            </div>

            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("Durée Cotisation (année)")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="number"
                placeholder="Durée de Cotisiation ..."
                {...register("cotisation")}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">
                {t("Age Assuré")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="text"
                placeholder="Age ..."
                {...register("age")}
                required
              />
            </div>
            <button type="submit" className="btn btn-purple col-12 fz-12">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-calculator me-2"
                viewBox="0 0 16 16"
              >
                <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
              </svg>
              {t("Calculer")}
            </button>
          </div>
        </div>
        {show && (
          <div className="card mb-4 ">
            <div className="card-body">
              <h5 className="text-center "> {t("Total du calcul")} </h5>
              <table className="table  mb-0 table-striped-primary mt-3">
                {loaderResult ? (
                  <Loader />
                ) : (
                  <tbody className="fz-12">
                    {/* <tr>
                      <td>{t("Prime Pure")} </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.prime_pure)
                        )}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Frais de gestion")} </td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.frais_gestion))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Frais d'acquisation")}</td>
                      <td className="text-center">
                        {numberWithSpaces(
                          Math.round(results?.frais_acquisition)
                        )}
                      </td>
                    </tr> */}
                    <tr>
                      <td>{t("Prime commerciale unique")}</td>
                      <td className="text-center">
                        {numberWithSpaces(
                          Math.round(results?.prime_commerciale)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Prime Annuelle")}</td>
                      <td className="text-center">
                        {numberWithSpaces(Math.round(results?.prime_annuelle))}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div className="row">
              <div className="col-12">
                <div className=" float-end">
                  <a
                    href={
                      "facturation_produits/id=" +
                      id +
                      "&title=" +
                      title +
                      "&type=" +
                      type
                    }
                    className="btn btn-success col-12 fz-12 mt-3 mb-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-receipt me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                    </svg>
                    {t("Souscrire")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
