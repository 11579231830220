import Loader from "../../../components/loading/Loader";
import ResumCard from "../components/resum/ResumCard";

export default function Step3({ groupedAssuree, Loading,  t,  data,  id,  groupedAdresses,  type,  setLISTE_ASSUREE,  LISTE_ASSUREE,  show3,  setShow3,  setShow2,  setShow4,cout_police, prime_nette, paramTech
}) {
  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <ResumCard
          t={t}
          data={data}
          category_id={id}
          groupedAdresses={groupedAdresses}
          type={type}
          groupedAssuree={groupedAssuree}
          setLISTE_ASSUREE={setLISTE_ASSUREE}
          LISTE_ASSUREE={LISTE_ASSUREE}
          cout_police={cout_police}
          prime_nette={prime_nette}
          paramTech={paramTech}
        />
      )}
      <button
       className="btn btn-purple float-end fz-12 mb-3" 
        onClick={() => {
          setShow3(false);
          setShow4(true);
        }}
      >
        {t("Souscrire")} <i className="bi bi-arrow-right me-1 fz-12" />
      </button>
      <button
        className="btn btn-success float-start fz-12 mb-3 text-white"
        onClick={() => {
          setShow3(!show3);
          setShow2(true);
        }}
      >
        <i className="bi bi-arrow-left me-1 fz-12" />
        {t("Previous Step")}
      </button>
    </>
  );
}
