/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
// # TRANSLATION LIBRARY
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
// # SEND AN OBJECT OF FORM DATA
import DotLoader from '../../components/loading/DotLoader'
import { addMediaSinistre } from '../../services/sinistre/sistreAPI'

export default function MediaSinistre() {
  const { sinistre_id } = useParams('')
  //HISTORY redirect page
  const history = useHistory('')
  //----------------FILES FORM ----------------
  const [files, setFiles] = useState('') //state for checking file size
  const [liste_files, setListe_Files] = useState([]) //state for checking file siz
  //---------------- LOADING -------------------
  const [loadingForm, setloadingForm] = useState(false) //Loader Form
  //------------- Traduction ---------------
  const [t] = useTranslation('global')

  const uploadFileHandler = (event) => {
    setFiles(event.target.files)
    setListe_Files(Object.values(event.target.files))
  }
  const saveMediaSinistre = () => {
    if (liste_files?.length === 0) {
      toast(t('Veuillez insérer vos justificatifs du sinistre'), {
        type: 'warning',
        position: 'top-right',
      })
    } else {
      setloadingForm(true)
      addMediaSinistre({
        sinistre_id: sinistre_id,
        file: files,
      })
        .then((result) => {
          if (result?.data.length > 0) {
            toast(t('Vos media sont ajoutés avec succès'), {
              type: 'success',
              position: 'top-right',
            })
            history.push({ pathname: '/liste_sinistre' })
          } else {
            toast('Erreur ', { type: 'error', position: 'top-right' })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setloadingForm(false)
        })
    }
  }
  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <div className="form-group mb-3">
            <label className="form-label" htmlFor="Username">
              {t('Fichiers Justificatifs')}
            </label>
            <div className="file-upload-card">
              <svg
                className="bi bi-file-earmark-arrow-up text-success"
                width={48}
                height={48}
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                <path
                  fillRule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 7.707V11.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
              <h5 className="mt-2 mb-4">{t('Upload your files')}</h5>
              <form>
                <div className="form-file">
                  <input
                    className="form-control d-none"
                    id="customFile"
                    type="file"
                    multiple
                    onChange={uploadFileHandler}
                  />
                  <label
                    className="form-file-label justify-content-center"
                    htmlFor="customFile"
                  >
                    <span className="form-file-button btn btn-success shadow w-100">
                      {t("Upload File")}
                    </span>
                  </label>
                </div>
              </form>
            </div>
            <table className="table mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('Nom Fichier')}</th>
                  <th scope="col">{t('Type')} </th>
                  <th scope="col">{t('Taille')}</th>
                </tr>
              </thead>
              <tbody>
                {liste_files?.map((items, keys) => (
                  <tr key={keys}>
                    <th scope="row">{keys + 1}</th>
                    <td>{items.name}</td>
                    <td>{items.type}</td>
                    <td>{items.size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingForm ? (
              <button
                className="btn btn-purple mt-3 col-12 "
                type="button"
                disabled
              >
                
                {t('En cours de chargement')} <DotLoader />
              </button>
            ) : (
              <button
                className="btn btn-purple mt-3 col-12 "
                type="button"
                onClick={saveMediaSinistre}
              >
                
                {t('Ajouter les Medias')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
