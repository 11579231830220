// ** COMPARAISON FILE : CONTAINS FUNCTIONS OF COMPARAISON 

// # COMPARE BETWEEN TWO STRING 
export function ComparaisonBetweenTwoSting  (text1 , text2)  {
   if(text1 === text2){return true} else {return false}
}

// # COMPARE BETWEEN TWO DATES 
export function comparaisonBetweenTwoDates(date1 , date2)  {
   // To set two dates to two variables
   var date_debut = new Date(date1);
   var date_fin = new Date(date2);
   var diff = date_fin.getTime() - date_debut.getTime();   
   return (diff / (1000 * 60 * 60 * 24));   
}
/**
 * CALCUL AGE 
 * @param {*} birthday 
 * @returns 
 */
export  function calculateAge(birthday) { // birthday is a date
   var ageDifMs = Date.now() - birthday;
   var ageDate = new Date(ageDifMs); // miliseconds from epoch
   return Math.abs(ageDate.getUTCFullYear() - 1970);
 }

 export function getPreviousPath(chaine){
   let lastPageUrl = document.referrer
  return lastPageUrl.indexOf(chaine)

 }