import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCategorie } from '../../services/categories/CategoriesApi'

export default function Produits({t , language,titre}) {
    //------------- HISTORY -----------------
    const history = useHistory()
    const [LISTE, setLISTE] = useState([])

    const redirectToDetails = (id) => {
        history.push({
            pathname: "/details_produits/"+id
        })
    }
    
    useEffect(() => {
        getCategorie()
            .then((result) => {
                if (result?.data?.code === 200) {
                    setLISTE(result?.data?.data)
                }
            }).catch((err) => {
                console.log(err)
            });
    }, [])
  return (
    <div className="row g-3 mt-2">
    {/* Single Team Member*/}
    {LISTE?.map((items, keys) => (

    <div className="col-6" key={keys}>
        
        <div className="card team-member-card shadow" style={{cursor:"pointer"}} onClick={()=>redirectToDetails(items.id)}>
            <div className="card-body">
                {/* Member Image*/}
                <img
                    className='mt-2 mb-0'
                    width={"60px"}
                    src={process.env.REACT_APP_IMAGE+items.logo}
                    alt="login_image"
                />                                
                {/* Team Info*/}
                <div className="team-info">
                    <h6 className="mb-1 mt-3 text-white display-5 fz-16">{language==="fr" ? items.titre : items.titre_en} </h6>
                </div>
            </div>
        </div>
    </div>))}
 
</div>
  )
}
