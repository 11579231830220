/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useUser } from "../../Auth/useUser";
import Loader from "../../components/loading/Loader";
import { getListeTicketsbyID } from "../../services/tickets/ticketAPI";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

export default function VosMessages() {
  // ---------- USER INFORMATIONS ----------
  const user = useUser();
  var { id } = user.result;
  //---------------- LISTE -----------------
  const [Liste, setListe] = useState([]); //Liste des messages
  //------------ LOADING -------------------
  const [Loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  const language = Cookies.get("locale");
  {
    language === "ar"
      ? require("moment/locale/ar-tn")
      : language === "fr"
      ? require("moment/locale/fr")
      : require("moment/locale/en-au");
  }

  useEffect(() => {
    getListeTicketsbyID(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListe(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      {Loading ? (
        <Loader />
      ) : (
        <div className="row mt-3">
          <h5 className="text-center"> {t("Vos messages envoyés")}</h5>
          <div className="text-center">
            <img
              src="img/bg-img/Email campaign-cuate.png"
              alt="news_images"
              style={{ width: "250px" }}
            />
          </div>
          {Liste?.map((items, keys) => (
            <div className="col-12 col-sm-6 col-md-6" key={keys}>
              <div className="card position-relative shadow-sm float-right mb-2">
                <div className="card-body">
                  <span className="badge bg-purple rounded-pill mb-2 d-inline-block">
                    {moment(items.created_at).format("DD MMMM YYYY ")}
                  </span>
                  {items.status === "opened" ? (
                    <span className="badge bg-success rounded-pill mb-2 d-inline-block float-end">
                      {t("Consulté")}
                    </span>
                  ) : (
                    <span className="badge bg-warning rounded-pill mb-2 d-inline-block float-end">
                      {t("Non Traité")}
                    </span>
                  )}
                  <h6>{items.type} </h6>
                  <p>{items.description} </p>
                  <a
                    className="text-purple float-end"
                    href={"/detailsMessages&id=" + items.id}
                  >
                    {t("Voir les médias")} ...{" "}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
