/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { getListeAssuree } from "../../../services/assuree/assureeAPI";

export default function FirstStep({ id_user, setassuree, type, setShow1, setShow, register, nbrAssuree, t, generateTabAssuree, createDataNbAssuree, setLISTE_ASSUREE }) {

  // LISTE ASSUREE
  const [liste_assuree, setliste_assuree] = useState([]);
  const [options, setoptions] = useState("");

  useEffect(() => {
    getListeAssuree(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setliste_assuree(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ChoisirOptions = (option) => {
    setoptions(option);
    if (option === "souscripteur") {
      setassuree(true);
      setShow(false);
      setShow1(true);
    } else if (option === "unassuree" && liste_assuree.length !== 0) {
      setassuree(false);
      setLISTE_ASSUREE(generateTabAssuree(1));
      setShow(false);
      setShow1(true);
    }
  };
  return (
    <div className="card">
      {(type === "education" ||
        options === "assuree" ||
        options === "unassuree") &&
        liste_assuree?.length === 0 ? (
        <div className="card-body text-center">
          <img
            className="mb-4"
            src="img/bg-img/100.png"
            style={{ width: "150px" }}
            alt="NO DATA"
          />
          <p className="mb-4">
            {t("Si vous désirez souscrire à un ou plusieurs assurés, veuillez les ajouter à votre livret d'assurance en cliquant ici.")}
          </p>
          <a className="btn btn-purple m-1 fz-12" href="/assuree">
            {t("Ajouter Assuré(e)")}
          </a>
        </div>
      ) : (
        <div className="card bg-transparent">
          <div className="card-body text-center">
            <img
              className="mb-4"
              src="img/bg-img/contrat.png"
              style={{ width: "150px" }}
              alt="NO DATA"
            />
            {type==="education" &&   <p className="mb-4">
              {t("Si ce n'est pas encore fait, veuillez créer la liste de vos assurés en cliquant ici, puis revenez sur la page dédiée pour poursuivre la souscription.")}
              <a className="text-purple m-1" href="/assuree">
                {t("Vos assurés")}
              </a>
            </p>}
            {type==="voyage" && <p className="mb-4">
            {t("Si vous désirez souscrire pour un ou plusieurs autres assurés, veuillez les rajouter ici, puis revenez sur la page dédiée pour la souscription.")}
              <a className="text-purple m-1" href="/assuree">
                {t("Vos assurés")}
              </a>
            </p>}
            {(type!=="education" &&  type!=="voyage") && <p className="mb-4">
            {t("Si vous désirez souscrire pour un autre assuré, veuillez le rajouter ici, puis revenez sur la page dédiée pour la souscription.")}
              <a className="text-purple m-1" href="/assuree">
                {t("Vos assurés")}
              </a>
            </p> }
            {type !== "education" && (
              <button
                className="btn btn-creative btn-purple col-12 fz-12 mb-2"
                onClick={() => ChoisirOptions("souscripteur")}
                type="button"
              >
                {(type === "voyage" ||
                  type === "deces" ||
                  type === "accident" ||
                  type === "retraite") &&
                  t("Je suis l'assuré")}
                {type === "vehicule" && t("Assurer mon véhicule")}
              </button>
            )}

            <button
              className="btn btn-creative btn-purple col-12 fz-12 mb-2"
              onClick={() => ChoisirOptions("unassuree")}
              type="button"
            >
              {t("Souscrire pour un autre")}
            </button>
            {(type === "education" || type === "voyage") && (
              <button
                className="btn btn-creative btn-purple col-12 fz-12"
                onClick={() => ChoisirOptions("assuree")}
                type="button"
              >
                {t("Souscrire pour plusieurs assurés")}
              </button>
            )}
          </div>
        </div>
      )}
      {liste_assuree?.length !== 0 && options === "assuree" && (
        <>
          <div className="card bg-transparent">
            <div className="card-body text-center">
              <div
                className="form-group m
              b-3"
              >
                <label className="mb-2 fz-12">{t("Nombre d'assurées")}</label>
                <input
                  className="form-control fz-12"
                  {...register("nbrAssuree")}
                  value={nbrAssuree}
                  step="any"
                  onChange={(e) => createDataNbAssuree(e)}
                  type="number"
                  placeholder={t("Nombre d'assurées") + "... "}
                  required
                />
              </div>
              <button
                className="btn btn-success mt-2  col-12 fz-12"
                onClick={() => {
                  setassuree(false);
                  setShow(false);
                  setShow1(true);
                }}
              >
                {t("Valider")}
              </button>
            </div>
          </div>
        </>
      )}
      {type !== "vehicule" && <div
        className="alert custom-alert-1 alert-warning alert-dismissible fade show  fz-12 "
        role="alert"
      >
        <i className="bi bi-exclamation-triangle" />
        {type === "education" &&
          t("L'âge maximal des assurés de la rente éducation, est  20 ans")}
        {type === "retraite" &&
          t("L'âge maximum des assurés de l'assurance retraite, est 60 ans  ")}
        {type === "deces" &&
          t("L'âge minimum de la l'assurance décès , est  20 ans")}
        {type === "voyage" && t("L'âge maximum de la rente voyage , est  65 ans")}
        {type === "accident" &&
          t("L'âge maximum de l'assurance accident , est  65 ans")}
      </div>}
    </div>
  );
}
