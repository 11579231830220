import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUser } from "../../Auth/useUser";
import { error_message, success_message, warning_message } from "../../components/alerte/AlerteMessage";
import Icon from "../../components/obligatoire/Icon";
import { createPIN } from "../../services/users/usersAPI";

export default function CreateCodePIN() {
  const user = useUser();
  var { id } = user?.result !== undefined ? user?.result : "";
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset } = useForm(); //FORM DATA
    //------------- Traduction ---------------
    const [t] = useTranslation("global");
  /**
   *
   * CREATE CODE PIN
   * @param {*} data
   */
  const createCodePIN = (data) => {
    var { code1, code2 } = data;
    if (code1 === code2) {
      var body = {
        code_PIN: code1,
      };
      createPIN(id, body)
        .then((result) => {
          if (result?.data?.code === 200) {
            success_message(t("Votre code PIN a été crée avec succès"))
          }else{
            error_message(t("Erreur lors de la création ou de la modification d'un code PIN, veuillez réessayer."))
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
       warning_message(t("Les codes PIN que vous avez entrés sont différents"))
    }
  };

  return (
    <div className="page-content-wrapper mt-0 py-3">
      <div className="container">
      <div className="card">
        <div className="card-body">
        <h1 className="display-6 mb-2 text-center"> {t("Création du code PIN")} </h1>
          <div>
          <div className="text-center px-4">
            <img
              className="login-intro-img"
              src="img/bg-img/Wavy_Tech-30_Single-03.png"
              alt="login_image"
              width={"200px"}
            />
          </div>
            </div>
          <form onSubmit={handleSubmit(createCodePIN)}>
            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleTextarea1">
                {t("Code PIN")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                id="psw-input"
                type="password"
                {...register("code1")}
                placeholder="Code PIN"
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label fz-12" htmlFor="exampleTextarea1">
                {t("Vérifier Code PIN")} <Icon />
              </label>
              <input
                className="form-control fz-12"
                type="password"
                id="psw-input"
                {...register("code2")}
                placeholder="Code PIN"
                required
              />
            </div>
            <button className="btn btn-success fz-10 col-12" type="submit">
              {t("Enregistrer Code PIN")}
            </button>
          </form>
        </div>
      </div>
      </div>
  
    </div>
  );
}
