// CALL AXIOS TO CONSUME WEB SERVICE
import axios from "axios"
import authHeader from "../authentification/AuthHeader";

//GET URL FROM .ENV ELEMENT
import { getAuthUrl } from "../authentification/DotenvConfig"

/**
 * URL OF APP 
 * EXAMPLE : https://exemple/api/v1
 */
 const url = getAuthUrl()

 /**
  * ADD STRIPE PAIEMENT
  * @param {*} data 
  * @returns 
  */
 export async function addStripePaiement(data) {
    try {
        const response = await axios.post(url + "stripe",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * GET ALL SERVICES 
 * WITH SECRET KEY OF API
 * @returns 
 */
export async function getAllService() {
    try {
        const response = await axios.get("https://api.intech.sn/api-services/services", {   headers: { 
            Secretkey: process.env.Secretkey
          } }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

/**
 * ADD THE PAIEMENT TO DATA BASE
 * @param {*} data 
 * @returns 
 */
export async function addPayement(data) {
    try {
        const response = await axios.post(url + "stripe/payment",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}

export async function addPaiement(data) {
    try {
        const response = await axios.post(url + "paiement",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}
/**
 * GET PAIEMENT BY POLICY CODE
 * @param {*} policy 
 * @returns 
 */
export async function getPaiementByPolicyCode(policy) {
    try {
        const response = await axios.get(url + "paiement/policy/"+policy , { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            return (error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
        }
        console.log(error.config);
        return error;
    }
}