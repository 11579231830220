import Swal from "sweetalert2";
import FileSaver from "file-saver";
import { deleteTicketMediaByID } from "../../services/tickets/ticketAPI";

export default function CardMediaContaxt({t, keys, getExtension, items}) {
  const download = (file) => {
    var filename = file.split("uploads/").pop();
    FileSaver.saveAs(file, filename);
  };

  const handleButton = (id) => {
    Swal.fire({
      title: t("Êtes-vous sure ?"),
      text: t("Êtes-vous sure de vouloir supprimer , votre Media ?"),
      icon: "info",
      showDenyButton: true,
      confirmButtonText: t("Oui"),
      denyButtonText: t("Non"),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteTicketMediaByID(id)
          .then((result) => {
            if (result?.data?.code === 200) {
              Swal.fire(
                t("Votre Media est supprimé avec succès!"),
                "",
                "success"
              );
              window.location.reload();
            } else {
              Swal.fire(t("Erreur de suppression Media !"), "", "error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        Swal.fire(t("Les modifications ne sont pas enregistrées"), "", "info");
      }
    });
  };

  return (
    <div className="col-12 mb-4">
      <div>
      
        <div className="card shadow" key={keys}>
          <div className="card-body">
            <div className="text-center">
              <img
                className="mt-2 mb-0"
                width={
                  getExtension(process.env.REACT_APP_API_AUTH + items?.file) !==
                    ("jpg" || "png" || "jpeg") && "60px"
                }
                src={
                  getExtension(process.env.REACT_APP_API_AUTH + items?.file) ===
                  ("jpg" || "png" || "jpeg")
                    ? process.env.REACT_APP_API_AUTH + items?.file
                    : getExtension(
                        process.env.REACT_APP_API_AUTH + items?.file
                      ) === "pdf"
                    ? "../img/bg-img/001-file.png"
                    : "../img/bg-img/003-file-2.png"
                }
                alt="login_image"
              />
            </div>
            <h6 className=" mt-4 fz-12">
              {items.file.split("uploads/").pop()}
            </h6>
            <div className="float-end">
              <a
                className="btn btn-warning mb-1 mt-3 me-2"
                href={process.env.REACT_APP_API_AUTH + items?.file}
                download
                onClick={(e) => download(e)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-save text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                </svg>
              </a>
              <button
                className="btn btn-danger mb-1 mt-3"
                onClick={() => handleButton(items.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
