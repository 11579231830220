import moment from "moment";

export default function CardContrats({
  keys,
  i,
  groupedListe,
  t,
  gender,
  nom,
  prenom,
  ASSUREE,
}) {
  console.log("liste card contrats ",
  keys,
  i,
  groupedListe,
  t,
  gender,
  nom,
  prenom,
  ASSUREE,)
  return (
    <div className="card timeline-card" key={keys}>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div className="timeline-text mb-2">
            <span className="badge mb-2 rounded-pill fz-10">
              {groupedListe[i][0]?.category?.titre}
            </span>
          </div>
        </div>

        <p className="fz-10">
          <b> {t("Nom & Prénom souscripteur")} </b> :
          {gender === "femme" ? "Mme." : "Mr"}
          {nom + " " + prenom}
        </p>
        {groupedListe[i]?.map((items, k) => (
          <>
            <p className="fz-10">
              <b> {t("Nom & Prénom assuré")} </b> :
              {items?.assuree_id === null
                ? nom + " " + prenom
                : ASSUREE[items?.assuree_id] !== undefined &&
                  ASSUREE[items?.assuree_id][0]?.nom +
                    " " +
                    ASSUREE[items?.assuree_id][0]?.prenom}
            </p>
            <p className="fz-10">
              <b>{t("Date naissance assuré")} </b> :
              {items?.assuree_id === null
                ? moment(items?.date_naissance).format("DD MMMM  YYYY")
                : ASSUREE[items?.assuree_id] !== undefined &&
                  moment(ASSUREE[items?.assuree_id][0]?.date_naissance).format(
                    "DD MMMM  YYYY"
                  )}
            </p>
          </>
        ))}
        <p className="fz-10">
          <b>{t("Date Effet")} </b> :
          {moment(groupedListe[i][0]?.date_effet).format("DD MMMM  YYYY")}
        </p>
        <p className=" fz-10">
          <b> {t("Crée le")} </b> :
          {moment(groupedListe[i][0]?.created_at).format("DD-MM-YYYY")}
        </p>
        <a
          className="btn btn-success text-white fz-10 float-end"
          href={
            "/details_contrats&id=" +
            groupedListe[i][0]?.id +
            "&type=" +
            groupedListe[i][0]?.category?.type +
            "&policy_code=" +
            groupedListe[i][0]?.policy_code
          }
        >
          {t("Détails Contrat")}
          <i className="bi bi-arrow-right  fz-10" />
        </a>
      </div>
    </div>
  );
}
