/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
/**
* * Get the user agent string from the browser and return the device type
* @returns The device type.
*/
export function getUA() {
  let device = "Unknown";
  const ua = {
    "Generic Linux": /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i,
  };
  Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
}
/**
* * Get the browser name and version from the user agent string
* @returns The browser name 
and version number.
*/
export function getBrowser() {
  const userAgent = navigator.userAgent;
  let browser = "unkown";
  // Detect browser name
  browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
  browser = /edg/i.test(userAgent) ? "Edge" : browser;
  browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
  browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
  browser = /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? "Firefox" : browser;
  browser = /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent) ? "IE" : browser;
  browser =
    /chrome|crios/i.test(userAgent) && !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
      ? "Chrome"
      : browser;
  browser =
    /safari/i.test(userAgent) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
      ? "Safari"
      : browser;
  browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;
  // detect browser version
  switch (browser) {
    case "UCBrowser":
      return `${browser}/${browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
    case "Edge":
      return `${browser}/${browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
    case "GoogleBot":
      return `${browser}/${browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
    case "Chromium":
      return `${browser}/${browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
    case "Firefox":
      return `${browser}/${browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
    case "Chrome":
      return `${browser}/${browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
    case "Safari":
      return `${browser}/${browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
    case "Opera":
      return `${browser}/${browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
    case "IE":
      const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
      // IE version is mapped using trident version
      // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
      return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
    default:
      return `unknown/0.0.0.0`;
  }
}
/**
* Given a user agent string and a regular expression, return the version of the browser
* @param userAgent - The user agent string to parse.
* @param regex - The regular expression to match against the user agent string.
* @returns The browser version.
*/
export function browserVersion(userAgent, regex) {
  return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
}
/**
* It returns the IP address information for the current user.
* @returns The response object.
*/
export async function getIpInformation() {
  try {
    const response = await axios.get(
      `https://api.ipdata.co/?api-key=ba65b62297a85746b6a3e01b5d9d32e60c03bb865f352acc5031de96`
    );
      return response
  } catch (error) {
    console.error(error);
  }
}



export const getAnswer = async () => {
  const res = await fetch(`https://api.ipdata.co/?api-key=ba65b62297a85746b6a3e01b5d9d32e60c03bb865f352acc5031de96`);
  const data = await res.json();
  return (data);
};









