import { Image,View,Text,StyleSheet} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
    signature: {
      width: 100,
      height: 50,
      marginTop: -20,
      textAlign: "right",
    },
    descriptionLeft: {
      width: "100%",
      textAlign: "left",
      paddingLeft: 8,
      letterSpacing: 1,
      fontSize: 8,
      color: "#4e545d",
      fontFamily:'Arial',
      fontStyle:"normal"  },

    descriptionright: {
      width: "100%",
      textAlign: "right",
      paddingLeft: 8,
      letterSpacing: 1,
      fontSize: 8,
      color: "#4e545d",
      fontFamily:'Arial',
      fontStyle:"normal"
    },
 
   
  });
  
  
export default function Signatures({data,signatures}) {
  const imageSrc = `${process.env.REACT_APP_IMAGE}${signatures[0]?.image}`;
  return (
    <View style={{marginTop:"20"}}>
        <View >
        <Text style={styles.descriptionLeft}> Pour le client, </Text>
        </View>
            <Text style={styles.descriptionright}>
              Fait à Dakar le,{moment(data?.created_at).format("DD/MM/YYYY")}
            </Text>
            <Text style={styles.descriptionright}>
              Diokotech / {data?.company?.nom}
            </Text>
            <Text style={styles.descriptionright}>
              <Image
                  style={styles.signature}
                  src={imageSrc}
                  alt=""
                />
            </Text>
            
    </View>
  )
}
