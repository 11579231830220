import { View, StyleSheet } from "@react-pdf/renderer";
import RefererenceQuitance from "./RefererenceQuitance";

const borderColor = "#fff";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    marginBottom: 0,
    borderColor:borderColor
  },
  });
export default function Quitance({ t,type, info,data,user_details,personnel}) {
  return (
    <View style={styles.tableContainer}>
        <RefererenceQuitance type={type}  data={data} user_details={user_details} personnel={personnel} />
    </View>
  )
}
