/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// # SEND AN OBJECT OF FORM DATA
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useUser } from "../../../Auth/useUser";
import { error_message, success_message } from "../../../components/alerte/AlerteMessage";
import DotLoader from "../../../components/loading/DotLoader";
import { add_adresse, getAdressesOfUser } from "../../../services/adresses/AdresseAPI";
import { addPersonnel, getPersonnelByID } from "../../../services/personnel/personnelAPI";
import { getUser, updateUser } from "../../../services/users/usersAPI";
import { pays } from "../../../utils/ListePays";

export default function InvalidForm({ t }) {
  const user = useUser();
  var { id } = user.result;
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset } = useForm(); //FORM DATA
  const [listePays, setlistePays] = useState(pays)
  const [data, setData] = useState(null); // PROFILE
  const [personnel, setPersonnel] = useState(null);
  const [gender, setgender] = useState("")

  // const [adresse, setAdresse] = useState(null)

  const [ok, setok] = useState(false)
  const [ok1, setok1] = useState(false)

  //---------------- LOADING -------------------
  const [loading, setLoading] = useState(true); // Loader Page
  const [loadingForm, setloadingForm] = useState(false); //Loader Form

  useEffect(() => {
    getPersonnelByID(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          if (result?.data?.data?.length === 0) {
            setPersonnel({
              user_id: id,
              n_cin: "",
              n_passport: "",
            });
            setok1(true)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getAdressesOfUser(id)
      .then((result) => {
        const informations = result?.data; //results data
        if (informations?.code === 200) {
          var LISTE = result?.data?.data
          if (LISTE?.length === 0) {
            setok(true)
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    getUser(id)
      .then((result) => {
        var info = result?.data?.data;
        setTimeout(
          () => {
            setData({
              id: id,
              nom: info?.nom,
              prenom: info?.prenom,
              mobile: info?.mobile,
              gender: info?.gender,
              image: info?.image,
              date_naissance: info?.date_naissance,
              lieu_naissance: info?.lieu_naissance,
              email: info?.email,
            });
          },

          1200
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1200);
      });
  }, []);

  // UPDATE DATA INTO FORM
  useEffect(() => {
    reset(data);
  }, [data]);

  function onFormSubmit(dataRes) {

    if (ok1) {
      addPersonnel({
        user_id: dataRes?.id,
        n_cin: dataRes?.n_cin,
        n_passport: dataRes?.n_passport
      })
        .then((result) => {
          if (result?.data?.code === 200) {
            success_message(t("Piéces d'identités ajouté avec succès"))
            setok1(false)
          } else {
            error_message(t("Erreur d'ajout de piéces d'identitié! "))
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setTimeout(() => setloadingForm(false), 1200)
        })
    }
    if (ok) {
      add_adresse({
        user_id: dataRes?.id,
        pays: dataRes?.pays,
        region: dataRes?.region,
        ligne: dataRes?.ligne,
        cite: dataRes?.cite,
        code_postal: dataRes?.code_postal
      }).then((result) => {
        const informations = result?.data; //results data
        if (informations?.code === 500) {
          error_message(t("Erreur Temporaire d'ajout d'adresse !"))
          setok(false)
        } else if (informations?.code === 200) {
          success_message(t("Adresse ajouté avec succès !"))
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setLoading(false)
      })
    }
    updateUser({
      id: dataRes?.id,
      nom: data?.nom,
      prenom: data?.prenom,
      mobile: data?.mobile,
      image: data?.image,
      gender: gender,
      date_naissance: dataRes?.date_naissance,
      lieu_naissance: dataRes?.lieu_naissance,
      email: dataRes?.email,
    }).then((result) => {
      if (result?.data?.code === 200) {
        success_message(t("Informations du profiles modifé avec succès!"))
        window.location.reload()
      } else {
        error_message(t("Erreur de modification de profile!"))
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setTimeout(
        () =>
          setloadingForm(false), 1200
      )
    })
  }
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="text-center">{t("Données incomplètes")}</h4>

            <form onSubmit={handleSubmit(onFormSubmit)}>
              {loading ? <DotLoader /> : <>

                <div className="form-group mb-3 mt-3">
                  <label className="form-label" htmlFor="email">
                    {t("E-Mail")}
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    {...register("email")}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="job">
                    {t("Mobile")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("mobile")}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="portfolio">
                    {t("Date de Naissance")}
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    {...register("date_naissance")}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="portfolio">
                    {t("Genre")}
                  </label>
                  <div>
                    <div className={gender === "femme" ? "single-plan-check shadow-sm active-effect active" : "single-plan-check shadow-sm active-effect"}>
                      <div className="form-check mb-0">
                        <input className="form-check-input" defaultChecked={gender === "femme" ? true : false} onChange={() => setgender("femme")} type="radio" name="planChoose" id="Individual" />
                        <label className="form-check-label" htmlFor="Individual">{t("Femme")}</label>
                      </div>
                      <i className="bi bi-gender-female text-pink" />


                    </div>
                    <div className={gender === "homme" ? "single-plan-check shadow-sm active-effect active" : "single-plan-check shadow-sm active-effect"}>
                      <div className="form-check mb-0">
                        <input className="form-check-input" defaultChecked={gender === "homme" ? true : false} onChange={() => setgender("homme")} type="radio" name="planChoose" id="Team" />
                        <label className="form-check-label" htmlFor="Team">{t("Homme")}</label>
                      </div>
                      <i className="bi bi-gender-male text-primary" />
                    </div>

                  </div>

                </div>
                <div className="form-group">
                  <label className="form-label fz-12">
                    {t("Lieu de naissance")}
                  </label>
                  <input
                    className="form-control fz-12"
                    placeholder="Lieu de la date de naissance"
                    type="text"
                    required
                    {...register("lieu_naissance")}
                  />
                </div>
              </>}

              {ok1 && <>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="portfolio">
                    {t("N° CIN")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="N° CIN"
                    {...register("n_cin")}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="portfolio">
                    {t("N° Passeport")}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="N° de passeport"
                    {...register("n_passport")}
                  />
                </div>
              </>}

              {ok && <>
                <div className="form-group">
                  <label class="form-label">{t("Pays")} <span className="text-danger">*</span></label>
                  <select class="form-select" id="defaultSelect" name="defaultSelect" required {...register("pays")}>
                    <option value={""}> {t("Choisir un pays")}</option>
                    {listePays?.map((items, keys) => (
                      <option value={items.name} key={keys}>{items.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-label" >{t("Région")} <span className="text-danger">*</span></label>
                  <input className="form-control" placeholder="Choisir une région" type="text" required  {...register("region")} />
                </div>
                <div className="form-group">
                  <label className="form-label" >{t("Rue")} <span className="text-danger">*</span></label>
                  <input className="form-control" placeholder="Choisir la rue" type="text" required  {...register("ligne")} />
                </div>
                <div className="form-group">
                  <label className="form-label" >{t("Cité")} <span className="text-danger">*</span></label>
                  <input className="form-control" placeholder="Choisir la cité" type="text" required  {...register("cite")} />
                </div>
                <div className="form-group">
                  <label className="form-label" >{t("Code Postal")} <span className="text-danger">*</span></label>
                  <input className="form-control" placeholder="Choisir un code postal" type="number" required {...register("code_postal")} />
                </div>
              </>}
              <p className="text-purple text-justify">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-info-circle me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                {t("Veuillez remplir les données manquantes pour poursuivre les étapes de souscription")}
              </p>
              {loadingForm ? (
                <button className="btn btn-purple w-100" type="submit" disabled>
                  {t("En Cours")} <DotLoader />
                </button>
              ) : (
                <button className="btn btn-purple w-100" type="submit">
                  {t("Modifier")}
                </button>
              )}
            </form>

          </div>
        </div>
      </div>
    </div>
  );
}
