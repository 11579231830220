/* eslint-disable no-unused-vars */
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify';
import HeaderEmptyLayout from '../components/shared/HeaderEmptyLayout';


export default function EmptyLayout(props) {

    // # THEME : SET THEME TO LIGHT VERSION 
    const [isDark, setisDark] = useState(Cookies.get("theme")==="light" ? false : true);
    const html = document.querySelector("html");
    isDark ? html.setAttribute("data-theme", "dark") : html.setAttribute("data-theme", "light");


  return (
    <div  >
      <HeaderEmptyLayout  isDark={isDark} />
      <ToastContainer />
      <React.Fragment>{props.children}</React.Fragment>
    </div>

  )
}
