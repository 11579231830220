import React from 'react'

export default function Parrainage({t,register, watch  }) {
  return (
    <div>   <div className="row mb-3 mt-3">
    <div className="col-12">
      <h5 className="mt-2 mb-4 text-center fz-16">
        {t("Coordonnées du parrain ou de l'apporteur d'affaires")}
      </h5>
      <div >
      <div className="form-group mb-3">
      <select
        className="form-select fz-12"
        name="duree"
        {...register('options')}
        required
      >
        <option value={"non"}>{t("Non parrainé")}</option>
        <option value={"apporteur"}>{t("Apporteur d'affaires")}</option>
        <option value={"parrainage"}>{t("Parrainage")}</option>
      </select>
    </div>
      </div>               
    </div>
  </div>
  {(watch("options") === "apporteur" ||  watch("options") === "parrainage" )&& <>
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label className="form-label">{t("Identifiant")} </label>
          <input
            className="form-control fz-12"
            type="text"
            name="nom"
            placeholder={t("Identifiant")}
            {...register("identifiant_parrain")}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <label className="form-label">{t("Code Parrain")} </label>
          <input
            className="form-control fz-12"
            type="text"
            placeholder={t("Code Parrain")}
            {...register("code_parrain")}
          />                  
        </div>
      </div>
    </div>
  </>}</div>
  )
}
