/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import amortissement from "../../../../formules/deces/TableauAmortissement.json"
//import tableCIMA from "../../../../formules/deces/Simulateur_deces_TABLE_CIMA_H_.json"
import _ from "lodash";
import { getCIMADeces } from "../../../../services/formules/FormulesAPI";

export function calculResultAssuranceDeces (paramTech,capital,periodicite, cotisation, age ,tableCIMA ){
  console.log(tableCIMA);
  //TAUX BANCAIRE +1
  var taux_a = parseFloat(paramTech?.taux_technique)+1
  // Taux banq mensu TBM
  var TBM = (-1+(Math.pow(taux_a,(1/parseInt(periodicite)))))
  //Facteur d'act. Banq (FAB)
  var FAB = (1 /(1+TBM))
  //annuité
  var annuité= (capital*(1-FAB))/(FAB*(1-Math.pow(FAB,(cotisation*periodicite))))
  //intert 
  var interet = TBM * capital
  var Dx0 = (capital*TBM) / (1-(Math.pow(1+TBM,-cotisation*periodicite)))
    var taux_annuel = 0.035 //INITIALISER LE TAUX ANNUEL A 3.5 %
 
        var newCIMA = tableCIMA
        var newAmortissement = [];
        amortissement?.map((items, keys) =>{
          //Formule (dx)*CRDk/Lx
          var crdk = (items.capital * newCIMA[parseInt(age)+keys][0].dx) * (Math.pow(1+taux_annuel, (-keys-0.5)) / newCIMA[age][0].Lx)
          // Formule 0,1%*(CRDk*Dx+k)/Dx
          var crkdx = (items.capital *newCIMA[parseInt(age)+keys][0].Dx1 * 0.001 ) / (newCIMA[age][0].Dx1)
          var obj = {
            age: items.age,
            valeur_1: items.valeur_1,
            valeur_2: items.valeur_2,
            valeur_3: items.valeur_3,
            valeur_4: items.valeur_4,
            capital: items.capital,
            crdk : crdk,
            crkdx : crkdx
          }
          newAmortissement.push(obj)
        })
        var tab_crdk = newAmortissement.slice(0,age-1)
       //CALCUL PRIME PURE
       const sumPrimePure = tab_crdk.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.crdk);
      }, 0);
      //CALCUL FRAIS DE GESTION
      const sumFraisGestion = tab_crdk.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.crkdx);
      }, 0);
      // CALCUL DE LA PRIME COMMERCIALE UNIQUE 
      var prime_commercial_unique = parseFloat(paramTech?.cout_police) + (sumPrimePure+ sumFraisGestion) / 0.85
      // FRAIS D'ACQUISITION 
      var frais_acquisition = 0.15 * prime_commercial_unique
      //PRIME ANNUELLE 
      var prime_annuelle =( parseFloat(prime_commercial_unique) * newCIMA[age][0].Dx1) / (newCIMA[age][0].Nx - newCIMA[parseInt(age)+parseInt(cotisation)][0].Nx)

    return({
        prime_pure: sumPrimePure, //Prime pure
        frais_gestion: sumFraisGestion, // Frais de gestion
        frais_acquisition: frais_acquisition, // Farais d'acquisition
        prime_commerciale: prime_commercial_unique, //PRIME COMMERCIALE
        prime_annuelle: prime_annuelle, //Prime Annuelle
      })
      
  
 
}