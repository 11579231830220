//----------------- REACT ROUTER DOM ------------------
import { BrowserRouter, Switch } from "react-router-dom";
//----------------- LAYOUTS ----------------------------
import EmptyLayout from "./layouts/EmptyLayout";
import MainLayout from "./layouts/MainLayout";
//----------------- ROUTES -----------------------------
import AppRoute from "./routes/AppRoute";
import PrivateRoute from "./routes/PrivateRoute";
//----------------- PAGES ------------------------------
import ForgetPassword from "./pages/forgetpassword/ForgetPassword";
import HomePage from "./pages/home/HomePage";
import Register from "./pages/signup/Register";
import WelcomePage from "./pages/Welcome/WelcomePage";
import Login from "./pages/signIn/Login.jsx"
import ValidationSMS from "./pages/SignInSMS/ValidationSMS";
import AuthSMS from "./pages/SignInSMS/AuthSMS";
import Page404 from "./pages/404/Page404";
import PublicRoute from "./routes/PublicRoute";
import NosProduits from "./pages/produits/NosProduits";
import ChangePassword from "./pages/forgetpassword/ChangePassword";
import AdressesUser from "./pages/user/adresses/AdressesUser";
import AjouterAdresse from "./pages/user/adresses/AjouterAdresse";
import EditAdresse from "./pages/user/adresses/EditAdresse";
//import ChatSupport from "./pages/support/ChatSupport";
import DetailsAssurance from "./pages/produits/DetailsAssurance";
import ProfilUser from "./pages/user/profil/ProfilUser";
import Contacts from "./pages/contacts/Contacts";
import DeclarationSinistre from "./pages/sinistre/DeclarationSinistre";
import DevisProduit from "./pages/devis/DevisProduit";
import WelcomeLayout from "./layouts/WelcomeLayout";
import MediaSinistre from "./pages/sinistre/MediaSinistre";
import ListeDeclarations from "./pages/sinistre/ListeDeclarations";
import DetailsDeclaration from "./pages/sinistre/DetailsDeclaration";
import SignupMobile from "./pages/signup/SignupMobile";
import InterfaceChooseRegister from "./pages/signup/InterfaceChooseRegister";
import VerifMail from "./pages/signIn/VerifMail";
import UploadsMedia from "./pages/contacts/UploadsMedia";
import VosMessages from "./pages/contacts/VosMessages";
import DetailsNews from "./pages/home/news/DetailsNews";
import ListeNews from "./pages/home/news/ListeNews";
import ListeEvents from "./pages/home/events/ListeEvents";
import DetailsEvents from "./pages/home/events/DetailsEvents";
import PersonnelInfo from "./pages/user/personnel/PersonnelInfo";
import DetailsContacts from "./pages/contacts/DetailsContacts";
import Assure from "./pages/assure/Assure";
import AddAssure from "./pages/assure/AddAssure";
import EditAssuree from "./pages/assure/EditAssuree";
import DetailsContrat from "./pages/contrats/gestionContrats/DetailsContrat";
import FacturationProduit from "./pages/contrats/souscription/FacturationProduit";
import GestionsContrat from "./pages/contrats/gestionContrats/GestionsContrat";
import Policy from "./pages/policy/Policy";
import UtilsContacts from "./pages/contacts/UtilsContacts";
import PaiementSuccee from "./pages/paiement/PaiementSuccee";
import PaiementFail from "./pages/paiement/PaiementFail";
import DetailsBanner from "./pages/home/slider/DetailsBanner";
import Parrainage from "./pages/parainage/Parrainage";
import ChangePasswordConnected from "./pages/forgetpassword/ChangePasswordConnected";
import Add_Parrainage from "./pages/parainage/Add_Parrainage";
import Security from "./pages/securite/Security";
import CreateCodePIN from "./pages/securite/CreateCodePIN";
import ListeMessages from "./pages/messagerie/ListeMessages";
import DetailsMessages from "./pages/messagerie/DetailsMessages";
import InformationsQR_Code from "./pages/qrCodePage/InformationsQR_Code";
//--------------- TOAST FILE ----------------------------
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./pages/sigin_pin/SignIn";
import CodePinLayout from "./layouts/CodePinLayout";
import ListeDesCategories from "./pages/facturation/ListeDesCategories";
import ChooseOptions from "./pages/contrats/ChooseOptions/ChooseOptions";
import Cotisation from "./pages/cotisation/Cotisation";
import Renouvellement from "./pages/renouvellement/Renouvellement";
import SuivieCotisation from "./pages/suiviCotisation/SuivieCotisation";
import Paiment_renouvellement from "./pages/renouvellement/Paiment_renouvellement";
import Cotisation_details from "./pages/cotisation/Cotisation_details";
import Cotisation_history from "./pages/cotisation/Cotisation_history";
import UserContratList from "./pages/renouvellement/UserContratList";
import AssuranceRenouvellement from "./pages/renouvellement/AssuranceRenouvellement";
// import AssuranceAccident1 from "./pages/renouvellement/AssuranceAccident1";
import AssuranceAccident from "./pages/renouvellement/AssuranceAccident";
import UnpaidFactures from "./pages/cotisation/UnpaidFactures";
import RenouvellementAccidentContrat from "./pages/renouvellement/RenouvellementAccidentContrat";
import AssuranceAutomobile from "./pages/renouvellement/AssuranceAutomobile";
import Cotisations_list from "./pages/cotisation/Cotisations_list";
import Cotisation_payment from "./pages/cotisation/Cotisation_payment";
import CotisationUnpaidFacture from "./pages/cotisation/CotisationUnpaidFacture";
import ListeFacturesCategries from "./pages/facturation/ListeFacturesCategries";
import Cotisation_facture from "./pages/cotisation/Cotisation_facture";
import ListeFacturesParCategories from "./pages/facturation/ListeFacturesParCategories";


function App() {

  return (
    <BrowserRouter>
      <Switch>
        {/**-------------------------------- PAGE WELCOME  ------------------------------- */}
        <PublicRoute layout={WelcomeLayout} component={WelcomePage} exact path="/welcome" />
        {/**-------------------------------- PAGE LOGIN  --------------------------------- */}
        <PublicRoute layout={EmptyLayout} component={Login} exact path="/login" />
        <PublicRoute layout={CodePinLayout} component={SignIn} exact path="/signin" />

        <PublicRoute layout={EmptyLayout} component={AuthSMS} exact path="/SMS_Login" />
        <PublicRoute layout={EmptyLayout} component={ValidationSMS} exact path="/SMS_Validation/:mobile" />
        <PublicRoute layout={EmptyLayout} component={VerifMail} exact path="/verif_mail" />
        {/**-------------------------------- FORGET PASSWORD  ---------------------------- */}
        <PublicRoute layout={EmptyLayout} component={ForgetPassword} exact path="/forgot-password" />
        {/**----------------------------- CHANGE PASSWORD  ------------------------------- */}
        <AppRoute layout={EmptyLayout} component={ChangePassword} exact path="/forgot-password/:code_validation" />
        <PrivateRoute layout={MainLayout} component={ChangePasswordConnected} exact path="/change-password/:id" />
        {/**-------------------------------- PAGE REGISTER  -------------------------------*/}
        <PublicRoute layout={EmptyLayout} component={Register} exact path="/register" />
        <PublicRoute layout={EmptyLayout} component={SignupMobile} exact path="/register_mobile" />
        <PublicRoute layout={EmptyLayout} component={InterfaceChooseRegister} exact path="/choose_register" />
        {/**-------------------------------- HOME PAGE  ---------------------------------- */}
        <PrivateRoute layout={MainLayout} component={HomePage} exact path="/" />
        {/**-------------------------------- News  ---------------------------------- */}
        <PrivateRoute layout={MainLayout} component={ListeNews} exact path="/allNews" />
        <PrivateRoute layout={MainLayout} component={DetailsNews} exact path="/details_news&id=:id" />
        {/**-------------------------------- Events  ---------------------------------- */}
        <PrivateRoute layout={MainLayout} component={ListeEvents} exact path="/allEvents" />
        <PrivateRoute layout={MainLayout} component={DetailsEvents} exact path="/details_events&id=:id" />
        {/**-------------------------- DECLARATION SINISTRE  -------------------------------- */}
        <PrivateRoute layout={MainLayout} component={DeclarationSinistre} exact path="/declaration_sinistre" />
        <PrivateRoute layout={MainLayout} component={MediaSinistre} exact path="/declaration_sinistre_media&id=:sinistre_id" />
        <PrivateRoute layout={MainLayout} component={ListeDeclarations} exact path="/liste_sinistre" />
        <PrivateRoute layout={MainLayout} component={DetailsDeclaration} exact path="/details_sinistea&id=:sinistre_id" />
        {/**-------------------------------- ADRESSES  ---------------------------------- */}
        <PrivateRoute layout={MainLayout} component={AdressesUser} exact path="/adresses" />
        <PrivateRoute layout={MainLayout} component={AjouterAdresse} exact path="/add_adresse" />
        <PrivateRoute layout={MainLayout} component={EditAdresse} exact path="/edit_adresse/:id" />
        {/**-------------------------------- ASSUREE  ---------------------------------- */}
        <PrivateRoute layout={MainLayout} component={Assure} exact path="/assuree" />
        <PrivateRoute layout={MainLayout} component={AddAssure} exact path="/add_assuree" />
        <PrivateRoute layout={MainLayout} component={EditAssuree} exact path="/edit_assuree/:id" />
        {/**-------------------------------- CHAT  ---------------------------------- */}
        <PrivateRoute layout={MainLayout} component={Contacts} exact path="/chat_support" />
        {/**-------------------------------- parrainage  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={Parrainage} exact path="/parrainage" />
        <PrivateRoute layout={MainLayout} component={Add_Parrainage} exact path="/add_parrainage" />
        {/**-------------------------------- Securite  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={Security} exact path="/securite" />
         {/**------------------------------  CREATE CODE PIN  ---------------------------------- */}
         <PrivateRoute layout={MainLayout} component={CreateCodePIN} exact path="/create_code_pin" />
        {/**-------------------------------- NOS PRODUITS  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={NosProduits} exact path="/nos_produits" />
        <PrivateRoute layout={MainLayout} component={DetailsAssurance} exact path="/details_produits/:id" />
        <PrivateRoute layout={MainLayout} component={GestionsContrat} exact path="/liste_contrats" />
        <PrivateRoute layout={MainLayout} component={DetailsContrat} exact path="/details_contrats&id=:id&type=:type&policy_code=:policy_code" />
        {/**-------------------------------- BANNERS  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={DetailsBanner} exact path="/details_publicite/:id" />

        {/**--------------------------------DEVIS PRODUIT  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={DevisProduit} exact path="/devis_produits/id=:id&title=:title&type=:type" />
        <PrivateRoute layout={MainLayout} component={FacturationProduit} exact path="/facturation_produits/id=:id&title=:title&type=:type" />
        {/**----------------------------- USER PROFILE  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={ProfilUser} exact path="/profil" />
        <PrivateRoute layout={MainLayout} component={PersonnelInfo} exact path="/personnel" />
        {/**----------------------------- CONTACTS  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={UtilsContacts} exact path="/contacts" />
        <PrivateRoute layout={MainLayout} component={UploadsMedia} exact path="/media_contacts/id=:ticket_id" />
        <PrivateRoute layout={MainLayout} component={VosMessages} exact path="/liste_messages" />
        <PrivateRoute layout={MainLayout} component={DetailsContacts} exact path="/detailsMessages&id=:id" />
        <AppRoute layout={EmptyLayout} component={Policy} exact path="/policy" />
        {/**----------------------------- Messageries  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={ListeMessages} exact path="/messages" />
        <PrivateRoute layout={MainLayout} component={DetailsMessages} exact path="/messages/:id" />

        {/**-------------------------------- REDIRECT TO FAILURE / SUCCESS PAGES   ---------------------------------- */}
        <AppRoute layout={EmptyLayout} component={PaiementSuccee} exact path="/paytech_success" />
        <AppRoute layout={EmptyLayout} component={PaiementFail} exact path="/paytech_fail" />
        {/**----------------------------- FACTURATION  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={ListeDesCategories} exact path="/categories_factures" />
        <PrivateRoute layout={MainLayout} component={ListeFacturesCategries} exact path="/factures_list/:categoryId" />
        <PrivateRoute layout={MainLayout} component={ListeFacturesParCategories} exact path="/factures_list_categries/:idFacture/:categoryId" />

        {/**---------------------------Choisir Contrat  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={ChooseOptions} exact path="/choisir_contrats" />
         {/**--------------------------- COTISATION  ------------------------------- */}
        <PrivateRoute layout={MainLayout} component={Cotisation} exact path="/cotisation_contrat" />
        <PrivateRoute layout={MainLayout} component={Cotisation_details} exact path="/cotisation_details/:idcategory/:policy_code" />
        <PrivateRoute layout={MainLayout} component={Cotisation_history} exact path="/cotisation_history/:idCotisation" />
        <PrivateRoute layout={MainLayout} component={Cotisations_list} exact path="/cotisation_list/:idCategory" />
        <PrivateRoute layout={MainLayout} component={Cotisation_payment} exact path="/cotisation_payment/:policy_code" />
        <PrivateRoute layout={MainLayout} component={Cotisation_facture} exact path="/cotisation_facture/:idFacture/:idcategory" />

        
        {/* <PrivateRoute layout={MainLayout} component={UnpaidFactures} exact path="/unpaid_facture" /> */}
        <PrivateRoute layout={MainLayout} component={CotisationUnpaidFacture} exact path="/cotisation_unpaid_facture/:idListe" />

        

         {/**--------------------------- Renouvellement  ------------------------------- */}
         <PrivateRoute layout={MainLayout} component={Renouvellement} exact path="/renouvellement_contrat" />
         <PrivateRoute layout={MainLayout} component={AssuranceAccident} exact path="/renouvellement_accident/:id" />
         <PrivateRoute layout={MainLayout} component={AssuranceAutomobile} exact path="/renouvellement_automobile/:id" />
         {/* <PrivateRoute layout={MainLayout} component={RenouvellementAccidentContrat} exact path="/renouvellement_accident/:id" /> */}
         <PrivateRoute layout={MainLayout} component={AssuranceRenouvellement} exact path="/renouvellement_assurance/:id" />
         {/* <PrivateRoute layout={MainLayout} component={UserContratList} exact path="/renouvellement_contrat" /> */}
         <PrivateRoute layout={MainLayout} component={Paiment_renouvellement} exact path="/renouvellement_paiment/:id" />
         <PrivateRoute layout={MainLayout} component={SuivieCotisation} exact path="/suivie_cotisation" />
         {/* <PrivateRoute layout={MainLayout} component={UnpaidFactures} exact path="/unpaid_factures/:policy_code" /> */}
         <PrivateRoute layout={MainLayout} component={UnpaidFactures} exact path="/unpaid_factures/:policy_code" />

         

        {/** QR CODE INFORMATIONS  */}
        <PublicRoute layout={WelcomeLayout} component={InformationsQR_Code} exact path="/policy/:policy_contrat" />

        {/**-------------------------------- 404   ---------------------------------- */}
        <AppRoute layout={EmptyLayout} component={Page404} />


      </Switch>
    </BrowserRouter>
  );
}

export default App;
