import parse from "html-react-parser";
import React from "react";

export default function ConditionGenerale({ setcondition1, t }) {
  return (
    <div
      className="modal fade"
      id="exampleModal1"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title fz-14" id="exampleModalLabel">
              {t("Conditions générales d’utilisation et de vente DiokoAssur")}
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {parse(`<p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>CONDITIONS G&Eacute;N&Eacute;RALES D&rsquo;UTILISATION ET DE VENTE DE &laquo;&nbsp;DIOKOASSUR&nbsp;&raquo;</b></span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"> <b>I/- CONDITIONS GENERALES D&rsquo;UTILISATION </b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 1 &ndash; DEFINITIONS </b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Base de donn&eacute;es&nbsp;: d&eacute;signe le lieu ou support dans lequel est stock&eacute; l&apos;int&eacute;gralit&eacute; des donn&eacute;es brutes ou d&apos;informations en rapport avec un th&egrave;me ou une activit&eacute; ; celles-ci peuvent &ecirc;tre de natures diff&eacute;rentes et plus ou moins reli&eacute;es entre elles.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bon de commande&nbsp;: d&eacute;signe le formulaire &eacute;lectronique rempli et valid&eacute; par le client aux fins de la souscription en ligne des services ou offres propos&eacute;s par l&rsquo;interm&eacute;diaire de l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CGU :&nbsp;</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d&eacute;signe les &laquo;&nbsp;Conditions G&eacute;n&eacute;rales d&rsquo;utilisation&nbsp;&raquo; de l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CGV&nbsp;:</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;d&eacute;signe les &laquo; Conditions G&eacute;n&eacute;rales de vente &raquo; de l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Client :&nbsp;</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d&eacute;signe l&rsquo;utilisateur qui &eacute;met un bon de commande par l&rsquo;interm&eacute;diaire de l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Code confidentiel / Identifiant</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;: d&eacute;signe les informations confidentielles, dont l&rsquo;utilisateur doit garder le secret, lui permettant, utilis&eacute;es conjointement, de justifier de son identit&eacute; et d&rsquo;acc&eacute;der aux rubriques r&eacute;serv&eacute;es.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cookie(s)&nbsp;:</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Un cookie est d&eacute;fini par le protocole de communication HTTP comme &eacute;tant une suite d&apos;informations envoy&eacute;e par un serveur HTTP &agrave; un client HTTP, que ce dernier retourne lors de chaque interrogation du m&ecirc;me serveur HTTP sous certaines conditions.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Services / Offres&nbsp;</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: d&eacute;signe les diff&eacute;rents services et offres commerciales propos&eacute;s par le vendeur par l&rsquo;interm&eacute;diaire de l&rsquo;Application tels qu&rsquo;ils figurent &agrave; l&rsquo;article 1 &laquo; Catalogue de produits &raquo; de la deuxi&egrave;me partie des pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;utilisation et de vente de l&rsquo;Application pr&eacute;sent&eacute;e sous le libell&eacute; &laquo; II/- CONDITIONS GENERALES DE VENTE &raquo;.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Application&nbsp;:</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;le logiciel applicatif d&eacute;nomm&eacute; &laquo; ASSUR 3.0 &raquo; t&eacute;l&eacute;chargeable depuis les plateformes d&eacute;di&eacute;es (ex : Play store, App Store, etc.) et offrant plusieurs produits et services relatifs &agrave; l&rsquo;assurance, notamment et sans que la liste ne soit consid&eacute;r&eacute;e comme exhaustive : la cr&eacute;ation de compte client, la r&eacute;alisation de devis ou simulation, la souscription d&rsquo;assurances, la d&eacute;claration de sinistres, etc.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Utilisateur&nbsp;:</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;d&eacute;signe toute personne navigant sur l&rsquo;Application ou utilisant l&rsquo;un des services propos&eacute;s par l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendeur&nbsp;:</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;la soci&eacute;t&eacute; DIOKOTECH ou l&rsquo;une de ses filiales proposant ses services (&quot;offres&quot;) par l&rsquo;interm&eacute;diaire de l&rsquo;Application et au profit de laquelle un bon de commande est &eacute;mis.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vente &agrave; distance et par voie &eacute;lectronique&nbsp;</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: technique de vente permettant au Client d&rsquo;&eacute;mettre un bon de commande &agrave; partir de l&rsquo;Application et de recevoir la livraison de sa commande, conform&eacute;ment aux CGV.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 2&nbsp;&ndash; INFORMATIONS L&Eacute;GALES </b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application est &eacute;dit&eacute; par&nbsp;:</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DIOKOTECH</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">, Soci&eacute;t&eacute; par Actions Simplifi&eacute;es au capital de Un Million (1 000&nbsp;000) Francs CFA, immatricul&eacute;e au RCCM de Dakar sous le num&eacute;ro SN-DKR-2021-B-21378, ayant son si&egrave;ge social &agrave; Sacr&eacute;-C&oelig;ur 3, villa n&deg;9432, Dakar &ndash; S&eacute;n&eacute;gal.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">T&eacute;l&eacute;phone : +221 33 827 00 05 &nbsp; &nbsp;/ &nbsp; &nbsp; &nbsp; Courriel : contact@diokotech.com</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Repr&eacute;sentant l&eacute;gal&nbsp;est : Madame Coumba BALDE</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Responsable des contenus&nbsp;: Anicet KAKOU</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application est h&eacute;berg&eacute; par&nbsp;Namecheap dont le si&egrave;ge social est sis &agrave; Los Angeles, en Californie &ndash; USA (United States of America).</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 3&nbsp;&ndash; PR&Eacute;SENTATION DE L&rsquo;APPLICATION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application est destin&eacute;e &agrave; :</span></p>
<ul>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Informer sur les produits disponibles ;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Permettre la cr&eacute;ation de compte client&nbsp;;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Permettre la r&eacute;alisation de devis&nbsp;;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Permettre la souscription de produits d&rsquo;assurance ;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">R&eacute;aliser des d&eacute;clarations de sinistres&nbsp;;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Permettre la conservation et le t&eacute;l&eacute;chargement de tous ses documents contractuels et factures d&rsquo;assurances&nbsp;;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Informer sur les offres promotionnelles&nbsp;;</span></li>
    <li dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Toutes les autres utilisations possibles et rajout&eacute;es par la suite et li&eacute;es &agrave; l&rsquo;actualit&eacute;, &agrave; la publicit&eacute; ou la proposition ou la vente d&rsquo;assurances.</span></li>
</ul>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 4 &ndash; CONTACT</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pour toutes questions ou demandes d&rsquo;informations concernant l&rsquo;Application, ou tout signalement de contenu ou d&rsquo;activit&eacute;s illicites, l&rsquo;utilisateur peut contacter l&rsquo;&eacute;diteur &agrave; l&rsquo;adresse e-mail suivante&nbsp;: contact@diokotech.com, ou lui adresser un courrier recommand&eacute; avec accus&eacute; de r&eacute;ception &agrave; Sicap Sacr&eacute;-C&oelig;ur III VDN Villa N&deg;9432 &agrave; Dakar &ndash; S&eacute;n&eacute;gal.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE&nbsp;5&nbsp;&ndash; ACCEPTATION DES CONDITIONS D&rsquo;UTILISATION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&apos;utilisation ou l&apos;acc&egrave;s &agrave; l&rsquo;Application et aux services propos&eacute;s par l&rsquo;interm&eacute;diaire de l&rsquo;Application impliquent l&apos;acceptation sans r&eacute;serve ni restriction des pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&apos;Utilisation (CGU).</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur se r&eacute;serve le droit de modifier, &agrave; tout moment et sans pr&eacute;avis, l&rsquo;Application et ses services ainsi que les pr&eacute;sentes CGU, et faire &eacute;voluer l&rsquo;Application par la mise &agrave; disposition de nouvelles fonctionnalit&eacute;s ou la suppression ou la modification de fonctionnalit&eacute;s existantes.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En cas de d&eacute;saccord avec les CGU, aucun usage de l&rsquo;Application ne saurait &ecirc;tre effectu&eacute; par l&rsquo;utilisateur.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 6 &ndash; ACCES ET NAVIGATION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application est accessible 24 heures/24 et 7 jours/7. L&rsquo;&eacute;diteur se r&eacute;serve cependant le droit de fermer ou de rendre inaccessible tout ou partie de l&rsquo;Application notamment pour des op&eacute;rations de maintenance ou &agrave; la demande de toute autorit&eacute; judiciaire et en cas de force majeure.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur ne peut, en aucun cas, &ecirc;tre responsable de la non-accessibilit&eacute; de l&rsquo;Application et des services, et de toute interruption totale ou partielle de la consultation de ses pages. L&apos;utilisateur se doit de v&eacute;rifier qu&apos;il dispose des outils et moyens de communication &eacute;lectroniques, des droits d&apos;acc&egrave;s fournis par l&rsquo;&eacute;diteur et des comp&eacute;tences permettant l&apos;acc&egrave;s &agrave; l&rsquo;Application et aux services.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La connexion et la navigation sur l&rsquo;Application valent acceptation sans r&eacute;serve des pr&eacute;sentes CGU, quelques soient les moyens techniques d&rsquo;acc&egrave;s et les terminaux utilis&eacute;s.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 7 &ndash; GESTION DE L&rsquo;APPLICATION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur se r&eacute;serve le droit, &agrave; tout moment, de modifier, tout ou partie, des conditions d&rsquo;utilisation de l&rsquo;Application ou des services propos&eacute;s, avec ou sans notification.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les modifications entreront en vigueur d&egrave;s leur mise en ligne.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Il appartient donc &agrave; l&apos;utilisateur de revoir, de mani&egrave;re r&eacute;guli&egrave;re, les termes des pr&eacute;sentes. Le simple fait de continuer &agrave; utiliser les services propos&eacute;s implique l&apos;acceptation par l&rsquo;utilisateur de toutes modifications apport&eacute;es aux pr&eacute;sentes.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 8 &ndash; ACC&Egrave;S AUX SERVICES DE L&rsquo;APPLICATION ET INSCRIPTION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;acc&egrave;s et, le cas &eacute;ch&eacute;ant, l&rsquo;inscription aux services de l&rsquo;Application sont r&eacute;serv&eacute;s exclusivement aux personnes physiques capables juridiquement et disposant, lorsqu&rsquo;elles agissent pour le compte de personnes morales, des habilitations n&eacute;cessaires.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;acc&egrave;s &agrave; certains services, notamment aux services payants de l&rsquo;Application, pourra &ecirc;tre conditionn&eacute; par l&rsquo;inscription de l&rsquo;utilisateur qui devra, dans ce cas, remplir et valider un formulaire d&rsquo;inscription mis en ligne &agrave; cet effet sur l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Lors de son inscription, l&rsquo;utilisateur s&rsquo;engage &agrave; fournir des informations exactes, sinc&egrave;res et &agrave; jour. Il devra, en outre, proc&eacute;der &agrave; une mise &agrave; jour r&eacute;guli&egrave;re des donn&eacute;es le concernant afin d&rsquo;en conserver l&rsquo;exactitude.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Une seule inscription aux services de l&rsquo;Application est admise par personne physique et par personne morale.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;identifiant et le code confidentiel ou mot de passe cr&eacute;&eacute;s lors de l&rsquo;inscription sont strictement personnels et confidentiels. Il est de la responsabilit&eacute; de l&rsquo;utilisateur de les conserver, de les tenir secrets et de ne les communiquer &agrave; quiconque. A d&eacute;faut, l&apos;utilisateur sera le seul responsable des cons&eacute;quences de leur divulgation ou de leur utilisation par des tiers. Le premier code confidentiel est g&eacute;n&eacute;r&eacute; automatiquement par l&rsquo;Application. Il appartiendra &agrave; l&rsquo;utilisateur de le modifier lors de sa premi&egrave;re connexion pour plus de s&eacute;curit&eacute;.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La responsabilit&eacute; de l&rsquo;&eacute;diteur ne peut &ecirc;tre engag&eacute;e quant aux cons&eacute;quences qui r&eacute;sulteraient d&rsquo;un usage frauduleux ou abusif de l&rsquo;identifiant ou du code confidentiel.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;utilisation concomitante de l&rsquo;identifiant et du code confidentiel constitue la preuve de l&rsquo;identit&eacute; de l&rsquo;utilisateur et de son consentement au traitement de l&rsquo;op&eacute;ration.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En cas de perte du code confidentiel, l&rsquo;utilisateur pourra demander sa r&eacute;initialisation par l&rsquo;Editeur en lui envoyer sa demande par mail &agrave; l&rsquo;adresse contact indiqu&eacute; &agrave; l&rsquo;article 4 ci-dessus. Lorsque cette option sera disponible, l&rsquo;utilisateur pourra lui-m&ecirc;me proc&eacute;der &agrave; la r&eacute;initialisation dudit code en cliquant sur le lien &laquo;&nbsp;Code confidentiel oubli&eacute;&nbsp;&raquo; ou &laquo;&nbsp;Mot de passe oubli&eacute;&nbsp;&raquo;, puis en suivant les instructions y relatives.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les enregistrements au sein du syst&egrave;me d&rsquo;information de l&rsquo;&eacute;diteur constituent &eacute;galement, pour l&rsquo;&eacute;diteur et l&rsquo;utilisateur, la preuve desdites op&eacute;rations.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 9 &ndash; LIMITES DE RESPONSABILIT&Eacute;S</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur ne prend aucun engagement quant aux liens hypertextes permettant &agrave; l&rsquo;utilisateur d&apos;acc&eacute;der &agrave; des sites tiers et ne s&apos;engage aucunement &agrave; contr&ocirc;ler ces liens, ni le contenu des sites vers lesquels ils renvoient. La mention d&rsquo;un lien vers un site tiers n&apos;implique ni l&rsquo;approbation par l&rsquo;&eacute;diteur du site tiers, ni l&apos;approbation par l&rsquo;&eacute;diteur des produits et services qui peuvent y &ecirc;tre propos&eacute;s. L&rsquo;&eacute;diteur d&eacute;cline, par cons&eacute;quent, toute responsabilit&eacute; quant au contenu ou &agrave; la politique de protection de la vie priv&eacute;e des sites auxquels renvoient ces liens hypertextes.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Compte tenu des al&eacute;as techniques propres &agrave; Internet, l&rsquo;&eacute;diteur ne peut garantir le fonctionnement ininterrompu des services propos&eacute;s et ne peut &ecirc;tre tenu responsable du fait des interruptions, suspensions, dysfonctionnements ou bogues des services, alt&eacute;rations, destructions ou pertes des donn&eacute;es fournies par les utilisateurs.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;utilisateur reconna&icirc;t aussi que le service pourra &ecirc;tre interrompu pour des op&eacute;rations de maintenance.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En outre, l&rsquo;&eacute;diteur ne saurait &ecirc;tre tenu responsable pour toute difficult&eacute; d&rsquo;acc&egrave;s ou d&rsquo;utilisation qui serait due &agrave; des dysfonctionnements ou indisponibilit&eacute;s des sites par lesquels l&rsquo;utilisateur acc&egrave;de aux services propos&eacute;s, d&egrave;s lors que ces sites sont sous la responsabilit&eacute; d&rsquo;une entit&eacute; autre que l&rsquo;&eacute;diteur.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur se r&eacute;serve le droit, &agrave; tout moment, de modifier ou de suspendre temporairement ou d&eacute;finitivement, tout ou partie des Services propos&eacute;s, avec ou sans notification. L&rsquo;&eacute;diteur ne peut &ecirc;tre tenu pour responsable envers l&rsquo;utilisateur ou envers les tiers de toutes modifications, suspensions ou cessations des services propos&eacute;s.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur ne sauraient &ecirc;tre tenus responsables des dommages de toute nature, directs ou indirects r&eacute;sultant du contenu et/ou de l&rsquo;acc&egrave;s et/ou de l&rsquo;utilisation du site et/ou des services propos&eacute;s incluant notamment mais non limitativement, toute perte d&rsquo;exploitation, perte de programme, perte de donn&eacute;es, toute d&eacute;t&eacute;rioration ou virus qui pourraient infecter l&rsquo;&eacute;quipement informatique ou tout autre bien de l&rsquo;utilisateur du site ou des services.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 10 &ndash; TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les traitements informatiques des donn&eacute;es &agrave; caract&egrave;re personnel collect&eacute;es via l&rsquo;Application ont fait l&rsquo;objet de d&eacute;claration aupr&egrave;s de la Commission Donn&eacute;es Personnelles (&quot;CDP&quot;).</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;utilisateur peut s&rsquo;opposer, &agrave; tout moment, &agrave; l&rsquo;utilisation commerciale de ses donn&eacute;es en adressant un courrier &agrave; l&rsquo;adresse mentionn&eacute;e ci-dessous ou par retour de courriel &agrave; l&rsquo;occasion d&rsquo;un envoi. Il pourra exercer ses droits d&rsquo;acc&egrave;s, d&rsquo;opposition, de rectification et de suppression aupr&egrave;s de l&rsquo;&eacute;diteur, en annexant &agrave; sa demande un justificatif d&rsquo;identit&eacute;.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 11 &ndash; COOKIES</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application pourrait avoir recours aux techniques de &quot;cookies&quot; lui permettant de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d&rsquo;am&eacute;liorer le service pour le confort de l&rsquo;utilisateur, lequel peut s&rsquo;opposer &agrave; l&rsquo;enregistrement de ces &quot;cookies&quot; en configurant son logiciel de navigation.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 12 &ndash; PROPRIETE INTELLECTUELLE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application et les diff&eacute;rentes pages de l&rsquo;Application permettant l&rsquo;acc&egrave;s aux services propos&eacute;s, ainsi que tous les &eacute;l&eacute;ments composants et/ou mis en &oelig;uvre pour le fonctionnement des services propos&eacute;s, et notamment les photographies, images, animations, logiciels, interfaces, marques, logos, textes, dessins, ic&ocirc;nes, illustrations (ci-apr&egrave;s d&eacute;sign&eacute;s ensemble comme les &laquo; Donn&eacute;es &raquo;) sont prot&eacute;g&eacute;s par le droit de la propri&eacute;t&eacute; intellectuelle aussi bien sur le territoire national du S&eacute;n&eacute;gal que dans le monde entier.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">D&rsquo;une mani&egrave;re g&eacute;n&eacute;rale, l&apos;acc&egrave;s &agrave; l&rsquo;Application et aux services propos&eacute;s ne conf&egrave;re &agrave; l&rsquo;utilisateur aucune pr&eacute;rogative sur les droits de propri&eacute;t&eacute; intellectuelle relatifs aux Donn&eacute;es, qui restent la propri&eacute;t&eacute; exclusive de l&rsquo;&eacute;diteur ou, le cas &eacute;ch&eacute;ant, de titulaires tiers.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;utilisateur est autoris&eacute; &agrave; copier les pages de l&rsquo;Application uniquement &agrave; titre d&apos;information personnelle et pour un usage strictement priv&eacute;. En dehors de cette hypoth&egrave;se, l&apos;utilisateur n&apos;est pas autoris&eacute; &agrave; copier, reproduire, repr&eacute;senter, modifier, traduire, cr&eacute;er des produits d&eacute;riv&eacute;s, distribuer tout ou partie des donn&eacute;es ou les exploiter et les utiliser de toute autre mani&egrave;re en dehors du service utilis&eacute;.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Toute utilisation non autoris&eacute;e ou ill&eacute;gale des donn&eacute;es peut entra&icirc;ner la responsabilit&eacute; civile et p&eacute;nale de l&rsquo;utilisateur conform&eacute;ment &agrave; la r&eacute;glementation en vigueur.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 13 &ndash; CONVENTION SUR LA PREUVE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Il est express&eacute;ment convenu que toutes les donn&eacute;es, informations, fichiers et tout autre &eacute;l&eacute;ment num&eacute;rique &eacute;chang&eacute;s entre l&rsquo;utilisateur et l&rsquo;&eacute;diteur ou conserv&eacute;s par l&rsquo;&eacute;diteur, notamment dans sa base de donn&eacute;es ou sur ses serveurs, constitueront des preuves recevables, valides, opposables et ayant la force probante d&apos;un acte sous seing priv&eacute;.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;L&rsquo;utilisateur s&rsquo;engage &agrave; ne pas contester la recevabilit&eacute;, la validit&eacute;, l&rsquo;opposabilit&eacute; ou la force probante des &eacute;l&eacute;ments de nature ou sous format &eacute;lectronique pr&eacute;cit&eacute;s, sur le fondement de leur nature &eacute;lectronique. Sauf preuve contraire, ces &eacute;l&eacute;ments seront valables et opposables de la m&ecirc;me mani&egrave;re, dans les m&ecirc;mes conditions et avec la m&ecirc;me force probante que tout document qui serait &eacute;tabli, re&ccedil;u ou conserv&eacute; par &eacute;crit.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 14 &ndash; FORCE MAJEURE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;&eacute;diteur ne sera responsable d&apos;aucun pr&eacute;judice, dommage ou perte r&eacute;sultant d&apos;un cas de force majeure tel que d&eacute;fini par la r&eacute;glementation.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 15 &ndash; LANGUE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application, ses contenus et les pr&eacute;sentes, sont &eacute;dit&eacute;s en langue fran&ccedil;aise. Lorsque l&rsquo;&eacute;diteur proposera une traduction en d&rsquo;autres langues, la r&eacute;daction fran&ccedil;aise primera sur les versions traduites.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 16 &ndash; INVALIDIT&Eacute;&nbsp;</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si une ou plusieurs stipulations des pr&eacute;sentes conditions d&rsquo;utilisation sont tenues pour non valides ou d&eacute;clar&eacute;es comme telles en application d&rsquo;une loi, d&rsquo;un r&egrave;glement ou &agrave; la suite d&rsquo;une d&eacute;cision devenue d&eacute;finitive d&rsquo;une juridiction comp&eacute;tente, les autres stipulations garderont toute leur force et leur port&eacute;e.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 17 &ndash; LOI APPLICABLE ET JURIDICTION COMPETENTE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les pr&eacute;sentes conditions d&apos;utilisation de l&rsquo;Application et les services propos&eacute;s sont soumis au droit s&eacute;n&eacute;galais.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En cas de litige sur l&rsquo;interpr&eacute;tation ou l&rsquo;ex&eacute;cution des CGU, l&rsquo;utilisateur s&rsquo;adressera en priorit&eacute; &agrave; l&rsquo;&eacute;diteur pour un r&egrave;glement amiable.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A d&eacute;faut de solution amiable dans le d&eacute;lai de trente (30) jours &agrave; compter de la date de la notification du manquement par l&rsquo;utilisateur, le litige pourra &ecirc;tre soumis au Tribunal de Grande Instance Hors Classe de Dakar, par la Partie la plus diligente.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>II/- CONDITIONS GENERALES DE VENTE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PREAMBULE</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente (ci-apr&egrave;s &quot;CGV&quot;) d&eacute;finissent les droits et obligations contractuels du vendeur et de son client dans le cadre d&rsquo;une vente d&rsquo;assurances ou fourniture de service &agrave; distance et/ou par voie &eacute;lectronique. Elles sont compl&eacute;t&eacute;es par les Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les CGV r&eacute;gissent exclusivement la relation entre le vendeur et le client. Le client est r&eacute;put&eacute; les accepter sans r&eacute;serve. En cas de doute sur l&rsquo;une des conditions g&eacute;n&eacute;rales de vente, le client peut se rapprocher du vendeur pour toutes informations compl&eacute;mentaires &agrave; l&rsquo;adresse et aux contacts indiqu&eacute;s aux CGU.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 1 &ndash; CATALOGUE DE PRODUITS</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>1.1/ DESCRIPTION DU CATALOGUE DE PRODUITS</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">L&rsquo;Application permet au client de consulter les offres d&rsquo;assurances propos&eacute;es par le vendeur et, sous certaines conditions, de souscrire &agrave; distance, &agrave; certaines des offres propos&eacute;es.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pour pouvoir souscrire aux offres propos&eacute;es par le vendeur, l&rsquo;utilisateur doit avoir la capacit&eacute; juridique n&eacute;cessaire notamment &ecirc;tre &acirc;g&eacute; d&rsquo;au moins 18 ans et disposer, le cas &eacute;ch&eacute;ant, des dispenses n&eacute;cessaires.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Il est pr&eacute;cis&eacute; que lorsque la souscription en ligne des produits d&rsquo;assurance sera op&eacute;rationnelle, elle pourra &ecirc;tre conditionn&eacute;e par l&rsquo;inscription de l&rsquo;utilisateur sur l&rsquo;Application, au pr&eacute;alable.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>1.2/ INFORMATIONS SUR LES SERVICES ET PRODUITS&nbsp;</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les informations sur l&rsquo;Application sont pr&eacute;sent&eacute;es &agrave; titre indicatif et g&eacute;n&eacute;ral et ne peuvent pas &ecirc;tre assimil&eacute;es &agrave; des conseils. L&rsquo;utilisateur devra, le cas &eacute;ch&eacute;ant, se rapprocher du vendeur pour tout conseil concernant ses besoins ou les produits du vendeur.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les informations ne pr&eacute;tendent aucunement &agrave; l&rsquo;exhaustivit&eacute;. Malgr&eacute; les mises &agrave; jour du contenu de l&rsquo;Application, le vendeur ne peut pas &ecirc;tre tenu pour responsable de la modification des dispositions administratives et juridiques survenant apr&egrave;s la publication. L&rsquo;utilisateur, quel que soit son lieu de r&eacute;sidence, est r&eacute;put&eacute; venir se renseigner de sa propre initiative sur les produits et services propos&eacute;s sur l&rsquo;Application.&nbsp;</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le vendeur se r&eacute;serve le droit, &agrave; tout moment et sans pr&eacute;avis, de modifier et d&rsquo;actualiser les pr&eacute;sentes informations ainsi que le contenu des offres commerciales.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 2 &ndash; PRIX ET TARIFICATION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le vendeur se r&eacute;serve le droit de r&eacute;viser ses prix &agrave; tout moment. Seuls s&rsquo;appliqueront les tarifs en vigueur indiqu&eacute;s au moment d&rsquo;une commande, d&rsquo;une r&eacute;servation ou de la souscription de l&rsquo;assurance.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les prix sont indiqu&eacute;s en Francs CFA (hors taxes et toutes taxes comprises) et ne tiennent pas compte des frais de livraison et accessoires, lesquels sont factur&eacute;s en suppl&eacute;ment. Le d&eacute;tail du prix Toutes Taxes Comprises (&quot;TTC&quot;) &agrave; payer est indiqu&eacute; avant la validation du bon de commande par le client.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les prix TTC tiennent compte des taxes applicables au jour de l&rsquo;&eacute;mission du bon de commande par le client et tout changement y relatif fera automatiquement l&rsquo;objet d&rsquo;une mise &agrave; jour. En outre, si une ou plusieurs taxes ou contributions venaient &agrave; &ecirc;tre cr&eacute;&eacute;es, supprim&eacute;es ou modifi&eacute;es par la r&eacute;glementation, les prix TTC seront mis &agrave; jour en cons&eacute;quence.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les paiements pourront &ecirc;tre effectu&eacute;s selon les possibilit&eacute;s offertes par l&rsquo;Application, en ligne ou &agrave; la livraison, et porteront sur la totalit&eacute; du prix TTC indiqu&eacute; lors de la commande.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 3 &ndash; COMMANDE EN LIGNE</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Toute commande ou souscription en ligne d&rsquo;une police d&rsquo;assurance s&rsquo;effectue au moyen d&rsquo;un formulaire disponible en ligne, apr&egrave;s que le client ait choisi l&rsquo;offre qu&rsquo;il souhaite souscrire. En remplissant puis en validant ce formulaire, le client accepte le prix et la description du produit.</span><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><span class="Apple-tab-span" style="white-space:pre;">&nbsp; &nbsp;&nbsp;</span></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le client devra marquer son acceptation des pr&eacute;sentes CGV et donner son consentement au traitement des donn&eacute;es personnelles le concernant, en cliquant &agrave; l&rsquo;endroit indiqu&eacute;.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Selon les possibilit&eacute;s de l&rsquo;Application, il pourra opter pour la livraison ou le retrait de sa commande aupr&egrave;s du vendeur ou d&rsquo;un point de vente de son r&eacute;seau de partenaires. Dans le cas de commandes li&eacute;s &agrave; des contrats d&eacute;mat&eacute;rialis&eacute;s, le client recevra tous les documents contractuels par mail et/ou dans son compte client.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Le vendeur se r&eacute;serve le droit d&rsquo;annuler la commande du client dans le cas l&rsquo;adresse fournie par celle-ci serait erron&eacute;e ou en cas de difficult&eacute;s de livraison imputables au client. Il pourra &eacute;galement d&eacute;cider de suspendre l&rsquo;ex&eacute;cution de la commande, jusqu&rsquo;&agrave; la r&eacute;solution du probl&egrave;me.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Lorsque le paiement est possible en ligne, le client devra valider le mode de paiement, s&rsquo;il choisit de payer en ligne.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 4 &ndash; PREUVE DE LA TRANSACTION</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les communications, commandes et paiements intervenues entre le client et le vendeur pourront &ecirc;tre prouv&eacute;es gr&acirc;ce aux registres informatis&eacute;s, conserv&eacute;s dans les syst&egrave;mes informatiques du vendeur. Les bons de commandes et factures sont archiv&eacute;s pour servir comme moyen preuve, &eacute;tant entendu que le client re&ccedil;oit &eacute;galement un mail r&eacute;capitulatif de l&rsquo;op&eacute;ration apr&egrave;s la transaction en ligne.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 5 &ndash; MODE DE PAIMENT</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les paiements &agrave; la livraison s&rsquo;effectueront en esp&egrave;ces. En cas de disponibilit&eacute; d&rsquo;autres moyens de paiement &agrave; la livraison, le paiement en esp&egrave;ces sera privil&eacute;gi&eacute; compte tenu des probl&egrave;mes techniques que peuvent rencontrer les moyens de paiement &eacute;lectroniques.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Lorsque l&rsquo;Application le permet, le client pourra proc&eacute;der au paiement de sa commande par le biais des moyens de paiement disponibles en ligne. Dans ce cas, le paiement ne sera effectif qu&rsquo;apr&egrave;s que le compte du vendeur ait &eacute;t&eacute; cr&eacute;dit&eacute; du montant total d&ucirc; au titre de la commande du client. A d&eacute;faut, la commande ne sera pas valid&eacute;e et la livraison ne sera pas effectu&eacute;e. Le client en sera inform&eacute; le cas &eacute;ch&eacute;ant dans les d&eacute;lais d&rsquo;usage ou dans les soixante-douze (72) heures de l&rsquo;op&eacute;ration, sauf si le client re&ccedil;oit l&rsquo;information en ligne au moment de sa commande sur l&rsquo;Application.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"><b>ARTICLE 6 &ndash; LIVRAISON</b></span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Les articles command&eacute;s ou polices d&rsquo;assurances sujets &agrave; livraison seront livr&eacute;es &agrave; l&rsquo;adresse indiqu&eacute;e par le client dans le formulaire en ligne lors de l&rsquo;&eacute;mission du bon de commande, le client &eacute;tant garant de l&rsquo;exactitude des informations fournies.</span></p>
<p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:11pt;sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tout colis renvoy&eacute; au vendeur &agrave; cause d&rsquo;une adresse de livraison erron&eacute;e ou incompl&egrave;te sera r&eacute;exp&eacute;di&eacute;e aux frais du client.</span></p>`)}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {" "}
              <i className="bi bi-x-lg m-1" /> {t("Fermer")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setcondition1(true)}
              data-bs-dismiss="modal"
            >
              {" "}
              <i className="bi bi-check2-circle m-1" /> {t("Confirmer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
