/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUser } from "../../Auth/useUser";
import { error_message, success_message } from "../../components/alerte/AlerteMessage";
import DotLoader from "../../components/loading/DotLoader";
import { addAssuree } from "../../services/assuree/assureeAPI";
import FormAssuree from "./FormAssuree";
import { useHistory } from "react-router-dom";

export default function AddAssure() {
  const user = useUser();
  const history = useHistory();
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit } = useForm();
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);
  /**
   * OnSubmit Function : ADD ASSUREE
   */
  const addAssurees = (data) => {
    var user_id = user?.result?.id;

    setLoading(true);
   
    var info ={
      user_id : user_id,
      nom: data?.nom,
      prenom : data?.prenom,
      date_naissance :data?.date_naissance,
      lieu_naissance :data?.lieu_naissance,
      cin: data?.cin,
      passport : data?.passport
    }
    if(data?.validite_passeport!==""){
      Object.assign(info,{
        validite_passeport : data?.validite_passeport
      })
    }
    addAssuree(info).then((result) => {
      if(result?.data?.code===200){
        success_message(t("Assuré(e) ajouté avec succès !"))
        history.push({pathname:"/assuree"})
        //window.location.reload()
      }else{
        error_message(t("Erreur Temporaire !"))
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  };

  return (
    <div className="container">
      <h4 className="mb-3  mt-3 text-center"> {t("Ajouter un(e) assuré(e)")}</h4>
      <div className="row">
        <div className="col-12">
          <div >
            <div className="card-body">
              <form onSubmit={handleSubmit(addAssurees)}>
                <FormAssuree register={register}  t={t}/>
                {loading ? (
                  <button
                    className="btn btn-purple w-100 d-flex align-items-center justify-content-center fz-12"
                    disabled
                    type="submit"
                  >
                    <DotLoader />{" "}
                  </button>
                ) : (
                  <button
                    className="btn btn-purple w-100 d-flex align-items-center justify-content-center fz-12"
                    type="submit"
                  >
                    {t("Ajouter Assuré(e)")}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
