import { Path, Svg } from '@react-pdf/renderer';

const PlacardQRCode = ({ dPath }) => {
	return (
		<Svg >
			<Path d={dPath} fill={'black'} />
		</Svg>
	);
};

export default PlacardQRCode;