import React from 'react'
import { useHistory } from 'react-router-dom';

export default function BackToPreviousPage({ url }) {

  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();

  }

  return (
    <button onClick={()=>goToPreviousPath()} className="btn ">
      <i className="bi bi-arrow-left text-purple" />
    </button>
  )
}
