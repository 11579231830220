import moment from "moment";
import { useState } from "react";
import { t } from "i18next";

import {
  error_message,
  success_message,
} from "../../../../components/alerte/AlerteMessage";
import DotLoader from "../../../../components/loading/DotLoader";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import MoyenPaiement from "./MoyenPaiement";

export default function MethodePaiementCotisation({ id, handleSubmit, payerContrat, type, data, array_pay, register, watch, paiement, setPaiement, cumul_des_points,enableCountDown,mobile,setmobile,results,unpaidFactures
}) {

  console.log("ddddddd");
  console.log("ddddddd");
  console.log("ddddddd");
  console.log("ddddddd", id, 
  "handleSubmit ===>",handleSubmit, 
  "payerContrat ====>",payerContrat, 
  "type====>",type, 
  "data====>",data,
   "array_pay======>",array_pay, 
   "register====>",register, 
  "watch=====>",watch, 
   "paiement====>",paiement, 
   "setPaiement===>",setPaiement,
    "cumul_des_points=====>",cumul_des_points,
    "enableCountDown=====>",enableCountDown,
   "mobile=====>", mobile,
    "setmobile======>",setmobile,
    "results===>",results,
    "unpaidFactures=====>",unpaidFactures);
  // CODE DE PROMOTIONS
  const [code, setCode] = useState("");
  const [message_parrain, setmessage_parrain] = useState(""); // MESSAGE PARRAINAGE
  const [saveCUMUL, setsaveCUMUL] = useState(cumul_des_points);
  const [points_cumuls, setpoints_cumuls] = useState(0);
  var [increment, setincrement] = useState(1);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);

  return (
    <div>
    
      <div className="shop-pagination mb-3 mt-3">
        <div className="d-flex align-items-center justify-content-between">
          <small className="ms-1 mb-3"> {t("Moyen de paiement")} </small>
        </div>
      </div>

      <form onSubmit={handleSubmit(payerContrat)}>
        <div className="cc-selector">
          <div className="row mb-3">
            {array_pay?.map((items, keys) => (
              <div className="col-4" key={keys}>
                <input
                  id={items.codeService}
                  type="radio"
                  name="credit-card"
                  value={items.codeService}
                  {...register("type_paiement")}
                />
                <label
                  style={{ backgroundImage: "url(" + items.icon + ")" }}
                  className="drinkcard-cc visa"
                  htmlFor={items.codeService}
                />
              </div>
            ))}
          </div>
        </div>
    {  unpaidFactures.map((item)=>(

     <div key={item.id}>
      {console.log("unpaid facture items :",item)}
          <label className="form-label me-2 fz-12" htmlFor="portfolio">
            <b>{t("Montant")} (TTC) </b>
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="bi bi-wallet2 text-primary" />
            </span>
            <input
              className="form-control"
              type="text"
              disabled
              placeholder={t("Montant") + "..."}
            //   value={Math.round(paiement)}
            value={item?.montant}
            />
            {/* <span className="input-group-text">FCFA</span> */}
          </div>
        </div>)) }
        <MoyenPaiement
          watch={watch}
          register={register}
          data={data}
          t={t}
          paiement={paiement}
          mobile={mobile}
          setmobile={setmobile}
        />

        <div className="mt-3 mb-3">
         {enableCountDown ? <button
            className="btn btn-purple float-end  fz-12 mb-3 text-white "
            disabled
          >
           <DotLoader />
          </button> : <button
            className="btn btn-purple float-end  fz-12 mb-3 text-white "
            type="submit"
          >
            <i className="bi bi-check2-circle  me-1 fz-12" />
            {t("Confirmer")}
          </button>}
        </div>
        {enableCountDown && <div className="col-12">
          <p className="fz-12 mt-3">
            {t("Attention ! Vous avez 5 minutes  pour valider votre paiement en ligne. Veuillez vérifier votre téléphone pour confirmer le paiement avant la fin du délai imparti. Si le code n'est pas saisi dans les 5 minutes, la transaction sera annulée.")}
          </p>
       </div>}
      </form>
    </div>
  );
}
