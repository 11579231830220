import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToken } from "../../Auth/useToken";
import { error_message, success_message } from "../../components/alerte/AlerteMessage";
import { create_session } from "../../services/sessions/sessionAPI";
import { login_email_code_PIN, login_mobile_code_PIN } from "../../services/signin/LoginAPI";
import { getBrowser, getIpInformation, getUA } from "../../utils/DeviceUtils";

export default function SignIn() {
  // # History CALL
  const history = useHistory();

  // # DATA CONTENT
  const data = JSON.parse(localStorage.getItem("save_user"));
  const [showMobile, setshowMobile] = useState(false);
  const [showEmail, setshowEmail] = useState(false);
  const [token, setToken] = useToken();
  //------------- PASSWORD TYPE ------------
  const [password, setpassword] = useState("password");
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);
  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (data?.mobile !== null) {
      setshowMobile(true);
    } else {
      setshowEmail(true);
    }
  }, []);
  // UPDATE TYPE OF INPUT PASSWORD
  const updateType = () => {
    if (password === "password") {
      setpassword("text");
    } else {
      setpassword("password");
    }
  };
  const handleRegistration = (dataRes) => {
    setLoading(true);
    if (showMobile) {
      login_mobile_code_PIN({ mobile: data?.mobile, code_PIN: dataRes?.password })
        .then((result) => {
          if (result?.data?.code === 200) {
            setLoading(true);
            var id = result?.data?.data?.user?.id
            success_message(t("Bienvenue "))
            // Redirect To home page
            setToken(result?.data?.data?.token);
            var os = getUA()
            var browser = getBrowser()
            getIpInformation().then(res => {
              var values = res?.data
              var info = {}
              Object.assign(info, {
                user_id: id, ip: values?.ip, browser: browser, continent: values?.continent_name, country: values?.country_name, latitude: values?.latitude, longitude: values?.longitude, os: os, language: values?.languages[0]?.name, currency: values?.currency?.name
              })
              create_session(info)
                .then((result) => {
                  //console.log(result);
                }).catch((err) => {
                  console.log(err)
                });
            })
            history.push({
              pathname: "/",
            });

          } else {
            error_message("CODE PIN INVALIDE")
          }
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
    } else {
      login_email_code_PIN({ email: data?.email, code_PIN: dataRes?.password })
        .then((result) => {
          if (result?.data?.code === 200) {
            setLoading(true);
            var id = result?.data?.data?.user?.id
            success_message(t("Bienvenue "))
            // Redirect To home page
            setToken(result?.data?.data?.token);
            var os = getUA()
            var browser = getBrowser()
            getIpInformation().then(res => {
              var values = res?.data
              var info = {}
              Object.assign(info, {
                user_id: id, ip: values?.ip, browser: browser, continent: values?.continent_name, country: values?.country_name, latitude: values?.latitude, longitude: values?.longitude, os: os, language: values?.languages[0]?.name, currency: values?.currency?.name
              })
              create_session(info)
                .then((result) => {
                  //console.log(result);
                }).catch((err) => {
                  console.log(err)
                });
            })
            history.push({
              pathname: "/",
            });

          } else {
            error_message("CODE PIN INVALIDE")
          }
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
    }


  }
  return (
    <div className="login-wrapper d-flex align-items-center justify-content-center">
      <div className="custom-container">
        <div className="text-center px-4">
          <img
            className="login-intro-img"
            src="img/bg-img/51.png"
            alt={"SIGN IN"}
          />
        </div>
        {/* Register Form */}
        <div className="register-form mt-4">
          <h6 className="mb-3 text-center">{t("Connectez vous avec code PIN.")}</h6>
          <form onSubmit={handleSubmit(handleRegistration)}>
            <div className="form-group text-center">
              {showMobile && (
                <label className="text-purple"> {data?.mobile} </label>
              )}
              {showEmail && (
                <label className="text-purple"> {data?.email} </label>
              )}
            </div>
            <div className="form-group position-relative">
              <input
                className="form-control fz-12"
                id="psw-input"
                type={password}
                {...register("password")}
                placeholder="Mot de passe"
                required
              />
              <div
                className="position-absolute"
                id="password-visibility"
                onClick={() => updateType()}
              >
                {password === "password" ? (
                  <i className="bi bi-eye" />
                ) : (
                  <i className="bi bi-eye-slash-fill" />
                )}
              </div>
            </div>
            <button className="btn btn-success w-100" type="submit">
              {t("Se connecter")}
            </button>
          </form>
        </div>
        {/* Login Meta */}
        <div className="login-meta-data text-center">
          <a
            className="stretched-link forgot-password  text-purple d-block mt-3 mb-1"
            href="/login"
          >
            {t("Vous avez oublié votre code PIN ?")}
          </a>
          <p className="mb-0">
            {t("Vous voulez créer un nouveau compte ?")}
            <a className="stretched-link text-purple" href="/choose_register">
              {t("Inscrivez-vous")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
