import ResumAccident from "../accidents/ResumAccident";
import ResumDeces from "../deces/ResumDeces";
import ResumEducation from "../education/ResumEducation";
import ResumRetraite from "../retraite/ResumRetraite";
import ResumAuto from "../vehicule/ResumAuto";
import CalculVoyage from "../voyage/CalculVoyage";

export default function CalculPrime({type,t,data,LISTE_ASSUREE,setLISTE_ASSUREE,groupedAssuree,category_id,cout_police, prime_nette, paramTech}) {
  return (
    <div className="shop-pagination mt-0">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14 mt-3"> {t("Calcul Prime")} </small>
        </div>
        
        {type === "vehicule" && (
          <ResumAuto
            t={t}
            data={data}
            LISTE_ASSUREE={LISTE_ASSUREE}
            setLISTE_ASSUREE={setLISTE_ASSUREE}
            groupedAssuree={groupedAssuree}
            category_id={category_id}
          />
        )}
        {type === "education" && (
          <ResumEducation
            category_id={category_id}
            t={t}
            setLISTE_ASSUREE={setLISTE_ASSUREE}
            data={data}
            liste={LISTE_ASSUREE}
            groupedAssuree={groupedAssuree}
          />
        )}
        {type === "accident" && (
          <ResumAccident
            category_id={category_id}
            t={t}
            setLISTE_ASSUREE={setLISTE_ASSUREE}
            data={data}
            liste={LISTE_ASSUREE}
            groupedAssuree={groupedAssuree}
          />
        )}
        {type === "voyage" && (
          <CalculVoyage
            category_id={category_id}
            liste={LISTE_ASSUREE}
            dataRes={data}
            t={t}
            setLISTE_ASSUREE={setLISTE_ASSUREE}
            groupedAssuree={groupedAssuree}
            cout_police={cout_police}
            prime_nette={prime_nette}
            paramTech={paramTech}
          />
        )}
         {type === "retraite" && (
          <ResumRetraite
          category_id={category_id}
          LISTE_ASSUREE={LISTE_ASSUREE}
            data={data}
            t={t}
            setLISTE_ASSUREE={setLISTE_ASSUREE}
            groupedAssuree={groupedAssuree}
          />
        )}
         {type === "deces" && (
          <ResumDeces
          category_id={category_id}
          LISTE_ASSUREE={LISTE_ASSUREE}
            data={data}
            t={t}
            setLISTE_ASSUREE={setLISTE_ASSUREE}
            groupedAssuree={groupedAssuree}
          />
        )}
      </div>
    </div>
  );
}
