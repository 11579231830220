import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getCategorie,
  getCategoriesByTypeOfFacturation,
  getdetailsCategorie,
} from "../../services/categories/CategoriesApi";
import Cookies from "js-cookie";
import { getContratAccident, getContratByUser } from "../../services/contrats/ContratApi";
import authHeader from "../../services/authentification/AuthHeader";
import { getUrl } from "../../services/authentification/DotenvConfig";

import { useParams } from "react-router-dom";
import {
  error_message,
  warning_message,
} from "../../components/alerte/AlerteMessage";
import { useUser } from "../../Auth/useUser";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getUser } from "../../services/users/usersAPI";
import { getListeAssuree } from "../../services/assuree/assureeAPI";
import _ from "lodash";

import { getPersonnelByID } from "../../services/personnel/personnelAPI";
import { getAdressesOfUser } from "../../services/adresses/AdresseAPI";
import { getCompagnyCategory } from "../../services/assignCategorieCompagny/assignCCApi";
import { getParamTechByCategorie } from "../../services/paramtech/paramTechAPI";
import {
  getCoutPolice,
  getFormuleAccident,
  getPrimeNette,
} from "../../services/formules/FormulesAPI";

import Paiement from "../contrats/components/paiement/Paiement";

import { getFormuleAuto } from "../../services/formules/FormulesAPI";
// # UTILS : FUNCTION NUMBER WITH SPACES
import { numberWithSpaces } from "../../utils/SessionUtiles";
import Loader from "../../components/loading/Loader";
import axios from "axios";
import Icon from "../../components/obligatoire/Icon";
import CurrencyInput from "react-currency-input-field";
// import AssuranceAccident1 from "./AssuranceAccident1";
import AssuranceAutomobile from "./AssuranceAutomobile";
import Step5 from "../contrats/steps/Step5";
import { getCompanies, getSignatureCompagny } from "../../services/compagny/CompagnyAPI";
import PaiementRenouvellement from "../contrats/components/paiement/PaiementRenouvellement";
export default function AssuranceAccident(props) {
  
  const history = useHistory();
  // Parametre id = id categorie
  // const { id, title, type } = useParams("");
  //------- INIT LANGAGE
  const { id, title, policy_code, category_id } = useParams("");
  const type = "accident";
  const language = Cookies.get("locale");
  // LISTE DES CATEGORY DONT LE TYPE DE PAIEMENT EST LA COTISATION
  const [LISTE_FACTURE, setLISTE_FACTURE] = useState([]);
  const [accidents, setAccidents] = useState([]);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  const [valid, setvalid] = useState(true);
  const [empty, setEmpty] = useState(false); // Verif if initial get of data object (n_cin exist or not ), if data exist we update the date else we add a new object

  const [LISTE_ASSUREE, setLISTE_ASSUREE] = useState([]); // Liste assurée
  // LISTE DES COMPAGNIES D'assurances
  const [Liste_Compagny, setListe_Compagny] = useState([]);

  //------------- ADRESSES of User --------
  const [ListeAdresses, setListeAdresses] = useState([]);
  const [groupedAdresses, setgroupedAdresses] = useState([]);
  // ALL ASSUREE OF CARNET ASSUREE OF USER
  const [ListeAssuree, setListeAssuree] = useState([]); // LISTE DES ASSURES
  const [groupedAssuree, setgroupedAssuree] = useState([]);
  // LISTE OF UNIQ POLICY
  const [policyListe, setpolicyListe] = useState([]);
  const [groupedListe, setgroupedListe] = useState([]);
  //------------------ SHOWS FORMS -----------
  const [show, setshow] = useState(false);
  const [showe, setshowe] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [currentCollapse, setCurrentCollapse] = useState(null);
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);
  // const [showFormAccident, setShowFormAccident] = useState(false);
  // ------------ LOADER ------------------
  const [Loading, setLoading] = useState(true);
  const [data, setdata] = useState({});
  const [dataa, setData] = useState({});
  const user = useUser();
  var id_user = user?.result?.id; //id of connected user
  const [liste_assuree, setliste_assuree] = useState([]);

  const url = getUrl();
  const [LISTE, setLISTE] = useState([]);
  // ---------------FORMULE voyages ---------
  const [cout_police, setcout_police] = useState([]);
  const [prime_nette, setprime_nette] = useState([]);
  const [paramTech, setparamTech] = useState([]);
  const [SaveLISTE, setSaveLISTE] = useState([]); // SAUVEGARDE DE LA LISTE INIT POUR LA RECUPERATION EN CAS DE FILTRE
  const [loading, setloading] = useState(true);
  const [categorie, setcategorie] = useState({});
  const [accident, setAccident] = useState("");
  const [adress_id, setAdress_id] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [selectedDate, setSelectDate] = useState("");

  const [FormuleAccident, setFormuleAccident] = useState([]);
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  const [dateEffect, setDateEffect] = useState('');
  const [duree, setDuree] = useState('');
  const [formule, setFormule] = useState('');
  const {contract} = props.location
  console.log('selected contract: ', contract);
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA

  const currentDate = new Date().toISOString().split("T")[0];
  useEffect(() => {
    // bech tjib les contrats by id user ==> var lfou9 tal9aha
    // bech testaaml fn _.groupBy(result?.data?.data, "id")
  }, []);

  useEffect(() => {
    getListeAssuree(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setliste_assuree(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCategoriesByTypeOfFacturation("renouvellement")
      .then((result) => {
        if (result?.data?.code === 200) {
          console.log(result?.data);
          setLISTE_FACTURE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Results
  const [results, setResults] = useState({
    date_effet:"",
    formule:"",
    duree:"",
    totalHt: 0, // sum total hors taxes
    taxes: 0, // taxes
    accessoires: 0, // taxes
    primettc: 0, //PRIME EN TTC
    prime_nette:""
  });

  useEffect(() => {
    getCategorie()
      .then((result) => {
        if (result?.data?.code === 200) {
          setLISTE(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (type === "voyage") {
      // Recuperer la formule de la prime nette
      getPrimeNette()
        .then((result) => {
          if (result?.data?.code === 200) {
            // RECUPERER LA FORMULE DU COUT DE POLICE
            getCoutPolice().then((res) => {
              if (result?.data?.code === 200) {
                setprime_nette(result?.data?.data);
                setcout_police(res?.data?.data);
                // Recuperer les PARAM TECHNIQUES
                getParamTechByCategorie(category_id)
                  .then((result) => {
                    if (result?.data?.code === 200) {
                      setparamTech(result?.data?.data);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getCompagnyCategory(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var C = [];
          result?.data?.data?.map(
            (items) => C.push(items.company)
            
            );
            console.log("conpany facture")
            console.log("conpany facture")
            console.log("conpany facture")
            console.log("conpany facture",result?.data?.data)
         
          setListe_Compagny(_.uniqBy(C, "id"));
      

        }
      })
      .catch((err) => {
        console.log(err);
      });
    getAdressesOfUser(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListeAdresses(result?.data?.data);
          setgroupedAdresses(_.groupBy(result?.data?.data, "id"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getdetailsCategorie(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setcategorie(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getListeAssuree(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListeAssuree(result?.data?.data);
          setgroupedAssuree(_.groupBy(result?.data?.data, "id"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getUser(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          var data = result?.data?.data;
          setdata(data);
          var { nom, prenom, email, mobile, date_naissance } = data;
          if (
            nom === null ||
            prenom === null ||
            date_naissance === null ||
            mobile === null ||
            email === null
          ) {
            setvalid(false);
          } else {
            setvalid(true);
            getPersonnelByID(id_user)
              .then((result) => {
                if (result?.data?.code === 200) {
                  if (result?.data?.data?.length === 0) {
                    setEmpty(true);
                  } else {
                    setEmpty(false);
                    var info = result?.data?.data[0];
                    Object.assign(data, {
                      n_cin: info?.n_cin,
                      n_passport: info?.n_passport,
                    });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      });
  }, []);
  // UPDATE DATA INTO FORM
  useEffect(() => {
    reset(data);
  }, [data]);



  // useEffect(() => {
  //   getUser(id)
  //     .then((result) => {
  //       console.log("result of user", result);
  //       if (result?.data?.code === 200) {
  //         var obj = result?.data?.data;
  //         if (obj?.gestion_contrat_code_pin === 1) {
  //           setshowCodePIN(true);
  //         }
          
  //         getContratByUser(id)
  //           .then((result) => {
  //             if (result?.data?.code === 200) {
  //               console.log(
  //                 "liste des contrats by this connected user:",
  //                 result
  //               );

  //               setData(result?.data?.data);
                
  //               getFormuleAccident(id).then((result) => {
  //                 if (result?.data?.code === 200) {
  //                   setFormuleAccident(result?.data?.data);
  //                   console.log("FormuleAccident");
  //                   console.log("FormuleAccident");
  //                   console.log("FormuleAccident");
  //                   console.log("FormuleAccident");
  //                   console.log("FormuleAccident");
  //                   console.log("FormuleAccident");
  //                   console.log("FormuleAccident", result?.data?.data);
  //                 }
                
  //               });
  //               var L = result?.data?.data?.filter(
  //                 (el) => el.category_id === 8 && el.company_id===1
                  
  //               );
  //               console.log("Contrats accident:", L);            
  //               console.log(data);

  //               for (let i = 0; i < L.length; i++) {
  //                 getCompanies(L[i].id).then((result)=>{
  //                   if (result?.data?.code === 200) {
  //                     // setAutoDetailsArray(result?.data?.data)
  //                   console.log("result",result?.data?.data)
  //                  var M = result?.data?.data.filter(
  //                   (el) => el.nom=== "SENVIE ASSURANCE VIE " 
  //                  )
  //                  console.log("errrrrrrrrrrrr",M)
  //                   //setAutoDetailsArray(N);
  //                   console.log("varrrrrrrrr")
  //                   console.log("varrrrrrrrr")
  //                   console.log("varrrrrrrrr")
  //                   console.log("varrrrrrrrr",L)
  //                   setListe_Compagny(M);
  //                   console.log("******")
  //                   console.log("******")
  //                   console.log("******")
  //                   console.log("******",M)
  //                   }
                    
  //                   setAccidents(L);
                    
  //                 // console.log(autoDetailsArray);
  //                 }).catch((err)=>{
  //                   console.log(err)
  //                 })                  
  //               }
               
              
  //             }
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
            
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  useEffect(() => {
    getUser(id)
      .then((result) => {
        console.log("result of user", result);
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
          getContratByUser(id)
            .then((result) => {
              if (result?.data?.code === 200) {
                console.log(
                  "liste des contrats by this connected user:",
                  result
                );

                setData(result?.data?.data);
                getFormuleAccident(id).then((result) => {
                  if (result?.data?.code === 200) {
                    setFormuleAccident(result?.data?.data);
                    console.log("FormuleAccident");
                    console.log("FormuleAccident");
                    console.log("FormuleAccident");
                    console.log("FormuleAccident");
                    console.log("FormuleAccident");
                    console.log("FormuleAccident");
                    console.log("FormuleAccident", result?.data?.data);
                  }
                
                });
                var L = result?.data?.data?.filter(
                  (el) => el.category_id === 8 && el.company_id
                  
                );
                console.log("Contrats accident:", L);            
                console.log(data);

                for (let i = 0; i < L.length; i++) {
                  getCompanies(L[i].id).then((result)=>{
                    if (result?.data?.code === 200) {
                      // setAutoDetailsArray(result?.data?.data)
                    console.log("result",result?.data?.data)
                    //setAutoDetailsArray(N);
                    console.log("varrrrrrrrr")
                    console.log("varrrrrrrrr")
                    console.log("varrrrrrrrr")
                    console.log("varrrrrrrrr",L)
                   
                    }
                    setAccidents(L);
                    setListe_Compagny(L);
                    console.log("******")
                    console.log("******")
                    console.log("******")
                    console.log("******",L)
                  // console.log(autoDetailsArray);
                  }).catch((err)=>{
                    console.log(err)
                  })                  
                }
               
              
              }
            })
            .catch((err) => {
              console.log(err);
            });
            
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function callbackAdress(adressId) {
    let newData = data;
    newData.adresse_id = adressId;
    setdata(newData);
  }
  const onChangeAdress = (e) => {
    setAdress_id(e.target.value);
    callbackAdress(e.target.value);
  };
  const onFormSubmit = (dataRes) => {
    console.log("88888888888");
    console.log("88888888888");
    console.log("88888888888");
    console.log("88888888888");
    console.log("88888888888");
    console.log("88888888888", dataRes);
 
    // setloaderResult(true);
    var { duree, formule,date_effet} = dataRes;
    getFormuleAccident().then((result) => {
      if (result?.data?.code === 200) {
        var groupedFormule = _.groupBy(result?.data?.data, "FORMULE");

        var table = groupedFormule[formule]?.filter(
          (el) => el.DUREE === parseInt(duree) 
        );
        console.log("table est :",table)
        var PRIME_NETTE = table[0]?.PRIME_NETTE;
        var accessoires = table[0]?.ACCESSOIRES;
        setResults({
          date_effet, 
          duree,
          formule,
          totalHt: parseFloat(PRIME_NETTE), // sum total hors taxes
          taxes: (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, // taxes
          accessoires: accessoires,
          primettc:
            parseFloat(PRIME_NETTE) +
            parseFloat(accessoires) +
            (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14, //PRIME EN TTC
        });
        console.log("results")
        console.log("results")
        console.log("results")
        console.log("results",data)
        var newData = data;
        newData.primettc =
          parseFloat(PRIME_NETTE) +
          parseFloat(accessoires) +
          (parseFloat(PRIME_NETTE) + parseFloat(accessoires)) * 0.14; //PRIME EN TTC

        console.log("neeeew daaaata", newData);
        setdata(newData);
        setshow(true);
      }
    });
  };

  const handleButtonClick = () => {
    setShowResult(true);
  };
  /**
   * Change informations of assurees
   * @param {*} e
   * @param {*} index
   */
  const handleChangeAssuree = (e, index) => {
    const newArr = LISTE_ASSUREE.map((item, i) => {
      if (index === i) {
        if(e.target.name==="prime_commercial"){
          const prime = e.target.value.replace(/\s+/g, '')
          return { ...item, [e.target.name]: prime };
        }
        else{
          return { ...item, [e.target.name]: e.target.value };
        }
      } else {
        return item;
      }
    });
    setLISTE_ASSUREE(newArr);
  };
  return (
    <div className="container">
      <div className="shop-pagination pb-3">
        {show5 ? (
          <>
            <PaiementRenouvellement
              id={8}
              handleSubmit={handleSubmit}
              register={register}
              data={data}
              watch={watch}
              groupedAdresses={groupedAdresses}
              LISTE_ASSUREE={LISTE_ASSUREE}
              type={type}
              t={t}
              results={results}
              accidents={accidents}
              contract = {contract}
            />
          </>
        ) : (
          <>
            <div className="col-6" style={{ margin: "auto", width: "100%" }}>
              <div className="mb-0">
                <div className="shop-pagination">
                
                  <div className="d-flex align-items-center justify-content-between">
                  
                    <small className="mb-3 fz-14 mt-3">
                      {" "}
                      {t("Souscripteur")}{" "}
                    </small>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2 fz-12">
                      {t("Nom & Prénom souscripteur")}
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="bi bi-person-fill text-purple" />
                      </span>
                      <input
                        className="form-control fz-12"
                        type="text"
                        disabled
                        value={data?.nom + " " + data?.prenom}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">{t("téléphone")}</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-telephone-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="text"
                          disabled
                          value={data?.mobile}
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">{t("E-Mail")}</label>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <i className="bi bi-envelope-fill text-purple" />
                      </span>
                      <input
                        className="form-control fz-12"
                        type="text"
                        disabled
                        value={data?.email}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">
                        {t("Date de naissance")}
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-balloon-fill text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          type="date"
                          disabled
                          value={moment(data?.date_naissance).format(
                            "YYYY-MM-DD"
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="mb-2 fz-12">
                      {t("Adresse Souscripteur")} <Icon />
                    </label>
                    <select
                      className="form-select fz-12"
                      required
                      onChange={(e) => onChangeAdress(e)}
                    >
                      <option value="">{t("Choisir")} ...</option>
                      {ListeAdresses?.map((items, keys) => (
                        
                        <option value={items.id} key={keys}>
                          {items.pays +
                            "," +
                            items.region +
                            " ," +
                            items.ligne +
                            "," +
                            items.cite +
                            " " +
                            items.code_postal}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <small className="mb-3 fz-14"> {t("Assuré(s)")} </small>
                  </div>

                  <h6 className="text-purple fz-12">
                    <i className="bi bi-info-circle me-2"></i>
                    {t("L'assuré est le souscripteur")}
                  </h6>

                  <div className="d-flex align-items-center justify-content-between">
                    <small className="mb-3 fz-14">
                      {t("Informations Contrat")}
                    </small>
                  </div>

                  <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="form-group mb-3">
                      <label className="mb-2 fz-12">{t("Date Effet")}</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">
                          <i className="bi bi-calendar-event text-purple" />
                        </span>
                        <input
                          className="form-control fz-12"
                          {...register("date_effet")}
                          type="date"
                          onChange={(e) => setDateEffect(e.target.value)}
                          placeholder="DD/MM/YYYY"
                          min={currentDate}
                          required
                          
                        />
                        
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="form-label text-purple fz-12">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-info-circle me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        {t(
                          "Produit disponible que pour les assurés âgés de moins de 60 ans"
                        )}
                      </label>
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label fz-12">{t("Durée")}</label>
                      <select
                        className="form-select fz-12"
                        {...register("duree")}
                        onChange={(e) => setDuree(e.target.duree)}
                        required
                      >
                        <option value={""}>{t("Choisir")} </option>
                        <option value={1}>{t("1 Mois")}</option>
                        <option value={3}>{t("3 Mois")}</option>
                        <option value={6}>{t("6 Mois")}</option>
                        <option value={12}>{t("12 Mois")}</option>
                      </select>
                    </div>
                    <label className="form-label fz-12">{t("Formule")}</label>
                    <div>
                      <div
                        className={
                          watch("formule") === "CLASSIQUE"
                            ? "single-plan-check shadow-sm active-effect active"
                            : "single-plan-check shadow-sm active-effect"
                        }
                      >
                        <div className="form-check  mb-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="formule"
                            value={"CLASSIQUE"}
                            onChange={(e) => setFormule(e.target.duree)}           
                            {...register("formule", { required: true })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Individual"
                          >
                            {t("FORMULE CLASSIQUE")} :
                            <li className="fz-10 mt-2 mb-1">
                              {t("Frais Pharmaceutiques")} :{" "}
                              <b> 100 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Hospitalisation")} : <b> 150 000 FCFA </b>
                            </li>
                            <li className="fz-10 mb-1">
                              {t("Frais funéraires")} : <b> 100 000 FCFA </b>
                            </li>
                            <li className="fz-10 mb-1">
                              {t("Capital décès et invalidité")} :{" "}
                              <b> 1 000 000FCFA </b>
                            </li>
                          </label>
                        </div>
                      </div>
                      <div
                        className={
                          watch("formule") === "DIAMOND"
                            ? "single-plan-check shadow-sm active-effect active"
                            : "single-plan-check shadow-sm active-effect"
                        }
                      >
                        <div className="form-check mb-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="formule"
                            value={"GOLD"}
                            onChange={(e) => setFormule(e.target.duree)} 
                            {...register("formule", { required: true })}
                          />
                          <label className="form-check-label" htmlFor="Team">
                            {t("FORMULE GOLD")} :
                            <li className="fz-10 mt-2 mb-1">
                              {t("Frais Pharmaceutiques")} :{" "}
                              <b> 150 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Hospitalisation")} : <b> 200 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Frais funéraires")} : <b> 125 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Capital décès et invalidité")} :{" "}
                              <b> 1 500 000 FCFA </b>
                            </li>
                          </label>
                        </div>
                      </div>
                      <div
                        className={
                          watch("formule") === "GOLD"
                            ? "single-plan-check shadow-sm active-effect active"
                            : "single-plan-check shadow-sm active-effect"
                        }
                      >
                        <div className="form-check mb-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="formule"
                            value={"DIAMOND"}
                            onChange={(e) => setFormule(e.target.duree)} 
                            {...register("formule", { required: true })}
                          />
                          <label className="form-check-label" htmlFor="Company">
                            {t("FORMULE DIAMOND")} :
                            <li className="fz-10 mt-2 mb-1">
                              {t("Frais Pharmaceutiques")} :{" "}
                              <b> 175 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Hospitalisation")} : <b> 250 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Frais funéraires")} : <b> 150 000 FCFA </b>
                            </li>
                            <li className="fz-10  mb-1">
                              {t("Capital décès et invalidité")} :{" "}
                              <b> 2 000 000 FCFA </b>
                            </li>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="  mt-3">
                      <button
                        type="submit"
                        className="btn btn-purple col-12 mb-2"
                        onClick={handleButtonClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-calculator me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                          <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
                        </svg>
                        {t("Calculer")}
                      </button>
                    </div>
                    {show && (
                      <div className="card mb-4 ">
                        <div className="card-body">
                          <h5 className="text-center">
                            {" "}
                            {t("Total du calcul")}{" "}
                          </h5>
                          <table className="table  mb-0 table-striped-primary mt-3">
                            {loaderResult ? (
                              <Loader />
                            ) : (
                              <tbody>
                                <tr>
                                  <td>{t("Total Prime Nette")}</td>
                                  <td className="text-center">
                                    {numberWithSpaces(
                                      Math.round(results?.totalHt)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("Accessoires")}</td>
                                  <td className="text-center">
                                    {numberWithSpaces(
                                      Math.round(results?.accessoires)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("Taxe")}</td>
                                  <td className="text-center">
                                    {numberWithSpaces(
                                      Math.round(results?.taxes)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("Prime TTC")}</td>
                                  <td className="text-center">
                                    {numberWithSpaces(
                                      Math.round(results?.primettc)
                                    )}
                                  </td>
                                </tr>
                                <button
                                  onClick={() => setShow5(true)}
                                  className="btn btn-success mt-1 float-end"
                                >
                                  Suivant
                                </button>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
