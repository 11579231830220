import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";
// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";
import Prefix from "../../components/select/Prefix";
import ConditionGenerale from "./ConditionGenerale";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite";
import {
  register_mobile,
  verify_parrain,
} from "../../services/signup/RegisterAPI";
import DotLoader from "../../components/loading/DotLoader";
import {
  error_message,
  success_message,
  warning_message,
} from "../../components/alerte/AlerteMessage";
import { updateParrain } from "../../services/users/usersAPI";
import { addParrainage } from "../../services/parrainage/parrainageAPI";
import Parrainage from "./Parrainage";
import Icon from "../../components/obligatoire/Icon";
import CodePIN from "./CodePIN";

export default function SignupMobile() {
  //------------- CONDITIONS POLICY --------
  const [codeParrain, setCodeParrain] = useState(false); //Vous avez un code parrain ?

  const [condition1, setcondition1] = useState(false);
  const [condition2, setcondition2] = useState(false);
  // # History CALL
  const history = useHistory();
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);
  // # SUBMIT FORM ELEMENTS
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const handleError = (errors) => {};

  /**
   * REGISTER OPTIONS
   */
  const registerOptions = {
    nom: {
      required: t("Name is required"),
    },
    prenom: {
      required: t("Name is required"),
    },
    mobile: {
      required: {
        value: true,
        message: "Please add your mobile phone number!",
      },
      pattern: {
        value: /^[0-9+-]+$/,
        message: "This is not a valid mobile phone, try again!",
      },
    },
  };
  const traitement = (data) => {
    var { nom, prenom, mobile, prefix, code_parrain, identifiant_parrain ,Code_PIN_1} =
      data;
    register_mobile({
      nom: nom,
      prenom: prenom,
      mobile: prefix + mobile,
      role: "user",
      Code_PIN : Code_PIN_1
    })
      .then((result) => {
        var data = result?.data;
        if (data?.code === 500) {
          warning_message(
            t("Vous ne pouvez pas vous inscrire avec ce numéro de téléphone")
          );
        } else if (data?.code === 503) {
          error_message(
            t(
              "Erreur temporaire de l'application ! Merci pour votre compréhension "
            )
          );
        } else if (data?.code === 200) {
          if (code_parrain !== undefined && identifiant_parrain !== undefined) {
            verify_parrain({
              code_parrain: code_parrain,
              identifiant_parrain: identifiant_parrain,
            })
              .then((result) => {
                if (result?.data?.code === 200) {
                  var data_res = result?.data?.data;
                  if (data_res?.length !== 0) {
                    updateParrain({
                      id: data_res[0]?.id,
                      cumul_points: data_res[0]?.cumul_points + 1,
                    })
                      .then((result) => {
                        if (result?.data?.code === 200) {
                          success_message(
                            "Votre parrain vous remercie d'avoir saisie son code parrain"
                          );
                          addParrainage({
                            user_id: data_res[0]?.id,
                            mobile_parraine: prefix + mobile,
                          });
                        } else {
                          error_message(
                            "Erreur, nous n'avons pas pu traiter votre demande de parrainage"
                          );
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } else {
                    warning_message(
                      t("Vous devez vérifier le code parrain ou l'identifiant!")
                    );
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          success_message(
            "Inscription avec succès ! Veuillez vérifier votre boite E-Mail"
          );
          history.push({
            pathname: "/SMS_Validation/" + prefix + mobile,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * Handle Registration  : OnSubmit Form function
   * @param {*} data
   */

  const handleRegistration = (data) => {
    //console.log(data);
    setLoading(true);
    if(data?.code_PIN_1 !== data?.code_PIN_2){
      warning_message("Les codes PIN doivent être identiques");
    }
    else if (condition1 && condition2) {
      traitement(data);
    } else {
      warning_message(
        t(
          "Vous devez accepter les conditions d'utilisation et la polique de confidentialité"
        )
      );
    }
    setLoading(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="custom-container">
        {/* Register Form */}
        <div >
          <h3 className="mb-3 text-center mt-2">{t("S'inscrire")} </h3>
          <form onSubmit={handleSubmit(handleRegistration, handleError)}>
            {/** ----------------------------------- INFORMATIONS GENERALES ----------------------------------- */}
            <h5 className="mt-4 mb-4 fz-16 text-center">
            {t("Coordonnées générales")}
            </h5>
            <div className="row">
             
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label  fz-12">
                    {t("Prénom")} <Icon />
                  </label>
                  <input
                    className="form-control  fz-12"
                    type="text"
                    placeholder="Prénom"
                    {...register("prenom", registerOptions.prenom)}
                    required
                  />
                  <small className="text-danger">
                    {errors?.prenom && errors.prenom.message}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label fz-12">
                    {t("Nom")} <Icon />
                  </label>
                  <input
                    className="form-control  fz-12"
                    type="text"
                    name="nom"
                    placeholder="Nom"
                    {...register("nom", registerOptions.nom)}
                    required
                  />
                  <small className="text-danger">
                    {errors?.nom && errors.nom.message}
                  </small>
                </div>
              </div>
            </div>

            <label className="form-label  fz-12"  htmlFor="exampleInputpassword">
              {t("téléphone")} <Icon />
            </label>
            <div className="input-group mb-3">
              <Prefix register={register} />
              <input
                className="form-control  fz-12"
                type="number"
                style={{width:"60%"}}
                {...register("mobile", registerOptions.mobile)}
                placeholder={t("Entrez le numéro de téléphone")}
              />
            </div>
            <small className="text-danger">
              {errors?.mobile && errors.mobile.message}
            </small>
            <CodePIN t={t} register={register} />
            {/** ----------------------------------- Parrainage  ----------------------------------- */}
            <Parrainage t={t} register={register} watch={watch} />

            <h5 className="mt-3 mb-4  fz-16 text-center">
              {" "}
              {t("Coordonnées d'identification")}
            </h5>
            <div className="form-check">
              <input
                className="form-check-input text-muted fz-12"
                type="checkbox"
                checked={condition1}
                onChange={(e) => setcondition1(e.target.checked)}
              />
              <label
                className="form-check-label text-muted fz-12"
                htmlFor="checkedCheckbox"
              >
                {t("Je déclare adhérer aux")}{" "}
                <b
                  className="fw-bold text-purple"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  {t(
                    "conditions générales d’utilisation et de vente Assur 3.0"
                  )}
                </b>
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input text-muted fz-12"
                type="checkbox"
                checked={condition2}
                onChange={(e) => setcondition2(e.target.checked)}
              />
              <label
                className="form-check-label text-muted fz-12"
                htmlFor="checkedCheckbox"
              >
                {t("Je déclare adhérer à")}{" "}
                <b
                  className="fw-bold text-purple"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  {t("la politique de protection des données Assur3.0")}
                </b>
              </label>
            </div>

            {/** ----------------------------------- Envoyer le resultat  ----------------------------------- */}
            {loading ? (
              <button className="btn btn-purple w-100" type="submit">
                <DotLoader />
              </button>
            ) : (
              <button
              className="btn btn-purple mt-3 col-12 text-center"
              type="submit"
            >
              {t("Créer un compte")}
              <i className="bi bi-arrow-right me-2" />
            </button>
            )}

            <label className="mt-3 mb-3 fz-12 text-danger">
              {
                "* Vous recevrez sur le numéro mobile fourni, par SMS, un code d’authentification, Veuillez vérifier qu’il s’agit du bon numéro pour l’activation de votre compte"
              }
            </label>
          </form>
        </div>
      </div>

      {/** MODAL -------------> CONDITIONS GENERALES D'utilisation */}
      <ConditionGenerale setcondition1={setcondition1} t={t} />
      {/** MODAL ------------->POLITIQUE DE CONFIDENTIALITE        */}
      <PolitiqueConfidentialite setcondition2={setcondition2}  t={t}/>
    </div>
  );
}
