/* eslint-disable no-redeclare */
import  { useState } from "react";
import { useForm } from "react-hook-form";
// COMPONENTS
import Loader from "../../../../components/loading/Loader";
// # UTILS
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { comparaisonBetweenTwoDates } from "../../../../utils/Comparaison";
// ----------- ALERT --------------------
import { useParams } from "react-router-dom";
import { error_message } from "../../../../components/alerte/AlerteMessage";
import { getCoutPolice, getPrimeNette } from "../../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";

export default function DevisVoyage({ t }) {
  const { id, title, type } = useParams("");
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, watch } = useForm(); //FORM DATA
  // SHOW RESULT OF PRIME total AFTER INPUT INFO
  const [show, setshow] = useState(false);
  // Results
  const [results, setResults] = useState({
    prime_nette: 0, // PRIME NETTE
    cout_police: 0, // Cout police
    taxe: 0, // taxe
    prime_ttc: 0, //PRIME TTC
  });
  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);
  function onFormSubmit(dataRes) {
    setloaderResult(true);
    var { age, date_depart, date_retour, nbr_jours, etat ,type} = dataRes;

   
    if (etat === "option2") {
      if (
        date_depart <= date_retour &&
        parseInt(age) >= 0 &&
        parseInt(age) <= 80
      ) {
        setshow(true);
        var duree = comparaisonBetweenTwoDates(date_depart, date_retour) + 1;
      } else if (date_depart > date_retour) {
        error_message(t("Veuillez saisir une date valide , la date depart doit être inférieur ou égal a la date d'arrivée "))
        setshow(false);
      } else if (parseInt(age) > 80) {
        error_message(t("L'age supérieur à 80 ans ne sera pas pris en charge avec l'assurance Voyage. "))
        setshow(false);
      }
    } else if (etat === "option1") {
      var duree = parseInt(nbr_jours);
      if (nbr_jours > 0 && parseInt(age) >= 0 && parseInt(age) <= 80) {
        setshow(true);
      }
    }
   
    //var groupedPays = _.groupBy(pays, "code");
    getPrimeNette().then((result) => {
      if(result?.data?.code === 200){
        getCoutPolice().then((res) => {
          if(res?.data?.code === 200){
            var prime_nette = result?.data?.data
            var cout_police = res?.data?.data
            var value_cout = 0;
            var value_prime = 0;
            getParamTechByCategorie(id)
            .then((result) => {
              if(result?.data?.code === 200){
                const found_duree =cout_police?.filter((element)=> (parseInt(element.duree) >= parseInt(duree)) && (element.type===dataRes?.type) )
                const found_primenette =prime_nette?.filter((element)=>(element.type===dataRes?.type) && (parseInt(element.duree) >= parseInt(duree)))
                var taxes = parseFloat(result?.data?.data[0]?.taxes)/100
             

                if (parseInt(age) >= 0 && parseInt(age) <= 17) {
                  value_cout = found_duree[0]?.age1;
                  value_prime = found_primenette[0]?.age1;
                 
                } else if (parseInt(age) >= 18 && parseInt(age) <= 65) {
                  value_cout = found_duree[0]?.age2;
                  value_prime = found_primenette[0]?.age2;
                  console.log({value_cout: found_duree[0]?.age2,
                    value_prime: found_primenette[0]?.age2});
                } else if (parseInt(age) >= 66 && parseInt(age) <= 75) {
                  value_cout = found_duree[0]?.age3;
                  value_prime = found_primenette[0]?.age3;
                } else if (parseInt(age) >= 76 && parseInt(age) <= 80) {
                  value_cout = found_duree[0]?.age4;
                  value_prime = found_primenette[0]?.age4;
                }
    
                setResults({
                  prime_nette: value_prime,
                  cout_police: value_cout,
                  taxe: (parseFloat(value_prime) + parseFloat(value_cout)) * taxes,
                  prime_ttc:
                    parseFloat(value_prime + value_cout) +
                    parseFloat((value_prime + value_cout) * taxes),
                });
              }
            }).catch((err) => {
              console.log(err)
            });
            
          }
        }).catch((err) => {
          console.log(err)
        });
      }
    }).catch((err) => {
      console.log(err)
    });
   /*  var value_cout = 0;
    var value_prime = 0;
    const found_duree =
      type === "Schengen"
        ? coutPoliceShengen.find((element) => element.duree >= duree)
        : type === "Monde"
        ? coutPoliceMonde.find((element) => element.duree >= duree)
        : coutPoliceAfrique.find((element) => element.duree >= duree);
    const found_primenette =
      type === "Schengen"
        ? primeNetteShengen.find((element) => element.duree >= duree)
        : type === "Monde"
        ? primetNetteMonde.find((element) => element.duree >= duree)
        : primeNettePoliceAfrique.find((element) => element.duree >= duree);
    if (parseInt(age) >= 0 && parseInt(age) <= 17) {
      value_cout = found_duree.age1;
      value_prime = found_primenette.age1;
    } else if (parseInt(age) >= 18 && parseInt(age) <= 65) {
      value_cout = found_duree.age2;
      value_prime = found_primenette.age2;
    } else if (parseInt(age) >= 66 && parseInt(age) <= 75) {
      value_cout = found_duree.age3;
      value_prime = found_primenette.age3;
    } else if (parseInt(age) >= 76 && parseInt(age) <= 80) {
      value_cout = found_duree.age4;
      value_prime = found_primenette.age4;
    }
    setResults({
      prime_nette: value_prime,
      cout_police: value_cout,
      taxe: (value_prime + value_cout) * 0.1,
      prime_ttc:
        parseFloat(value_prime + value_cout) +
        parseFloat((value_prime + value_cout) * 0.1),
    }); */
    setloaderResult(false);
  }
  return (
    <div>
      <h5 className="mt-4 mb-4 text-center"> {t("Devis Voyage")} </h5>
      <div className="text-center">
        <img
          className="mb-4 text-center"
          src="../img/bg-img/21.png"
          width={"100px"}
          alt="Assurance vie"
        />
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-group mb-3">
          <label className="form-label fz-12">{t("Age Assuré")}</label>
          <input
            className="form-control fz-12"
            type="number"
            placeholder="Age ... "
            {...register("age")}
            required
          />
        </div>
        <div className="form-group text-center mb-3">
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input fz-12"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                {...register("etat")}
                defaultValue="option1"
              />
              <label className="form-check-label fz-12" htmlFor="inlineRadio1">
                {t("Nombre de jours")}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input fz-12"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                {...register("etat")}
                defaultValue="option2"
              />
              <label className="form-check-label fz-12" htmlFor="inlineRadio2">
                {t("Date départ / arrivé")}
              </label>
            </div>
          </div>
        </div>
        {watch("etat") === "option1" ? (
          <div className="form-group mb-3">
            <label className="form-label fz-12">
              {t("Nbr° Jours du séjour")} 
            </label>
            <input
              className="form-control fz-12"
              type="number"
              placeholder="nombre de jours du séjour ... "
              {...register("nbr_jours")}
              required
            />
          </div>
        ) : (
          watch("etat") === "option2" && (
            <>
              <div className="form-group mb-3">
                <label className="form-label fz-12">
                  {t("Date départ")} 
                </label>
                <input
                  className="form-control fz-12"
                  type="date"
                  {...register("date_depart")}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label className="form-label fz-12">
                  {t("Date retour")} 
                </label>
                <input
                  className="form-control fz-12 "
                  type="date"
                  {...register("date_retour")}
                  required
                />
              </div>
            </>
          )
        )}
        <div className="form-group mb-3">
          <label className="form-label fz-12">{t("Formules")}</label>
          <select
            className="form-select fz-12"
            {...register("type")}
          >
            <option value={""}>{t("Choisir")} </option>
            <option value={"shengen"}>{t("Schengen")} </option>
            <option value={"monde"}>{t("Monde")} </option>
            <option value={"afrique"}>{t("Afrique")} </option>
          </select>
        </div>
       {/*  <div className="form-group mb-3">
          <label className="form-label fz-12">{t("Pays de destination")}</label>
          <select
            className="form-select fz-12"
            {...register("pays_destination")}
          >
            <option value={""}>{t("Choisir")} </option>
            {pays?.map((items, keys) => (
              <option key={keys} value={items.code}>
                {language === "fr" ? items.nameFr : items.name}
              </option>
            ))}
          </select>
        </div> */}
        <div className="row">
          <div className="col-12">
              <button type="submit" className="btn btn-purple mb-3 col-12 fz-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="bi bi-calculator me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                  <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
                </svg>
                {t("Calculer")}
              </button>
          </div>
        </div>
      </form>
      {show && (
        <div className="card mb-4 ">
          <div className="card-body">
            <h5 className="text-center"> {t("Total du calcul")} </h5>
            <table className="table  mb-0 table-striped-primary mt-3">
              {loaderResult ? (
                <Loader />
              ) : (
                <tbody>
                  <tr>
                    <td>{t("Prime Nette")}</td>
                    <td className="text-center">
                      {numberWithSpaces(Math.round(results?.prime_nette))}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Coût de police")}</td>
                    <td className="text-center">
                      {" "}
                      {numberWithSpaces(Math.round(results?.cout_police))}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Taxe")}</td>
                    <td className="text-center">
                      {numberWithSpaces(Math.round(results?.taxe))}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Prime TTC")}</td>
                    <td className="text-center">
                      {numberWithSpaces(Math.round(results?.prime_ttc))}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="row">
            <div className="col-12">
              <div className=" float-end">
                <a
                  href={
                    "facturation_produits/id=" +
                    id +
                    "&title=" +
                    title +
                    "&type=" +
                    type
                  }
                  className="btn btn-success fz-12 mt-3 mb-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-receipt me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                  {t("Souscrire")}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
