import Icon from "../../../../components/obligatoire/Icon";

export default function Assuree({ ListeAssuree, register, autres, setautres, t, beneficiaire, setbeneficiaire, LISTE_ASSUREE, nbrAssuree, createDataNbAssuree, handleChangeAssuree, type})
 {
 
  return (
    <div className="shop-pagination">
      <div className="d-flex align-items-center justify-content-between">
        <small className="mb-3 fz-14">{t("Assuré(s)")}</small>
      </div>
      {(type === "retraite" || type === "deces" || type === "education") && (
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Béneficiaire du contrat")} <Icon />
          </label>
          <select
            className="form-select fz-12"
            value={beneficiaire}
            onChange={(e) => setbeneficiaire(e.target.value)}
            required
          >
            <option value="">{t("Choisir")}</option>
            { type !== "education" && <option value={"Mon conjoint"}>{t("Mon conjoint")}</option> }
           <option value={"Mes enfants"}>{t("Mes enfants")}</option>
           { type !== "education" &&  <option value={"Mon père"}>{t("Mon père")}</option> }
           { type !== "education" &&  <option value={"Ma mère"}>{t("Ma mère")}</option> }
            <option value={"Mes frères/soeurs"}>
              {t("Mes frères/soeurs")}
            </option>
            <option value={"autres"}>{t("Autres à préciser")}</option>
          </select>
          {beneficiaire === "autres" && (
            <input
              type="text"
              className="form-control mt-3 fz-12"
              placeholder="autres .. "
              value={autres}
              onChange={(e) => setautres(e.target.value)}
            />
          )}
        </div>
      )}
 
      {LISTE_ASSUREE?.map((items, keys) => (
        <div key={keys} className="card border border-secondary bg-transparent mb-3">
          <div className="card-body">
          
          <h6 className="text-center fz-14">{t("L'assurée N°")} {keys + 1} </h6>
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">
              {t("Nom Assuré")} & {t("Prénom Assuré")} <Icon />
            </label>
            <select
            className="form-select fz-12" 
            name="assuree_id"
            onChange={(e) => handleChangeAssuree(e, keys)}
            required
          >
             <option>{t("Choisir")}</option>
             {ListeAssuree?.map((items,keys)=>(
              <option key={keys} value={items.id}>{items.nom+" "+items.prenom}</option>
             ))}
          </select>
          </div>

        </div>
        </div>
      ))}
    </div>
  );
}
