import Icon from "../../../../components/obligatoire/Icon";

export default function DetailsContrats({ register, t, type }) {
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div className="shop-pagination">
      <div className="d-flex align-items-center justify-content-between">
      <small className="mb-3 fz-14">{t("Détails Contrat")} </small>
      </div>
      {type !== "voyage" && (
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">
            {t("Date Effet")} <Icon />
          </label>
          <input
            className="form-control fz-12"
            {...register("date_effet")}
            type="date"
            placeholder="DD/MM/YYYY"
            required
            min={currentDate}
          />
        </div>
      )}
    </div>
  );
}
