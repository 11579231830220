import parse from "html-react-parser";

export default function PolitiqueConfidentialite({ setcondition2 ,t }) {
  return (
    <div
      className="modal fade"
      id="exampleModal2"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
          <h6 className="modal-title fz-14" id="exampleModalLabel">
              {t("Politique de protection des données")}
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div>
              <h6>{t("Politique de traitement des donnees personnelles")}</h6>
              {parse(`<p>Les donn&eacute;es &agrave; caract&egrave;re personnel collect&eacute;es dans le pr&eacute;sent formulaire seront trait&eacute;es exclusivement pour la cr&eacute;ation de votre compte client et dans le cadre de la prise en charge de vos besoins d&rsquo;assurances&nbsp;: devis, souscription, diligences li&eacute;es &agrave; vos sinistres, etc.</p>
<p>Le client consent n&eacute;anmoins &agrave; DIOKOTECH le droit de conserver lesdites donn&eacute;es pendant la dur&eacute;e l&eacute;gale de conservation et de le contacter ult&eacute;rieurement pour lui proposer ses produits, le renouvellement de polices d&rsquo;assurance et aussi l&rsquo;informer de ses offres promotionnelles et de toute &eacute;volution de son catalogue de produits d&rsquo;assurance.</p>
<p>Le client atteste de l&rsquo;exactitude des informations fournies par lui et reconna&icirc;t avoir pris connaissance des garanties incluses dans l&rsquo;offre qu&rsquo;il envisage souscrire et bien les comprendre.</p>
<p>Il reconna&icirc;t que les donn&eacute;es &agrave; caract&egrave;re personnel peuvent &ecirc;tre communiqu&eacute;es aux assureurs, aux partenaires de DIOKOTECH notamment pour les besoins de livraison des polices d&rsquo;assurance souscrites, ainsi qu&rsquo;aux autorit&eacute;s de tutelle et &agrave; toute autre autorit&eacute; comp&eacute;tente au regard des lois et r&egrave;glements en vigueur au S&eacute;n&eacute;gal.</p>
<p>En application des&nbsp;dispositions de la loi n&deg;2008-12 du 25 janvier 2008 portant sur la protection des donn&eacute;es &agrave; caract&egrave;re personnel, le traitement portant sur les donn&eacute;es collect&eacute;es a &eacute;t&eacute; d&eacute;clar&eacute;&nbsp;devant la CDP (&laquo; Commission des Donn&eacute;es Personnelles &raquo;). &nbsp;</p>
<p>Conform&eacute;ment &agrave; la loi pr&eacute;cit&eacute;e, vous pouvez exercer vos droits d&rsquo;acc&egrave;s, d&rsquo;opposition, de rectification et de suppression aupr&egrave;s de DIOKOTECH.</p>`)}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {" "}
              <i className="bi bi-x-lg m-1" />  {t("Fermer")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setcondition2(true)}
              data-bs-dismiss="modal"
            >
              {" "}
              <i className="bi bi-check2-circle m-1" />  {t("Confirmer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
