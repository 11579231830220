/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useUser } from "../../Auth/useUser";
import {
  error_message,
  success_message,
} from "../../components/alerte/AlerteMessage";
import Loader from "../../components/loading/Loader";
import { getUser, updateParrain } from "../../services/users/usersAPI";

export default function Parrainage() {
  const user = useUser();
  const [info, setInfo] = useState({});
  const [enabled, setEnabled] = useState(false); // false =  disabled ; true enabled
  const [code, setCode] = useState("");
  // OK PARRAINAGE
  const [isOk, setIsOK] = useState(false);
  /**
   * LOADER
   */
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    //console.log(user?.result?.id)
    getUser(user?.result?.id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var data = result?.data?.data;
          console.log(data);
          if (data?.code_parrain !== "" && data?.identifiant_parrain !== "" && data?.code_parrain !== null && data?.identifiant_parrain !== null  ) {
            setIsOK(true);
            setInfo({
              code_parrain: data?.code_parrain,
              identifiant_parrain: data?.identifiant_parrain,
              cumul_points: data?.cumul_points,
            });
            setCode(data?.code_parrain);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enabled]);

  const editCodeParrain = () => {
    if (enabled) {
      setEnabled(false);
      setLoading(true);
      //and save changes
      updateParrain({
        id: user?.result?.id,
        identifiant_parrain: info?.identifiant_parrain,
        code_parrain: code,
      })
        .then((result) => {
          if (result?.data?.code === 200) {
            success_message("Code parrain modifié avec succès !");
            //window.location.reload();
          } else {
            error_message("Erreur Temporaire !");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setEnabled(true);
    }
  };
  return (
    <div className="page-content-wrapper py-3">
      <div className="container">
        <div className="card bg-transparent mb-3">
          <div className="card-body">
            <h5 className="mb-3 text-center display-5">
              Parrainer un contact{" "}
            </h5>
            <div className="text-center">
              <img
                className="mb-4 text-center"
                src="../img/bg-img/Refer a friend-pana.png"
                width={"150px"}
                alt="Assurance vie"
              />
            </div>
            <p className="text-center fz-14">
              Inviter ton prochain à télécharger et créer un compte sur
              l'application Mobile DiokoAssur
            </p>
            {Loading ? (
              <Loader />
            ) : (
              <>
                {isOk ? (
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group">
                          <label className="form-label fz-12">
                            Identifiant
                          </label>
                          <input
                            className="form-control fz-12"
                            placeholder="Identifiant du parrainage ... "
                            type="text"
                            value={info?.identifiant_parrain}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-label fz-12">
                            Code de parrainage
                          </label>
                          <div className="input-group mb-3">
                            {enabled ? (
                              <input
                                className="form-control fz-12"
                                type="text"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                              />
                            ) : (
                              <input
                                className="form-control fz-12"
                                type="text"
                                placeholder="xxxx"
                                disabled
                                value={info?.code_parrain}
                              />
                            )}

                            <button
                              type="button"
                              className="btn btn-purple fz-12"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  info?.code_parrain
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-files"
                                viewBox="0 0 16 16"
                              >
                                <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                              </svg>
                            </button>
                            <button
                              type="button"
                              className="btn btn-success fz-12"
                              onClick={() => editCodeParrain()}
                            >
                              {enabled ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-save"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <a
                    className="btn btn-creative btn-purple text-white  mt-1 mb-3 fz-12 col-12"
                    href="/add_parrainage"
                  >
                    <i className="bi bi-plus me-1 fz-12" /> Ajouter un code
                    parrainage
                  </a>
                )}

                <h6 className="mb-3 mt-4 display-6 fz-16 text-center">
                  Mes points de parrainage (<b>{info?.cumul_points}</b>){" "} (1 point = <b>50 </b> FCFA)

                </h6>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
