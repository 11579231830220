
export default function InforamtionsContrats({ t, data, moment, type }) {
  return (
    <div>
      <p className="mt-3 fz-12">
        <b>{t("Type de contrat")} : </b>
        {data?.category?.titre}
      </p>
      <p className="mt-3 fz-12">
        <b>{t("Police N°")}: </b> {data?.policy_code}
      </p>
      <p className="mt-3 fz-12">
        <b>{t("Date Effet")}</b> :
        {moment(data?.date_effet).format("DD/MM/YYYY")}
      </p>
      {data?.duree !== null && (
        <p className="mt-3 fz-12">
          <b> {t("Date Echéance")}</b> :
          {moment(data?.date_effet).subtract(1, "days").add(data?.duree, "y").format("DD/MM/YYYY")}

        </p>
      )}
      {data?.duree !== null && (
        <p className="mt-3 fz-12">
          <b> {t("Durée du contrat (mois)")} </b> : {data?.duree}
        </p>
      )}
      <p className="mt-3 fz-12">
        <b> {t("Compagnie d'assurance")} </b> :{data?.company?.nom}
      </p>
      {type === "voyage" && (
        <>
          <p className="mt-3 fz-12">
            <b>Date départ </b> :
            {" " + moment(data?.date_depart).format("DD/MM/YYYY")}
          </p>
          <p className="mt-3 fz-12">
            <b>Date Arrivé </b> :
            {" " + moment(data?.date_arrive).format("DD/MM/YYYY")}
          </p>
          <p className="mt-3 fz-12">
            <b>Pays de départ </b> :{" " + data?.pays_destination}
          </p>
          <p className="mt-3 fz-12">
            <b>Pays de destination </b> :{" " + data?.pays_alle}
          </p>
        </>
      )}
    </div>
  );
}
