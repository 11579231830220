import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#fff";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 18,
    fontSize: 8,
  },
  description1: {
    width: "50%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    letterSpacing: 1,
    fontSize: 9,
    color: "#4e545d",
    fontFamily:"Arial",
    fontStyle:"normal"
  },
  description: {
    width: "50%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 9,
    letterSpacing: 1,
    color: "#4e545d",
    fontFamily:"Arial",
    fontStyle:"normal"
  },
  text:{
    fontFamily:"Arial",
    fontStyle:"bold"
  },
  text2:{
    fontFamily:"Arial",
    fontStyle:"bold",
    color:"red"
  }
});
const HeadrCP = ({ type, data }) => {
  const dateOrigine = moment(data?.date_effet);
  // Ajouter un mois
const dateAvecMoisSupplementaire = dateOrigine.clone().add(1, 'months');

// Soustraire un jour
const dateFinale = dateAvecMoisSupplementaire.clone().subtract(1, 'day');

// Formater la date finale selon le format souhaité
const dateFormatee = dateFinale.format('DD/MM/YYYY');

  const rows = (
    <>
      <View style={styles.row}>
        <Text style={styles.description1}>
          Type de Contrat: {data?.category?.titre}
        </Text>
        <Text style={styles.description}>
          Date Effet : {moment(data?.date_effet).format("DD/MM/YYYY")}{" "}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description1}>Police N°: <Text style={styles.text}>{data?.policy_code} </Text> </Text>
        <Text style={styles.description}>
          Date Echéance :{" "}
          {data?.category?.type !== "voyage" && data?.auto !== null && moment(data?.date_effet).subtract(1, "days").add(data?.auto?.duree, "months").format("DD/MM/YYYY") }
          {data?.category?.type !== "voyage" && data?.education !== null && moment(data?.date_effet).subtract(1, "days").add(data?.duree, "y").format("DD/MM/YYYY") }
          {data?.category?.type !== "voyage" && data?.dece !== null && moment(data?.date_effet).subtract(1, "days").add(data?.dece?.duree_cotisation, "y").format("DD/MM/YYYY") }
          {data?.category?.type !== "voyage" && data?.retraite !== null && moment(data?.date_effet).subtract(1, "days").add(data?.duree, "y").format("DD/MM/YYYY") }
          {data?.category?.type !== "voyage" && data?.accident !== null && moment(data?.date_effet).subtract(1, "days").add(data?.accident?.duree, "months").format("DD/MM/YYYY") }
          {data?.category?.type === "voyage" && moment(data?.date_effet).subtract(1, "days").add(data?.duree,"day").format("DD/MM/YYYY") }
        </Text>
      </View>
      {type !== "accident" && (
        <View style={styles.row}>
          <Text style={styles.description1}>Durée: {data?.duree} (ans)</Text>
        </View>
      )}
    </>
  );
  return <Fragment>{rows}</Fragment>;
};
export default HeadrCP;
