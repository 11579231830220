
export const pays = [
    { nameFr : "Afghanistan", type:"Monde" , name: 'Afghanistan', code: 'AF' }, 
    { nameFr:'Åland', type:"Monde", name: 'Åland Islands', code: 'AX' }, 
    { nameFr:'Albanie',  type:"Monde",name: 'Albania', code: 'AL'}, 
    { nameFr:"Algérie",  type:"Afrique", name: 'Algeria', code: 'DZ'}, 
    { nameFr:"Samoa américaines",  type:"Monde", name: 'American Samoa', code: 'AS'}, 
    { nameFr:"Andorre",  type:"Monde", name: 'AndorrA', code: 'AD'}, 
    { nameFr:"Angola",  type:"Afrique", name: 'Angola', code: 'AO'}, 
    { nameFr:"Anguilla",  type:"Monde", name: 'Anguilla', code: 'AI'}, 
    { nameFr:"Antarctique",  type:"Monde", name: 'Antarctica', code: 'AQ'}, 
    { nameFr:"Antigua-et-Barbuda",  type:"Monde", name: 'Antigua and Barbuda', code: 'AG'}, 
    { nameFr:"Argentine",  type:"Monde", name: 'Argentina', code: 'AR'}, 
    { nameFr:"Arménie",  type:"Monde", name: 'Armenia', code: 'AM'}, 
    { nameFr:"Aruba",  type:"Monde", name: 'Aruba', code: 'AW'}, 
    { nameFr:"Australie",  type:"Monde", name: 'Australia', code: 'AU'}, 
    { nameFr:"Autriche",  type:"Schengen", name: 'Austria', code: 'AT'}, 
    { nameFr:"Azerbaïdjan",  type:"Monde", name: 'Azerbaijan', code: 'AZ'}, 
    { nameFr:"Bahamas",  type:"Monde", name: 'Bahamas', code: 'BS'}, 
    { nameFr:"Bahreïn",  type:"Monde", name: 'Bahrain', code: 'BH'}, 
    { nameFr:"Bangladesh",  type:"Monde", name: 'Bangladesh', code: 'BD'}, 
    { nameFr:"Barbade",  type:"Monde", name: 'Barbados', code: 'BB'}, 
    { nameFr:"Biélorussie",  type:"Monde", name: 'Belarus', code: 'BY'}, 
    { nameFr:"Belgique",  type:"Schengen", name: 'Belgium', code: 'BE'}, 
    { nameFr:"Bélize",  type:"Monde", name: 'Belize', code: 'BZ'}, 
    { nameFr:"Bénin",  type:"Afrique", name: 'Benin', code: 'BJ'}, 
    { nameFr:"Bermuda",  type:"Monde", name: 'Bermuda', code: 'BM'}, 
    { nameFr:"Bhutan",  type:"Monde", name: 'Bhutan', code: 'BT'}, 
    { nameFr:"Bolivie",  type:"Monde", name: 'Bolivia', code: 'BO'}, 
    { nameFr:"Bosnie-Herzégovine",  type:"Monde", name: 'Bosnia and Herzegovina', code: 'BA'}, 
    { nameFr:"Botswana",  type:"Afrique", name: 'Botswana', code: 'BW'}, 
    { nameFr:"Île Bouvet",  type:"Monde", name: 'Bouvet Island', code: 'BV'}, 
    { nameFr:"Brésil",  type:"Monde", name: 'Brazil', code: 'BR'}, 
    { nameFr:"Territoire britannique de l'océan Indien",  type:"Monde", name: 'British Indian Ocean Territory', code: 'IO'}, 
    { nameFr:"Brunei Darussalam",  type:"Monde", name: 'Brunei Darussalam', code: 'BN'}, 
    { nameFr:"Bulgarie",  type:"Monde", name: 'Bulgaria', code: 'BG'}, 
    { nameFr:"Burkina Faso",  type:"Afrique", name: 'Burkina Faso', code: 'BF'}, 
    { nameFr:"Burundi",  type:"Afrique", name: 'Burundi', code: 'BI'}, 
    { nameFr:"Cambodge",  type:"Monde", name: 'Cambodia', code: 'KH'}, 
    { nameFr:"Cameroon",  type:"Afrique", name: 'Cameroon', code: 'CM'}, 
    { nameFr:"Canada",  type:"Monde", name: 'Canada', code: 'CA'}, 
    { nameFr:"Cap-Vert",  type:"Afrique", name: 'Cape Verde', code: 'CV'}, 
    { nameFr:"Îles Caïmans",  type:"Monde", name: 'Cayman Islands', code: 'KY'}, 
    { nameFr:"République central Africaine",  type:"Afrique", name: 'Central African Republic', code: 'CF'}, 
    { nameFr:"Tchad",  type:"Afrique", name: 'Chad', code: 'TD'}, 
    { nameFr:"Chili",  type:"Monde", name: 'Chile', code: 'CL'}, 
    { nameFr:"Chine",  type:"Monde", name: 'China', code: 'CN'}, 
    { nameFr:"Île Christmas",  type:"Monde", name: 'Christmas Island', code: 'CX'}, 
    { nameFr:"Îles Cocos",  type:"Monde", name: 'Cocos (Keeling) Islands', code: 'CC'}, 
    { nameFr:"Colombie",  type:"Monde", name: 'Colombia', code: 'CO'}, 
    { nameFr:"Comores    ",  type:"Afrique", name: 'Comoros', code: 'KM'}, 
    { nameFr:"Congo",  type:"Afrique", name: 'Congo', code: 'CG'}, 
    { nameFr:"République démocratique du Congo",  type:"Monde", name: 'The Democratic Republic of the Congo', code: 'CD'}, 
    { nameFr:"Îles Cook",  type:"Monde", name: 'Cook Islands', code: 'CK'}, 
    { nameFr:"Costa Rica",  type:"Monde", name: 'Costa Rica', code: 'CR'}, 
    { nameFr:"Cote D'Ivoire",  type:"Afrique", name: "Cote D'Ivoire", code: 'CI'}, 
    { nameFr:"Croatie",  type:"Monde", name: 'Croatia', code: 'HR'}, 
    { nameFr:"Cuba",  type:"Monde", name: 'Cuba', code: 'CU'}, 
    { nameFr:"Cyprus",  type:"Monde", name: 'Cyprus', code: 'CY'}, 
    { nameFr:"Tchéquie",  type:"Schengen", name: 'Czech Republic', code: 'CZ'}, 
    { nameFr:"Danemark",  type:"Schengen", name: 'Denmark', code: 'DK'}, 
    { nameFr:"Djibouti",  type:"Afrique", name: 'Djibouti', code: 'DJ'}, 
    { nameFr:"Dominique",  type:"Monde", name: 'Dominica', code: 'DM'}, 
    { nameFr:"République dominicaine",  type:"Monde", name: 'Dominican Republic', code: 'DO'}, 
    { nameFr:"Équateur",  type:"Monde", name: 'Ecuador', code: 'EC'}, 
    { nameFr:"Égypte",  type:"Afrique", name: 'Egypt', code: 'EG'}, 
    { nameFr:"El Salvador",  type:"Monde", name: 'El Salvador', code: 'SV'}, 
    { nameFr:"Guinée équatoriale",  type:"Afrique", name: 'Equatorial Guinea', code: 'GQ'}, 
    { nameFr:"Eritrea",  type:"Afrique", name: 'Eritrea', code: 'ER'}, 
    { nameFr:"Estonie",  type:"Schengen", name: 'Estonia', code: 'EE'}, 
    { nameFr:"Éthiopie",  type:"Afrique", name: 'Ethiopia', code: 'ET'}, 
    { nameFr:"Îles Falkland ",  type:"Monde", name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    { nameFr:"Faroe Islands",  type:"Monde", name: 'Faroe Islands', code: 'FO'}, 
    { nameFr:"Fiji",  type:"Monde", name: 'Fiji', code: 'FJ'}, 
    { nameFr:"Finlande",  type:"Schengen", name: 'Finland', code: 'FI'}, 
    { nameFr:"France",  type:"Schengen", name: 'France', code: 'FR'}, 
    { nameFr:"Guyane française",  type:"Monde", name: 'French Guiana', code: 'GF'}, 
    { nameFr:"Polynésie française",  type:"Monde", name: 'French Polynesia', code: 'PF'}, 
    { nameFr:"Terres australes et antarctiques françaises",  type:"Monde", name: 'French Southern Territories', code: 'TF'}, 
    { nameFr:"Gabon",  type:"Afrique", name: 'Gabon', code: 'GA'}, 
    { nameFr:"Gambie",  type:"Afrique", name: 'Gambia', code: 'GM'}, 
    { nameFr:"Georgia",  type:"Monde", name: 'Georgia', code: 'GE'}, 
    { nameFr:"Allemagne ",  type:"Schengen", name: 'Germany', code: 'DE'}, 
    { nameFr:"Ghana",  type:"Afrique", name: 'Ghana', code: 'GH'}, 
    { nameFr:"Gibraltar",  type:"Monde", name: 'Gibraltar', code: 'GI'}, 
    { nameFr:"Greece",  type:"Schengen", name: 'Greece', code: 'GR'}, 
    { nameFr:"Greenland",  type:"Monde", name: 'Greenland', code: 'GL'}, 
    { nameFr:"Grenada",  type:"Monde", name: 'Grenada', code: 'GD'}, 
    { nameFr:"Guadeloupe",  type:"Monde", name: 'Guadeloupe', code: 'GP'}, 
    { nameFr:"Guam",  type:"Monde", name: 'Guam', code: 'GU'}, 
    { nameFr:"Guatemala",  type:"Monde", name: 'Guatemala', code: 'GT'}, 
    { nameFr:"Guernsey",  type:"Monde", name: 'Guernsey', code: 'GG'}, 
    { nameFr:"Guinea",  type:"Afrique", name: 'Guinea', code: 'GN'}, 
    { nameFr:"Guinea-Bissau",  type:"Afrique", name: 'Guinea-Bissau', code: 'GW'}, 
    { nameFr:"Guyana",  type:"Monde", name: 'Guyana', code: 'GY'}, 
    { nameFr:"Haiti",  type:"Monde", name: 'Haiti', code: 'HT'}, 
    { nameFr:"Ile Heard et Ile Mcdonald ",  type:"Monde", name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    { nameFr:"Holy See ",  type:"Monde", name: 'Holy See (Vatican City State)', code: 'VA'}, 
    { nameFr:"Honduras",  type:"Monde", name: 'Honduras', code: 'HN'}, 
    { nameFr:"Hong Kong",  type:"Monde", name: 'Hong Kong', code: 'HK'}, 
    { nameFr:"Hongrie ",  type:"Schengen", name: 'Hungary', code: 'HU'}, 
    { nameFr:"Islande ",  type:"Schengen", name: 'Iceland', code: 'IS'}, 
    { nameFr:"India",  type:"Monde", name: 'India', code: 'IN'}, 
    { nameFr:"Indonesia",  type:"Monde", name: 'Indonesia', code: 'ID'}, 
    { nameFr:"Iran",  type:"Monde", name: 'Iran', code: 'IR'}, 
    { nameFr:"Iraq",  type:"Monde", name: 'Iraq', code: 'IQ'}, 
    { nameFr:"Irelande",  type:"Monde", name: 'Ireland', code: 'IE'}, 
    { nameFr:"Isle of Man",  type:"Monde", name: 'Isle of Man', code: 'IM'}, 
    { nameFr:"Israel",  type:"Monde", name: 'Israel', code: 'IL'}, 
    { nameFr:"Italie",  type:"Schengen", name: 'Italy', code: 'IT'}, 
    { nameFr:"Jamaique",  type:"Monde", name: 'Jamaica', code: 'JM'}, 
    { nameFr:"Japon",  type:"Monde", name: 'Japan', code: 'JP'}, 
    { nameFr:"Jersey",  type:"Monde", name: 'Jersey', code: 'JE'}, 
    { nameFr:"Jordanie",  type:"Monde", name: 'Jordan', code: 'JO'}, 
    { nameFr:"Kazakhstan",  type:"Monde", name: 'Kazakhstan', code: 'KZ'}, 
    { nameFr:"Kenya",  type:"Afrique", name: 'Kenya', code: 'KE'}, 
    { nameFr:"Kiribati",  type:"Monde", name: 'Kiribati', code: 'KI'}, 
    { nameFr:"corée du nord",  type:"Monde", name: 'North Korea', code: 'KP'}, 
    { nameFr:"corée du sud",  type:"Monde", name: 'South Korea', code: 'KR'}, 
    { nameFr:"Kuwait",  type:"Monde", name: 'Kuwait', code: 'KW'}, 
    { nameFr:"Kyrgyzstan",  type:"Monde", name: 'Kyrgyzstan', code: 'KG'}, 
    { nameFr:"Lao",  type:"Monde", name: 'Lao People Democratic Republic', code: 'LA'}, 
    { nameFr:"Latvia",  type:"Schengen", name: 'Latvia', code: 'LV'}, 
    { nameFr:"Liban",  type:"Monde", name: 'Lebanon', code: 'LB'}, 
    { nameFr:"Lesotho",  type:"Afrique", name: 'Lesotho', code: 'LS'}, 
    { nameFr:"Liberia",  type:"Afrique", name: 'Liberia', code: 'LR'}, 
    { nameFr:"Libye",  type:"Afrique", name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    { nameFr:"Liechtenstein",  type:"Schengen", name: 'Liechtenstein', code: 'LI'}, 
    { nameFr:"Lithuania",  type:"Schengen", name: 'Lithuania', code: 'LT'}, 
    { nameFr:"Luxembourg",  type:"Schengen", name: 'Luxembourg', code: 'LU'}, 
    { nameFr:"Macao",  type:"Monde", name: 'Macao', code: 'MO'}, 
    { nameFr:"Macedonia",  type:"Monde", name: 'Macedonia', code: 'MK'}, 
    { nameFr:"Madagascar",  type:"Afrique", name: 'Madagascar', code: 'MG'}, 
    { nameFr:"Malawi",  type:"Afrique", name: 'Malawi', code: 'MW'}, 
    { nameFr:"Malaysia",  type:"Monde", name: 'Malaysia', code: 'MY'}, 
    { nameFr:"Maldives",  type:"Monde", name: 'Maldives', code: 'MV'}, 
    { nameFr:"Mali",  type:"Afrique", name: 'Mali', code: 'ML'}, 
    { nameFr:"Malta",  type:"Schengen", name: 'Malta', code: 'MT'}, 
    { nameFr:"Ile de Marshall",  type:"Monde", name: 'Marshall Islands', code: 'MH'}, 
    { nameFr:"Martinique",  type:"Monde", name: 'Martinique', code: 'MQ'}, 
    { nameFr:"Mauritanie",  type:"Afrique", name: 'Mauritania', code: 'MR'}, 
    { nameFr:"Mauritius",  type:"Afrique", name: 'Mauritius', code: 'MU'}, 
    { nameFr:"Mayotte",  type:"Monde", name: 'Mayotte', code: 'YT'}, 
    { nameFr:"Mexique",  type:"Monde", name: 'Mexico', code: 'MX'}, 
    { nameFr:"Micronesia",  type:"Monde", name: 'Micronesia', code: 'FM'}, 
    { nameFr:"Moldavie",  type:"Monde", name: 'Moldova', code: 'MD'}, 
    { nameFr:"Monaco",  type:"Monde", name: 'Monaco', code: 'MC'}, 
    { nameFr:"Mongolia",  type:"Monde", name: 'Mongolia', code: 'MN'}, 
    { nameFr:"Montserrat",  type:"Monde", name: 'Montserrat', code: 'MS'}, 
    { nameFr:"Maroc",  type:"Afrique", name: 'Morocco', code: 'MA'}, 
    { nameFr:"Mozambique",  type:"Afrique", name: 'Mozambique', code: 'MZ'}, 
    { nameFr:"Myanmar",  type:"Monde", name: 'Myanmar', code: 'MM'}, 
    { nameFr:"Namibia",  type:"Afrique", name: 'Namibia', code: 'NA'}, 
    { nameFr:"Nauru",  type:"Monde", name: 'Nauru', code: 'NR'}, 
    { nameFr:"Nepal",  type:"Monde", name: 'Nepal', code: 'NP'}, 
    { nameFr:"Pays-Bas",  type:"Schengen", name: 'Netherlands', code: 'NL'}, 
    { nameFr:"Antilles néerlandaises",  type:"Monde", name: 'Netherlands Antilles', code: 'AN'}, 
    { nameFr:"Nouvelle-Calédonie",  type:"Monde", name: 'New Caledonia', code: 'NC'}, 
    { nameFr:"Nouvelle-Zélande",  type:"Monde", name: 'New Zealand', code: 'NZ'}, 
    { nameFr:"Nicaragua",  type:"Monde", name: 'Nicaragua', code: 'NI'}, 
    { nameFr:"Niger",  type:"Afrique", name: 'Niger', code: 'NE'}, 
    { nameFr:"Nigeria",  type:"Afrique", name: 'Nigeria', code: 'NG'}, 
    { nameFr:"Niue",  type:"Monde", name: 'Niue', code: 'NU'}, 
    { nameFr:"Norfolk Island",  type:"Monde", name: 'Norfolk Island', code: 'NF'}, 
    { nameFr:"Northern Mariana Islands",  type:"Monde", name: 'Northern Mariana Islands', code: 'MP'}, 
    { nameFr:"Norway",  type:"Schengen", name: 'Norway', code: 'NO'}, 
    { nameFr:"Oman",  type:"Monde", name: 'Oman', code: 'OM'}, 
    { nameFr:"Pakistan",  type:"Monde", name: 'Pakistan', code: 'PK'}, 
    { nameFr:"Palau",  type:"Monde", name: 'Palau', code: 'PW'}, 
    { nameFr:"Palestine",  type:"Monde", name: 'Palestinian Territory, Occupied', code: 'PS'}, 
    { nameFr:"Panama",  type:"Monde", name: 'Panama', code: 'PA'}, 
    { nameFr:"Papua New Guinea",  type:"Monde", name: 'Papua New Guinea', code: 'PG'}, 
    { nameFr:"Paraguay",  type:"Monde", name: 'Paraguay', code: 'PY'}, 
    { nameFr:"Pérou",  type:"Monde", name: 'Peru', code: 'PE'}, 
    { nameFr:"Philippines",  type:"Monde", name: 'Philippines', code: 'PH'}, 
    { nameFr:"Pitcairn",  type:"Monde", name: 'Pitcairn', code: 'PN'}, 
    { nameFr:"Pologne ",  type:"Schengen", name: 'Poland', code: 'PL'}, 
    { nameFr:"Portugal",  type:"Schengen", name: 'Portugal', code: 'PT'}, 
    { nameFr:"Puerto Rico",  type:"Monde", name: 'Puerto Rico', code: 'PR'}, 
    { nameFr:"Qatar",  type:"Monde", name: 'Qatar', code: 'QA'}, 
    { nameFr:"Reunion",  type:"Monde", name: 'Reunion', code: 'RE'}, 
    { nameFr:"Roumanie",  type:"Monde", name: 'Romania', code: 'RO'}, 
    { nameFr:"Russie",  type:"Monde", name: 'Russian Federation', code: 'RU'}, 
    { nameFr:"Rwanda",  type:"Afrique", name: 'RWANDA', code: 'RW'}, 
    { nameFr:"Saint Helena",  type:"Monde", name: 'Saint Helena', code: 'SH'}, 
    { nameFr:"Saint Kitts and Nevis",  type:"Monde", name: 'Saint Kitts and Nevis', code: 'KN'}, 
    { nameFr:"Saint Lucia",  type:"Monde", name: 'Saint Lucia', code: 'LC'}, 
    { nameFr:"Saint Pierre and Miquelon",  type:"Monde", name: 'Saint Pierre and Miquelon', code: 'PM'}, 
    { nameFr:"Saint Vincent and the Grenadines",  type:"Monde", name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    { nameFr:"Samoa",  type:"Monde", name: 'Samoa', code: 'WS'}, 
    { nameFr:"San Marino",  type:"Monde", name: 'San Marino', code: 'SM'}, 
    { nameFr:"Sao Tome et Principe",  type:"Afrique", name: 'Sao Tome and Principe', code: 'ST'}, 
    { nameFr:"Arabie saoudite",  type:"Monde", name: 'Saudi Arabia', code: 'SA'}, 
    { nameFr:"Sénégal",  type:"Monde", name: 'Senegal', code: 'SN'}, 
    { nameFr:"Serbia et Montenegro",  type:"Monde", name: 'Serbia and Montenegro', code: 'CS'}, 
    { nameFr:"Seychelles",  type:"Afrique", name: 'Seychelles', code: 'SC'}, 
    { nameFr:"Sierra Leone",  type:"Afrique", name: 'Sierra Leone', code: 'SL'}, 
    { nameFr:"Singapour",  type:"Monde", name: 'Singapore', code: 'SG'}, 
    { nameFr:"Slovaquie",  type:"Schengen", name: 'Slovakia', code: 'SK'}, 
    { nameFr:"Slovénie",  type:"Schengen", name: 'Slovenia', code: 'SI'}, 
    { nameFr:"Îles Salomon",  type:"Monde", name: 'Solomon Islands', code: 'SB'}, 
    { nameFr:"Somalie",  type:"Afrique", name: 'Somalia', code: 'SO'}, 
    { nameFr:"Afrique du sud",  type:"Afrique", name: 'South Africa', code: 'ZA'}, 
    { nameFr:"Espagne",  type:"Schengen", name: 'Spain', code: 'ES'}, 
    { nameFr:"SriLanka",  type:"Monde", name: 'Sri Lanka', code: 'LK'}, 
    { nameFr:"Sudan",  type:"Afrique", name: 'Sudan', code: 'SD'}, 
    { nameFr:"Suriname",  type:"Monde", name: 'Suriname', code: 'SR'}, 
    { nameFr:"Svalbard et Jan Mayen",  type:"Monde", name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    { nameFr:"Eswatini",  type:"Afrique", name: 'Swaziland', code: 'SZ'}, 
    { nameFr:"Suède",  type:"Schengen", name: 'Sweden', code: 'SE'}, 
    { nameFr:"Suisse",  type:"Schengen", name: 'Switzerland', code: 'CH'}, 
    { nameFr:"Syrie",  type:"Monde", name: 'Syrian Arab Republic', code: 'SY'}, 
    { nameFr:"Taïwan ",  type:"Monde", name: 'Taiwan', code: 'TW'}, 
    { nameFr:"Tajikistan",  type:"Monde", name: 'Tajikistan', code: 'TJ'}, 
    { nameFr:"Tanzanie",  type:"Afrique", name: "United Republic of Tanzania", code: 'TZ'}, 
    { nameFr:"Thaïlande",  type:"Monde", name: 'Thailand', code: 'TH'}, 
    { nameFr:"Timor-Leste",  type:"Monde", name: 'Timor-Leste', code: 'TL'}, 
    { nameFr:"Togo",  type:"Afrique", name: 'Togo', code: 'TG'}, 
    { nameFr:"Tokelau",  type:"Monde", name: 'Tokelau', code: 'TK'}, 
    { nameFr:"Tonga",  type:"Monde", name: 'Tonga', code: 'TO'}, 
    { nameFr:"Trinidad et Tobago",  type:"Monde", name: 'Trinidad and Tobago', code: 'TT'}, 
    { nameFr:"Tunisie",  type:"Afrique", name: 'Tunisia', code: 'TN'}, 
    { nameFr:"Turquie",  type:"Monde", name: 'Turkey', code: 'TR'}, 
    { nameFr:"Turkménistan",  type:"Monde", name: 'Turkmenistan', code: 'TM'}, 
    { nameFr:"Îles Turks et Caïques",  type:"Monde", name: 'Turks and Caicos Islands', code: 'TC'}, 
    { nameFr:"Tuvalu",  type:"Monde", name: 'Tuvalu', code: 'TV'}, 
    { nameFr:"Uganda",  type:"Afrique", name: 'Uganda', code: 'UG'}, 
    { nameFr:"Ukraine",  type:"Monde", name: 'Ukraine', code: 'UA'}, 
    { nameFr:"Émirats arabes unis",  type:"Monde", name: 'United Arab Emirates', code: 'AE'}, 
    { nameFr:"Royaume-Uni",  type:"Monde", name: 'United Kingdom', code: 'GB'}, 
    { nameFr:"États-Unis",  type:"Monde", name: 'United States', code: 'US'}, 
    { nameFr:"Uruguay",  type:"Monde", name: 'Uruguay', code: 'UY'}, 
    { nameFr:"Ouzbékistan",  type:"Monde", name: 'Uzbekistan', code: 'UZ'}, 
    { nameFr:"Vanuatu",  type:"Monde", name: 'Vanuatu', code: 'VU'}, 
    { nameFr:"Venezuela",  type:"Monde", name: 'Venezuela', code: 'VE'}, 
    { nameFr:"Vietnam",  type:"Monde", name: 'Viet Nam', code: 'VN'}, 
    { nameFr:"îles Vierges britanniques",  type:"Monde", name: 'Virgin Islands, British', code: 'VG'}, 
    { nameFr:" Îles Vierges des États-Unis",  type:"Monde", name: 'Virgin Islands, U.S.', code: 'VI'}, 
    { nameFr:"Wallis et Futuna",  type:"Monde", name: 'Wallis and Futuna', code: 'WF'}, 
    { nameFr:"Sahara occidental",  type:"Monde", name: 'Western Sahara', code: 'EH'}, 
    { nameFr:"Yemen",  type:"Monde", name: 'Yemen', code: 'YE'}, 
    { nameFr:"Zambie",  type:"Afrique", name: 'Zambia', code: 'ZM'}, 
    { nameFr:"Zimbabwe",  type:"Afrique", name: 'Zimbabwe', code: 'ZW'} 
] 