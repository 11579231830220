import CardChoosr from "./CardChoosr";
/**
 * IMAGE : les images de l'interface choisir contrat
 */
import Contrat from "./image/contract.png"
import Renouvellement from "./image/renouvellement.png"
import Facture from "./image/validating-ticket.png"
import Revenue from "./image/salary.png"
import { useTranslation } from "react-i18next";

export default function ChooseOptions() {
   //------------- Traduction ---------------
  const [t] = useTranslation("global");
  return (
    <div className="container">
      <div className="shop-pagination pb-3">
        <div className="container mb-3">
          <div className="card">
            <div className="card-body p-2">
              <div className="d-flex align-items-center justify-content-between">
                <small className="ms-1">{t("Choisir Votre option")}</small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-2 p-3">
          <div className="col-6 mb-2">
            <CardChoosr image={Contrat} titre={t("Contrats")} url={"/liste_contrats"} />           
          </div>
          <div className="col-6 mb-2">
            <CardChoosr image={Facture} titre={t("Factures")} url={"/categories_factures"} />           
          </div>
          <div className="col-6">
            <CardChoosr image={Renouvellement} titre={t("Renouvellement")} url={"/renouvellement_contrat"} />           
          </div>
          <div className="col-6">
            <CardChoosr image={Revenue} titre={t("Cotisation")} url={"/cotisation_contrat"} />           
          </div>
        </div>
      </div>
    </div>
  );
}
