/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* Import modules */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// # TOAST AND ALERT ELEMENTS
import { toast } from "react-toastify";

// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";

// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";

//---------------------------- SERVICES ---------------------------------
// # SERVICE LOGIN AUTHENTIFCATION WITH E-MAIL AND PASSWORD
import {
  login_email,
  login_email_code_PIN,
  login_google,
  login_mobile_code_PIN,
  send_verification_email,
} from "../../services/signin/LoginAPI";


// --------------------------- COMPONENTS -------------------------------

// # ELEMENT WITH ICON BACK TO PREVIOUS PAGE
import ButtonWithIcon from "../../components/buttons/ButtonWithIcon";
import DotLoader from "../../components/loading/DotLoader";
import { useToken } from "../../Auth/useToken";
import { useQueryParams } from "../../utils/useQueryParams";
import { getBrowser, getIpInformation, getUA } from "../../utils/DeviceUtils";
import { create_session } from "../../services/sessions/sessionAPI";
import Prefix from "../../components/select/Prefix";


export default function Login() {

  // # History CALL
  const history = useHistory();
  //--------------- VERIFICATION CODE ------
  const [email, setemail] = useState(""); // TO SEND FOR VERIF function
  const [codeVerify, setcodeVerify] = useState("");
  const [isverifed, setisverifed] = useState(true);
  //------------ Authentifications types ---------
  const [Auth_Login, setAuth_Login] = useState(false)
  const [Auth_PIN_Mobile, setAuth_PIN_Mobile] = useState(false)
  const [Auth_PIN_Email, setAuth_PIN_Email] = useState(false)

  //------------- PASSWORD TYPE ------------
  const [password, setpassword] = useState("password");
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // -------------- Loading -----------
  const [loading, setLoading] = useState(false);
  // # SUBMIT FORM ELEMENTS
  const { register, handleSubmit } = useForm();
  const [token, setToken] = useToken();

  const [googleOauthUrlt, setGoogleOauthUrl] = useState(null);

  /**
   * Handle Registration  : OnSubmit Form function
   * @param {*} data
   */
  const handleRegistration = (data) => {
    if (isNaN(data?.identifiant)) {
      setLoading(true);
      login_email({ email: data?.identifiant, password: data?.password })
        .then((result) => {
          const data = result.data;
          // status === 500 => WRONG E-Mail or Password
          if (data?.code === 500) {
            if (data?.errorMessage === "user not exist") {
              toast(t("Utilisateur non inscrit!"), {
                type: "error",
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast(t("Mot de passe ou email invalide"), {
                type: "error",
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
          // code (status) === 200 => Valid E-Mail or Password
          else if (data?.code === 200) {
            setLoading(true);
            if (data?.data?.user?.is_verified === "1") {
              var id = data?.data?.user?.id
              setisverifed(true);
              toast(t("Bienvenue "), {
                type: "success",
                position: toast.POSITION.TOP_RIGHT,
              });
              // Redirect To home page
              setToken(data?.data?.token);
              var os = getUA()
              var browser = getBrowser()
              getIpInformation().then(res => {
                var values = res?.data
                var data = {}
                Object.assign(data, {
                  user_id: id, ip: values?.ip, browser: browser, continent: values?.continent_name, country: values?.country_name, latitude: values?.latitude, longitude: values?.longitude, os: os, language: values?.languages[0]?.name, currency: values?.currency?.name
                })
                create_session(data)
                  .then((result) => {
                    //console.log(result);
                  }).catch((err) => {
                    console.log(err)
                  });
              })
              history.push({
                pathname: "/",
              });
            } else {
              setisverifed(false);
              setemail(data?.data?.user?.email);
              setcodeVerify(data?.data?.user?.code_verification_email);
              toast(t("Vous devez vérifier votre compte avec votre E-Mail !"), {
                type: "warning",
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleRegistrationPIN_MOBILE = (data) => {
    login_mobile_code_PIN({ mobile: data?.prefix + data?.mobile, code_PIN: data?.code_PIN })
      .then((result) => {
        const data = result.data;
        // status === 500 => WRONG E-Mail or Password
        if (data?.code === 500) {
          if (data?.errorMessage === "user not exist") {
            toast(t("Utilisateur non inscrit!"), {
              type: "error",
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast(t("Mot de passe ou email invalide"), {
              type: "error",
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        // code (status) === 200 => Valid E-Mail or Password
        else if (data?.code === 200) {
          setLoading(true);
          if (data?.data?.user?.is_verified === "1") {
            var id = data?.data?.user?.id
            setisverifed(true);
            toast(t("Bienvenue "), {
              type: "success",
              position: toast.POSITION.TOP_RIGHT,
            });
            // Redirect To home page
            setToken(data?.data?.token);
            var os = getUA()
            var browser = getBrowser()
            getIpInformation().then(res => {
              var values = res?.data
              var data = {}
              Object.assign(data, {
                user_id: id, ip: values?.ip, browser: browser, continent: values?.continent_name, country: values?.country_name, latitude: values?.latitude, longitude: values?.longitude, os: os, language: values?.languages[0]?.name, currency: values?.currency?.name
              })
              create_session(data)
                .then((result) => {
                  //console.log(result);
                }).catch((err) => {
                  console.log(err)
                });
            })
            history.push({
              pathname: "/",
            });
          } else {
            setisverifed(false);
            setemail(data?.data?.user?.email);
            setcodeVerify(data?.data?.user?.code_verification_email);
            toast(t("Vous devez vérifier votre compte avec votre E-Mail !"), {
              type: "warning",
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

  };

  const handleRegistrationPIN_EMAIL = (data) => {
    login_email_code_PIN({ email: data?.identifiant, code_PIN: data?.code_PIN })
      .then((result) => {
        const data = result.data;
        // status === 500 => WRONG E-Mail or Password
        if (data?.code === 500) {
          if (data?.errorMessage === "user not exist") {
            toast(t("Utilisateur non inscrit!"), {
              type: "error",
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast(t("Mot de passe ou email invalide"), {
              type: "error",
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        // code (status) === 200 => Valid E-Mail or Password
        else if (data?.code === 200) {
          setLoading(true);
          if (data?.data?.user?.is_verified === "1") {
            var id = data?.data?.user?.id
            setisverifed(true);
            toast(t("Bienvenue "), {
              type: "success",
              position: toast.POSITION.TOP_RIGHT,
            });
            // Redirect To home page
            setToken(data?.data?.token);
            var os = getUA()
            var browser = getBrowser()
            getIpInformation().then(res => {
              var values = res?.data
              var data = {}
              Object.assign(data, {
                user_id: id, ip: values?.ip, browser: browser, continent: values?.continent_name, country: values?.country_name, latitude: values?.latitude, longitude: values?.longitude, os: os, language: values?.languages[0]?.name, currency: values?.currency?.name
              })
              create_session(data)
                .then((result) => {
                  //console.log(result);
                }).catch((err) => {
                  console.log(err)
                });
            })
            history.push({
              pathname: "/",
            });
          } else {
            setisverifed(false);
            setemail(data?.data?.user?.email);
            setcodeVerify(data?.data?.user?.code_verification_email);
            toast(t("Vous devez vérifier votre compte avec votre E-Mail !"), {
              type: "warning",
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyEmail = () => {
    /**
     * SEND EMAIL OF VERIFICATION
     */
    var data = {
      email: email,
    };
    //console.log(data);
    send_verification_email(codeVerify, data)
      .then((result) => {
        if (result?.data?.code === 200) {
          history.push({
            pathname: "/verif_mail"
          })
        } else {
          toast("Erreur d'envoi mail temporaire !", { position: "top-right", type: "error" })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // UPDATE TYPE OF INPUT PASSWORD
  const updateType = () => {
    if (password === "password") {
      setpassword("text");
    } else {
      setpassword("password");
    }
  };


  const { token: oauthToken } = useQueryParams();
  useEffect(() => {
    if (oauthToken) {
      setToken(oauthToken);
      history.push({
        pathname: "/",
      });
    }
  }, [oauthToken, token, history]);

  useEffect(() => {
    login_google().then((result) => {
      const { url } = result?.data?.data;
      setGoogleOauthUrl(url);

    }).catch((err) => {
      console.log(err);

    });

  }, []);

  return (
    <div>
      {/* Login Wrapper Area */}
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          {/** IMAGE LOGIN  */}
          <div className="text-center px-4">
            <img
              className="login-intro-img"
              src="img/bg-img/Login-cuate.png"
              alt="login_image"
              width={"150px"}
            />
          </div>
          {/* Register Form */}
          <div className="register-form mt-4">
            <h6 className="mb-3 text-center ">{t("S'authentifier")}</h6>
            {Auth_Login &&
              <form onSubmit={handleSubmit(handleRegistration)}>
                <div className="form-group">
                  <input
                    className="form-control fz-12"
                    type="text"
                    placeholder="E-Mail"
                    {...register("identifiant")}
                    required
                  />
                </div>
                <div className="form-group position-relative">
                  <input
                    className="form-control fz-12"
                    id="psw-input"
                    type={password}
                    {...register("password")}
                    placeholder="Mot de passe"
                    required
                  />
                  <div
                    className="position-absolute"
                    id="password-visibility"
                    onClick={() => updateType()}
                  >
                    {password === "password" ? (
                      <i className="bi bi-eye" />
                    ) : (
                      <i className="bi bi-eye-slash-fill" />
                    )}
                  </div>
                </div>
                {/** BUTTON SIGN IN  */}
                {loading ? (
                  <button className="btn btn-success w-100 fz-12" type="submit">
                    <DotLoader />
                  </button>
                ) : (
                  <button className="btn btn-success w-100 fz-12" type="submit">
                    {t("Se Connecter")}
                  </button>
                )}
                {!isverifed && (
                  <a
                    className=" w-100 mt-3 fz-12"
                    type="button"
                    onClick={() => verifyEmail()}
                  >
                    <i className="bi bi-envelope-plus me-2 fz-12" />
                    <b className="text-purple fz-12">{t("Cliquez ici")}</b> {t("pour envoyer un E-Mail de vérification")}
                  </a>
                )}
              </form>
            }
            {Auth_PIN_Mobile && <form onSubmit={handleSubmit(handleRegistrationPIN_MOBILE)}>
              <div className="input-group mb-3">
                <Prefix register={register} />
                <input
                  className="form-control  fz-12"
                  type="number"
                  style={{ width: "60%" }}
                  {...register("mobile")}
                  placeholder={t("Entrez le numéro de téléphone")}
                />
              </div>

              <div className="form-group position-relative">
                <input
                  className="form-control fz-12"
                  id="psw-input"
                  type={password}
                  {...register("code_PIN")}
                  placeholder="Code PIN"
                  required
                />
                <div
                  className="position-absolute"
                  id="password-visibility"
                  onClick={() => updateType()}
                >
                  {password === "password" ? (
                    <i className="bi bi-eye" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
              </div>
              {/** BUTTON SIGN IN  */}
              {loading ? (
                <button className="btn btn-success w-100 fz-12" type="submit">
                  <DotLoader />
                </button>
              ) : (
                <button className="btn btn-success w-100 fz-12" type="submit">
                  {t("Se Connecter")}
                </button>
              )}
              {!isverifed && (
                <a
                  className=" w-100 mt-3 fz-12"
                  type="button"
                  onClick={() => verifyEmail()}
                >
                  <i className="bi bi-envelope-plus me-2 fz-12" />
                  <b className="text-purple fz-12">{t("Cliquez ici")}</b> {t("pour envoyer un E-Mail de vérification")}
                </a>
              )}
            </form>}

            {Auth_PIN_Email && <form onSubmit={handleSubmit(handleRegistrationPIN_EMAIL)}>
              <div className="form-group">
                <input
                  className="form-control fz-12"
                  type="text"
                  placeholder="E-Mail"
                  {...register("identifiant")}
                  required
                />
              </div>
              <div className="form-group position-relative">
                <input
                  className="form-control fz-12"
                  id="psw-input"
                  type={password}
                  {...register("code_PIN")}
                  placeholder="Code PIN"
                  required
                />
                <div
                  className="position-absolute"
                  id="password-visibility"
                  onClick={() => updateType()}
                >
                  {password === "password" ? (
                    <i className="bi bi-eye" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
              </div>
              {/** BUTTON SIGN IN  */}
              {loading ? (
                <button className="btn btn-success w-100 fz-12" type="submit">
                  <DotLoader />
                </button>
              ) : (
                <button className="btn btn-success w-100 fz-12" type="submit">
                  {t("Se Connecter")}
                </button>
              )}
              {!isverifed && (
                <a
                  className=" w-100 mt-3 fz-12"
                  type="button"
                  onClick={() => verifyEmail()}
                >
                  <i className="bi bi-envelope-plus me-2 fz-12" />
                  <b className="text-purple fz-12">{t("Cliquez ici")}</b> {t("pour envoyer un E-Mail de vérification")}
                </a>
              )}
            </form>}
            {/** AUTHENTIFICATION WITH GOOGLE / SMS  */}
            <div className="register-form mt-4">
              {/*                 <h6 className="mb-3 text-center fz-12"> {t("Ou")} </h6>
 */}                <div className="row">
                <div className="col-12">
                  <button className="btn btn-purple text-white  fz-12 mb-3 w-100" type="button" onClick={() => { setAuth_Login(true); setAuth_PIN_Email(false); setAuth_PIN_Mobile(false) }}>
                    {t("S'authentifier avec un identifiant")}
                  </button>
                  <button className="btn btn-purple text-white  fz-12 mb-3 w-100" type="button" onClick={() => { setAuth_Login(false); setAuth_PIN_Email(false); setAuth_PIN_Mobile(true) }}>
                    {t("S'authentifier avec Mobile/Code PIN")}
                  </button>
                  <button className="btn btn-purple text-white  fz-12 mb-3 w-100" type="button" onClick={() => { setAuth_Login(false); setAuth_PIN_Email(true); setAuth_PIN_Mobile(false) }}>
                    {t("S'authentifier avec E-Mail/Code PIN")}
                  </button>
                  {/* <ButtonWithIcon
                      title={t("S'authentifier par SMS")}
                      link="/SMS_Login"
                      styles="btn btn-purple text-white  fz-12 mb-3 w-100"
                    /> */}
                  {googleOauthUrlt && (
                    <button
                      className="btn btn-purple  mb-3 fz-12  w-100"
                      disabled={!googleOauthUrlt}
                      type="button"
                      onClick={() => {
                        window.location.href = googleOauthUrlt;
                      }}
                    ><i className="bi bi-google fz-12  me-3" />

                      {t("S'authentifier par Google")}
                    </button>)}
                </div>
              </div>
            </div>

          </div>

          {/* Login Meta */}
          <div className="login-meta-data text-center">
            <a
              className="text-success forgot-password d-block fz-12 mt-3 mb-1"
              href="/forgot-password"
            >
              {t("Mot de passe oublié?")}
            </a>
            <p className="mb-0 fz-12">
              {t("Vous n'avez pas de compte ?")}
              <a className="text-success fz-12" href="/choose_register">
                {t("S'inscrire")}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
