import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#4e545d",
        borderBottomWidth: 1,
        borderTopWidth:1,
        borderRightWidth : 1,
        borderLeftWidth : 1,
        alignItems: "center",
        height: 18,
        fontSize: 8,
    },
    description1: {
        width: "50%",
        textAlign: "left",
        borderRightColor: "#4e545d",
        borderRightWidth: 1,
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
    description: {
        width: "50%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 8,
        letterSpacing: 1,
        color: "#4e545d",
        fontFamily:"Arial",
        fontStyle:"normal"
    },
});

const BeneficiaireCG= ({ type , data,user_details,personnel}) => {
    const rows = (
        <>
            <View style={styles.row}>
                <Text style={styles.description1}>Nom & Prénom :  {data?.assuree_id !== null? data?.assure?.prenom : user_details?.prenom}  {data?.assuree_id !== null
                ? data?.assure?.nom
                : user_details?.nom}</Text>
                <Text style={styles.description}> Adresse :{data?.adresse}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Date de naissance :{moment(
                data?.assuree_id !== null
                  ? data?.assure?.date_naissance
                  : user_details?.date_naissance
              ).format("DD/MM/YYYY")} </Text>
                <Text style={styles.description}>Télèphone : {user_details?.mobile}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}>Lieux de Naissance :{data?.assure?.lieu_naissance}</Text>
                <Text style={styles.description}>Pays de résidence : Sénegale</Text>
            </View>

        </>
    );
    return <Fragment>{rows}</Fragment>;
}


export default BeneficiaireCG;