import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../Auth/useUser";
import { getUser } from "../../services/users/usersAPI";
import {
  getContratByUser,
  getContratEducation,
  getContratRetraite,
  getContratDeces,
} from "../../services/contrats/ContratApi";
import { useHistory } from "react-router-dom";
import {
  getFactureByUser,
} from "../../services/factures/FactureApi";
import { t } from "i18next";
import { getListeAssuree } from "../../services/assuree/assureeAPI";
import _ from "lodash";

import accounting from "accounting"
export default function Cotisations_list() {
  const history = useHistory();
  const { idCategory } = useParams();
  const user = useUser();
  var { id, nom, prenom, gender } = user.result;
  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  const [contrats, setContrats] = useState([]);
  //LISTE ASSUREE
  const [ASSUREE, setASSUREE] = useState([]);
  

  const africanCurrencyOptions = {
    symbol: 'FCFA', // Set the currency symbol
    decimal: ' ', // Set the decimal separator
    thousand: ' ', // Set the thousand separator
    precision: " ", // Set the number of decimal places
    format: '%v %s', // Set the format pattern
  };
  const formatCurrency = (value) => {
    return accounting.formatMoney(value, africanCurrencyOptions);
  };
  useEffect(() => {
    getUser(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
          getListeAssuree(id)
            .then((result) => {
              if (result?.data?.code === 200) {
                const liste_assuree = _.groupBy(result?.data?.data, "id");
                setASSUREE(liste_assuree);
                console.log("liste assure by :");
                console.log("liste assure by :");
                console.log("liste assure by :");
                console.log("liste assure by :");
                console.log("liste assure by :", liste_assuree);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          getContratByUser(id)
            .then((result) => {
              console.log(result);
              if (result?.data?.code === 200) {
                var L = result?.data?.data?.filter(
                  (el) => el.category_id === Number(idCategory)
                );
                for (let i = 0; i < L.length; i++) {
                  getContratRetraite(L[i].id)
                    .then((result) => {
                      if (result?.data?.code === 200) {
                        console.log("Retraite by contrat id", result);
                        console.log(
                          "periodicite of ",
                          L[i].id,
                          "est : ",
                          result?.data?.data[0].periodicite
                        );
                        L[i].periodicite = result?.data?.data[0].periodicite;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  getContratEducation(L[i].id)
                    .then((result) => {
                      console.log("resultat de contrat education", result);
                      if (result?.data?.code === 200) {
                        console.log("Education by contrat id", result);
                        console.log(
                          "periodicite of ",
                          L[i].id,
                          "est : ",
                          result?.data?.data[0].periodicite
                        );
                        L[i].periodicite = result?.data?.data[0].periodicite;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                    getContratDeces(L[i].id)
                    .then((result) => {
                      console.log("resultat de contrat education", result);
                      if (result?.data?.code === 200) {
                        console.log("Education by contrat id", result);
                        console.log(
                          "periodicite of ",
                          L[i].id,
                          "est : ",
                          result?.data?.data[0].periodicite
                        );
                        L[i].periodicite = result?.data?.data[0].periodicite;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }

                console.log(L);
                getFactureByUser(id)
                  .then((result) => {
                    console.log("current");
                    if (result?.data?.code === 200) {
                      console.log("Facture of selected contrat", result);
                      var F = result?.data?.data;
                      // var periodicite = 0;
                      for (let i = 0; i < L.length; i++) {
                        for (let j = 0; j < F.length; j++) {
                          if (L[i].policy_code === F[j].num_facture) {
                            L[i].facture = F[j];
                            F[j].montant=formatCurrency(F[j].montant)
                          }
                        }
                      }
                      console.log("Contrats populated with factures", L);
                    }
                    setContrats(L);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const redirectToDetails = (id) => {
    history.push(`/cotisation_details/${idCategory}/${id}`);
  };
  return (
    <div className="container">
      <div className="shop-pagination pb-3">
        <div className="container mb-3">
          {contrats.map((contrat) => (
            <div
              className="card timeline-card"
              key={contrat.id}
              style={{ marginTop: "40px" }}
            >
              <>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    {/* <div className="timeline-text mb-2">
                      <span
                        className="badge mb-2 rounded-pill fz-10"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          height: "47px",
                          width: "180px",
                        }}
                      >
                        Souscripteur : {nom} {prenom}
                      </span>
                    </div> */}
                    <div className="d-flex justify-content-between">
                                  <div className="timeline-text mb-2">
                                    <span className="badge mb-2 rounded-pill fz-10">
                                    Souscripteur : {nom} {prenom}
                                    </span>
                                  </div>
                                </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                      <div>
                        <p className="fz-10">
                          <b>{t("Nom et Prénom(s) Assurée")} :</b>{" "}
                          {contrat?.assuree_id === null
                            ? nom + " " + prenom
                            : ASSUREE[contrat?.assuree_id] !== undefined &&
                              ASSUREE[contrat?.assuree_id][0]?.nom +
                                " " +
                                ASSUREE[contrat?.assuree_id][0]?.prenom}
                        </p>
                        <p className="fz-10">
                          <b>
                            <b>{t("Périodicité  ")} :</b>
                            {contrat.periodicite}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                      <div>
                        <p className="fz-10">
                          <b>{t("Durée ")} :</b> {contrat.duree}
                        </p>
                        <p className="fz-10">
                          <b>{t("Date dernière Cotisation ")} :</b>{" "}
                          {contrat.date_effet}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                      <div>
                        <p className="fz-10">
                          <b>{t("Montant Cotisation  ")} :</b>{" "}
                          {formatCurrency(contrat?.facture?.montant)}{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-success text-white fz-10 float-end"
                   
                    onClick={() => redirectToDetails(contrat.policy_code)}
                  >
                    Cotisation
                  </button>
                </div>
              </>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
