/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../Auth/useUser";
// # TRANSLATION LIBRARY
import { useTranslation } from "react-i18next";
import { getUser } from "../../../services/users/usersAPI";
import Loader from "../../../components/loading/Loader";
// # SEND AN OBJECT OF FORM DATA
import { useForm } from "react-hook-form";
import { getdetailsCategorie } from "../../../services/categories/CategoriesApi";
import { getAdressesOfUser } from "../../../services/adresses/AdresseAPI";
import { getPersonnelByID } from "../../../services/personnel/personnelAPI";
import _ from "lodash";
import { calculateAge } from "../../../utils/Comparaison";
import Paiement from "../components/paiement/Paiement";
import Step1 from "../steps/Step1";
import Step2 from "../steps/Step2";
import Step3 from "../steps/Step3";
import Step4 from "../steps/Step4";
import InvalidForm from "../steps/InvalidForm";
import FirstStep from "../steps/FirstStep";
import { getListeAssuree } from "../../../services/assuree/assureeAPI";
import moment from "moment";
import { error_message, warning_message } from "../../../components/alerte/AlerteMessage";
import { getCompagnyCategory } from "../../../services/assignCategorieCompagny/assignCCApi";
import NoCompagny from "../steps/NoCompagny";
import { getCoutPolice, getPrimeNette } from "../../../services/formules/FormulesAPI";
import { getParamTechByCategorie } from "../../../services/paramtech/paramTechAPI";

export default function FacturationProduit() {
  // Parametre id = id categorie
  const { id, title, type } = useParams("");
  //GET USER INFORMATIONS
  const user = useUser();
  const [valid, setvalid] = useState(true);
  var id_user = user?.result?.id; //id of connected user
  const [data, setdata] = useState({});
  const [categorie, setcategorie] = useState({});
  //---------------- VERIF DATA OBJECT
  const [empty, setEmpty] = useState(false); // Verif if initial get of data object (n_cin exist or not ), if data exist we update the date else we add a new object
  //------------- Assuree -----------------
  const [assuree, setassuree] = useState(true); // Assuree est le souscripteur si oui rien ne s'affiche sinon le formulaire d'assuree
  const [nbrAssuree, setnbrAssuree] = useState(0); // value of total nbr assurée
  const [LISTE_ASSUREE, setLISTE_ASSUREE] = useState([]); // Liste assurée
  // LISTE DES COMPAGNIES D'assurances 
  const [Liste_Compagny, setListe_Compagny] = useState([])
  //BENEFICIATIRES DU CONTRAT
  const [beneficiaire, setbeneficiaire] = useState("");
  const [autres, setautres] = useState("");
  //------------- ADRESSES of User --------
  const [ListeAdresses, setListeAdresses] = useState([]);
  const [groupedAdresses, setgroupedAdresses] = useState([]);
  // ALL ASSUREE OF CARNET ASSUREE OF USER
  const [ListeAssuree, setListeAssuree] = useState([]); // LISTE DES ASSURES
  const [groupedAssuree, setgroupedAssuree] = useState([])
  // ------------ LOADER ------------------
  const [Loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  // ---------------USE FORM ELEMENTS ----------
  const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA
  
  // ---------------FORMULE voyages ---------
  const [cout_police, setcout_police] = useState([])
  const [prime_nette, setprime_nette] = useState([])
  const [paramTech, setparamTech] = useState([])

  //------------------ SHOWS FORMS -----------
  const [show,  setShow]   = useState(true);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  useEffect(() => {
    if(type==="voyage"){
      // Recuperer la formule de la prime nette
      getPrimeNette().then((result) => {
        if (result?.data?.code === 200) {
          // RECUPERER LA FORMULE DU COUT DE POLICE
          getCoutPolice()
          .then((res) => {
            if (result?.data?.code === 200) {
              setprime_nette(result?.data?.data)
              setcout_police(res?.data?.data)
              // Recuperer les PARAM TECHNIQUES 
              getParamTechByCategorie(id)
              .then((result) => {
                if (result?.data?.code === 200) {
                  setparamTech(result?.data?.data)
                }
              }).catch((err) => {
                console.log(err);
              });
            }})
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    getCompagnyCategory(id).then((result) => {
      if(result?.data?.code===200){
        var C = []
        result?.data?.data?.map((items)=>
        C.push(items.company))
        setListe_Compagny(_.uniqBy(C,"id"))
      }
    }).catch((err) => {
      console.log(err);
    });
    getAdressesOfUser(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListeAdresses(result?.data?.data);
          setgroupedAdresses(_.groupBy(result?.data?.data, "id"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getdetailsCategorie(id)
      .then((result) => {
        if (result?.data?.code === 200) {
          setcategorie(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getListeAssuree(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          setListeAssuree(result?.data?.data);
          setgroupedAssuree(_.groupBy(result?.data?.data,"id"))
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getUser(id_user)
      .then((result) => {
        if (result?.data?.code === 200) {
          var data = result?.data?.data;
          setdata(data);
          var { nom, prenom, email, mobile, date_naissance } = data;
          if (
            nom === null ||
            prenom === null ||
            date_naissance === null ||
            mobile === null ||
            email === null
          ) {
            setvalid(false);
          } else {
            setvalid(true);
            getPersonnelByID(id_user)
              .then((result) => {
                if (result?.data?.code === 200) {
                  if (result?.data?.data?.length === 0) {
                    setEmpty(true);
                  } else {
                    setEmpty(false);
                    var info = result?.data?.data[0];
                    Object.assign(data, {
                      n_cin: info?.n_cin,
                      n_passport: info?.n_passport,
                    });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      });
  }, []);

  // UPDATE DATA INTO FORM
  useEffect(() => {
    reset(data);
  }, [data]);
  
  const generateTabAssuree = (n)=>{
    var L= [];
    [...Array(parseInt(n))].map((elementInArray, index) => {
      if (type === "voyage") {
        L.push({
          assuree_id: "",
          validite_passeport_assuree: "",
        });
      } else if (type === "education") {
        L.push({
          assuree_id: "",
          periodicite: "",
          prime_commercial: 0,
          duree: "",
        });
      } else if (type === "accident") {
        L.push({
          assuree_id: "",
          duree: "",
          formule: "",
        });
      } else if (type === "vehicule" || type==="retraite") {
        L.push({
          assuree_id: "",
        });
      } else {
        L.push({
          assuree_id: "",
        });
      }
    });
    return L
  }
  /**
   * CREATE DATA NB assurance => CREATION TABLEAU DES CLIENTS ASSUREE
   * @param {*} e
   */
  const createDataNbAssuree = (e) => {
    if (!isNaN(e.target.value)) {
      setnbrAssuree(parseInt(e.target.value));
      var n = e.target.value;
      setLISTE_ASSUREE(      generateTabAssuree(n)
      );

    }
  };

  /**
   * onChange FIRST Form wizard
   * @param {*} e
   * @param {*} index
   */
  function onFormSubmitStep1(dataRes) {
    if (empty === false) {
      Object.assign(dataRes, {
        nom_assuree: data?.nom,
        prenom_assuree: data?.prenom,
        data_naissance_assuree: data?.date_naissance,
      });
    }
    Object.assign(data, dataRes);
    // VERIFIER L'age de l'assuré DANS LES DEUX CAS
    //SOUSCRIPTEURS OU LISTE DES ASSUREES
    if (assuree) {
      if (  beneficiaire === "Mon père" ||  beneficiaire === "Ma mère" ||  beneficiaire === "Mon conjoint") {
        if (nbrAssuree > 1) {
        warning_message(t("Le nombre d'assurés selon le bénéficiaire  sera supérieur ou égale a 1 "))
          return 0;
        }
      }
      var age = calculateAge(new Date(data?.date_naissance));
      if (type === "education") {
        if (parseInt(age) > 0 && parseInt(age) <= 20) {
          setShow1(!show1);
          setShow2(true);
        } else {
          error_message( t("L'age de l'assuré n'est pas pris en charge"))
        }
      } else if (type === "accident") {
        if (parseInt(age) >= 18 && parseInt(age) <= 65) {
          setShow1(!show1);
          setShow2(true);
        } else {
          error_message(t("L'age de l'assuré n'est pas pris en charge"))

        }
      } else if (type === "voyage") {
        if (parseInt(age) >= 18 && parseInt(age) <= 80) {
          setShow1(!show1);
          setShow2(true);
        } else {
          error_message(t("L'age de l'assuré n'est pas pris en charge"))
        }
        Object.assign(data, {
          pays_depart: "Senegal",
        });
      } else if (type === "vehicule") {
        if (parseInt(age) >= 18 && parseInt(age) <= 80) {
          setShow1(!show1);
          setShow2(true);
        } else {
          error_message(t("L'age de l'assuré n'est pas pris en charge"))
        }
      }else if (type === "retraite" || type === "deces") {
        setShow1(!show1);
        setShow2(true);
      }
    } 
     else {
      Object.assign(data, {
        beneficiaire_contrat: beneficiaire === "autres" ? autres : beneficiaire,
      });

      var ok = true;
      //Verifier l'age des assurees
      LISTE_ASSUREE?.map((items) => {
        if (type === "education") {
          var date_naissance = groupedAssuree[items?.assuree_id][0]?.date_naissance
          if (
            parseInt(calculateAge(new Date(date_naissance))) <
              0 ||
            parseInt(calculateAge(new Date(date_naissance))) > 20
          ) {
            ok = false;
            error_message(t( "L'age de l'assuré ")+groupedAssuree[items?.assuree_id][0]?.nom +
            " " + groupedAssuree[items?.assuree_id][0]?.prenom + t(" n'est pas pris en charge"))
          }
        } else if (type === "accident") {
          if (
            parseInt(calculateAge(new Date(date_naissance))) <
              18 ||
            parseInt(calculateAge(new Date(date_naissance))) > 65
          ) {
            ok = false;
            error_message(t( "L'age de l'assuré ")+groupedAssuree[items?.assuree_id][0]?.nom +
            " " + groupedAssuree[items?.assuree_id][0]?.prenom + t(" n'est pas pris en charge"))
          }
        } else if (type === "voyage") {
          if (
            parseInt(calculateAge(new Date(date_naissance))) <
              0 ||
            parseInt(calculateAge(new Date(date_naissance))) > 80
          ) {
            ok = false;
            error_message(t( "L'age de l'assuré ")+groupedAssuree[items?.assuree_id][0]?.nom +
            " " + groupedAssuree[items?.assuree_id][0]?.prenom + t(" n'est pas pris en charge"))
          }
        } else {
          if (
            parseInt(calculateAge(new Date(date_naissance))) <
              0 ||
            parseInt(calculateAge(new Date(date_naissance))) > 80
          ) {
            ok = false;
            error_message(t( "L'age de l'assuré ")+groupedAssuree[items?.assuree_id][0]?.nom +
            " " + groupedAssuree[items?.assuree_id][0]?.prenom + t(" n'est pas pris en charge"))
          }
        }
      });
      if (ok) {
        setShow1(!show1);
        setShow2(true);
      }
    }
  }

  /**
   * onChange Second Form wizard
   * @param {*} e
   * @param {*} index
   */
  function onFormSubmitStep2(dataRes) {
    var today = new Date();
   if(type==="retraite" && dataRes?.duree_contrat <5 ){
    warning_message(t("La durée du contrat doit aller au-delà de 5 ans."))
    } else if (type === "voyage" && dataRes?.etat === "option1") {
      const date = new Date(dataRes?.date_effet.valueOf());
      date.setDate(date.getDate() + parseInt(dataRes?.nbr_jours));
      Object.assign(data, {
        date_depart: dataRes.date_effet,
        date_retour: date,
      });
      Object.assign(data, dataRes);
      setShow2(!show2);
      setShow3(true);
    } else if (moment(new Date(dataRes?.date_effet)).format("YYYY-MM-DD") >= moment(today).format("YYYY-MM-DD")) {
      Object.assign(data, dataRes);
      setShow2(!show2);
      setShow3(true); 
      
    } else {
      warning_message(t("La date d'effet du contrat doit être supérieure ou égale à la date d'aujourd'hui!"))
    }
  }

  /**
   * onChange Third Form Wizard
   * @param {*} dataRes
   */
  function onFormSubmitStep3(dataRes) {
    Object.assign(data, dataRes);
    setShow4(!show4);
    setShow5(true);
  }
  /**
   * Change informations of assurees
   * @param {*} e
   * @param {*} index
   */
  const handleChangeAssuree = (e, index) => {
    const newArr = LISTE_ASSUREE.map((item, i) => {
      if (index === i) {
        if(e.target.name==="prime_commercial"){
          const prime = e.target.value.replace(/\s+/g, '')
          return { ...item, [e.target.name]: prime };
        }
        else{
          return { ...item, [e.target.name]: e.target.value };
        }
      } else {
        return item;
      }
    });
    setLISTE_ASSUREE(newArr);
  };
  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div>
          <div className="custom-container">
            <div className=" mt-4">
              {valid && !empty ? (
                <div className="row">
                  <div className="col-12">
                    <div>
                      <h3 className="mb-3 text-center fz-18">{title}</h3>
                      <div>
                        <form onSubmit={handleSubmit(onFormSubmitStep1)}>
                          {/** INFORMATIONS SOUSCRIPTEUR & Assuree */}
                          {show && (
                            Liste_Compagny?.length === 0 ?
                             <>
                            <NoCompagny title={title} t={t}/>
                            </>: 
                            <FirstStep
                              setassuree={setassuree}
                              setShow1={setShow1}
                              setShow={setShow}
                              type={type}
                              id_user={id_user}
                              createDataNbAssuree={createDataNbAssuree}
                              register={register} 
                              nbrAssuree={console.log(nbrAssuree)}
                              setnbrAssuree={setnbrAssuree}
                              generateTabAssuree={generateTabAssuree}
                              t={t}
                              setLISTE_ASSUREE={setLISTE_ASSUREE}
                            />
                          )}

                          {show1 && (
                            <>
                              <Step1
                                register={register}
                                t={t}
                                ListeAssuree={ListeAssuree}
                                data={data}
                                ListeAdresses={ListeAdresses}
                                assuree={assuree}
                                setassuree={setassuree}
                                setautres={setautres}
                                autres={autres}
                                beneficiaire={beneficiaire}
                                setbeneficiaire={setbeneficiaire}
                                LISTE_ASSUREE={LISTE_ASSUREE}
                                nbrAssuree={nbrAssuree}
                                createDataNbAssuree={createDataNbAssuree}
                                handleChangeAssuree={handleChangeAssuree}
                                type={type}
                                setShow1={setShow1} 
                                show1={show1} 
                                setShow={setShow}
                              />
                            </>
                          )}
                        </form>
                        <form onSubmit={handleSubmit(onFormSubmitStep2)}>
                          {show2 && (
                            <>
                              <Step2
                                register={register}
                                t={t}
                                groupedAssuree={groupedAssuree}
                                data={data}
                                type={type}
                                watch={watch}
                                handleChangeAssuree={handleChangeAssuree}
                                LISTE_ASSUREE={LISTE_ASSUREE}
                                show2={show2}
                                setShow2={setShow2}
                                setShow1={setShow1}
                                assuree={assuree}
                              />
                            </>
                          )}
                        </form>
                        {show3 && (
                          <>
                            <Step3
                              Loading={Loading}
                              t={t}
                              data={data}
                              groupedAssuree={groupedAssuree}
                              id={id}
                              groupedAdresses={groupedAdresses}
                              type={type}
                              setLISTE_ASSUREE={setLISTE_ASSUREE}
                              LISTE_ASSUREE={LISTE_ASSUREE}
                              show3={show3}
                              setShow3={setShow3}
                              setShow2={setShow2}
                              setShow4={setShow4}
                              cout_police={cout_police}
                              prime_nette={prime_nette}
                              paramTech={paramTech}
                            />
                          </>
                        )}
                        {show4 && (
                          <form onSubmit={handleSubmit(onFormSubmitStep3)}>
                            <Step4
                            Liste_Compagny={Liste_Compagny}
                              t={t}
                              register={register}
                              id={id}
                              setShow4={setShow4}
                              setShow3={setShow3}
                              show4={show4}
                            />
                          </form>
                        )}
                        {show5 && (
                          <>
                            <Paiement
                              id={id}
                              handleSubmit={handleSubmit}
                              register={register}
                              data={data}
                              watch={watch}
                              groupedAdresses={groupedAdresses}
                              LISTE_ASSUREE={LISTE_ASSUREE}
                              type={type}
                              t={t}

                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <InvalidForm t={t} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
