/* eslint-disable jsx-a11y/heading-has-content */
import moment from "moment";
import { calculateAge } from "../../../../utils/Comparaison";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import ResumVoyage from "../voyage/ResumVoyage";

export default function InformationContrat({  t,  data,  type,  LISTE_ASSUREE}) {
  var age = calculateAge(new Date(data?.date_naissance));
  return (
    <div>
      <div className="shop-pagination pb-1">
        <div className="d-flex align-items-center justify-content-between">
          <small className="mb-3 fz-14">{t("Informations Contrat")}</small>
        </div>
        <div className="form-group mb-3">
          <label className="mb-2 fz-12">{t("Date Effet")}</label>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="bi bi-calendar-event text-purple" />
            </span>
            <input
              className="form-control fz-12"
              type="date"
              disabled
              value={moment(data?.date_effet).format("YYYY-MM-DD")}
            />
          </div>
        </div>
        {type === "vehicule" && (
          <div className="form-group mb-3">
            <label className="mb-2 fz-12"> {t("Durée du contrat")} </label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-card-text text-purple" />
              </span>
              <input
                className="form-control fz-12"
                type="text"
                disabled
                value={data?.duree==="1" ? "1 Mois" :data?.duree==="3" ? "3 Mois" : data?.duree==="6"? "6 Mois" : "12 Mois"}
              />
            </div>
          </div>
        )}
        {(type === "retraite" || type === "deces" || type === "education") && (
          <div className="form-group mb-3">
            <label className="mb-2 fz-12">{t("Béneficiaire au terme")}</label>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <i className="bi bi-hand-thumbs-up-fill text-purple" />
              </span>
              <input
                className="form-control fz-12"
                type="text"
                disabled
                value={
                  data?.beneficiaire_contrat === undefined
                    ? data?.nom + " " + data?.prenom
                    : data?.beneficiaire_contrat
                }
              />
            </div>
          </div>
        )}
        {type === "retraite" && (
          <>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("Prime Commerciale")}</label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.prime_commercial}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12"> {t("Durée contrat (année)")} </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.duree_contrat}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2 fz-12">{t("Periodicité")}</label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={
                    data?.periodicite === "1"
                      ? t("Annuelle")
                      : data?.periodicite === "2"
                      ? t("Semestrielle")
                      : data?.periodicite === "4"
                      ? t("Trimistrielle")
                      : data?.periodicite === "12"
                      ? t("Mensuelle")
                      : 0
                  }
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Durée de service de la rente (annee)")}
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.durre_rente}
                />
              </div>
            </div>
          </>
        )}
        {type==="deces" && <>
        <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Coût de la police")}
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.cout_police}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Taux bancaire annuel")}
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.taux_bancaire+" %"}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
                {t("Capital")}
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.capital}
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label className="form-label fz-12">
              {t("Durée du contrat")}
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="bi bi-hourglass-split text-purple" />
                </span>
                <input
                  className="form-control fz-12"
                  type="text"
                  disabled
                  value={data?.cotisation}
                />
              </div>
            </div>

        </>}
        {LISTE_ASSUREE?.length === 0 && (
          <>
            <h5 className="product-title d-block text-truncate mt-0 mb-3"></h5>

            {type === "education" && (
              <>
                <div className="form-group mb-3">
                  <label className="mb-2 fz-12">
                    {t("Durée contrat (année)")} 
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="bi bi-hourglass-split text-purple" />
                    </span>
                    <input
                      className="form-control fz-12"
                      type="text"
                      disabled
                      value={25 - parseInt(age)}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-2 fz-12">
                    {t("Durée de la rente (année)")}
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="bi bi-hourglass-split text-purple" />
                    </span>
                    <input
                      className="form-control fz-12"
                      type="text"
                      disabled
                      value={data?.duree}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="mb-2 fz-12">{t("Prime Commerciale")}</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="bi bi-cash-coin text-purple" />
                    </span>
                    <input
                      className="form-control fz-12"
                      type="text"
                      disabled
                      value={numberWithSpaces(data?.prime_commercial)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {type === "voyage" && (
          <ResumVoyage LISTE_ASSUREE={LISTE_ASSUREE} t={t} data={data} />
        )}
      </div>
    </div>
  );
}
