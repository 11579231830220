import { Fragment, useEffect, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { numberWithSpaces } from "../../../utils/SessionUtiles";
import {
  findById,
  verifCodePromo,
} from "../../../services/promotions/promotionsAPI";
import accounting from "accounting"
import moment from "moment";

const borderColor = "#fff";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  text: {
    fontFamily: "Arial",
    fontStyle: "bold",
  },
  description: {
    width: "100%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontFamily: "Arial",
    fontStyle: "normal",
    fontSize: 10,
  },
});
export default function Contrat({
  t,
  type,
  data,
  user_details,
  personnels,
  info,
}) {
  const [code, setCode] = useState("");
  const id = data?.id;
  const africanCurrencyOptions = {
    symbol: "", // Set the currency symbol
    decimal: ".", // Set the decimal separator
    thousand: " ", // Set the thousand separator
    precision: 0, // Set the number of decimal places
    format: "%v %s", // Set the format pattern
  };
// Function to format a given value with the African currency options
const formatCurrency = (value) => {
return accounting.formatMoney(value, africanCurrencyOptions);
};
  const rows = (
    <>
      {type === "education" && (
        <>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Prime")} : </Text>
              {formatCurrency(parseFloat(data?.education?.prime_commercial))} FCFA{" "}
            </Text>
          </View>
          {data?.education?.remise !== 0 && data?.education?.remise !== null && (
            <View style={styles.row}>
              <Text style={styles.description}>
                <Text style={styles.text}>{t("Remise")} : </Text>-
                {formatCurrency(parseFloat(data?.education?.remise))} FCFA{" "}
              </Text>
            </View>
          )}
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Capital garanti")} : </Text>
              {formatCurrency(parseFloat(data?.education?.capital_acquis))} FCFA
            </Text>
          </View>
        </>
      )}
      {type === "deces" && (
        <>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Frais d'acquisation")} :</Text>{" "}
              {numberWithSpaces(Math.round(info?.frais_acquisation))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Frais de gestion")} : </Text>{" "}
              {numberWithSpaces(Math.round(info?.frais_gestion))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Prime Annuelle")} :</Text>{" "}
              {numberWithSpaces(Math.round(info?.prime_annuelle))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Prime Commerciale")} :</Text>{" "}
              {numberWithSpaces(Math.round(info?.prime_commercial))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Prime pure")} :</Text>
              {formatCurrency(Math.round(info?.prime_pure))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Taux Bancaire")} :</Text>{" "}
              {info?.taux_bancaire} (%)
            </Text>
          </View>
        </>
      )}
      {type === "accident" && (
        <>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Prime Nette")} :</Text>
              {formatCurrency(Math.round(info?.total_ht))} FCFA
            </Text>
          </View>
          {info?.remise !== 0 && info?.remise !== null && (
            <View style={styles.row}>
              <Text style={styles.description}>
                <Text style={styles.text}>{t("Remise")} : </Text>-
                {formatCurrency(parseFloat(info?.remise))} FCFA{" "}
              </Text>
            </View>
          )}
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Accessoires")} :</Text>
              {2000} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Taxe")} :</Text>{" "}
              {formatCurrency(Math.round(info?.taxe))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Prime TTC")} :</Text>{" "}
              {numberWithSpaces(Math.round(info?.prime_ttc))} FCFA
            </Text>
          </View>
        </>
      )}

      {type === "retraite" && (
        <>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Prime Commerciale")} : </Text>{" "}
              {numberWithSpaces(Math.round(info?.prime_commercial))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> {t("Capital Garantie")} : </Text>{" "}
              {numberWithSpaces(Math.round(info?.capital_garantie))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>{t("Total Rente")} :</Text>{" "}
              {numberWithSpaces(Math.round(info?.total_rente))} FCFA
            </Text>
          </View>
        </>
      )}
      {type === "voyage" && (
        <>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> Prime Nette : </Text>
              {numberWithSpaces(Math.round(data?.prime_nette))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}> Coût de police: </Text>{" "}
              {numberWithSpaces(Math.round(data?.cout_police))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>Taxe :</Text>{" "}
              {numberWithSpaces(Math.round(data?.taxe))} FCFA
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>Prime TTC :</Text>
              {numberWithSpaces(Math.round(data?.prime_ttc))} FCFA
            </Text>
          </View>
        </>
      )}
      {type === "vehicule" && (
        <>
          <View style={styles.row}>
            <Text style={styles.description}>
              <Text style={styles.text}>Prime TTC : </Text>{" "}
              {numberWithSpaces(Math.round(data?.auto?.prime_ttc))} FCFA
            </Text>
          </View>
        </>
      )}
    </>
  );
  return <Fragment>{rows}</Fragment>;
}
