import dotenv from "dotenv";
//#loads environment variables from a . env file into the process. env object
dotenv.config();

//GET URL FROM ENVIRONEMENT
const { REACT_APP_API_URL ,GOOGLE_REACT_ID ,REACT_PAY_TECH_URL} = process.env;

/**
 * CREATE A SIMPLE CONFIGURATION
 * @returns url of api
 */
export function getUrl() {
  //GET URL OF REACT APP
  const url = REACT_APP_API_URL;
  return url;
}

/**
 * CREATE SIMPLE CONFIGURATION OF AUTH
 * @returns url of api
 */
export function getAuthUrl() {
  //GET URL OF REACT APP
  const url = REACT_APP_API_URL;
  return url;
}

/**
 * GET GOOGLE KEY 
 * @returns 
 */
export function getGoogleKey (){
  const key = GOOGLE_REACT_ID
  return key
}  

/**
 * GET GOOGLE KEY 
 * @returns 
 */
export function getPaiementURL (){
  const key = REACT_PAY_TECH_URL
  return key
}  

