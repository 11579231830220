import React from "react";
import Icon from "../../components/obligatoire/Icon";

export default function FormAssuree({ register, t }) {
  return (
    <div>
      <div className="form-group">
        <label className="form-label fz-12">
          {t("Nom")} <Icon />
        </label>
        <input
          className="form-control fz-12"
          placeholder="Nom de famille ... "
          type="text"
          required
          {...register("nom")}
        />
      </div>
      <div className="form-group">
        <label className="form-label fz-12">
          {t("Prénom")} <Icon />
        </label>
        <input
          className="form-control fz-12"
          placeholder="Prénom  ... "
          type="text"
          required
          {...register("prenom")}
        />
      </div>
      <div className="form-group">
        <label className="form-label fz-12">
          {t("Date de Naissance")} <Icon />
        </label>
        <input
          className="form-control fz-12"
          placeholder="dd/mm/yyyy"
          type="date"
          required
          {...register("date_naissance")}
        />
      </div>
      <div className="form-group">
        <label className="form-label fz-12">
          {t("Lieu de la date de naissance")} <Icon />
        </label>
        <input
          className="form-control fz-12"
          placeholder={t("Lieu de la date de naissance") + "..."}
          type="text"
          required
          {...register("lieu_naissance")}
        />
      </div>
      <div className="form-group">
        <label className="form-label fz-12">{t("N° CIN")}</label>
        <input
          className="form-control fz-12"
          placeholder={t("N° CIN") + "... "}
          type="text"
          {...register("cin")}
        />
      </div>
      <div className="form-group">
        <label className="form-label fz-12">{t("N° Passeport")}</label>
        <input
          className="form-control fz-12"
          placeholder={t("N° Passeport") + "..."}
          type="text"
          {...register("passport")}
        />
      </div>
      <div className="form-group">
        <label className="form-label fz-12">{t("Validité Passeport")}</label>
        <input
          className="form-control fz-12"
          type="date"
          placeholder="dd/mm/yyyy"
          {...register("validite_passeport")}
        />
      </div>
    </div>
  );
}
