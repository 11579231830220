import React from 'react'

export default function ModalLanguages({ t, changeLanguage, lg }) {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('Settings')}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="single-plan-check shadow-sm active-effect">
              <div className="form-check mb-0">
                <input
                  className="form-check-input"
                  onChange={() => changeLanguage('en')}
                  type="radio"
                  name="languageRadio"
                  id="English"
                  defaultChecked={lg !== 'Français' ? true : false}
                />
                <label className="form-check-label" htmlFor="English">
                  English
                </label>
              </div>
              <svg
                id="svg6800"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50.54 26.68"
                width={28}
                height={28}
              >
                <g id="layer4">
                  <rect
                    id="rect8767"
                    x="0.09"
                    y="24.32"
                    width="50.24"
                    height="2.12"
                    style={{ fill: '#bf0a30' }}
                  />
                  <rect
                    id="rect8769"
                    x="0.09"
                    y="22.2"
                    width="50.24"
                    height="2.12"
                    style={{ fill: '#fff' }}
                  />
                  <rect
                    id="rect8771"
                    x="0.09"
                    y="20.08"
                    width="50.24"
                    height="2.12"
                    style={{ fill: '#bf0a30' }}
                  />
                  <rect
                    id="rect8773"
                    x="0.09"
                    y="17.96"
                    width="50.24"
                    height="2.12"
                    style={{ fill: '#fff' }}
                  />
                  <rect
                    id="rect8775"
                    x="0.09"
                    y="15.97"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#bf0a30' }}
                  />
                  <rect
                    id="rect8777"
                    x="0.09"
                    y="13.99"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#fff' }}
                  />
                  <rect
                    id="rect8779"
                    x="0.09"
                    y={12}
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#bf0a30' }}
                  />
                  <rect
                    id="rect8781"
                    x="0.09"
                    y="10.01"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#fff' }}
                  />
                  <rect
                    id="rect8783"
                    x="0.09"
                    y="8.02"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#bf0a30' }}
                  />
                  <rect
                    id="rect8785"
                    x="0.09"
                    y="6.03"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#fff' }}
                  />
                  <rect
                    id="rect8787"
                    x="0.09"
                    y="4.04"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#bf0a30' }}
                  />
                  <rect
                    id="rect8791"
                    x="0.09"
                    y="2.06"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#fff' }}
                  />
                  <rect
                    id="rect8793"
                    x="0.09"
                    y="0.07"
                    width="50.24"
                    height="1.99"
                    style={{ fill: '#bf0a30' }}
                  />
                </g>
                <g id="layer5">
                  <rect
                    id="rect8796"
                    x="0.09"
                    y="0.07"
                    width="20.15"
                    height="13.92"
                    style={{ fill: '#002868' }}
                  />
                </g>
                <g id="layer6">
                  <path
                    id="path8825"
                    d="M94.76,63.34l.58-.06.22-.57.24.56.59,0-.44.41.14.59-.51-.3-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8827"
                    d="M98.07,63.34l.59-.06.22-.57.24.56.58,0-.44.41.14.59-.5-.3-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8829"
                    d="M101.52,63.34l.58-.06.22-.57.24.56.59,0-.44.41.14.59-.51-.3-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8831"
                    d="M104.83,63.34l.59-.06.22-.57.24.56.58,0-.44.41.14.59-.5-.3-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8833"
                    d="M108.15,63.34l.58-.06.22-.57.24.56.59,0-.44.41.14.59L109,64l-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8835"
                    d="M111.59,63.34l.59-.06.22-.57.24.56.58,0-.43.41.13.59-.5-.3-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8837"
                    d="M96.48,62l.58-.06.23-.57.24.56.58.05-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8839"
                    d="M94.76,60.56l.58-.07.22-.57.24.57.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8841"
                    d="M96.48,59.23l.58-.06.23-.57.24.56.58.05-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8843"
                    d="M94.76,57.77l.58-.06.22-.57.24.56.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8845"
                    d="M96.48,56.45l.58-.07.23-.56.24.56.58,0-.44.41.14.6-.51-.31-.5.33.13-.61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8847"
                    d="M94.76,55l.58-.06.22-.57.24.56.59,0-.44.41.14.59-.51-.31-.5.33.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8849"
                    d="M96.48,53.66l.58-.06.23-.57.24.56.58,0-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8851"
                    d="M94.76,52.21l.58-.07.22-.57.24.57.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8853"
                    d="M99.79,62l.59-.06.22-.57.24.56.58.05-.43.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8855"
                    d="M98.07,60.56l.59-.07.22-.57.24.57.58,0-.44.41.14.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8857"
                    d="M99.79,59.23l.59-.06.22-.57.24.56.58.05-.43.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8859"
                    d="M98.07,57.77l.59-.06.22-.57.24.56.58,0-.44.41.14.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8861"
                    d="M99.79,56.45l.59-.07.22-.56.24.56.58,0-.43.41.14.6-.51-.31-.5.33.12-.61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8863"
                    d="M98.07,55l.59-.06.22-.57.24.56.58,0-.44.41.14.59-.5-.31-.5.33.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8865"
                    d="M99.79,53.66l.59-.06.22-.57.24.56.58,0-.43.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8867"
                    d="M98.07,52.21l.59-.07.22-.57.24.57.58,0-.44.41.14.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8869"
                    d="M103.11,62l.58-.06.23-.57.23.56.59.05-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8871"
                    d="M101.52,60.56l.58-.07.22-.57.24.57.59,0-.44.41.14.6-.51-.31-.5.32L102,61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8873"
                    d="M108.15,60.56l.58-.07.22-.57.24.57.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8875"
                    d="M104.83,60.56l.59-.07.22-.57.24.57.58,0-.44.41.14.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8877"
                    d="M109.87,62l.58-.06.23-.57.23.56.59.05-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8879"
                    d="M106.55,62l.59-.06.22-.57.24.56.59.05-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8881"
                    d="M101.52,52.21l.58-.07.22-.57.24.57.59,0-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8883"
                    d="M103.11,53.66l.58-.06.23-.57.23.56.59,0-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8885"
                    d="M101.52,55l.58-.06.22-.57.24.56.59,0-.44.41.14.59-.51-.31-.5.33.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8887"
                    d="M103.11,56.45l.58-.07.23-.56.23.56.59,0-.44.41.14.6-.51-.31-.5.33.13-.61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8889"
                    d="M101.52,57.77l.58-.06.22-.57.24.56.59,0-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8891"
                    d="M103.11,59.23l.58-.06.23-.57.23.56.59.05-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8893"
                    d="M111.59,60.56l.59-.07.22-.57.24.57.58,0-.43.41.13.6-.5-.31-.5.32L112,61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8895"
                    d="M104.83,57.77l.59-.06.22-.57.24.56.58,0-.44.41.14.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8897"
                    d="M104.83,52.21l.59-.07.22-.57.24.57.58,0-.44.41.14.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8899"
                    d="M104.83,55l.59-.06.22-.57.24.56.58,0-.44.41.14.59-.5-.31-.5.33.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8901"
                    d="M109.87,59.23l.58-.06.23-.57.23.56.59.05-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8903"
                    d="M106.55,59.23l.59-.06.22-.57.24.56.59.05-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8905"
                    d="M106.55,53.66l.59-.06.22-.57.24.56.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8907"
                    d="M106.55,56.45l.59-.07.22-.56.24.56.59,0-.44.41.14.6-.51-.31-.5.33.12-.61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8909"
                    d="M111.59,57.77l.59-.06.22-.57.24.56.58,0-.43.41.13.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8911"
                    d="M108.15,57.77l.58-.06.22-.57.24.56.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8913"
                    d="M108.15,52.21l.58-.07.22-.57.24.57.59,0-.44.41.14.6-.51-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8915"
                    d="M108.15,55l.58-.06.22-.57.24.56.59,0-.44.41.14.59-.51-.31-.5.33.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8917"
                    d="M111.59,52.21l.59-.07.22-.57.24.57.58,0-.43.41.13.6-.5-.31-.5.32.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8919"
                    d="M109.87,53.66l.58-.06.23-.57.23.56.59,0-.44.41.14.6-.51-.31-.5.32.13-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8921"
                    d="M111.59,55l.59-.06.22-.57.24.56.58,0-.43.41.13.59-.5-.31-.5.33.12-.6Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                  <path
                    id="path8923"
                    d="M109.87,56.45l.58-.07.23-.56.23.56.59,0-.44.41.14.6-.51-.31-.5.33.13-.61Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff', fillRule: 'evenodd' }}
                  />
                </g>
                <g id="layer7">
                  <path
                    id="path3835"
                    d="M93.86,77.5l50.5-26.33-.11,26.39Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff' }}
                  />
                </g>
                <g id="layer8">
                  <path
                    id="path3839"
                    d="M123.15,77.47l0-9.63,21,0,0,9.59Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#00247d' }}
                  />
                  <path
                    id="path3843"
                    d="M144.16,51.12l-17.1,9.05h17.07Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#00247d' }}
                  />
                  <path
                    id="path3845"
                    d="M93.92,77.47l18.76-9.77,2.62,0-.06,9.77Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#00247d' }}
                  />
                </g>
                <g id="layer9">
                  <path
                    id="path3847"
                    d="M144.27,74.82l-.06,2.59-4.39.27-17.23-9,.19-1.26,7.65.21Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff' }}
                  />
                  <path
                    id="path3855"
                    d="M144.3,51l-17.69,9.41,5.48.12,12.13-6.44Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff' }}
                  />
                  <path
                    id="path3859"
                    d="M93.86,77.38l19.69-10.12,2,.08v1.92l-15.46,8.18Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#fff' }}
                  />
                  <path
                    id="path4355"
                    d="M93.83,77.41l19.42-10h2l-.06,1-17.16,9Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#cf142b' }}
                  />
                  <path
                    id="path4357"
                    d="M125.48,67.55l3.82.12,14.89,8-.06,1.72Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#cf142b' }}
                  />
                </g>
                <g id="layer3">
                  <path
                    id="path3058"
                    d="M144.15,66.4l-29,0,9.62-4.93,19.4,0Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#cf142b' }}
                  />
                  <path
                    id="path3060"
                    d="M121.65,77.38l.06-14.25-5,2.53V77.44Z"
                    transform="translate(-93.83 -51)"
                    style={{ fill: '#cf142b' }}
                  />
                </g>
                <g id="layer1">
                  <rect
                    id="rect8764"
                    x="0.09"
                    y="0.07"
                    width="50.24"
                    height="26.38"
                    style={{ fill: 'none' }}
                  />
                </g>
              </svg>
            </div>
            <div className="single-plan-check shadow-sm active-effect">
              <div className="form-check mb-0">
                <input
                  className="form-check-input"
                  onChange={() => changeLanguage('fr')}
                  type="radio"
                  name="languageRadio"
                  id="Français"
                  defaultChecked={lg === 'Français' ? true : false}
                />
                <label className="form-check-label" htmlFor="Français">
                  Français
                </label>
              </div>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="-10 -10 3020 2020"
              >
                <g id="French_Flag_by_Adam_Stanislav">
                  <title>Flag of France, by Adam Stanislav</title>
                  <rect
                    fill="rgb(0%,14%,58%)"
                    x={0}
                    y={0}
                    width={1010}
                    height={2000}
                  />
                  <rect
                    fill="rgb(97%,97%,97%)"
                    x={1000}
                    y={0}
                    width={1010}
                    height={2000}
                  />
                  <rect
                    fill="rgb(93%,16%,22%)"
                    x={2000}
                    y={0}
                    width={1000}
                    height={2000}
                  />
                  <rect
                    fill="none"
                    stroke="rgb(55%,55%,55%)"
                    strokeWidth={10}
                    x={0}
                    y={0}
                    width={3000}
                    height={2000}
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
