import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import {
  getContratAccident,
  getContratAutos,
  getContratByUser,
} from "../../services/contrats/ContratApi";
import { useUser } from "../../Auth/useUser";
import { getUser } from "../../services/users/usersAPI";
import "./Renouvellement.css";
import { useHistory } from "react-router-dom";
import { getFormuleAccident } from "../../services/formules/FormulesAPI";
import { t } from "i18next";
import { set } from "react-hook-form";
import accounting from "accounting"
export default function Renouvellement() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [data, setData] = useState([]);
  const [accidents, setAccidents] = useState([]);
  const [automobile, setAutomobiles] = useState([]);
  const [automobileDetailsArray, setAutomobilesDetailsArray] = useState([]);
  const [accidentDetailsArray, setAccidentDetailsArray] = useState([]);
  const [FormuleAccident, setFormuleAccident] = useState([]);

  const [showCodePIN, setshowCodePIN] = useState(false); // default state is false
  const [accordion1Open, setAccordion1Open] = useState(false);
  const [accordion2Open, setAccordion2Open] = useState(false);
  const africanCurrencyOptions = {
    symbol: 'FCFA', // Set the currency symbol
    decimal: '.', // Set the decimal separator
    thousand: ' ', // Set the thousand separator
    precision: '', // Set the number of decimal places
    format: '%v %s', // Set the format pattern
  };
  const formatCurrency = (value) => {
    return accounting.formatMoney(value, africanCurrencyOptions);
  };
  const user = useUser();
  var { id, nom, prenom, gender } = user.result;
  console.log("user id : ", id);
  useEffect(() => {
    getUser(id)
      .then((result) => {
        console.log("result of user", result);
        if (result?.data?.code === 200) {
          var obj = result?.data?.data;
          if (obj?.gestion_contrat_code_pin === 1) {
            setshowCodePIN(true);
          }
          getContratByUser(id)
            .then((result) => {
              if (result?.data?.code === 200) {
                console.log(
                  "liste des contrats by this connected user:",
                  result
                );

                setData(result?.data?.data);
                getFormuleAccident(id).then((result) => {
                  if (result?.data?.code === 200) {
                    setFormuleAccident(result?.data?.data);
                    console.log("FormuleAccident", result?.data?.data);
                  }
                });
                var L = result?.data?.data?.filter(
                  (el) => el.category_id === 8
                );
                var N = result?.data?.data?.filter(
                  (el) => el.category_id === 4
                );
                console.log("Contrats accident:", L);
                console.log("Contrats Auto:", N);
                console.log(data);
                var finalResults = []
                setAccidents(L);
                for (let i = 0; i < L.length; i++) {
                  getContratAccident(L[i].id)
                    .then((result) => {
                      if (result?.data?.code === 200) {
                        setAccidentDetailsArray(result?.data?.data);
                       
                        console.log("details by contrat accident ", result);
                      
                        var results = result.data.data;
                        console.log("result",results)
                        for (let j = 0; j < results.length; j++) {
                          results[j].beneficiaire = L[i].beneficiaire;
                          results[j].prime_ttc=formatCurrency(results[j].prime_ttc)
                         
                          var date_effet = new Date(L[i].date_effet);
                          console.log(
                            "date effet index",
                            j,
                            "est: ",
                            date_effet
                          );
                          var duree = results[j].duree;
                          results[j].contrat = L[i]
                          console.log("duree index", j, "est: ", duree);
                          var echeance = date_effet.setMonth(
                            date_effet.getMonth() + duree
                          );
                          var echeanceInDateFormat = new Date(echeance)

                          console.log(echeanceInDateFormat);
                          // Get the updated date components
                          var year = echeanceInDateFormat.getFullYear();
                          var month = echeanceInDateFormat.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
                          var day = echeanceInDateFormat.getDate();
                          console.log('better date format : ', day, month, year);
                          // Format the date as a string
                          var formattedDate =
                            year +
                            "-" +
                            month.toString().padStart(2, "0") +
                            "-" +
                            day.toString().padStart(2, "0");

                          results[j].echeance = formattedDate;
                          console.log(formattedDate);
                          console.log('populated result', results[j]);
                          finalResults.push(results[j])
                          console.log('final populated results are:', finalResults);
                        }
                        setAccidentDetailsArray(finalResults);
                        console.log(accidentDetailsArray);
                        console.log(results);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }

                setAutomobiles(N);
                for (let i = 0; i < N.length; i++) {
                  getContratAutos(N[i].id)
                    .then((result) => {
                      console.log("result contrat auto",result)
                      if (result?.data?.code === 200) {
                        setAutomobilesDetailsArray(result?.data?.data);
                        var results = result.data.data;
                        for (let j = 0; j < results.length; j++) {
                          results[j].beneficiaire = N[i].beneficiaire;
                          results[j].prime_ttc=formatCurrency(results[j].prime_ttc)
                          var date_effet = new Date(N[i].date_effet);
                          console.log(
                            "date effet index",
                            j,
                            "est: ",
                            date_effet
                          );
                          var duree = results[j].duree;
                          console.log("duree index", j, "est: ", duree);
                          var echeance = date_effet.setMonth(
                            date_effet.getMonth() + duree
                          );
                          var echeanceInDateFormat = new Date(echeance)

                          console.log(echeanceInDateFormat);
                          // Get the updated date components
                          var year = echeanceInDateFormat.getFullYear();
                          var month = echeanceInDateFormat.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
                          var day = echeanceInDateFormat.getDate();
                          console.log('better date format : ', day, month, year);
                          // Format the date as a string
                          var formattedDate =
                            year +
                            "-" +
                            month.toString().padStart(2, "0") +
                            "-" +
                            day.toString().padStart(2, "0");

                          results[j].echeance = formattedDate;
                          console.log(formattedDate);
                        }
                        setAutomobilesDetailsArray(results);
                        console.log(automobileDetailsArray);
                        console.log(results);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const history = useHistory();
  const handleClick = () => {
    history.push(`/renouvellement_accident/${id}`);
  };
  const historyAccident = useHistory();
  const handleClickAccident = () => {
    historyAccident.push(`/renouvellement_automobile/${id}`);
  };
  const toggleAccordion1 = () => {
    setAccordion1Open(!accordion1Open);
  };

  const toggleAccordion2 = () => {
    setAccordion2Open(!accordion2Open);
  };


  return (
    <div className="container">
      <div className="shop-pagination pb-3">
        <div className="container mb-3">
          <div className="card">
            <div className="card-body p-2">
              <div
                className="accordion accordion-flush accordion-style-two"
                id="accordionStyle2"
              >
                <div className="accordion-item">
                  <div
                    className="accordion-header"
                    id="accordionFive"
                    onClick={toggleAccordion1}
                  >
                    <h6
                      className="collapsed fz-14"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionStyleFive"
                      aria-expanded="false"
                      aria-controls="accordionStyleFive"
                    >
                      <i className="bi bi-plus-lg" />
                      {t("Assurance Accident")}
                    </h6>
                  </div>
                  {accordion1Open && (
                    <div className="accordion-content">
                      {accidentDetailsArray.map((item) => (
                         <div
                         className="card timeline-card"
                         key={item.id}
                         style={{ marginTop: "40px" }}
                       >
                         <>
                           <div className="card-body">
                             {/* <div className="d-flex justify-content-between">
                               <div className="timeline-text mb-2">
                                 <span
                                   className="badge mb-2 rounded-pill fz-10"
                                   style={{
                                     alignItems: "center",
                                     justifyContent: "center",
                                     display: "flex",
                                     height: "47px",
                                     width: "180px",
                                   }}
                                 >
                                    Souscripteur :{nom} {prenom}
                                 </span>
                               </div>
                             </div> */}
                                  <div className="d-flex justify-content-between">
                                    <div className="timeline-text mb-2">
                                      <span className="badge mb-2 rounded-pill fz-10">
                                      Souscripteur :{nom} {prenom}
                                      </span>
                                    </div>
                                  </div>
                             <div className="row" style={{marginBottom:"-1.2rem"}}>
                               <div className="col-md-6 d-flex align-items-center">
                                 <div>
                                   <p className="fz-10">
                                     <b>{t("Bénéficiaire ")} :</b> {item.beneficiaire}
                                   </p>
                                   <p className="fz-10">
                                     <b >
                                       <b>{t("Date d’échéance ")} :</b>
                                      {item.echeance}
                                     </b>
                                   </p>
                                 </div>
                               </div>
                               <div className="col-md-6 d-flex align-items-center">
                                 <div>
                                   <p className="fz-10">
                                     <b>{t("Durée ")} :</b>{" "}
                                     {item.duree}
                                   </p>
                                   <p className="fz-10">
                                     <b>{t("Formule")} :</b> {item.formule}
                                   </p>
                                 </div>
                               </div>
                               <div className="col-md-6 d-flex align-items-center">
                                 <div>
                                   <p className="fz-10">
                                     <b>{t("Prime TTC ")} :</b> {item.prime_ttc}{" "}
                                     
                                   </p>
                                  
                                 </div>
                               </div>
                             </div>
                             <Link to={{ pathname: '/renouvellement_accident/'+item.id, contract: { item } }}>
                             <button
                            className="btn btn-success text-white fz-10 float-end">
                              Renouveler
                             <i className="bi bi-arrow-right  fz-10" />
                           </button>
                            </Link>
                            
                           </div>
                         </>
                       </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="accordion-item" style={{ marginTop: "30px" }}>
                  <div
                    className="accordion-header"
                    id="accordionFive"
                    onClick={toggleAccordion2}
                  >
                    <h6
                      className="collapsed fz-14"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionStyleFive"
                      aria-expanded="false"
                      aria-controls="accordionStyleFive"
                    >
                      <i className="bi bi-plus-lg" />
                      {t("Assurance Automobile")}
                    </h6>
                  </div>
                  {accordion2Open && (
                    <div className="accordion-content">
                      {automobileDetailsArray.map((item) => (
                         <div
                         className="card timeline-card"
                         key={item.id}
                         style={{ marginTop: "40px" }}
                       >
                         <>
                           <div className="card-body">
                             {/* <div className="d-flex justify-content-between">
                               <div className="timeline-text mb-2">
                                 <span
                                   className="badge mb-2 rounded-pill fz-10"
                                   style={{
                                     alignItems: "center",
                                     justifyContent: "center",
                                     display: "flex",
                                     height: "47px",
                                     width: "180px",
                                   }}
                                 >
                                    N° immat/ Chassis : {item.matricule}
                                 </span>
                               </div>
                             </div> */}
                             <div className="d-flex justify-content-between">
                            <div className="timeline-text mb-2">
                              <span className="badge mb-2 rounded-pill fz-10">
                              N° immat/ Chassis : {item.matricule}
                              </span>
                            </div>
                          </div>
                             <div className="row" style={{marginBottom:"-1.2rem"}}>
                               <div className="col-md-6 d-flex align-items-center">
                                 <div>
                                   <p className="fz-10">
                                     <b>{t("Marque ")} :</b>  {item.marque}
                                   </p>
                                   <p className="fz-10">
                                     <b >
                                       <b>{t("Date d’échéance ")} :</b>
                                        {item.echeance}
                                     </b>
                                   </p>
                                 </div>
                               </div>
                               <div className="col-md-6 d-flex align-items-center">
                                 <div>
                                   <p className="fz-10">
                                     <b>{t("Durée ")} :</b>{" "}
                                     {item.duree}
                                   </p>
                                   <p className="fz-10">
                                     <b>{t("Puissance")} :</b> {item.puissance}
                                   </p>
                                 </div>
                               </div>
                               <div className="col-md-6 d-flex align-items-center">
                                 <div>
                                   <p className="fz-10">
                                     <b>{t("Prime TTC ")} :</b> {item.prime_ttc}{" "}
                                     
                                   </p>
                                  
                                 </div>
                               </div>
                             </div>
                             <Link to={{ pathname: '/renouvellement_automobile/'+item.id, contract: { item } }}>
                             <button
                            className="btn btn-success text-white fz-10 float-end">
                              Renouveler
                             <i className="bi bi-arrow-right  fz-10" />
                           </button>
                            </Link>
                            {/* <button
                             className="btn btn-success text-white fz-10 float-end"
                             onClick={handleClickAccident}
                           >
                             Renouveler
                             <i className="bi bi-arrow-right  fz-10" />
                           </button> */}
                           </div>
                         </>
                       </div>
                        
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
