import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = "#fff";
const headerColor = "#7e02b0";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: headerColor,
        backgroundColor: headerColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        fontFamily:'Arial',
        flexGrow: 1,
        marginBottom:10
        
    },
    description: {
        width: '100%',
        borderRightColor: headerColor,
        borderRightWidth: 1,
        color: borderColor,
        fontFamily:'Arial',
        fontSize:10,
        fontStyle:"normal"

    },

  });

  const InvoiceTableHeader = ({title}) => (
    <View style={styles.container}>
        <Text style={styles.description}>{title}</Text>
    </View>
  );
  
  export default InvoiceTableHeader