/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../Auth/useUser'
import Loader from '../../../components/loading/Loader'
import { getAdressesOfUser } from '../../../services/adresses/AdresseAPI'
import DeleteAdresse from './DeleteAdresse'

// # TOAST AND ALERT ELEMENTS
import { toast } from "react-toastify";

export default function AdressesUser() {
  const user = useUser()
  // -------------- LISTE USERS --------
  const [liste, setliste] = useState([])
  // -------------- Loading -----------
  const [loading, setLoading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //Modal DELete
  const [OK1, setOK1] = useState(false)
  const [deleteCode, setdeleteCode] = useState("")
  const [indexDelete, setindexDelete] = useState(-1)

  useEffect(() => {
    var data = user?.result
    getAdressesOfUser(data?.id)
      .then((result) => {
        const informations = result?.data; //results data
        if (informations?.code === 200) {
          var LISTE = result?.data?.data
          setliste(LISTE)
        } else {
          toast(t("Erreur Temporaire !"), {
            type: "error",
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setLoading(false)
      })
  }, [OK1])

  return (
    <>
      {loading ? <Loader /> :
        <div className='container mt-3'>
          {liste?.length === 0 ?
            <div className="card">
              <div className="card-body px-5 text-center">
                <h4> {t("Aucune adresse")} <br /></h4>
                <p className="mb-4">{t("Veuillez créer une ou plusieurs adresse pour s'implifier les procédures de création de vos contrats")} </p>
                <img className="mb-4" src="img/bg-img/100.png" alt="NO DATA" />
                <a className="btn btn-creative btn-purple fz-12" href="/add_adresse"><i className="bi bi-plus me-2" /> {t("Ajouter une nouvelle adresse")}</a>
              </div>
            </div>
            : <div className='row'>
              <div className='col-12 '>
                <a className='btn btn-creative btn-purple text-white float-end mb-3' href="/add_adresse"><i className="bi bi-plus me-1" />  {t("Ajouter une adresse")} </a>
              </div>
              {liste?.map((items, keys) => (
                <div className='col-12 mb-3'>
                  <div className="card timeline-card m-2" key={keys}>
                    <div className="card-body">
                      <h5 className="card-title fz-14 text-center "> {t("Adresse N°")} {keys + 1}</h5>
                      <p className="card-text fz-12 mt-3">  <b> {t("Adresse")} : </b>  {items?.ligne}  {items?.cite} {items?.pays}   {items?.region}  </p>
                      <p className="card-text fz-12"> <b> {t("Code Postal")} : </b> {items?.code_postal}</p>
                      <a href="#" className="btn btn-purple float-end me-2 fz-12" data-bs-toggle="modal" data-bs-target="#deleteModal"
                        onClick={() => { setdeleteCode(items.id); setindexDelete(keys) }}><i className="bi bi-trash fz-12" />
                      </a>
                      <a href={"/edit_adresse/" + items.id} className="btn btn-success text-white me-2  fz-12 float-end"><i className="bi bi-pencil-square fz-12" />
                      </a>

                    </div>
                  </div>
                </div>
              ))}
              <DeleteAdresse deleteCode={deleteCode} indexDelete={indexDelete} setOK1={setOK1} OK1={OK1} t={t} />
            </div>
          }
        </div>}

    </>

  )
}
