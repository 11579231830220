export const types = [
    {
      name: "Free Money",
      icon: "https://intech-apiv2.s3.amazonaws.com/icons/free_sn.png",
      codeService: "FREE_SN_WALLET_CASH_OUT",
      typeOperation: "CREDIT",
      typeService: "CASHOUT",
    },
    {
      name: "E Money",
      icon: "https://intech-apiv2.s3.amazonaws.com/icons/emoney.png",
      codeService: "EXPRESSO_SN_WALLET_CASH_OUT",
      typeOperation: "CREDIT",
      typeService: "CASHOUT",
    },
    {
      name: "Orange Money",
      icon: "https://intech-apiv2.s3.amazonaws.com/icons/om_sn.png",
      codeService: "ORANGE_SN_API_CASH_OUT",
      typeOperation: "CREDIT",
      typeService: "CASHOUT",
    },
    {
      name: "Wave Money",
      icon: "https://intech-apiv2.s3.amazonaws.com/icons/wave.png",
      codeService: "WAVE_SN_API_CASH_OUT",
      typeOperation: "CREDIT",
      typeService: "CASHOUT",
    },
    {
      name: "Wizall Money",
      icon: "https://intech-apiv2.s3.amazonaws.com/icons/wizall.jpg",
      codeService: "WIZALL_SN_API_CASH_OUT",
      typeOperation: "CREDIT",
      typeService: "CASHOUT",
    },
    {
      name: "Carte Bancaire",
      icon: "https://intech-apiv2.s3.amazonaws.com/icons/CB.png",
      codeService: "BANK_CARD_API_CASH_OUT",
      typeOperation: "CREDIT",
      typeService: "CASHOUT",
    },
  ];