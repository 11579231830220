/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getParamTechByCategorie } from "../../../../services/paramtech/paramTechAPI";
import { numberWithSpaces } from "../../../../utils/SessionUtiles";
import { calculResultRetraite } from "../../../devis/components/retraite/RetraiteFunction";

export default function ResumRetraite({category_id,data,t,LISTE_ASSUREE,groupedAssuree,setLISTE_ASSUREE}) {
  // Results
  const [results, setResults] = useState({
    capitalacquis: 0, // Capital garenti
    rente: 0, // RENTRE annuelle equivalente certaine service
  });

  // LOADER OF RESULTS
  const [loaderResult, setloaderResult] = useState(false);

  useEffect(() => {
    getParamTechByCategorie(category_id)
      .then((result) => {
        if (result?.data?.code === 200) {
          // PARAMETRE TECHNIQUE
          var paramTech = result?.data?.data[0];

          var total = calculResultRetraite(
            data?.prime_commercial,
            data?.periodicite,
            data?.duree_contrat,
            data?.durre_rente,
            paramTech
          );
        
          if (LISTE_ASSUREE?.length !== 0) {
            /**
             * ARRAY : LISTE ASSUREE
             */
            var new_liste_assuree = [];

            LISTE_ASSUREE?.map((items) => {
              // INFO ASSUREE (NOM ,PRENOM ,  )
              var info_assuree =
                groupedAssuree[items?.assuree_id] !== undefined
                  ? groupedAssuree[items?.assuree_id][0]
                  : {};
              /**
               * CREATE NEW OBJECT TO PUSH INTO THE NEW ARRAY ASSUREE
               */
              var obj = {
                assuree_id: items?.assuree_id,
                nom_assuree: info_assuree?.nom,
                prenom_assuree: info_assuree?.prenom,
                data_naissance_assuree: info_assuree?.date_naissance,
                capitalacquis: total?.capital_acquis,
                rente: total?.total_rente,
              };
              new_liste_assuree.push(obj);
            });
            setLISTE_ASSUREE(_.uniq(new_liste_assuree));
          }
          Object.assign(data, {
            capitalacquis: total?.capital_acquis,
            rente: total?.total_rente,
          });
          setResults({
            capitalacquis: total?.capital_acquis,
            rente: total?.total_rente,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="row mb-3 mt-3">
        <div className=" card bg-purple mt-0 mb-0 ">
          <div className="card-body">
            <table className="table mb-0 mt-0">
              <tbody className="text-white">
                <tr>
                  <td className="fz-10"> {t("CAPITAL GARANTI")} </td>
                  <td className=" fz-10">
                    {numberWithSpaces( Math.round(results?.capitalacquis))} FCFA
                  </td>
                </tr>
                <tr>
                  <td className="fz-10">
                    {t("RENTE ANNUELLE EQUIVALENTE CERTAINE")}
                  </td>
                  <td className=" fz-10">
                    {numberWithSpaces( Math.round(results?.rente))} FCFA
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
