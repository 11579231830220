import React from "react";
import designImage from "./gestionContrats/images/Download-cuate.png";

export default function ChoisirContrat({
  data,
  setshowInterface,
  setshowResum,
  setshowFacture,
  setshowCP,
  setshowContrat,
  setshowCG,
  type,
  t,
  setshowRenouvellementContrat,
}) {

  return (
    <div className="py-3">
      <div className="container">
        <h4 className="text-center mb-2 display-3"> {data?.category?.titre}</h4>
        <p className="lead mb-0 text-center fz-14">
          {t(
            "Pour visualiser les informations reliées au contrat dont le N° Police"
          )}{" "}
          : <b className="text-success"><b>{data?.policy_code}</b></b>
        </p>
        <div className="text-center">
          <img
            src={designImage}
            className="text-center"
            alt="design"
            width="200"
          />
        </div>
        <div className="row">
          <button
            className="btn btn-purple col-12 mb-2 mt-2"
            onClick={() => {
              setshowResum(true);
              setshowInterface(false);
              setshowCP(false);
              setshowContrat(false);
              setshowFacture(false);
              setshowCG(false);
            }}
          >
            {t("Resumé")}
          </button>
          {type !== "automobile" && type !== "voyage" && type !=="accident" ?
          <button
            className="btn btn-purple col-12 mb-2"
            onClick={() => {
              setshowResum(false);
              setshowInterface(false);
              setshowCP(false);
              setshowContrat(false);
              setshowFacture(true);
              setshowCG(false);
            }}
          >
            {t("Facture")}
          </button>
          :
          <button
            className="btn btn-purple col-12 mb-2"
            onClick={() => {
              setshowResum(false);
              setshowInterface(false);
              setshowCP(false);
              setshowContrat(false);
              setshowFacture(true);
              setshowCG(false);
            }}
          >
            {t("Facture")}
          </button>
        }

       
          {type === "voyage" && (
            <button
              className="btn btn-purple col-12 mb-2"
              onClick={() => {
                setshowResum(false);
                setshowInterface(false);
                setshowCP(false);
                setshowContrat(true);
                setshowFacture(false);
                setshowCG(false);
              }}
            >
              {t("Attestation de voyage")}
            </button>
          )}
        {type !== "voyage" && (   <button
            className="btn btn-purple col-12 mb-2"
            onClick={() => {
              setshowResum(false);
              setshowInterface(false);
              setshowCP(true);
              setshowContrat(false);
              setshowFacture(false);
              setshowCG(false);
            }}
          >
            {t("Conditions Particuliéres")}
          </button>)}
          <button
            className="btn btn-purple col-12 mb-2"
            onClick={() => {
              setshowResum(false);
              setshowInterface(false);
              setshowCP(false);
              setshowContrat(false);
              setshowFacture(false);
              setshowCG(true);
            }}
          >
            {t("Conditions Génèrales")}
          </button>
         
        </div>
      </div>
    </div>
  );
}
