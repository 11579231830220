/* eslint-disable no-lone-blocks */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useUser } from '../../Auth/useUser';
import Cookies from 'js-cookie';
import moment from 'moment';
import { getAllMessages } from '../../services/chatApi';

export default function ListeMessages() {
  const [LISTE, setLISTE] = useState([]); //LISTE DES DECLARATIONS SINSITRES
  //---------- LOADING -----------------------
  const [loading, setloading] = useState(true);
  //------------- Traduction ---------------
  const [t] = useTranslation("global");
  //--------INFORMATIONS OF ACTUAL USER---------
  const user = useUser();
  var { id } = user.result;
  const language = Cookies.get('locale')

  {  language === "fr" ? require('moment/locale/fr') : require('moment/locale/en-au') }

  useEffect(() => {
    getAllMessages(id).then((result) => {
      if(result?.data?.code===200){
        setLISTE(result?.data?.data)
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <div className="container">
      <h3 className="text-center fz-16 mb-3 mt-3">
        Liste des messages
      </h3>
      {
        LISTE?.length !== 0 ? (<>
          {LISTE?.map((items, keys) => (
            <div className="card timeline-card" key={keys}>
              <div className="card-body">
                {console.log(LISTE)}
                <div className="d-flex justify-content-between">
                  <div className="timeline-text mb-2">
                    <span className="badge mb-2  fz-10 rounded-pill">
                      {moment(items.created_at).format("DD MMMM  YYYY")}
                    </span>
                  </div>
                </div>
                <p>Type : <b>{items.type}</b> </p>
                <p>Message : <b>{(items.text).substring(0, 80)}</b> </p>

                
                <div className="timeline-tags">
                  <span className="badge bg-light ">
                    <a className="text-purple fz-12" href={"/messages/" + items.id}>
                     Voir le message au complet
                      <i className="bi bi-arrow-right me-2" />
                    </a>
                  </span>
                </div>
              </div>
            </div>))}

        </>
        ) : (<> <div className="card">
          <div className="card-body px-5 text-center">
            <h4>
              Aucun Message
              <br />
            </h4>
            <p className="mb-4 fz-12">
              {t(
                " Vous n'avez aucun message reçus"
              )}
            </p>
            <img className="mb-4" src="img/bg-img/100.png" alt="NO DATA" />

          </div>
        </div></>)}
    </div>
  )
}
