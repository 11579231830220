import moment from 'moment';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getEvents } from '../../../services/evenements/eventAPI';
import Cookies from 'js-cookie';

export default function ListeEvents() {
    // LISTE DES NEWS
    const [ListeEvents, setListeEvents] = useState([])
    //------- INIT LANGAGE 
    const language = Cookies.get('locale')
    //------------- Traduction -------------
    const [t] = useTranslation("global");

    useEffect(() => {
        getEvents().then((result) => {
            if (result?.data?.code === 200) {
                setListeEvents(result?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }, [])
    return (
        <div className="container">
            <div className="shop-pagination pb-3">
                <div className="container">
                    <div className="card">
                        <div className="card-body p-2">
                            <div className="d-flex align-items-center justify-content-between"><small className="ms-1">{t("Tous les événements")} </small>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 mt-2">
                        {/* Single Blog Card */}
                        {ListeEvents?.length !== 0 ? ListeEvents?.map((items, keys) =>
                            <div className="col-12 col-sm-6 col-md-6" key={keys}>
                                <div className="card position-relative shadow-sm">
                                    <img className="card-img-top" src={items.image !== null ? process.env.REACT_APP_IMAGE + items.image : "img/bg-img/6274.jpg"} alt="news_images" height={"150px"} />
                                    <div className="card-body">
                                        <span className="badge bg-purple rounded-pill mb-2 d-inline-block">{moment(items.date).format("DD MMMM YYYY ")}</span>
                                        <h5 className="mb-1 fz-14" >{language==="fr"? items.titre : items.titre_en} </h5>
                                        <a className="text-purple float-end" href={"/details_events&id=" + items.id}>{t("Lire la suite")} ...  </a>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className="col-12 col-sm-12 col-md-12" >
                            <div className=" text-center">
                                <img className="text-center mb-3" width={50} src="../img/bg-img/nodata.png" alt="news_images" />
                                <h6 className='text-purple'>{t("Pas d'évènement pour l'instant")}. </h6>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}
