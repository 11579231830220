import {
    Font,
    StyleSheet,
    PDFViewer,
    Document,
    Page,
    View,
    Text,
    Image,
    PDFDownloadLink,
  } from "@react-pdf/renderer";
  import React, { Fragment, useEffect, useState } from "react";
  // FONT TIMES NEW ROMAN
  import TimesNewRoman_normal from "../contrats/styles/TimesNewRoman/times.ttf";
  import TimesNewRoman_bold from "../contrats/styles/TimesNewRoman/times-new-roman-grassetto.ttf";
  import { t } from "i18next";
  import InvoiceTableHeader from "../contrats/invoice/InvoiceTableHeader";
  import Contrat from "../contrats/invoice/Contrat";
  import moment from "moment";
  import Signatures from "../contrats/conditionParticuliere/Signatures";
  import PlacardQRCode from "../contrats/conditionParticuliere/PlacardQRCode";
  import QRCode from "qrcode-svg";
  import { useLocation } from "react-router-dom";
  import accounting from "accounting";
  import { numberWithSpaces } from "../../utils/SessionUtiles";
  import { useUser } from "../../Auth/useUser";
  import { getUser } from "../../services/users/usersAPI";
  import {
    getFactureByNumero,
    getFactureByUser,
  } from "../../services/factures/FactureApi";
  import { NumberToLetter } from "convertir-nombre-lettre";
  import {
    findContratByUserConnected,
  } from "../../services/contrats/ContratApi";
  import { useParams } from "react-router-dom";
  import _ from "lodash";
  import { useHistory } from "react-router-dom";
  import logo from "../cotisation/logo_dikotech.jpeg";
  import { isMobile } from "react-device-detect";
  import DownloadImage from "../contrats/gestionContrats/DownloadImage";
  import Loader from "../../components/loading/Loader";
import { getSignatureCompagny } from "../../services/compagny/CompagnyAPI";
  
  export default function ListeFacturesParCategories() {
    const selectedFactureCotisation = JSON.parse(
      localStorage.getItem("selectedFactureCotisation")
    );
    console.log("selected facture cotisation", selectedFactureCotisation);
  console.log("isMobile ",isMobile )
    const borderColor = "#fff";
    const [data, setdata] = useState({});
    const africanCurrencyOptions = {
      symbol: "", // Set the currency symbol
      decimal: ".", // Set the decimal separator
      thousand: " ", // Set the thousand separator
      precision: 0, // Set the number of decimal places
      format: "%v %s", // Set the format pattern
    };
    // Function to format a given value with the African currency options
    const formatCurrency = (value) => {
      return accounting.formatMoney(value, africanCurrencyOptions);
    };
    const user = useUser();
    var { id, nom, prenom, email, mobile } = user.result;
    
  //-------------- LOADING ------------
  const [Loading, setLoading] = useState(false);
  
    const { id_fact, idcategory,categoryId } = useParams();
    const [signatures, setsignatures] = useState(false);

    const history = useHistory();
    
  
    // useEffect(() => {
    //   findContratByUserConnected(id)
    //     .then((result) => {
    //       if (result?.data?.code === 200) {
    //         console.log("data of contraat", result?.data?.data);
    //         const filteredData = result?.data?.data.filter(
    //           (item) => item.category_id === Number(idcategory)
    //         );
    //         setdata(filteredData);
    //       }
    //       console.log("dataaa",data)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }, []);
    
    useEffect(() => {
      findContratByUserConnected(id)
        .then((result) => {
          if (result?.data?.code === 200) {
            var compagny_id =result?.data?.data[0].company_id
            console.log("data of contraat", result?.data?.data);
            const filteredData = result?.data?.data.filter(
              (item) => item.category_id === Number(idcategory)
            );
            setdata(filteredData);
          }
           getSignatureCompagny(compagny_id).then((result) => {
          if(result?.data?.code===200){
            console.log("give me the data of signatures :",result?.data?.data)
            setsignatures(result?.data?.data);
          }
        }).catch((err) => {
          console.log(err);
        });
          console.log("dataaa",data)
        })
        .catch((err) => {
          console.log(err);
        })
       
    }, []);
    const headerColor = "#7e02b0";
  
    Font.register({
      family: "Arial",
      fonts: [
        {
          src: TimesNewRoman_normal,
          fontWeight: 400,
          fontStyle: "normal",
        },
        {
          src: TimesNewRoman_bold,
          fontWeight: 700,
          fontStyle: "bold",
        },
      ],
    });
    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
  
      reportTitle: {
        letterSpacing: 3,
        fontSize: 12,
        textAlign: "center",
        marginBottom: 10,
        fontFamily: "Arial",
        fontStyle: "bold",
        color: "#000",
        textTransform: "uppercase",
      },
      logo: {
        width: 80,
        height: 40,
        marginTop: -20,
      },
      logo2: {
        width: 60,
        height: 40,
        marginTop: -20,
        marginLeft: 30,
        textAlign: "right",
      },
      entete: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
      },
      enteteRow: {
        width: "35%",
        alignContent: "center",
      },
      enteteRow3: {
        width: "20%",
      },
      enteteRow1: {
        width: "10%",
        marginTop: -20,
        padding: 0,
      },
      enteteRow2: {
        width: "20%",
        textAlign: "right",
      },
      headerTitle: {
        letterSpacing: 2,
        fontSize: 8,
        color: "#4e545d",
        textAlign: "center",
        textTransform: "uppercase",
        marginBottom: 0,
        fontFamily: "Arial",
        fontStyle: "normal",
      },
      headerContainer: {
        marginTop: 20,
      },
      tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
        borderWidth: 1,
        borderColor: "#fff",
        color: "#443f52",
      },
      billTo: {
        letterSpacing: 1,
        paddingBottom: 3,
        fontSize: 10,
        fontFamily: "Arial",
        fontStyle: "normal",
      },
      description2: {
        width: "70%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: "Arial",
        fontStyle: "bold",
      },
      description1: {
        width: "70%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontFamily: "Times-Roman",
        paddingLeft: 8,
      },
      description: {
        width: "30%",
        textAlign: "right",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontFamily: "Arial",
        fontStyle: "normal",
        paddingLeft: 8,
      },
      row: {
        flexDirection: "row",
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: "center",
        height: 16,
        fontFamily: "Arial",
        fontStyle: "bold",
        fontSize: 10,
      },
      text: {
        fontFamily: "Arial",
        fontStyle: "bold",
        color: "red",
      },
      right: {
        padding: 5,
        width: "50%", //<- working alternative
        flexShrink: 1,
        marginBottom: 0,
        textAlign: "center",
      },
      left: {
        padding: 5,
        width: "50%", //<- working alternative
        flexShrink: 1,
        marginBottom: 0,
        textAlign: "center",
      },
      pageNumber: {
        position: "absolute",
        fontSize: 9,
        bottom: 30,
        left: 20,
        right: 20,
        textAlign: "center",
        color: "#6003a9",
        borderTop: "2px solid #6003a9",
      },
      logoApp: {
        width: 50,
        textAlign: "center",
      },
      link: {
        textDecoration: "none",
      },
      signature: {
        width: 100,
        height: 50,
        marginTop: -20,
        textAlign: "right",
      },
      descriptionLeft: {
        width: "100%",
        textAlign: "left",
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
  
      descriptionright: {
        width: "100%",
        textAlign: "right",
        paddingLeft: 8,
        letterSpacing: 1,
        fontSize: 8,
        color: "#4e545d",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
      rowp: {
        flexDirection: "row",
        borderBottomColor: "#fff",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        fontStyle: "bold",
      },
      textp: {
        fontFamily: "Arial",
        fontStyle: "bold",
      },
      descriptionp: {
        width: "100%",
        textAlign: "right",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: "Arial",
        fontStyle: "normal",
        fontSize: 10,
      },
      row2: {
        flexDirection: "row",
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: "center",
        height: 18,
        fontSize: 10,
      },
      description2: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily: "Arial",
        fontStyle: "normal",
        textTransform: "capitalize",
      },
      text2: {
        fontFamily: "Arial",
        fontStyle: "bold",
      },
      
      description3: {
        width: "100%",
        borderRightColor: headerColor,
        borderRightWidth: 1,
        color: borderColor,
        fontFamily: "Arial",
        fontSize: 10,
        fontStyle: "normal",
      },
      titleContainer4: {
        flexDirection: "row",
        marginTop: 12,
        fontFamily: "Arial",
        fontStyle: "normal",
      },
      reportTitle4: {
        fontSize: 9,
        textAlign: "left",
        fontFamily: "Arial",
        fontStyle: "normal",
        letterSpacing: 1,
        width: "100%",
        marginBottom: 5,
      },
      text4: {
        textDecoration: "underline",
      },
    });
  
  
    const generateQrCodePath = (content, size) => {
      // console.log({ content, size });
      const htmlStr = new QRCode({
        content,
        join: true,
        padding: 0,
        width: 40,
        height: 40,
      }).svg({ container: "none" });
      const parsableHtml = new DOMParser().parseFromString(htmlStr, "text/html");
      const dElement =
        parsableHtml
          .querySelector("html > body > rect > path")
          ?.getAttribute("d") ?? "";
      return dElement;
    };
  
    // const redirectToDetails = (num_facture) => {
    //   history.push(`/cotisation_details/${idcategory}/${num_facture}`);
    // };
    const redirectToDetails = (category_id) => {
        history.push(`/factures_list/${category_id}`);
      };
    return (
      <div>
        <div className="container">
          <div className="shop-pagination pb-3">
          {Loading ? (
              <Loader />
            ) : (
              <div className="row">
                  <div>
                    {!isMobile && (
                      <PDFViewer width="1000" height="600" className="app">
                        <Document renderMode="svg">
                          <Page size="A4" style={styles.body} wrap>
                            <div className="entete">
                              <View style={styles.entete} fixed>
                                <View style={styles.entete} fixed>
                                  <View style={styles.enteteRow1}>
                                    <PlacardQRCode
                                      dPath={generateQrCodePath(
                                        "https://diokoassur.com/policy/"
                                          +
                                          data?.policy_code, 50
                                      )}
                                    />
                                  </View>
  
                                  <View style={styles.enteteRow3}>
                                    <Image style={styles.logo} src={logo} />
                                  </View>
                                  <View style={styles.enteteRow}>
                                    <Text style={styles.headerTitle}>
                                      {" "}
                                      Partenaire distributeur de{" "}
                                    </Text>
                                  </View>
                                  <View style={styles.enteteRow2}>
                                    {console.log(
                                      "grand probleme est ",
                                      process.env.REACT_APP_IMAGE
                                    )}
                                    {/* {console.log("grand probleme data est ", data?.company?.logo)} */}
                                    <Image
                                      style={styles.logo2}
                                      src={
                                        process.env.REACT_APP_IMAGE + data[0]?.company?.logo
                                      }
                                    />
                                  </View>
                                </View>
                                <View style={styles.enteteRow3}>
                                  {/* <Image style={styles.logo} src={logo} /> */}
                                </View>
                                
                                <View style={styles.enteteRow2}>
                                  {/* <Image
                                style={styles.logo2}
                                src={process.env.REACT_APP_IMAGE + }
                              /> */}
                                </View>
                              </View>
                            </div>
                            <View>
                              <Text style={styles.reportTitle}>Facture</Text>
                            </View>
                            <div className="billTo">
                              <Fragment>
                                <View style={styles.tableContainer}>
                                  <View style={styles.row}>
                                    <Text style={styles.description2}>
                                      {selectedFactureCotisation?.category?.titre}
                                      {/* El title si Aala */}
                                    </Text>
                                    <Text style={styles.description}>
                                      {t("Police N°")}:{" "}
                                      {selectedFactureCotisation?.num_facture}
                                    </Text>
                                  </View>
                                  <View style={styles.row}>
                                    <Text style={styles.description1}>
                                      {t("Compagnie d'assurance")} :{" "}
                                      {data[0]?.company?.nom}
                                      {console.log("data company nom",data[0]?.company?.nom)}
                                    </Text>
                                    <Text style={styles.description}>
                                    {t("N°Facture")} :{" "}  {selectedFactureCotisation?.id}{" "}
                                    </Text>
                                  </View>
                                  <View style={styles.row}>
                                    <Text style={styles.description1}>
                                    {t("Adresse Compagnie")} :{" "}  {data[0]?.company?.adresse}{" "}
                                    </Text>
                                    <Text style={styles.description}>
                                      {t("Date Effet")}:{" "}
                                      {moment(selectedFactureCotisation?.Date_prevu).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                    </Text>
                                  </View>
                                  <View style={styles.row}>
                                    <Text style={styles.description1}>
                                    {t("Télèphone Compagnie")} :{" "}  {data[0]?.company?.mobile}
                                    </Text>
                                    <Text style={styles.description}>
                                      <Text style={styles.text}>
                                        {" "}
                                        {t("Date Paiement")}:{" "}
                                        {moment(
                                          selectedFactureCotisation?.Date_paiement
                                        ).format("DD/MM/YYYY")}{" "}
                                      
                                      </Text>
                                    </Text>
                                  </View>
                                </View>
                              </Fragment>
                            </div>
                            <div className="invoiceItemTable">
                              <View style={styles.tableContainer}>
                                <>
                                  <View style={styles.left}>
                                    <InvoiceTableHeader title={"Souscripteur"} />
                                    <View style={styles.row2}>
                                      <Text style={styles.description2}>
                                        <Text style={styles.text2}>
                                          {" "}
                                          {t("Nom & Prénom(s)")} :{" "} 
                                        </Text>{" "}
                                        {prenom + " " + nom}
                                      </Text>
                                    </View>
                                    <View style={styles.row2}>
                                      <Text style={styles.description2}><Text style={styles.text2}> {t("Adresse")} :{" "} </Text>{data[0]?.adresse}</Text>
                                    </View>
                                    <View style={styles.row2}>
                                      <Text style={styles.description2}>
                                        <Text style={styles.text2}> {t("Télèphone")} :{" "}  </Text>{" "}
                                        {mobile}
                                      </Text>
                                    </View>
                                    <View style={styles.row2}>
                                      <Text style={styles.description2}>
                                        <Text style={styles.text2}>{t("E-Mail")} :{" "} </Text> {email}
                                      </Text>
                                    </View>
                                  </View>
                                        
                                  <View style={styles.right}>
                                    <View style={styles.container}>
                                      <InvoiceTableHeader title={"Bénèficiaire"} />
                                    </View>
  
                                    <View style={styles.row}>
                                      <Text style={styles.description1}>
                                        <Text style={styles.text2}>{t("Nom & Prénom(s)")} :{" "}  </Text>
                                        {data[0]?.assure?.prenom + " " + data[0]?.assure?.prenom}
                                      </Text>
                                    </View>
                                    <View style={styles.row}>
                                      <Text style={styles.description1}>
                                        <Text style={styles.text2}> {t("Date De Naissance")} :{" "} </Text>{" "}
                                        {data[0]?.assure?.date_naissance}
                                      </Text>
                                    </View>
                                    <View style={styles.row}>
                                      <Text style={styles.description1}>
                                        <Text style={styles.text2}> {t("CIN")} :{" "} </Text>{" "}
                                        {data[0]?.assure?.cin}
                                      </Text>
                                    </View>
                                  </View>
                                </>
                              </View>
                            </div>
                            <div className="headerTable">
                              <InvoiceTableHeader title={"Prime – Capital – Rente"} />
                              <View style={styles.rowp}>
                                <Text style={styles.descriptionp}>
                                  <Text style={styles.textp}> {t("Montant")} : </Text>
                                  {selectedFactureCotisation.montant}
                                </Text>
                              </View>
                            </div>
  
                            <div className="thanks">
                              <View style={styles.left_calcul}>
                                <View style={styles.titleContainer4}>
                                  <Text style={styles.reportTitle4}>
                                    Arrêtée la présente facture à la somme de:
                                    
                                    <Text style={styles.text4}>
                                      {NumberToLetter(
                                        parseFloat(selectedFactureCotisation?.montant)
                                      )}
                                    </Text>
                                    FCFA
                                  </Text>
                                </View>
                                <View style={styles.titleContainer4}>
                                  <Text style={styles.reportTitle4}>
                                    Terme et conditions: Conformément à l'Article 13 du Code
                                    CIMA, "la prime est payable au comptant. La prise
                                    d'effet du contrat est subordonnée au paiement de la
                                    prime par le souscripteur." Conformément à l’Article 516
                                    du Code des impôts relatif au timbre des quittances,
                                    "Tout règlement en espèces supérieur à 100 000 FCFA
                                    supportera un timbre de 1%."
                                  </Text>
                                </View>
                              </View>
                              {/* <View style={styles.right_calcul}>
                                <Contrat
                                info={info}
                                t={t}
                                type={type}
                                data={data}
                                user_details={user.result}
                                personnel={personnel}
                                />
                                
                              </View> */}
                              
                            </div>
                            <div className="signature">
                              <View style={{ marginTop: "20" }}>
                                <View>
                                  <Text style={styles.descriptionLeft}>
                                    {" "}
                                    {t("Pour le client,")} {" "}
                                  </Text>
                                </View>
                                <Text style={styles.descriptionright}>
                                  Fait à Dakar le,
                                  {moment(selectedFactureCotisation?.updated_at).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Text>
                                <Text style={styles.descriptionright}>
                                  Diokotech / {data[0]?.company?.nom} 
                                </Text>
                                <Text style={styles.descriptionright}>
                              {console.log(" data of back end :",process.env.REACT_APP_IMAGE)}
                              {console.log(" data of front end :",signatures[0]?.image)}
                                <img
                                  style={styles.signature}
                                  src={process.env.REACT_APP_IMAGE + signatures[0]?.image}
                                  alt=""
                                />{" "}
                              </Text>
                              </View>
                            </div>
  
                      
                          </Page>
                            <div className="footer">
                            <View style={styles.pageNumber} fixed>
                
                              <Text style={{marginTop:"10",  fontFamily:'Times-Roman',
                            }}>
                              DIOKOTECH, Société par Action Simplifiée au capital de 1 000 000 Francs CFA
                              RCCM : SN-DKR-2021-B-21378 | NINEA  : 008658992 2V5
                              Tel Fixe : +221 33 827 00 05 |  Tel Mobile : +221 78 192 65 65  |  contact@diokotech.com  | www.diokotech.com 
                              I3e étage, lot n16 Cité CCBM, Ouest-Foire Dakar-Sénégal
  
                              </Text>
                            </View>
                        </div> 
  
                        </Document>
  
                      </PDFViewer>
                     )} 
                     {isMobile && (
                        <>
                          <DownloadImage t={t} />
                        </>
                      )}
                       {isMobile && (
                        <>
                          <PDFDownloadLink
                            document={
                              <Document renderMode="svg">
                                <Page size="A4" style={styles.body} wrap>
                                  <div className="entete">
                                    <View style={styles.entete} fixed>
                                      <View style={styles.entete} fixed>
                                        <View style={styles.enteteRow1}>
                                          <PlacardQRCode
                                            dPath={generateQrCodePath(
                                              "https://diokoassur.com/policy/"
                                                 +
                                                data?.policy_code, 50
                                            )}
                                          />
                                        </View>
                  
                                        <View style={styles.enteteRow3}>
                                          <Image style={styles.logo} src={logo} />
                                        </View>
                                        <View style={styles.enteteRow}>
                                          <Text style={styles.headerTitle}>
                                            {" "}
                                            Partenaire distributeur de{" "}
                                          </Text>
                                        </View>
                                        <View style={styles.enteteRow2}>
                                          {console.log(
                                            "grand probleme est ",
                                            process.env.REACT_APP_IMAGE
                                          )}
                                          {/* {console.log("grand probleme data est ", data?.company?.logo)} */}
                                          <Image
                                            style={styles.logo2}
                                            src={
                                              process.env.REACT_APP_IMAGE + data[0]?.company?.logo
                                            }
                                          />
                                        </View>
                                      </View>
                                      <View style={styles.enteteRow3}>
                                        {/* <Image style={styles.logo} src={logo} /> */}
                                      </View>
                                      
                                      <View style={styles.enteteRow2}>
                                        {/* <Image
                                      style={styles.logo2}
                                      src={process.env.REACT_APP_IMAGE + }
                                    /> */}
                                      </View>
                                    </View>
                                  </div>
                                  <View>
                                    <Text style={styles.reportTitle}>Facture</Text>
                                  </View>
                                  <div className="billTo">
                                    <Fragment>
                                      <View style={styles.tableContainer}>
                                        <View style={styles.row}>
                                          <Text style={styles.description2}>
                                            {selectedFactureCotisation?.category?.titre}
                                            {/* El title si Aala */}
                                          </Text>
                                          <Text style={styles.description}>
                                            {t("Police N°")}:{" "}
                                            {selectedFactureCotisation?.num_facture}
                                          </Text>
                                        </View>
                                        <View style={styles.row}>
                                          <Text style={styles.description1}>
                                            {t("Compagnie d'assurance")} :{" "}
                                            {data[0]?.company?.nom}
                                            {console.log("data company nom",data[0]?.company?.nom)}
                                          </Text>
                                          <Text style={styles.description}>
                                          {t("N°Facture")} :{" "}  {selectedFactureCotisation?.id}{" "}
                                          </Text>
                                        </View>
                                        <View style={styles.row}>
                                          <Text style={styles.description1}>
                                          {t("Adresse Compagnie")} :{" "}  {data[0]?.company?.adresse}{" "}
                                          </Text>
                                          <Text style={styles.description}>
                                            {t("Date Effet")}:{" "}
                                            {moment(selectedFactureCotisation?.Date_prevu).format(
                                              "DD/MM/YYYY"
                                            )}{" "}
                                          </Text>
                                        </View>
                                        <View style={styles.row}>
                                          <Text style={styles.description1}>
                                          {t("Télèphone Compagnie")} :{" "}  {data[0]?.company?.mobile}
                                          </Text>
                                          <Text style={styles.description}>
                                            <Text style={styles.text}>
                                              {" "}
                                              {t("Date Paiement")}:{" "}
                                              {moment(
                                                selectedFactureCotisation?.Date_paiement
                                              ).format("DD/MM/YYYY")}{" "}
                                            
                                            </Text>
                                          </Text>
                                        </View>
                                      </View>
                                    </Fragment>
                                  </div>
                                  <div className="invoiceItemTable">
                                    <View style={styles.tableContainer}>
                                      <>
                                        <View style={styles.left}>
                                          <InvoiceTableHeader title={"Souscripteur"} />
                                          <View style={styles.row2}>
                                            <Text style={styles.description2}>
                                              <Text style={styles.text2}>
                                                {" "}
                                                {t("Nom & Prénom(s)")} :{" "} 
                                              </Text>{" "}
                                              {prenom + " " + nom}
                                            </Text>
                                          </View>
                                          <View style={styles.row2}>
                                            <Text style={styles.description2}><Text style={styles.text2}> {t("Adresse")} :{" "} </Text>{data[0]?.adresse}</Text>
                                          </View>
                                          <View style={styles.row2}>
                                            <Text style={styles.description2}>
                                              <Text style={styles.text2}> {t("Télèphone")} :{" "}  </Text>{" "}
                                              {mobile}
                                            </Text>
                                          </View>
                                          <View style={styles.row2}>
                                            <Text style={styles.description2}>
                                              <Text style={styles.text2}>{t("E-Mail")} :{" "} </Text> {email}
                                            </Text>
                                          </View>
                                        </View>
                                              
                                        <View style={styles.right}>
                                          <View style={styles.container}>
                                            <InvoiceTableHeader title={"Bénèficiaire"} />
                                          </View>
                  
                                          <View style={styles.row}>
                                            <Text style={styles.description1}>
                                              <Text style={styles.text2}>{t("Nom & Prénom(s)")} :{" "}  </Text>
                                              {data[0]?.assure?.prenom + " " + data[0]?.assure?.prenom}
                                            </Text>
                                          </View>
                                          <View style={styles.row}>
                                            <Text style={styles.description1}>
                                              <Text style={styles.text2}> {t("Date De Naissance")} :{" "} </Text>{" "}
                                              {data[0]?.assure?.date_naissance}
                                            </Text>
                                          </View>
                                          <View style={styles.row}>
                                            <Text style={styles.description1}>
                                              <Text style={styles.text2}> {t("CIN")} :{" "} </Text>{" "}
                                              {data[0]?.assure?.cin}
                                            </Text>
                                          </View>
                                        </View>
                                      </>
                                    </View>
                                  </div>
                                  <div className="headerTable">
                                    <InvoiceTableHeader title={"Prime – Capital – Rente"} />
                                    <View style={styles.rowp}>
                                      <Text style={styles.descriptionp}>
                                        <Text style={styles.textp}> {t("Montant")} : </Text>
                                        {selectedFactureCotisation.montant}
                                      </Text>
                                    </View>
                                  </div>
                  
                                  <div className="thanks">
                                    <View style={styles.left_calcul}>
                                      <View style={styles.titleContainer4}>
                                        <Text style={styles.reportTitle4}>
                                          Arrêtée la présente facture à la somme de:
                                          
                                          <Text style={styles.text4}>
                                            {NumberToLetter(
                                              parseFloat(selectedFactureCotisation?.montant)
                                            )}
                                          </Text>
                                          FCFA
                                        </Text>
                                      </View>
                                      <View style={styles.titleContainer4}>
                                        <Text style={styles.reportTitle4}>
                                          Terme et conditions: Conformément à l'Article 13 du Code
                                          CIMA, "la prime est payable au comptant. La prise
                                          d'effet du contrat est subordonnée au paiement de la
                                          prime par le souscripteur." Conformément à l’Article 516
                                          du Code des impôts relatif au timbre des quittances,
                                          "Tout règlement en espèces supérieur à 100 000 FCFA
                                          supportera un timbre de 1%."
                                        </Text>
                                      </View>
                                    </View>
                                    {/* <View style={styles.right_calcul}>
                                      <Contrat
                                       info={info}
                                       t={t}
                                       type={type}
                                       data={data}
                                       user_details={user.result}
                                       personnel={personnel}
                                      />
                                      
                                    </View> */}
                                    
                                  </div>
                                  <div className="signature">
                                    <View style={{ marginTop: "20" }}>
                                      <View>
                                        <Text style={styles.descriptionLeft}>
                                          {" "}
                                          {t("Pour le client,")} {" "}
                                        </Text>
                                      </View>
                                      <Text style={styles.descriptionright}>
                                        Fait à Dakar le,
                                        {moment(selectedFactureCotisation?.updated_at).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Text>
                                      <Text style={styles.descriptionright}>
                                        Diokotech / {data[0]?.company?.nom} 
                                      </Text>
                                      <Text style={styles.descriptionright}>
                                  {console.log(" data of back end :",process.env.REACT_APP_IMAGE)}
                                  {console.log(" data of front end :",signatures[0]?.image)}
                                    <img
                                      style={styles.signature}
                                      src={process.env.REACT_APP_IMAGE + signatures[0]?.image}
                                      alt=""
                                    />{" "}
                                  </Text>
                                    </View>
                                  </div>
                  
                             
                                </Page>
                                  <div className="footer">
                                  <View style={styles.pageNumber} fixed>
                      
                                    <Text style={{marginTop:"10",  fontFamily:'Times-Roman',
                                  }}>
                                    DIOKOTECH, Société par Action Simplifiée au capital de 1 000 000 Francs CFA
                                    RCCM : SN-DKR-2021-B-21378 | NINEA  : 008658992 2V5
                                    Tel Fixe : +221 33 827 00 05 |  Tel Mobile : +221 78 192 65 65  |  contact@diokotech.com  | www.diokotech.com 
                                    I3e étage, lot n16 Cité CCBM, Ouest-Foire Dakar-Sénégal
                  
                                    </Text>
                                  </View>
                              </div> 
                  
                              </Document>
                               }
                            
                            fileName="Facture.pdf"
                          >
                            {({ blob, url, Loading, error }) =>
                              Loading ? (
                                "Loading document..."
                              ) : (
                                <button className="btn btn-purple mb-2 fz-10 col-12 ">
                                  {t("Télecharger la Facture")}
                                </button>
                              )
                            }
                          </PDFDownloadLink>
                        </>
                      )}
            </div>
                 <button
                      className="btn btn-success text-white fz-10 float-end"
                     
                      onClick={() => redirectToDetails(selectedFactureCotisation.category_id)}
                    >
                      Précedente
                    </button>
          </div>
            )}
           
          </div>
        </div>
      </div>
    );
  }
  