import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#fff";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: "center",
        height: 18,
        fontSize: 10,
    },
    description1: {
        width: "100%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontFamily:'Arial',
        fontStyle:"normal",
        textTransform:"capitalize"
    },
    text: {
        fontFamily:'Arial',
        fontStyle:"bold",
    },
});

const InvoiceTableRow = ({ type , data,user_details,personnels}) => {
    const rows = (
        <>
            <View style={styles.row}>
                <Text style={styles.description1}><Text style={styles.text}> Nom & Prénom(s) : </Text> {user_details?.prenom+" " + user_details?.nom}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}><Text style={styles.text}>Adresse : </Text>{data?.adresse}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}><Text style={styles.text}>Télèphone : </Text> {user_details?.mobile}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description1}><Text style={styles.text}>E-Mail :</Text> {user_details?.email}</Text>
            </View>
        </>
    );
    return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
