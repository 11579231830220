
export default function DownloadImage({t}) {
  return (
    <>
        <h4 className="text-center mb-2 display-6">
           {t("Télècharger les documents")}
          </h4>
          <div className="text-center">
            <img
              src={"img/bg-img/nodataa.png"}
              className="text-center"
              alt="design"
              width="150"
            />
          </div>
    </>
  )
}
