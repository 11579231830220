import React, { Fragment } from 'react'
import Paiement from '../contrats/components/paiement/Paiement'
import { useForm } from 'react-hook-form';

export default function Paiement_cotisation() {
    const { register, handleSubmit, reset, watch } = useForm(); //FORM DATA


    const  data= {}
    const  groupedAdresses= {}
    const  LISTE_ASSUREE= {}
    const t=""
    const type=""
  return (
    <Fragment>
        
        <Paiement


                              id={8}
                              handleSubmit={handleSubmit}
                              register={register}
                              data={data}
                              watch={watch}
                              groupedAdresses={groupedAdresses}
                              LISTE_ASSUREE={LISTE_ASSUREE}
                              type={type}
                              t={t}

                            />
    
    </Fragment>
  )
}
